import { normalize, schema } from 'normalizr';

import {
  getMutationTypes,
} from '@/store/mutation-types';
// eslint-disable-next-line import/no-cycle
import csite from '../services/csite';
import endpointDefinitions from '../services/csite/endpointDefinitions';

function getActionsFromEndpointAction(client, mutationTypes, action) {
  const actions = {};
  const actionNameCapitalized = action.mutationName.charAt(0).toUpperCase()
    + action.mutationName.slice(1);

  actions[`get${actionNameCapitalized}`] = async ({ commit }, id, data) => {
    commit(mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]);
    return client[action.name].get(id, data)
      .then((response) => {
        commit(mutationTypes[`${action.mutationName}_GET_MUTATION`], response.data);
        return response;
      })
      .catch((error) => {
        commit(mutationTypes[`${action.mutationName}_ERROR_MUTATION`]);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  actions[`post${actionNameCapitalized}`] = async ({ commit }, id, data) => {
    commit(mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]);
    return client[action.name].post(id, data)
      .then((response) => {
        commit(mutationTypes[`${action.mutationName}_POST_MUTATION`], response.data);
        return response;
      })
      .catch((error) => {
        commit(mutationTypes[`${action.mutationName}_ERROR_MUTATION`]);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  actions[`patch${actionNameCapitalized}`] = async ({ commit }, id, data) => {
    commit(mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]);
    return client[action.name].patch(id, data)
      .then((response) => {
        commit(mutationTypes[`${action.mutationName}_PATCH_MUTATION`], response.data);
        return response;
      })
      .catch((error) => {
        commit(mutationTypes[`${action.mutationName}_ERROR_MUTATION`]);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  actions[`put${actionNameCapitalized}`] = async ({ commit }, id, data) => {
    commit(mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]);
    return client[action.name].put(id, data)
      .then((response) => {
        commit(mutationTypes[`${action.mutationName}_PUT_MUTATION`], response.data);
        return response;
      })
      .catch((error) => {
        commit(mutationTypes[`${action.mutationName}_ERROR_MUTATION`]);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  actions[`delete${actionNameCapitalized}`] = async ({ commit }, id, data) => {
    commit(mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]);
    return client[action.name].delete(id, data)
      .then((response) => {
        commit(mutationTypes[`${action.mutationName}_DELETE_MUTATION`], response.data);
        return response;
      })
      .catch((error) => {
        commit(mutationTypes[`${action.mutationName}_ERROR_MUTATION`]);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };
  return actions;
}

function getActions(endpoint) {
  let actions = {};
  const nameCapitalized = endpoint.name.charAt(0).toUpperCase() + endpoint.name.slice(1);
  const dataSchema = new schema.Entity(endpoint.name);
  const dataListSchema = [dataSchema];
  const client = csite[endpoint.name];
  const mutationTypes = getMutationTypes(endpoint);

  // 'List' action requests a list of objects and adds the response to the store.
  actions[`list${nameCapitalized}`] = async ({ commit }, params) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.get({ params })
      .then((response) => {
        const { results: responseResults = response.data } = response.data;
        const normalizedData = normalize(responseResults, dataListSchema);
        const normData = normalizedData.entities[endpoint.name] || {};
        const dataList = normalizedData.result;
        commit(mutationTypes.LIST_SUCCESS_MUTATION, { dataList, normData });
        // In certain cases, we might need the response as well.
        return { dataList, normData, response };
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individually
        throw error;
      });
  };

  // 'Retrieve' action makes a get request to a detail resource and adds the response to the store.
  actions[`retrieve${nameCapitalized}`] = async ({ commit }, id, params) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.get(id, { params })
      .then((response) => {
        commit(mutationTypes.RETRIEVE_SUCCESS_MUTATION, response.data);
        return response.data;
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  // 'Create' action makes a post request and adds the response to the store.
  actions[`create${nameCapitalized}`] = async ({ commit }, data) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.post(data)
      .then((response) => {
        commit(mutationTypes.CREATE_SUCCESS_MUTATION, response.data);
        return response.data;
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  // 'Update' action makes a update request and adds the response to the store.
  actions[`update${nameCapitalized}`] = async ({ commit }, { id, data }) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.put(id, data)
      .then((response) => {
        commit(mutationTypes.UPDATE_SUCCESS_MUTATION, response.data);
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  // 'Patch' action makes an update request and adds the response to the store.
  actions[`patch${nameCapitalized}`] = async ({ commit }, { id, data }) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.patch(id, data)
      .then((response) => {
        commit(mutationTypes.UPDATE_SUCCESS_MUTATION, response.data);
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  // 'Destroy' action makes a delete request and adds the response to the store.
  actions[`destroy${nameCapitalized}`] = async ({ commit }, id) => {
    commit(mutationTypes.REQUEST_MUTATION);
    return client.delete(id)
      .then(() => {
        commit(mutationTypes.DESTROY_SUCCESS_MUTATION, id);
      })
      .catch((error) => {
        commit(mutationTypes.ERROR_MUTATION);
        // Raise an exception as each error should be handed individualy
        throw error;
      });
  };

  // Add the endpoint actions here.
  if (endpoint.actions !== undefined) {
    endpoint.actions.forEach((action) => {
      const endPointActions = getActionsFromEndpointAction(client, mutationTypes, action);
      actions = { ...actions, ...endPointActions };
    });
  }
  return actions;
}

export default endpointDefinitions.reduce(
  (accumulator, endpoint) => ({ ...accumulator, ...getActions(endpoint) }),
  {},
);
