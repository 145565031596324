import { render, staticRenderFns } from "./CameraTimeTravel.vue?vue&type=template&id=63b64d7a&scoped=true&"
import script from "./CameraTimeTravel.vue?vue&type=script&lang=js&"
export * from "./CameraTimeTravel.vue?vue&type=script&lang=js&"
import style0 from "./CameraTimeTravel.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./CameraTimeTravel.vue?vue&type=style&index=1&id=63b64d7a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63b64d7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VSelect,VSlider,VSubheader})
