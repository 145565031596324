<template>
  <div class="download">
    <i class="mi mi-cloud-download" v-if="!isDownloading"
       @click="onDownloadClickHandler"/>
    <v-progress-circular
      :indeterminate="indeterminate"
      :value="downloadingProgress"
      :rotate="-90"
      :size="50"
      color="#1654A2"
      v-else
    >{{ downloadingProgress }}</v-progress-circular>
  </div>
</template>

<script>
import { isNumber } from 'lodash';

export default {
  name: 'Download',
  emits: ['error'],
  props: {
    objectId: {
      type: Number,
      required: true,
    },
    downloadAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      indeterminate: true,
      isDownloading: false,
      downloadingProgress: null,
    };
  },
  watch: {
    isDownloading(newValue) {
      if (!newValue) {
        this.downloadingProgress = null;
      }
    },
  },
  methods: {
    onDownloadClickHandler() {
      if (this.isDownloading) {
        return;
      }

      this.isDownloading = true;
      const payload = {
        id: this.objectId,
        responseType: 'blob',
        onDownloadProgress: (e) => {
          const { total = null, loaded = null } = e;
          if (total && isNumber(total) && loaded && isNumber(loaded)) {
            this.downloadingProgress = Math.floor((e.loaded / e.total) * 100);
            this.indeterminate = false;
            return;
          }

          const loadedMb = loaded * 10 ** (-6);
          this.downloadingProgress = `${loadedMb.toFixed(1)}`;
        },
      };
      this.$store.dispatch(this.downloadAction, payload).then(({ data, headers }) => {
        const { 'x-file-name': filename, 'content-type': contentType } = headers;
        const blobOptions = contentType ? { type: contentType } : undefined;
        const url = window.URL.createObjectURL(new Blob([data], blobOptions));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }).catch((e) => {
        this.$emit('error', e);
      }).finally(() => {
        this.isDownloading = false;
        this.indeterminate = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.download {
  display: inline-block;

  .mi {
    cursor: pointer;
  }

  ::v-deep .v-progress-circular {
    width: 1em!important;
    height: 1em!important;

    .v-progress-circular__info {
      font-size: .35em;
    }
  }

  @media only screen and (max-width: 900px) {
    ::v-deep .v-progress-circular {
      width: .8em !important;
      height: .8em !important;

      .v-progress-circular__info {
        font-size: .35em;
      }
    }
  }
}
</style>
