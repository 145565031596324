const PERMISSIONS = {
  download_image: 'download_image',
  share_image: 'share_image',
  add_like: 'add_like',
  delete_like: 'delete_like',
  add_tag: 'add_tag',
  add_taggedimage: 'add_taggedimage',
  delete_taggedimage: 'delete_taggedimage',
  add_logo: 'add_logo',
  delete_logo: 'delete_logo',
  download_timelapse: 'download_timelapse',
  view_daily_timelapse: 'view_dailytimelapse',
  delete_daily_timelapse: 'delete_dailytimelapse',
  view_weekly_timelapse: 'view_weeklytimelapse',
  delete_weekly_timelapse: 'delete_weeklytimelapse',
  view_monthly_timelapse: 'view_monthlytimelapse',
  delete_monthly_timelapse: 'delete_monthlytimelapse',
  view_stp_timelapse: 'view_stptimelapse',
  delete_stp_timelapse: 'delete_stptimelapse',
  view_custom_timelapse: 'view_customtimelapse',
  delete_custom_timelapse: 'delete_customtimelapse',
  add_custom_timelapse: 'add_customtimelapse',
  view_livestream: 'view_livestream',
  view_image_area_identifier: 'view_imageareaidentifier',
  add_address: 'add_address',
  change_address: 'change_address',
  add_project: 'add_project',
  change_project: 'change_project',
  view_ziparchive: 'view_ziparchive',
  view_model_algorithm: 'view_modelalgorithm',
};

export default PERMISSIONS;
