const POPUP_TRUE_VALUE = 'true';

export const popupMixin = {
  data: () => ({
    $_popupMixin: {
      showPopup: true,
    },
  }),
  mounted() {
    if (localStorage.getItem(this.popupName) === POPUP_TRUE_VALUE) {
      this.$_popupMixin_showPopup = false;
    }
  },
  computed: {
    $_popupMixin_showPopup: {
      get() {
        return this.$data.$_popupMixin.showPopup;
      },
      set(newValue) {
        this.$data.$_popupMixin.showPopup = newValue;
      },
    },
  },
  methods: {
    $_popupMixin_closePopUp() {
      this.$data.$_popupMixin.showPopup = false;
      localStorage.setItem(this.popupName, POPUP_TRUE_VALUE);
    },
  },
};

export default popupMixin;
