<template>
  <div class="auth">
    <div class="auth__left-side">
      <picture>
        <img :src="logo" alt="C-SITE logo" />
      </picture>
    </div>
    <div class="auth__right-side">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
               v-for="(loginSliderImage, key) of loginSliderImages" :key="`login_image_${key}`">
            <picture>
              <img :src="loginSliderImage" alt="login_image_1" />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="language_switcher">
      <LanguageSwitcher />
    </div>
    <div class="auth__form">
      <router-view @apiError="onApiErrorHandler" @clearApiError="onClearApiErrorHandler"
                   @showPreloader="onShowPreloaderHandler">
        <div class="auth__form__error-text" v-if="error.isShow">
          {{ error.text }}
        </div>
      </router-view>
    </div>
    <FullscreenPreloader class="auth-form__fullscreen-preloader" :is-show="isShowLayoutPreloader"/>
    <CookiesPopup />
  </div>
</template>

<script>
import SwiperCore, { Autoplay, EffectFade } from 'swiper/core';
import { swiperMixin } from '@/mixins/v3';
import FullscreenPreloader from '@/components/v3/Helpers/FullscreenPreloader';
import CookiesPopup from '@/components/v3/Helpers/CookiesPopup';
import LanguageSwitcher from '@/components/v3/Helpers/LanguageSwitcher';

SwiperCore.use([Autoplay, EffectFade]);

const logo = require('@/assets/images/v3/c-site_logo.svg');

const loginPic1 = require('@/assets/images/v3/login/login_pic_1.jpeg');
const loginPic2 = require('@/assets/images/v3/login/login_pic_2.jpeg');
const loginPic3 = require('@/assets/images/v3/login/login_pic_3.jpeg');

export default {
  name: 'Auth',
  mixins: [swiperMixin],
  components: {
    LanguageSwitcher,
    FullscreenPreloader,
    CookiesPopup,
  },
  beforeMount() {
    const swiperOptions = {
      ...this.swiper.swiperOptions,
      allowTouchMove: true,
      effect: 'fade',
      speed: 1000,
      autoplay: {
        delay: 5000,
      },
    };
    this.swiper = { swiperOptions };
  },
  data: () => ({
    logo,
    loginSliderImages: [
      loginPic1,
      loginPic2,
      loginPic3,
    ],
    isShowLayoutPreloader: false,
    error: {
      isShow: false,
      text: null,
    },
  }),
  computed: {
    swiper: {
      get() {
        return this.$data.$_swiperMixin;
      },
      set(newValue) {
        this.$_swiperMixin_swiper = {
          ...this.$data.$_swiperMixin,
          ...newValue,
        };
      },
    },
  },
  methods: {
    onApiErrorHandler(e) {
      this.error = {
        ...this.error,
        isShow: true,
        text: e.message,
      };
    },
    onClearApiErrorHandler() {
      this.error = {
        ...this.error,
        isShow: false,
        text: null,
      };
    },
    onShowPreloaderHandler(e) {
      this.isShowLayoutPreloader = e.isShowLayoutPreloader;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .auth {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;

    font-family: $body-font-family;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-blue-ultra-dark-color;
    background-color: $default-background-color;
    width: 100%;
    height: 100%;

    .auth__left-side {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 0 0 30%;
      width: 30%;
      padding: 1.3vw 0 0 10vw;

      @media (orientation: portrait) {
        position: absolute;
        top: 2vh;
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        padding: 0;
        margin: 0;
        z-index: 10000000;
      }

      picture {
        position: relative;
        display: flex;
        width: 7vw;
        z-index: 100000;

        @media (orientation: portrait) {
          width: 17vw;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    .auth__right-side {
      flex: 0 0 70%;
      width: 70%;

      @media (orientation: portrait) {
        flex: 0 0 100%;
        width: 100%;
      }

      .swiper-container {
        width: 100%;
        height: 100%;

        .swiper-slide {
          picture {
            display: block;
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    .language_switcher {
      position: absolute;
      font-size: $default-font-size;
      top: 2vh;
      right: 1vw;
      z-index: 999;
      padding: 0;
      margin: 0;
    }

    .auth__form {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      bottom: 0;
      width: 47.2vw;
      height: 82.5vh;
      padding: 0 5.75vw 0 11.3vw;
      background-color: $default-light-color;
      z-index: 10000;

      @media (orientation: portrait) {
        left: 50%;
        bottom: 50%;
        transform: translateX(-50%) translateY(50%);
        width: 90%;
        height: auto;
        padding: 14vw 5vw;
        font-size: 2em;
      }

      .auth__form__error-text {
        position: absolute;
        top: -3vh;
        transform: translateY(-100%);
        font-size: 1.3em;
        color: $fail-color;
      }
    }
  }
</style>
