<template>
  <div class="ai">
    <ExitFullscreen />
    <Menu :class="{ cameras__menu__hidden: isFullScreen }" :menu-list="permissionMenuList"
          @menuHashInit="onTabChanged" @activeTabChanged="onTabChanged"
          class="ai__menu__mobile"
    />
    <component :is="activeComponent" v-bind="activeComponentProps" v-if="activeComponent" />
  </div>
</template>

<script>
import ExitFullscreen from '@/components/v3/Helpers/ExitFullscreen';
import Menu from '@/components/v3/TabsMenu';
import { filter, map, some } from 'lodash';
import PERMISSIONS from '@/constants/v3';
import PowerBiReport from '@/components/v3/AI/PowerBiReport';
import { mapState } from 'vuex';
import { permissionMixin } from '@/mixins/v3';
import AskTheMachine from '@/components/v3/AI/AskTheMachine';

export default {
  name: 'AI',
  mixins: [permissionMixin],
  components: {
    Menu,
    ExitFullscreen,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('my_csite_menu.tabs.questions'),
          code: 'questions',
          component: AskTheMachine,
          iconHtml: "<i class='mdi mdi-magnify mdi-rotate-90'></i>",
        },
        {
          name: this.$t('my_csite_menu.tabs.report'),
          code: 'reports',
          component: PowerBiReport,
          permissions: [PERMISSIONS.view_model_algorithm],
        },
      ],
      activeMenuTab: null,
    };
  },
  computed: {
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(
            menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission),
          )),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || {};
    },
    activeComponent() {
      return this.activeMenuTabForRender.component;
    },
    // activeComponentProps() {
    //   const props = this.activeMenuTabForRender?.props || {};
    //   props.searchBy = this.searchByProjectId;
    //   return props;
    // },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    onTabChanged(newTab) {
      this.activeMenuTab = newTab;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.cameras {
  width: 100%;

  .cameras__menu__hidden {
    display: none;
  }

  .cameras__menu__mobile {
    @media screen and (max-width: 500px) {
      font-size: 2.1em;
    }
    @media screen and (width > 500px) and (max-width: 900px) {
      font-size: 1.5em;
    }
  }
}
</style>
