import { omit } from 'lodash';
import { ACTION_TYPES } from '@/services/csite/endpointDefinitions';
// eslint-disable-next-line import/no-cycle
import csiteClient from './client';

class ResourceAction {
  constructor(base, name, type, methods) {
    this.base = base;
    this.name = name;
    this.type = type;
    this.methods = methods;
    this.base_url = `/${this.base}`;
  }

  getUrl(options) {
    let url = this.base_url;
    if (this.type === ACTION_TYPES.detail) {
      url = `${url}/${options.id}/${this.name}/`;
    } else {
      url = `${url}/${this.name}/`;
    }
    return url;
  }

  getAxiosOptions(options) {
    const excludeOptions = ['params'];
    if (this.type === ACTION_TYPES.detail) {
      excludeOptions.push('id');
    }
    return omit(options, excludeOptions);
  }

  get(options) {
    if (this.methods.indexOf('get') === -1) {
      throw new Error(`${this.base} ${this.name} has no action get`);
    }
    const url = this.getUrl(options);
    const { params = null } = options || {};
    const axiosOptions = this.getAxiosOptions(options);
    return csiteClient.get(url, { params, ...axiosOptions });
  }

  post(options) {
    if (this.methods.indexOf('post') === -1) {
      throw new Error(`${this.base} ${this.name} has no action post`);
    }
    const url = this.getUrl(options);
    const { params = null } = options || {};
    return csiteClient.post(url, params);
  }

  patch(options) {
    if (this.methods.indexOf('patch') === -1) {
      throw new Error(`${this.base} ${this.name} has no action patch`);
    }
    const url = this.getUrl(options);
    return csiteClient.patch(url, options);
  }

  put(options) {
    if (this.methods.indexOf('put') === -1) {
      throw new Error(`${this.base} ${this.name} has no action put`);
    }
    const url = this.getUrl(options);
    return csiteClient.put(url, options);
  }

  delete(options) {
    if (this.methods.indexOf('delete') === -1) {
      throw new Error(`${this.base} ${this.name} has no action delete`);
    }
    const url = this.getUrl(options);
    return csiteClient.delete(url, options);
  }
}

export default ResourceAction;
