<template>
  <div class="email-subscription">
    <v-text-field v-model="value" dense flat hide-details
                  :placeholder="$t(placeholder)" />
    <base-button
      :title="$t('footer.buttons.register')"
      skin="dark-blue" class="email-subscription__register-button"/>
  </div>
</template>

<script>
import BaseButton from '@/components/v3/BaseButton';

export default {
  name: 'NewsletterSubscription',
  components: {
    BaseButton,
  },
  props: {
    placeholder: {
      type: String,
      default: 'footer.placeholders.your_email',
    },
  },
  data() {
    return {
      value: null,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.email-subscription::v-deep {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  font-family: $main-font-family, "Material Design Icons", sans-serif;

  .v-input {
    font-family: $main-font-family, "Material Design Icons", sans-serif;
    font-size: 1em;
    padding-top: 0;
    border-bottom: 1px solid $default-blue-ultra-dark-color;

    .v-input__control {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .v-input__slot:after,
      .v-input__slot:before {
        content: none;
      }

      &.v-text-field {
        margin: 0;
      }

      .v-text-field__slot {
        color: $default-blue-ultra-dark-color;

        input {
          padding: 0;
          line-height: 1;
          color: $default-blue-ultra-dark-color;

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $default-blue-ultra-dark-color;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $default-blue-ultra-dark-color;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $default-blue-ultra-dark-color;
          }
        }
      }
    }
  }

  .email-subscription__register-button {
    width: 7.3vw;
    font-weight: 600;
    font-size: 1.2em;

    @media (orientation: portrait) {
      width: auto;
    }
  }
}
</style>
