<template>
  <router-link class="camera_detail_card_slideshow" event="" append ref="link"
               :to="{ path: getDetailUrl(projectView.project, projectView.id) }"
               @click.native="onClickHandler" :title="title"
               >
    <div class="camera_detail_card_slideshow__caption_line" :class="{'no-image': isNoImage}"
         @click.prevent="onCameraDetailCaptionLineClickHandler">
      <div class="camera_detail_card_slideshow__caption_line__left_side">
        <div>
          <i class="mdi mdi-autorenew camera_detail_card_slideshow__caption_line__icon"
          @click="onClickShowSelection"/>
        </div>
        <div class="camera_detail_card_slideshow__caption_line__project_name">
          {{ companyAndProjectName }}
        </div>
        <div class="camera_detail_card_slideshow__caption_line__camera_name">
          {{ projectView.name }}
        </div>
      </div>
      <div class="camera_detail_card_slideshow__caption_line__right_side">
        {{ imageDate }}
      </div>
    </div>
    <camera-picture :image-details="projectView.latest_image_details" :is-hide-all-actions="true"
                    height="100%" class="camera_detail_card_slideshow__camera_picture"
                    :fill-by-height="true" @wheelZoomChanged="onPicturesZoomChangeHandler" />
    <div class="camera_detail_card_slideshow__logo">
      <picture>
        <img :src="logo" alt="logo" />
      </picture>
    </div>
  </router-link>
</template>

<script>
import { findIndex } from 'lodash';
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import getDetailUrl from '@/helpers/menu';
import { IMAGE_DATE_FORMAT } from '@/constants/v3/cameras';
import moment from 'moment-timezone';
import fullscreenMixin from '@/mixins/v3/fullscreen/fullscreenMixin';

const logo = require('@/assets/images/dashboardNg/c-site_logo_white.svg');

export default {
  name: 'CameraDetailCardSlideShow',
  components: {
    CameraPicture,
  },
  mixins: [fullscreenMixin],
  emits: ['showCameraSelection'],
  props: {
    projectView: {
      type: Object,
      required: true,
    },
    followLink: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    logo,
    allowFollowLink: true,
  }),
  computed: {
    title() {
      return this.companyAndProjectName
        ? `${this.companyAndProjectName}(${this.projectView.name})` : this.projectView.name;
    },
    projectDetails() {
      return this.projectView.project_details || {};
    },
    projectName() {
      return this.projectDetails.name;
    },
    companyDetails() {
      return this.projectDetails.company_details || {};
    },
    companyName() {
      return this.companyDetails.name;
    },
    companyAndProjectName() {
      if (!this.projectName) {
        return null;
      }

      const companyName = this.companyName ? `${this.companyName} / ` : null;
      return `${companyName}${this.projectName}`;
    },
    imageDetails() {
      return this.projectView.latest_image_details || {};
    },
    isNoImage() {
      return !this.imageDetails.is_pending && !this.imageDetails.image;
    },
    imageDate() {
      const { date } = this.imageDetails;
      if (!date) {
        return null;
      }

      return moment(date).format(IMAGE_DATE_FORMAT);
    },
  },
  methods: {
    onClickShowSelection() {
      this.$emit('showCameraSelection');
    },
    getDetailUrl(projectId, projectViewId) {
      return getDetailUrl(projectId, projectViewId);
    },
    onClickHandler(e) {
      if (!this.followLink) {
        return;
      }
      // don't follow link if click on any object inside image component
      const isClickOnCameraPictureArea = findIndex(
        e.path,
        (item) => item.classList?.contains('camera-picture'),
      ) > -1;
      if (isClickOnCameraPictureArea && e.target.tagName !== 'IMG') {
        return;
      }
      if (this.isFullScreen) {
        this.$_fullscreenMixin_exitFullScreenClickHandler();
      }
      const path = this.$refs?.link?.to?.path;
      if (!path || !this.allowFollowLink) {
        return;
      }
      this.$router.push({ path });
    },
    onPicturesZoomChangeHandler(data) {
      if (data.zoom !== data.min) {
        this.allowFollowLink = false;
        return;
      }
      this.allowFollowLink = true;
    },
    onCameraDetailCaptionLineClickHandler(e) {
      e.preventDefault();
      e.stopPropagation();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .camera_detail_card_slideshow {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    color: inherit;
    height: 100%;

    &:visited,
    &:active,
    &:hover {
      text-decoration: none;
      color: inherit;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .camera_detail_card_slideshow__caption_line {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      top: 0;
      left: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 0.926vh 0.521vw;
      color: $default-white-color;
      background-color: rgba(4, 48, 103, 0.2);
      z-index: 1000000;

      &.no-image {
        background-color: rgba(4, 48, 103, 0.4);
      }

      @media (orientation: portrait) {
        font-size: .7em;
        padding: .3vh 0.521vw;
      }

      .camera_detail_card_slideshow__caption_line__left_side {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .camera_detail_card_slideshow__caption_line__icon {
          margin-right: 0.5vw;
          font-size: 1.5em;
        }

        .camera_detail_card_slideshow__caption_line__project_name {
          &:after {
            content: "-";
            margin: 0 .25vw;
          }
        }
      }
    }

    .camera_detail_card_slideshow__camera_picture {
      height: 100%;
    }

    .camera_detail_card_slideshow__logo {
      position: absolute;
      right: 1.2vw;
      bottom: 1.4vh;
      width: 3vw;

      picture {
        display: block;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
</style>
