<template>
  <div class="editor">
    <div class="editor__parameters">
      <div class="editor__parameters__parameter">
        <div class="editor__parameters__parameter__caption">
          1. {{ $t('timelapses_menu.custom_menu.titles.general') }}</div>
        <div class="editor__parameters__parameter__content
          editor__parameters__parameter__content_general">
          <input type="text" class="editor__parameters__parameter__content__name"
                 :class="{editor__parameters__parameter__content__name__alert: isShowNameAlert}"
                 name="timelapse_name"
                 :placeholder="$t('timelapses_menu.custom_menu.placeholders.name')"
                 v-model="timelaseName" />
          <div class="editor__parameters__parameter__content_general__camera">
            <v-select :items="projects.list" item-text="company_project_name" append-icon=""
                      placeholder="Site 󰅀" single-line v-model="projects.selected" hide-details
                      :loading="projects.isLoading" return-object />
            <v-select class="camera__projectview__select" :items="availableProjectViewsForProject"
                      item-text="name" item-value="id" append-icon="" placeholder="Camera 󰅀"
                      single-line hide-details return-object v-model="projectviews.selected"
                      :loading="projectviews.isLoading">
              <template v-slot:selection="{ item, parent }">
                <div class="camera__projectview__select__selected">
                  <picture>
                    <img :src="getImageSrc(item)" :alt="`selected_image_${item.id}`"
                         @load="getImageResolutionOptions"/>
                  </picture>
                  <div class="camera__projectview__select__selected__text">
                    {{ item[parent.itemText] }}
                  </div>
                </div>
              </template>
              <template v-slot:item="{ item, parent }">
                <div class="camera__projectview__select__item">
                  <picture>
                    <img :src="getImageSrc(item)" :alt="`image_${item.id}`" />
                  </picture>
                  <div class="camera__projectview__select__selected__text">
                    {{ item[parent.itemText] }}
                  </div>
                </div>
              </template>
            </v-select>
          </div>
        </div>
      </div>
      <div class="editor__parameters__parameter">
        <div class="editor__parameters__parameter__caption">
          2. {{ $t('timelapses_menu.custom_menu.titles.date') }}</div>
        <div class="editor__parameters__parameter__content">
          <div class="editor__parameters__parameter__content__calendars">
            <div class="editor__parameters__parameter__content__calendars__calendar">
              <input type="text" name="date_from"
                     :placeholder="$t('timelapses_menu.custom_menu.placeholders.start_date')"
                     :disabled="!from_datepicker.isActive"
                     @keydown.prevent="() => false"
                     id="editor__parameters__parameter__content__calendars__calendar__date_from" />
            </div>
            <div class="editor__parameters__parameter__content__calendars__calendar">
              <input type="text" name="date_from"
                     :placeholder="$t('timelapses_menu.custom_menu.placeholders.end_date')"
                     :disabled="!to_datepicker.isActive"
                     @keydown.prevent="() => false"
                     id="editor__parameters__parameter__content__calendars__calendar__date_to" />
            </div>
          </div>
          <div class="editor__parameters__parameter__content__checkboxes">
            <div class="editor__parameters__parameter__content__checkboxes__checkbox">
              <input type="checkbox" id="include_saturday" :checked="isIncludeSaturday"
                     @change="isIncludeSaturday = !isIncludeSaturday" />
              <label for="include_saturday" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.include_saturday') }}
              </div>
            </div>
            <div class="editor__parameters__parameter__content__checkboxes__checkbox">
              <input type="checkbox" id="include_sunday" :checked="isIncludeSunday"
                     @change="isIncludeSunday = !isIncludeSunday" />
              <label for="include_sunday" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.include_sunday') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editor__parameters__parameter editor__parameters__parameter__hour_range">
        <div class="editor__parameters__parameter__caption">
          3. {{ $t('timelapses_menu.custom_menu.titles.hour_range') }}</div>
        <div class="editor__parameters__parameter__content">
          <v-select :items="hour_range.list" item-text="text" item-value="value" append-icon=""
                    :placeholder="$t('timelapses_menu.custom_menu.placeholders.hour_range') +' 󰅀'"
          single-line v-model="activeHourRange"
                    hide-details />
          <div class="editor__parameters__parameter__hour_range__manual"
               :class="{ inactive: !isActiveManualHourRange }">
            <div class="editor__parameters__parameter__hour_range__manual__ranges">
              <div class="editor__parameters__parameter__hour_range__manual__ranges__range">
                <input type="text" name="manual_range_from" :disabled="!isActiveManualHourRange"
                       @keydown.prevent="() => false"
                       id="editor__parameters__parameter__hour_range__manual__ranges__range__from"/>
                <label for="editor__parameters__parameter__hour_range__manual__ranges__range__from">
                  {{ $t('timelapses_menu.custom_menu.other_text.from') }}
                </label>
              </div>
              <div class="editor__parameters__parameter__hour_range__manual__ranges__range">
                <input type="text" name="manual_range_to" :disabled="!isActiveManualHourRange"
                       @keydown.prevent="() => false"
                       id="editor__parameters__parameter__hour_range__manual__ranges__range__to"/>
                <label for="editor__parameters__parameter__hour_range__manual__ranges__range__to">
                  {{ $t('timelapses_menu.custom_menu.other_text.to') }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="editor__parameters__parameter">
        <div class="editor__parameters__parameter__caption">
          4. {{ $t('timelapses_menu.custom_menu.titles.duration') }}</div>
        <div class="editor__parameters__parameter__content">
          <div class="editor__parameters__parameter__content__description">
            <div>
              {{ $t('timelapses_menu.custom_menu.other_text.max_duration') }}
              {{ maxDurationFormatted }}</div>
            <div class="editor__parameters__parameter__content__description__error"
                 v-if="!!max_duration.error">{{ max_duration.error }}</div>
          </div>
          <div class="editor__parameters__parameter__content__duration">
            <label for="editor__parameters__parameter__content__duration__field">
              {{ $t('timelapses_menu.custom_menu.other_text.duration_in_seconds') }}
            </label>
            <input type="text" name="duration" v-model="duration.value"
                   :disabled="!duration.isActive" @keypress="onDurationKeyPress"
                   @change="onDurationChange"
                   id="editor__parameters__parameter__content__duration__field"/>
          </div>
        </div>
      </div>
      <div class="editor__parameters__parameter editor__parameters__parameter__optional">
        <div class="editor__parameters__parameter__caption">
          5. {{ $t('timelapses_menu.custom_menu.titles.optional') }}</div>
        <div class="editor__parameters__parameter__content">
          <div class="editor__parameters__parameter__content__checkboxes"
               style="margin-top: 0">
            <div class="editor__parameters__parameter__content__checkboxes__checkbox"
                 style="margin-bottom: 2vh;">
              <input type="checkbox" id="show_tmix" :checked="isShowTmix" @change="handleTmixChange"
                      />
              <label for="show_tmix" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.tmix') }}
              </div>
            </div>
          </div>
          <div class="editor__parameters__parameter__content__resolution">
            <label for="editor__parameters__parameter__content__resolution__field">
                {{ $t('timelapses_menu.custom_menu.titles.filters') }}
            </label>
            <v-select :items="filter_options.list" item-text="text" item-value="value"
                        append-icon=""
                        :placeholder="$t('timelapses_menu.custom_menu.placeholders.choose') + ' 󰅀'"
                        single-line
                        v-model="filter_options.selected"
                        hide-details id="editor__parameters__parameter__content__resolution__field"
                        class="editor__parameters__parameter__content__resolution__field_class"
                        :disabled="lackImage"
            />
          </div>
          <div v-if="filter_options.selected === 'enhance_ts'"
               class="editor__parameters__parameter__content__tiltshift">
            <div class="tilt-shift-slider-wrapper">
              <v-slider v-if="isShowTiltShift"
                          :label="$t('timelapses_menu.custom_menu.checkboxes.focus_height')"
                          horizontal :color="color" :track-color="trackColor"
                          :thumb-size="24" v-model="tsFocusHeight"
                          :step="1" :min="0" :max="imageHeight - tsDof / 2"
                          class="tilt-shift-slider">
              </v-slider>
              <v-slider v-if="isShowTiltShift"
                          horizontal :color="color" :track-color="trackColor"
                          :thumb-size="24"
                          :label="$t('timelapses_menu.custom_menu.checkboxes.dof')"
                          v-model="tsDof"
                          :step="1"
                          :min="5"
                          :max="0.7 * imageHeight"
                          class="tilt-shift-slider">
              </v-slider>
            </div>
            </div>
            <div class="editor__parameters__parameter__content__resolution">
              <label for="editor__parameters__parameter__content__resolution__field">
                {{ $t('timelapses_menu.custom_menu.other_text.resolution') }}
              </label>
              <v-select :items="resolution_options.list" item-text="text" item-value="value"
                        append-icon=""
                        :placeholder="$t('timelapses_menu.custom_menu.placeholders.choose') + ' 󰅀'"
                        single-line
                        v-model="resolution_options.selected"
                        hide-details id="editor__parameters__parameter__content__resolution__field"
                        class="editor__parameters__parameter__content__resolution__field_class"
              />
            </div>
          <div class="editor__parameters__parameter__content__checkboxes">
            <div class="editor__parameters__parameter__content__checkboxes__checkbox">
              <input type="checkbox" id="show_time" :checked="isShowTime" :disabled="isShowTmix"
                     @change="isShowTime = !isShowTime" />
              <label for="show_time" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.show_time') }}
              </div>
            </div>
            <div class="editor__parameters__parameter__content__checkboxes__checkbox">
              <input type="checkbox" id="show_project" :checked="isShowProject"
                     :disabled="isShowTmix"
                     @change="isShowProject = !isShowProject" />
              <label for="show_project" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.show_project') }}
              </div>
            </div>
            <div class="editor__parameters__parameter__content__checkboxes__checkbox">
              <input type="checkbox" id="show_camera" :checked="isShowCamera" :disabled="isShowTmix"
                     @change="isShowCamera = !isShowCamera" />
              <label for="show_camera" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.show_camera') }}
              </div>
            </div>
            <div class="editor__parameters__parameter__content__checkboxes__checkbox"
                 v-if="isAllowToAddLogo">
              <input type="checkbox" id="show_logo" :checked="isShowLogo"
                     @change="isShowLogo = !isShowLogo" :disabled="isShowTmix"/>
              <label for="show_logo" />
              <div class="editor__parameters__parameter__content__checkboxes__checkbox__title">
                {{ $t('timelapses_menu.custom_menu.checkboxes.show_logo') }}
              </div>
            </div>
          </div>
          <div class="editor__parameters__parameter__content__logo" v-if="isAllowToAddLogo">
            <input type="button" :value="$t('timelapses_menu.custom_menu.select_menus.upload_logo')"
                   :disabled="!isShowLogo || logo.newLogo.isUploading"
                   @click="onUploadLogoClickHandler"/>
            <v-file-input
              ref="upload_logo"
              class="editor__parameters__parameter__content__logo___file_input"
              :rules="logo.newLogo.rules"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              v-model="logo.newLogo.file"
            ></v-file-input>
            <v-select class="editor__parameters__parameter__content__logo__select"
                      :items="logo.library.list" item-value="id" append-icon=""
                      :placeholder="$t('timelapses_menu.custom_menu.placeholders.c_library') + ' 󰅀'"
                      single-line hide-details
                      v-model="logo.library.selected"
                      :disabled="!isShowLogo || logo.newLogo.isUploading"
                      :loading="logo.library.isLoading || logo.newLogo.isUploading">
              <template v-slot:selection="{ item }">
                <picture>
                  <img :src="item.logo" :alt="`logo_${item.id}`" />
                </picture>
              </template>
              <template v-slot:item="{ item }">
                <picture class="editor__parameters__parameter__content__logo__select__item">
                  <img :src="item.logo" :alt="`logo_${item.id}`" />
                </picture>
              </template>
            </v-select>
            <v-select class="editor__parameters__parameter__content__logo__position"
                      :items="logo.position.list" item-text="text" item-value="value" append-icon=""
                      :placeholder="$t('timelapses_menu.custom_menu.select_menus.' +
                       'logo_position_select.title') +' 󰅀'"
                      single-line hide-details
                      v-model="logo.position.selected" :disabled="!isShowLogo" />
            <div class="editor__parameters__parameter__content__logo__recommendation"
                 :class="{ disabled: !isShowLogo }">
              {{ $t('timelapses_menu.custom_menu.other_text.recommended_size') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="['editor__preview', { 'fixed-position': isFixed }]" ref="editorPreview">
      <img
        :src="noCameraSelected"
        alt="no camera selected"
        class="editor__preview__no-camera"
        v-if="!projectviews.selected">
      <EditorCameraPicture
        v-else-if="projectviews.selected
        && ['warm', 'cold', 'gotham'].includes(filter_options.selected)"
        class="editor__preview__camera_picture"
        :image-details="previewImage"
        :project-name="previewProjectName"
        :camera-name="previewCameraName"
        :is-show-time="isShowTime"
        :logo-url="previewLogo"
        :logo-position="logo.position.selected"
        :key="previewImage.id"
        ref="editorCanvasRef"
        :use-canvas="true"/>
      <EditorCameraPicture
        ref="editorCameraPictureRef"
        :image-details="previewImage"
        :project-name="previewProjectName"
        :camera-name="previewCameraName"
        :is-show-time="isShowTime"
        :logo-url="previewLogo"
        :logo-position="logo.position.selected"
        :key="previewImage.id"
        :apply-filter="filter_options.selected"
        :apply-dof="tsDof"
        :apply-focus-height="tsFocusHeight"
        :apply-tilt-shift="isShowTiltShift"
        @updateHeight="handleHeight"
        @initialImageSize="handleSize"
        @editorCameraPictureMounted="saveImageElement"
        class="editor__preview__camera_picture"
        v-else />
      <ButtonSubmit :title="$t('timelapses_menu.buttons.make_my_timelapse')"
                    :is-show-loading="isLoading"
                    :is-disabled="!isSubmitActive" @click="onSubmitClick"/>
      <div class="editor__preview__error" v-if="!!error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';

import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import EditorCameraPicture from '@/components/v3/Timelapses/MakeOwnTimelapse/EditorCameraPicture';
import { mapActions, mapState } from 'vuex';
import {
  sortBy, filter, map, every, isNumber, join, compact, isEmpty, find, toString, forEach, includes,
  flatMap, times,
} from 'lodash';
import moment from 'moment-timezone';
import { getErrorFromResponse } from '@/helpers';
import { DEFAULT_DATE_TIME_FORMAT, DEFAULT_TIME_FORMAT } from '@/constants/cameras';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import { MAX_LOGO_SIZE } from '@/constants/v3/timelapses';

const md5 = require('md5');

const MIN_TIMELAPSE_DURATION = 2; // seconds
const MAX_TIMELAPSE_DURATION = 600; // seconds
const DEFAULT_TIMELAPSE_DURATION = 60; // second
const DEFAULT_TIMELAPSE_FPS = 25; // per second
const DEFAULT_TIMELAPSE_START_BEFORE_END_DAYS = 7;
const FIXED_RANGE_TIME_UPDATE_TIMEOUT = 1000; // ms

const HOUR_RANGE_ALL = 'all';
const HOUR_RANGE_FIXED = 'fixed';
const HOUR_RANGE_SMART = 'smart';

const RESOLUTION_FULL_HD = '1080p';
const RESOLUTION_4MP = '1520p';
const RESOLUTION_8MP = '2160p';
const RESOLUTION_FULL_HD_VERTICAL = '1920p';
const RESOLUTION_4MP_VERTICAL = '2688p';
const RESOLUTION_8MP_VERTICAL = '3840p';

const FILTER_SEPIA_HC = 'sepia_noir';
const FILTER_GREYSCALE = 'greyscale';
const FILTER_ENHANCE = 'enhance';
const FILTER_ENHANCE_TS = 'enhance_ts';
const FILTER_WARM = 'warm';
const FILTER_COLD = 'cold';
const FILTER_GOTHAM = 'gotham';
const FILTER_NONE = 'none';

const noCameraImg = require('@/assets/images/dashboardNg/no_camera.svg');
const noCameraSelected = require('@/assets/images/dashboardNg/TLEditor-noimages.png');

export default {
  name: 'Editor',
  mixins: [permissionMixin],
  emits: ['scheduled'],
  components: {
    ButtonSubmit,
    EditorCameraPicture,
  },
  data() {
    return {
      noCameraSelected,
      isLoading: false,
      previewImage: {},
      initialImageSize: {},
      isShowTime: true,
      isShowProject: true,
      isShowCamera: true,
      isShowLogo: false,
      isShowTmix: false,
      isShowTiltShift: false,
      tsDof: 50,
      tsFocusHeight: 200,
      color: '#043067',
      trackColor: '#1654A299',
      imageHeight: 0,
      isIncludeSaturday: false,
      isIncludeSunday: false,
      timelaseName: null,
      projects: {
        isLoading: true,
        selected: null,
        list: [],
      },
      projectviews: {
        isLoading: true,
        selected: null,
        list: [],
      },
      filter_options: {
        selected: FILTER_NONE,
        list: [
          {
            text: 'None',
            value: FILTER_NONE,
          },
          {
            text: 'Slate',
            value: FILTER_GREYSCALE,
          },
          {
            text: 'Sandstone',
            value: FILTER_SEPIA_HC,
          },
          {
            text: 'Warm',
            value: FILTER_WARM,
          },
          {
            text: 'Cold',
            value: FILTER_COLD,
          },
          {
            text: 'Spark',
            value: FILTER_ENHANCE,
          },
          {
            text: 'Brick',
            value: FILTER_GOTHAM,
          },
          {
            text: 'Maquette',
            value: FILTER_ENHANCE_TS,
          },
        ],
      },
      hour_range: {
        selected: HOUR_RANGE_SMART,
        list: [
          {
            text: this.$t('timelapses_menu.custom_menu.select_menus.hour_range_select.'
              + 'automatic_daylight'),
            value: HOUR_RANGE_SMART,
          },
          {
            text: this.$t('timelapses_menu.custom_menu.select_menus.hour_range_select.manual'),
            value: HOUR_RANGE_FIXED,
          },
          {
            text: this.$t('timelapses_menu.custom_menu.select_menus.hour_range_select.all'),
            value: HOUR_RANGE_ALL,
          },
        ],
      },
      resolution_options: {
        selected: RESOLUTION_FULL_HD,
        list: [
          {
            text: 'Full HD',
            value: RESOLUTION_FULL_HD,
          },
        ],
      },
      logo: {
        newLogo: {
          file: null,
          isUploading: false,
          error: false,
          rules: [
            (value) => !value || value.size < MAX_LOGO_SIZE
              || this.$t('timelapses_menu.custom_menu.errors.size_to_big'),
          ],
        },
        library: {
          isLoading: false,
          selected: null,
          list: [],
        },
        position: {
          selected: null,
          list: [
            {
              text: this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.t_left'),
              value: 'top_left',
            },
            {
              text: this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.t_right'),
              value: 'top_right',
            },
            {
              text: this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.b_left'),
              value: 'bottom_left',
            },
            {
              text: this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.b_right'),
              value: 'bottom_right',
            },
          ],
        },
      },
      from_datepicker: {
        isActive: false,
        instance: null,
        error: null,
        value: null,
      },
      to_datepicker: {
        isActive: false,
        instance: null,
        error: null,
        value: null,
      },
      hour_range_from_datepicker: {
        instance: null,
        error: null,
        value: '06:00',
      },
      hour_range_to_datepicker: {
        instance: null,
        error: null,
        value: '18:00',
      },
      hourRangeTimeoutId: null,
      max_duration: {
        timeoutId: null,
        activeHash: null,
        hashList: {},
        error: null,
      },
      duration: {
        isActive: true,
        min: MIN_TIMELAPSE_DURATION,
        max: null,
        value: 60,
      },
      error: null,
      imageSize: null,
      isFixed: false,
      opencvReady: false,
      midtone_contrast_increase: [
        9.41602393e-02, 7.57248789e-01, 1.39938782e+00, 2.02149343e+00,
        2.62448172e+00, 3.20926878e+00, 3.77677071e+00, 4.32790362e+00,
        4.86358360e+00, 5.38472674e+00, 5.89224915e+00, 6.38706692e+00,
        6.87009616e+00, 7.34225296e+00, 7.80445341e+00, 8.25761362e+00,
        8.70264969e+00, 9.14047771e+00, 9.57201378e+00, 9.99817400e+00,
        1.04198745e+01, 1.08380313e+01, 1.12535605e+01, 1.16673783e+01,
        1.20804008e+01, 1.24935439e+01, 1.29077240e+01, 1.33238569e+01,
        1.37428589e+01, 1.41656460e+01, 1.45931343e+01, 1.50262400e+01,
        1.54658791e+01, 1.59129677e+01, 1.63684219e+01, 1.68331579e+01,
        1.73080917e+01, 1.77941393e+01, 1.82922170e+01, 1.88032409e+01,
        1.93281269e+01, 1.98677912e+01, 2.04231499e+01, 2.09951192e+01,
        2.15846150e+01, 2.21925536e+01, 2.28198509e+01, 2.34674232e+01,
        2.41361864e+01, 2.48270567e+01, 2.55409503e+01, 2.62787831e+01,
        2.70410888e+01, 2.78268712e+01, 2.86347515e+01, 2.94633511e+01,
        3.03112911e+01, 3.11771929e+01, 3.20596777e+01, 3.29573669e+01,
        3.38688816e+01, 3.47928432e+01, 3.57278729e+01, 3.66725920e+01,
        3.76256218e+01, 3.85855836e+01, 3.95510986e+01, 4.05207881e+01,
        4.14932734e+01, 4.24671758e+01, 4.34411165e+01, 4.44137168e+01,
        4.53835980e+01, 4.63493813e+01, 4.73096881e+01, 4.82631395e+01,
        4.92083570e+01, 5.01445869e+01, 5.10735767e+01, 5.19976988e+01,
        5.29193260e+01, 5.38408307e+01, 5.47645856e+01, 5.56929632e+01,
        5.66283361e+01, 5.75730768e+01, 5.85295580e+01, 5.95001522e+01,
        6.04872320e+01, 6.14931700e+01, 6.25203388e+01, 6.35711109e+01,
        6.46478589e+01, 6.57529554e+01, 6.68887730e+01, 6.80576842e+01,
        6.92620616e+01, 7.05042778e+01, 7.17867054e+01, 7.31117169e+01,
        7.44816850e+01, 7.58989822e+01, 7.73659810e+01, 7.88843240e+01,
        8.04527328e+01, 8.20691990e+01, 8.37317142e+01, 8.54382700e+01,
        8.71868580e+01, 8.89754698e+01, 9.08020969e+01, 9.26647309e+01,
        9.45613634e+01, 9.64899861e+01, 9.84485904e+01, 1.00435168e+02,
        1.02447710e+02, 1.04484209e+02, 1.06542656e+02, 1.08621043e+02,
        1.10717360e+02, 1.12829601e+02, 1.14955756e+02, 1.17093817e+02,
        1.19241775e+02, 1.21397622e+02, 1.23559350e+02, 1.25724951e+02,
        1.27892415e+02, 1.30059740e+02, 1.32224947e+02, 1.34386062e+02,
        1.36541111e+02, 1.38688119e+02, 1.40825114e+02, 1.42950121e+02,
        1.45061167e+02, 1.47156277e+02, 1.49233479e+02, 1.51290797e+02,
        1.53326258e+02, 1.55337888e+02, 1.57323714e+02, 1.59281761e+02,
        1.61210055e+02, 1.63106624e+02, 1.64969493e+02, 1.66796687e+02,
        1.68586234e+02, 1.70336160e+02, 1.72044490e+02, 1.73709250e+02,
        1.75328467e+02, 1.76900168e+02, 1.78423043e+02, 1.79898446e+02,
        1.81328397e+02, 1.82714914e+02, 1.84060017e+02, 1.85365724e+02,
        1.86634055e+02, 1.87867029e+02, 1.89066665e+02, 1.90234983e+02,
        1.91374000e+02, 1.92485737e+02, 1.93572213e+02, 1.94635446e+02,
        1.95677455e+02, 1.96700261e+02, 1.97705881e+02, 1.98696336e+02,
        1.99673644e+02, 2.00639824e+02, 2.01596895e+02, 2.02546877e+02,
        2.03491788e+02, 2.04433648e+02, 2.05374476e+02, 2.06315837e+02,
        2.07257480e+02, 2.08198699e+02, 2.09138790e+02, 2.10077048e+02,
        2.11012767e+02, 2.11945242e+02, 2.12873769e+02, 2.13797641e+02,
        2.14716155e+02, 2.15628605e+02, 2.16534285e+02, 2.17432491e+02,
        2.18322518e+02, 2.19203660e+02, 2.20075212e+02, 2.20936470e+02,
        2.21786728e+02, 2.22625281e+02, 2.23451423e+02, 2.24264451e+02,
        2.25063659e+02, 2.25848341e+02, 2.26617792e+02, 2.27371308e+02,
        2.28108184e+02, 2.28827923e+02, 2.29530871e+02, 2.30217582e+02,
        2.30888608e+02, 2.31544506e+02, 2.32185829e+02, 2.32813130e+02,
        2.33426965e+02, 2.34027887e+02, 2.34616450e+02, 2.35193208e+02,
        2.35758717e+02, 2.36313529e+02, 2.36858199e+02, 2.37393281e+02,
        2.37919329e+02, 2.38436898e+02, 2.38946540e+02, 2.39448812e+02,
        2.39944266e+02, 2.40433457e+02, 2.40916938e+02, 2.41395265e+02,
        2.41868991e+02, 2.42338670e+02, 2.42804857e+02, 2.43268105e+02,
        2.43728969e+02, 2.44188003e+02, 2.44645760e+02, 2.45102796e+02,
        2.45559664e+02, 2.46016918e+02, 2.46475113e+02, 2.46934802e+02,
        2.47396540e+02, 2.47860881e+02, 2.48328379e+02, 2.48799587e+02,
        2.49275061e+02, 2.49755355e+02, 2.50241022e+02, 2.50732616e+02,
        2.51230693e+02, 2.51735805e+02, 2.52248507e+02, 2.52769353e+02,
        2.53298897e+02, 2.53837694e+02, 2.54386298e+02, 2.54945262e+02,
      ],
      lowermids_increase: [
        1.50304749e-01, 1.31080448e+00, 2.44957754e+00, 3.56865611e+00,
        4.67007234e+00, 5.75585842e+00, 6.82804653e+00, 7.88866883e+00,
        8.93975750e+00, 9.98334471e+00, 1.10214626e+01, 1.20561435e+01,
        1.30894193e+01, 1.41233224e+01, 1.51598850e+01, 1.62011391e+01,
        1.72491170e+01, 1.83058508e+01, 1.93733727e+01, 2.04537149e+01,
        2.15489095e+01, 2.26609888e+01, 2.37919848e+01, 2.49439299e+01,
        2.61188561e+01, 2.73187956e+01, 2.85457806e+01, 2.98018433e+01,
        3.10890158e+01, 3.24093303e+01, 3.37648191e+01, 3.51575142e+01,
        3.65894478e+01, 3.80615529e+01, 3.95703652e+01, 4.11113214e+01,
        4.26798579e+01, 4.42714115e+01, 4.58814185e+01, 4.75053156e+01,
        4.91385394e+01, 5.07765264e+01, 5.24147131e+01, 5.40485362e+01,
        5.56734321e+01, 5.72848375e+01, 5.88781889e+01, 6.04489229e+01,
        6.19924760e+01, 6.35051597e+01, 6.49867850e+01, 6.64380379e+01,
        6.78596044e+01, 6.92521703e+01, 7.06164217e+01, 7.19530444e+01,
        7.32627245e+01, 7.45461478e+01, 7.58040003e+01, 7.70369679e+01,
        7.82457367e+01, 7.94309925e+01, 8.05934213e+01, 8.17337090e+01,
        8.28525417e+01, 8.39504743e+01, 8.50275391e+01, 8.60836373e+01,
        8.71186702e+01, 8.81325390e+01, 8.91251451e+01, 9.00963897e+01,
        9.10461741e+01, 9.19743997e+01, 9.28809676e+01, 9.37657792e+01,
        9.46287357e+01, 9.54697385e+01, 9.62886888e+01, 9.70854879e+01,
        9.78600371e+01, 9.86122376e+01, 9.93419908e+01, 1.00049198e+02,
        1.00733760e+02, 1.01395579e+02, 1.02034556e+02, 1.02650592e+02,
        1.03243588e+02, 1.03813446e+02, 1.04360066e+02, 1.04883351e+02,
        1.05383201e+02, 1.05859519e+02, 1.06312204e+02, 1.06741158e+02,
        1.07146284e+02, 1.07527950e+02, 1.07888403e+02, 1.08230360e+02,
        1.08556535e+02, 1.08869644e+02, 1.09172403e+02, 1.09467528e+02,
        1.09757733e+02, 1.10045734e+02, 1.10334247e+02, 1.10625988e+02,
        1.10923672e+02, 1.11230014e+02, 1.11547730e+02, 1.11879536e+02,
        1.12228147e+02, 1.12596279e+02, 1.12986648e+02, 1.13401968e+02,
        1.13844955e+02, 1.14318326e+02, 1.14824795e+02, 1.15367078e+02,
        1.15947891e+02, 1.16569949e+02, 1.17235968e+02, 1.17948663e+02,
        1.18710749e+02, 1.19524944e+02, 1.20393961e+02, 1.21320516e+02,
        1.22307326e+02, 1.23355662e+02, 1.24461025e+02, 1.25617470e+02,
        1.26819055e+02, 1.28059836e+02, 1.29333870e+02, 1.30635213e+02,
        1.31957922e+02, 1.33296054e+02, 1.34643665e+02, 1.35994812e+02,
        1.37343552e+02, 1.38683941e+02, 1.40010036e+02, 1.41315893e+02,
        1.42596766e+02, 1.43852694e+02, 1.45084912e+02, 1.46294658e+02,
        1.47483166e+02, 1.48651672e+02, 1.49801413e+02, 1.50933625e+02,
        1.52049542e+02, 1.53150401e+02, 1.54237438e+02, 1.55311889e+02,
        1.52049542e+02, 1.53150401e+02, 1.54237438e+02, 1.55311889e+02,
        1.56374990e+02, 1.57427976e+02, 1.58472084e+02, 1.59508548e+02,
        1.60538448e+02, 1.61562230e+02, 1.62580180e+02, 1.63592588e+02,
        1.64599742e+02, 1.65601929e+02, 1.66599438e+02, 1.67592557e+02,
        1.68581574e+02, 1.69566777e+02, 1.70548455e+02, 1.71526894e+02,
        1.72502384e+02, 1.73475213e+02, 1.74445668e+02, 1.75414037e+02,
        1.76380610e+02, 1.77345674e+02, 1.78309516e+02, 1.79272426e+02,
        1.80234691e+02, 1.81196599e+02, 1.82158439e+02, 1.83120498e+02,
        1.84083065e+02, 1.85046427e+02, 1.86010874e+02, 1.86976692e+02,
        1.87944171e+02, 1.88913598e+02, 1.89885261e+02, 1.90859448e+02,
        1.91836384e+02, 1.92816038e+02, 1.93798316e+02, 1.94783123e+02,
        1.95770364e+02, 1.96759945e+02, 1.97751772e+02, 1.98745750e+02,
        1.99741785e+02, 2.00739781e+02, 2.01739646e+02, 2.02741283e+02,
        2.03744600e+02, 2.04749500e+02, 2.05755890e+02, 2.06763676e+02,
        2.07772762e+02, 2.08783054e+02, 2.09794459e+02, 2.10806880e+02,
        2.11820225e+02, 2.12834397e+02, 2.13849304e+02, 2.14864850e+02,
        2.15880941e+02, 2.16897482e+02, 2.17914380e+02, 2.18931538e+02,
        2.19948864e+02, 2.20966262e+02, 2.21983638e+02, 2.23000898e+02,
        2.24017947e+02, 2.25034690e+02, 2.26051033e+02, 2.27066882e+02,
        2.28082142e+02, 2.29096719e+02, 2.30110517e+02, 2.31123444e+02,
        2.32135403e+02, 2.33146302e+02, 2.34156044e+02, 2.35164536e+02,
        2.36171684e+02, 2.37177392e+02, 2.38181566e+02, 2.39184112e+02,
        2.40184936e+02, 2.41183942e+02, 2.42181037e+02, 2.43176126e+02,
        2.44169113e+02, 2.45159906e+02, 2.46148409e+02, 2.47134529e+02,
        2.48118169e+02, 2.49099237e+02, 2.50077637e+02, 2.51053275e+02,
        2.52026056e+02, 2.52995887e+02, 2.53962672e+02, 2.54926317e+02,
      ],
      uppermids_decrease: [
        -0.27440589, 0.8349419, 1.93606131, 3.02916902,
        4.11448171, 5.19221607, 6.26258878, 7.32581654,
        8.38211602, 9.4317039, 10.47479689, 11.51161165,
        12.54236488, 13.56727326, 14.58655348, 15.60042221,
        16.60909616, 17.61279199, 18.6117264, 19.60611607,
        20.59617769, 21.58212793, 22.5641835, 23.54256107,
        24.51747732, 25.48914895, 26.45779263, 27.42362506,
        28.38686291, 29.34772288, 30.30642164, 31.26317589,
        32.2182023, 33.17171757, 34.12393838, 35.07508141,
        36.02536335, 36.97500088, 37.92421069, 38.87320947,
        39.82221389, 40.77144065, 41.72110643, 42.67142792,
        43.62262179, 44.57490474, 45.52849345, 46.4836046,
        47.44045489, 48.39926099, 49.36023959, 50.32360737,
        51.28958103, 52.25837724, 53.2302127, 54.20530408,
        55.18386807, 56.16612136, 57.15228063, 58.14256257,
        59.13718386, 60.13636118, 61.14031123, 62.14925069,
        63.16339624, 64.18288312, 65.20752081, 66.23703733,
        67.27116072, 68.30961899, 69.35214017, 70.3984523,
        71.44828341, 72.50136151, 73.55741463, 74.61617082,
        75.67735808, 76.74070445, 77.80593796, 78.87278663,
        79.9409785, 81.01024159, 82.08030392, 83.15089353,
        84.22173844, 85.29256668, 86.36310628, 87.43308527,
        88.50223167, 89.57027351, 90.63693881, 91.70195562,
        92.76505195, 93.82595583, 94.88439529, 95.94009835,
        96.99279305, 98.04220741, 99.08806947, 100.13010723,
        101.16804875, 102.20162203, 103.23055512, 104.25457604,
        105.27341281, 106.28679346, 107.29444602, 108.29609852,
        109.29147899, 110.28031545, 111.26233594, 112.23726847,
        113.20484107, 114.16478179, 115.11681863, 116.06067963,
        116.99609282, 117.92278623, 118.84048788, 119.74892579,
        120.64782801, 121.53692255, 122.41593744, 123.28460072,
        124.1426404, 124.98978451, 125.82576109, 126.65029817,
        127.46312376, 128.26407936, 129.05346033, 129.83167551,
        130.59913371, 131.35624376, 132.10341449, 132.84105472,
        133.56957329, 134.28937901, 135.00088072, 135.70448723,
        136.40060739, 137.08965, 137.7720239, 138.44813792,
        139.11840089, 139.78322162, 140.44300894, 141.09817169,
        141.74911868, 142.39625875, 143.04000071, 143.68075341,
        144.31892565, 144.95492628, 145.58916411, 146.22204797,
        146.85398669, 147.4853891, 148.11666402, 148.74822027,
        149.38046669, 150.0138121, 150.64866532, 151.28543519,
        151.92453053, 152.56636017, 153.21133292, 153.85985763,
        154.5123431, 155.16919819, 155.83083169, 156.49765246,
        157.1700693, 157.84849105, 158.53332653, 159.22498457,
        159.923874, 160.63040364, 161.34498232, 162.06801886,
        162.7999221, 163.54110085, 164.29196395, 165.05292022,
        165.82437848, 166.60674757, 167.4004363, 168.20585352,
        169.02340803, 169.85350868, 170.69656428, 171.55298366,
        172.42346792, 173.30988726, 174.21440414, 175.13918103,
        176.0863804, 177.05816471, 178.05669644, 179.08413805,
        180.14265201, 181.23440078, 182.36154683, 183.52625263,
        184.73068066, 185.97699336, 187.26735322, 188.6039227,
        189.98781908, 191.41597881, 192.88429319, 194.38865348,
        195.92495097, 197.48907694, 199.07692265, 200.68437939,
        202.30733844, 203.94169106, 205.58332854, 207.22814216,
        208.87202319, 210.51086291, 212.14055259, 213.75698351,
        215.35668483, 216.93873714, 218.50285893, 220.04876868,
        221.57618488, 223.084826, 224.57441053, 226.04465693,
        227.49528371, 228.92600933, 230.33655228, 231.72663103,
        233.09596408, 234.44426989, 235.77126695, 237.07667375,
        238.36020875, 239.62159045, 240.86053732, 242.07676785,
        243.27000051, 244.43995378, 245.58634615, 246.7088961,
        247.80732211, 248.88134265, 249.93067621, 250.95504128,
        251.95415632, 252.92773982, 253.87551027, 254.79718614,
      ],
      increaseTable: [
        7.32204295e-15, 1.03827895e+00, 2.08227359e+00, 3.13191257e+00,
        4.18712454e+00, 5.24783816e+00, 6.31398207e+00, 7.38548493e+00,
        8.46227539e+00, 9.54428209e+00, 1.06314337e+01, 1.17236588e+01,
        1.28208862e+01, 1.39230444e+01, 1.50300621e+01, 1.61418680e+01,
        1.72583906e+01, 1.83795587e+01, 1.95053010e+01, 2.06355459e+01,
        2.17702223e+01, 2.29092588e+01, 2.40525839e+01, 2.52001265e+01,
        2.63518150e+01, 2.75075782e+01, 2.86673447e+01, 2.98310432e+01,
        3.09986023e+01, 3.21699507e+01, 3.33450169e+01, 3.45237298e+01,
        3.57060179e+01, 3.68918098e+01, 3.80810343e+01, 3.92736199e+01,
        4.04694954e+01, 4.16685893e+01, 4.28708304e+01, 4.40761472e+01,
        4.52844685e+01, 4.64957228e+01, 4.77098389e+01, 4.89267454e+01,
        5.01463708e+01, 5.13686440e+01, 5.25934935e+01, 5.38208480e+01,
        5.50506362e+01, 5.62827866e+01, 5.75172279e+01, 5.87538889e+01,
        5.99926981e+01, 6.12335841e+01, 6.24764758e+01, 6.37213016e+01,
        6.49679902e+01, 6.62164704e+01, 6.74666707e+01, 6.87185198e+01,
        6.99719463e+01, 7.12268790e+01, 7.24832464e+01, 7.37409772e+01,
        7.50000000e+01, 7.62602435e+01, 7.75216365e+01, 7.87841074e+01,
        8.00475849e+01, 8.13119978e+01, 8.25772746e+01, 8.38433441e+01,
        8.51101348e+01, 8.63775754e+01, 8.76455946e+01, 8.89141209e+01,
        9.01830832e+01, 9.14524099e+01, 9.27220299e+01, 9.39918716e+01,
        9.52618638e+01, 9.65319352e+01, 9.78020143e+01, 9.90720298e+01,
        1.00341910e+02, 1.01611585e+02, 1.02880981e+02, 1.04150029e+02,
        1.05418657e+02, 1.06686792e+02, 1.07954365e+02, 1.09221303e+02,
        1.10487536e+02, 1.11752991e+02, 1.13017599e+02, 1.14281286e+02,
        1.15543982e+02, 1.16805616e+02, 1.18066116e+02, 1.19325411e+02,
        1.20583429e+02, 1.21840099e+02, 1.23095351e+02, 1.24349111e+02,
        1.25601310e+02, 1.26851875e+02, 1.28100736e+02, 1.29347821e+02,
        1.30593059e+02, 1.31836378e+02, 1.33077707e+02, 1.34316974e+02,
        1.35554109e+02, 1.36789040e+02, 1.38021696e+02, 1.39252004e+02,
        1.40479895e+02, 1.41705296e+02, 1.42928137e+02, 1.44148345e+02,
        1.45365850e+02, 1.46580580e+02, 1.47792463e+02, 1.49001429e+02,
        1.50207407e+02, 1.51410324e+02, 1.52610109e+02, 1.53806692e+02,
        1.55000000e+02, 1.56189963e+02, 1.57376508e+02, 1.58559566e+02,
        1.59739063e+02, 1.60914930e+02, 1.62087094e+02, 1.63255484e+02,
        1.64420030e+02, 1.65580659e+02, 1.66737300e+02, 1.67889882e+02,
        1.69038334e+02, 1.70182584e+02, 1.71322560e+02, 1.72458192e+02,
        1.73589408e+02, 1.74716137e+02, 1.75838308e+02, 1.76955848e+02,
        1.78068688e+02, 1.79176754e+02, 1.80279977e+02, 1.81378284e+02,
        1.82471605e+02, 1.83559868e+02, 1.84643001e+02, 1.85720934e+02,
        1.86793594e+02, 1.87860912e+02, 1.88922814e+02, 1.89979230e+02,
        1.91030089e+02, 1.92075319e+02, 1.93114849e+02, 1.94148608e+02,
        1.95176523e+02, 1.96198525e+02, 1.97214540e+02, 1.98224499e+02,
        1.99228330e+02, 2.00225961e+02, 2.01217321e+02, 2.02202339e+02,
        2.03180943e+02, 2.04153063e+02, 2.05118625e+02, 2.06077561e+02,
        2.07029797e+02, 2.07975263e+02, 2.08913887e+02, 2.09845598e+02,
        2.10770324e+02, 2.11687995e+02, 2.12598538e+02, 2.13501884e+02,
        2.14397959e+02, 2.15286693e+02, 2.16168015e+02, 2.17041852e+02,
        2.17908135e+02, 2.18766791e+02, 2.19617749e+02, 2.20460938e+02,
        2.21296286e+02, 2.22123722e+02, 2.22943175e+02, 2.23754573e+02,
        2.24557845e+02, 2.25352920e+02, 2.26139726e+02, 2.26918192e+02,
        2.27688246e+02, 2.28449818e+02, 2.29202836e+02, 2.29947228e+02,
        2.30682923e+02, 2.31409851e+02, 2.32127938e+02, 2.32837115e+02,
        2.33537310e+02, 2.34228451e+02, 2.34910468e+02, 2.35583288e+02,
        2.36246840e+02, 2.36901053e+02, 2.37545857e+02, 2.38181178e+02,
        2.38806947e+02, 2.39423091e+02, 2.40029539e+02, 2.40626221e+02,
        2.41213064e+02, 2.41789997e+02, 2.42356949e+02, 2.42913849e+02,
        2.43460625e+02, 2.43997206e+02, 2.44523520e+02, 2.45039496e+02,
        2.45545064e+02, 2.46040151e+02, 2.46524685e+02, 2.46998597e+02,
        2.47461814e+02, 2.47914265e+02, 2.48355879e+02, 2.48786584e+02,
        2.49206310e+02, 2.49614984e+02, 2.50012535e+02, 2.50398892e+02,
        2.50773984e+02, 2.51137739e+02, 2.51490086e+02, 2.51830954e+02,
        2.52160271e+02, 2.52477966e+02, 2.52783967e+02, 2.53078203e+02,
        2.53360604e+02, 2.53631097e+02, 2.53889610e+02, 2.54136074e+02,
        2.54370416e+02, 2.54592566e+02, 2.54802451e+02, 2.55000000e+02,
      ],
      decreaseTable: [
        -5.69492230e-15, 7.24142824e-01, 1.44669675e+00, 2.16770636e+00,
        2.88721627e+00, 3.60527107e+00, 4.32191535e+00, 5.03719372e+00,
        5.75115076e+00, 6.46383109e+00, 7.17527928e+00, 7.88553995e+00,
        8.59465768e+00, 9.30267708e+00, 1.00096427e+01, 1.07155993e+01,
        1.14205912e+01, 1.21246632e+01, 1.28278599e+01, 1.35302258e+01,
        1.42318056e+01, 1.49326438e+01, 1.56327850e+01, 1.63322739e+01,
        1.70311550e+01, 1.77294729e+01, 1.84272723e+01, 1.91245976e+01,
        1.98214936e+01, 2.05180048e+01, 2.12141758e+01, 2.19100513e+01,
        2.26056757e+01, 2.33010937e+01, 2.39963499e+01, 2.46914890e+01,
        2.53865554e+01, 2.60815938e+01, 2.67766488e+01, 2.74717650e+01,
        2.81669869e+01, 2.88623592e+01, 2.95579266e+01, 3.02537334e+01,
        3.09498245e+01, 3.16462443e+01, 3.23430375e+01, 3.30402486e+01,
        3.37379223e+01, 3.44361031e+01, 3.51348357e+01, 3.58341647e+01,
        3.65341346e+01, 3.72347900e+01, 3.79361755e+01, 3.86383358e+01,
        3.93413155e+01, 4.00451590e+01, 4.07499111e+01, 4.14556163e+01,
        4.21623192e+01, 4.28700644e+01, 4.35788966e+01, 4.42888602e+01,
        4.50000000e+01, 4.57123605e+01, 4.64259862e+01, 4.71409219e+01,
        4.78572120e+01, 4.85749013e+01, 4.92940342e+01, 5.00146554e+01,
        5.07368095e+01, 5.14605411e+01, 5.21858948e+01, 5.29129151e+01,
        5.36416467e+01, 5.43721342e+01, 5.51044221e+01, 5.58385551e+01,
        5.65745777e+01, 5.73125346e+01, 5.80524703e+01, 5.87944295e+01,
        5.95384568e+01, 6.02845966e+01, 6.10328938e+01, 6.17833927e+01,
        6.25361381e+01, 6.32911745e+01, 6.40485465e+01, 6.48082988e+01,
        6.55704759e+01, 6.63351224e+01, 6.71022829e+01, 6.78720020e+01,
        6.86443243e+01, 6.94192944e+01, 7.01969570e+01, 7.09773565e+01,
        7.17605376e+01, 7.25465449e+01, 7.33354231e+01, 7.41272165e+01,
        7.49219700e+01, 7.57197281e+01, 7.65205353e+01, 7.73244363e+01,
        7.81314757e+01, 7.89416980e+01, 7.97551479e+01, 8.05718700e+01,
        8.13919088e+01, 8.22153090e+01, 8.30421151e+01, 8.38723718e+01,
        8.47061236e+01, 8.55434151e+01, 8.63842910e+01, 8.72287959e+01,
        8.80769742e+01, 8.89288707e+01, 8.97845300e+01, 9.06439965e+01,
        9.15073150e+01, 9.23745300e+01, 9.32456861e+01, 9.41208279e+01,
        9.50000000e+01, 9.58832470e+01, 9.67706136e+01, 9.76621442e+01,
        9.85578835e+01, 9.94578761e+01, 1.00362167e+02, 1.01270800e+02,
        1.02183820e+02, 1.03101271e+02, 1.04023199e+02, 1.04949648e+02,
        1.05880663e+02, 1.06816287e+02, 1.07756566e+02, 1.08701545e+02,
        1.09651267e+02, 1.10605778e+02, 1.11565121e+02, 1.12529343e+02,
        1.13498486e+02, 1.14472597e+02, 1.15451719e+02, 1.16435897e+02,
        1.17425175e+02, 1.18419599e+02, 1.19419213e+02, 1.20424061e+02,
        1.21434188e+02, 1.22449639e+02, 1.23470458e+02, 1.24496689e+02,
        1.25528378e+02, 1.26565570e+02, 1.27608307e+02, 1.28656636e+02,
        1.29710601e+02, 1.30770246e+02, 1.31835616e+02, 1.32906756e+02,
        1.33983710e+02, 1.35066523e+02, 1.36155239e+02, 1.37249903e+02,
        1.38350559e+02, 1.39457253e+02, 1.40570029e+02, 1.41688931e+02,
        1.42814003e+02, 1.43945292e+02, 1.45082840e+02, 1.46226694e+02,
        1.47376896e+02, 1.48533493e+02, 1.49696528e+02, 1.50866047e+02,
        1.52042093e+02, 1.53224711e+02, 1.54413946e+02, 1.55609843e+02,
        1.56812446e+02, 1.58021800e+02, 1.59237949e+02, 1.60460938e+02,
        1.61690811e+02, 1.62927613e+02, 1.64171390e+02, 1.65422184e+02,
        1.66680042e+02, 1.67945006e+02, 1.69217123e+02, 1.70496437e+02,
        1.71782992e+02, 1.73076833e+02, 1.74378004e+02, 1.75686551e+02,
        1.77002517e+02, 1.78325947e+02, 1.79656886e+02, 1.80995379e+02,
        1.82341470e+02, 1.83695203e+02, 1.85056624e+02, 1.86425776e+02,
        1.87802705e+02, 1.89187455e+02, 1.90580071e+02, 1.91980597e+02,
        1.93389077e+02, 1.94805558e+02, 1.96230082e+02, 1.97662695e+02,
        1.99103441e+02, 2.00552364e+02, 2.02009511e+02, 2.03474924e+02,
        2.04948649e+02, 2.06430730e+02, 2.07921212e+02, 2.09420139e+02,
        2.10927556e+02, 2.12443508e+02, 2.13968039e+02, 2.15501194e+02,
        2.17043018e+02, 2.18593554e+02, 2.20152848e+02, 2.21720944e+02,
        2.23297886e+02, 2.24883721e+02, 2.26478491e+02, 2.28082241e+02,
        2.29695017e+02, 2.31316863e+02, 2.32947823e+02, 2.34587942e+02,
        2.36237264e+02, 2.37895835e+02, 2.39563698e+02, 2.41240899e+02,
        2.42927481e+02, 2.44623490e+02, 2.46328971e+02, 2.48043967e+02,
        2.49768523e+02, 2.51502684e+02, 2.53246495e+02, 2.55000000e+02,
      ],
      imageElement: null,
    };
  },
  beforeMount() {
    const scriptId = 'opencv-js';
    if (!document.getElementById(scriptId)) {
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://docs.opencv.org/4.x/opencv.js';
      script.async = true;
      script.onload = () => this.initializeOpenCV();
      document.head.appendChild(script);
    } else {
      this.initializeOpenCV();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.calculateFooterTop);
    this.calculateFooterTop();
    this.initCameras();
    this.$nextTick(() => {
      this.initCalendars();
    });
  },
  watch: {
    'filter_options.selected': function (newVal) {
      if (newVal === 'enhance_ts') {
        this.isShowTiltShift = true;
      } else if (['warm', 'cold', 'gotham'].includes(newVal)) {
        this.isShowTiltShift = false;
        this.startCanvas();
      } else {
        this.isShowTiltShift = false;
      }
    },
    selectedProject() {
      this.from_datepicker.instance.clear();
      this.to_datepicker.instance.clear();
      this.duration.max = null;
      [this.projectviews.selected = null] = this.availableProjectViewsForProject;
    },
    selectedProjectView(newProjectView) {
      this.filter_options.selected = 'none';
      if (!newProjectView || !newProjectView.latest_image_details) {
        this.previewImage = {};
        return;
      }
      this.initPreviewImage();
    },
    isShowLogo: {
      handler(newValue) {
        if (!this.isAllowToAddLogo) {
          return;
        }
        if (newValue && !this.logo.library.list.length) {
          this.logo.library.isLoading = true;
          const fetchLogosPayload = {
            user: this.user.id,
            limit: 10000,
            ordering: '-id',
          };
          this.fetchLogos(fetchLogosPayload).then((response) => {
            this.logo.library.list = map(
              response.normData,
              (logo) => ({ id: logo.id, logo: logo.logo }),
            );
          }).finally(() => {
            this.logo.library.isLoading = false;
          });
        }
      },
      immediate: true,
    },
    logoUploadFile() {
      if (this.logo.newLogo.error) {
        this.logo.newLogo.error = false;
        this.error = null;
      }
      if (!this.logoUploadFile) {
        return;
      }
      const { upload_logo: uploadLogo } = this.$refs;
      uploadLogo.validate();
      if (!uploadLogo.valid) {
        setTimeout(() => { [this.error] = uploadLogo.validations; }, 100);
        this.logo.newLogo.error = true;
        return;
      }
      this.uploadNewLogo();
    },
    activeHourRange() {
      this.updateMaxDuration();
    },
    isIncludeSaturday() {
      this.updateMaxDuration();
    },
    isIncludeSunday() {
      this.updateMaxDuration();
    },
  },
  computed: {
    lackImage() {
      if ('image' in this.previewImage) {
        return false;
      }
      return true;
    },
    isImageHeight() {
      return this.imageHeight;
    },
    activeHourRange: {
      get() {
        return this.hour_range.selected;
      },
      set(newValue) {
        this.hour_range.selected = newValue;
      },
    },
    maxDurationFormatted() {
      const mins = Math.floor(this.maxDuration / 60);
      const seconds = this.maxDuration - (mins * 60);

      const translate = (key, count) => {
        const translations = {
          minute: [this.$t('time.minute'), this.$t('time.minutes')],
          second: [this.$t('time.second'), this.$t('time.seconds')],
        };
        return translations[key][count > 1 ? 1 : 0];
      };
      let result = `${mins} ${translate('minute', mins)}`;
      if (seconds > 0) {
        result += ` ${seconds} ${translate('second', seconds)}`;
      }
      return `${result} (${this.maxDuration} ${this.$t('time.seconds')})`;
    },
    maxDuration() {
      return isNumber(this.duration.max) ? this.duration.max : MAX_TIMELAPSE_DURATION;
    },
    availableProjectViewsForProject() {
      if (!this.projects.selected) {
        return [];
      }
      return filter(
        this.projectviews.list,
        (projectviews) => projectviews.project === this.projects.selected.id,
      );
    },
    selectedProject() {
      return this.projects.selected;
    },
    selectedProjectView() {
      return this.projectviews.selected;
    },
    previewProjectName() {
      return (this.isShowProject && this.selectedProject?.name) || null;
    },
    previewCameraName() {
      return (this.isShowCamera && this.selectedProjectView?.name) || null;
    },
    previewLogo() {
      const { library: logoLibrary } = this.logo;
      if (!this.isShowLogo || !logoLibrary.selected) {
        return null;
      }
      const selectedLogo = find(
        this.logo.library.list,
        (logo) => logo.id === this.logo.library.selected,
      );
      return selectedLogo ? selectedLogo.logo : null;
    },
    logoUploadFile() {
      return this.logo.newLogo.file;
    },
    isJustNameNotFilled() {
      const project = this.projects.selected || {};
      const projectview = this.projectviews.selected || {};
      return !this.isLoading
        && project.id
        && projectview.id
        && this.isDatesActive
        && this.duration.isActive
        && this.isShowLogoValid;
    },
    isShowNameAlert() {
      return this.isJustNameNotFilled && !this.timelaseName;
    },
    isActiveManualHourRange() {
      return this.hour_range.selected === HOUR_RANGE_FIXED;
    },
    isShowLogoValid() {
      return !this.isShowLogo || (this.logo.library.selected && this.logo.position.selected);
    },
    isDatesActive() {
      return this.from_datepicker.isActive && this.to_datepicker.isActive;
    },
    isSubmitActive() {
      return this.isJustNameNotFilled && !!this.timelaseName;
    },
    isAllowToAddLogo() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_logo);
    },
    isAllowToCreateCustomTimelapse() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_custom_timelapse);
    },
    ...mapState({
      logos: (state) => state.logos,
      user: (state) => state.activeUser,
      allCompanies: (state) => state.extra.allCompanies,
    }),
  },
  methods: {
    handleTmixChange() {
      this.isShowTmix = !this.isShowTmix;
      if (this.isShowTmix) {
        this.isShowTime = false;
        this.isShowCamera = false;
        this.isShowProject = false;
        this.isShowLogo = false;
      } else {
        this.isShowTime = true;
        this.isShowCamera = true;
        this.isShowProject = true;
      }
    },
    handleSize(size) {
      this.initialImageSize = size;
    },
    handleHeight(height) {
      this.imageHeight = height;
    },
    calculateFooterTop() {
      const footer = document.querySelector('.footer');
      if (footer) {
        this.footerTop = footer.offsetTop;
      }
    },
    handleScroll() {
      const previewHeight = this.$refs.editorPreview.offsetHeight;
      const windowScrollTop = window.pageYOffset;
      const previewBottom = windowScrollTop + previewHeight;
      const spaceAbove = this.footerTop - 30;
      if (windowScrollTop > 300 && previewBottom < spaceAbove) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
    getImageResolutionOptions(event) {
      const image = event.target;
      this.imageSize = {
        width: image.naturalWidth,
        height: image.naturalHeight,
      };
      const resolutionOptions = [1080, 1520, 1920, 2160, 2688, 3840];
      this.resolution_options.list = [
        {
          text: 'Full HD',
          value: RESOLUTION_FULL_HD,
        },
      ];
      this.resolution_options.selected = RESOLUTION_FULL_HD;

      if (!resolutionOptions.includes(this.imageSize.height)) {
        return;
      }
      switch (this.imageSize.height) {
        case 1520:
          this.resolution_options.list.push({ text: '4MP', value: RESOLUTION_4MP });
          break;
        case 1920:
          this.resolution_options.list.push(
            {
              text: 'Full HD vertical', value: RESOLUTION_FULL_HD_VERTICAL,
            },
          );
          break;
        case 2160:
          this.resolution_options.list.push({ text: '8MP', value: RESOLUTION_8MP });
          break;
        case 2688:
          this.resolution_options.list.push(
            {
              text: '4MP vertical', value: RESOLUTION_4MP_VERTICAL,
            },
          );
          break;
        case 3840:
          this.resolution_options.list.push(
            {
              text: '8MP vertical', value: RESOLUTION_8MP_VERTICAL,
            },
          );
          break;
        default:
          break;
      }
    },
    getImageSrc({ latest_image_details: imageDetails = null }) {
      if (!imageDetails) {
        return noCameraImg;
      }
      return imageDetails.image;
    },
    toggleDateFieldsActive(state = null) {
      if (state === true || state === false) {
        this.to_datepicker.isActive = state;
        this.from_datepicker.isActive = state;
        return;
      }
      this.to_datepicker.isActive = !this.to_datepicker.isActive;
      this.from_datepicker.isActive = !this.from_datepicker.isActive;
    },
    getFormattedTimelapseStart() {
      /* eslint-disable camelcase */
      const projectViewDateCreated = this.projectviews?.selected?.date_created;
      const projectDateCreated = this.projects?.selected?.date_created;
      /* eslint-enable camelcase */
      const currentDate = this.from_datepicker.value || projectViewDateCreated || projectDateCreated
        || this.getTimelapseEnd().subtract(DEFAULT_TIMELAPSE_START_BEFORE_END_DAYS, 'd')
          .format(DEFAULT_DATE_TIME_FORMAT);
      return moment(currentDate).utc().format(DEFAULT_DATE_TIME_FORMAT);
    },
    getTimelapseEnd() {
      const currentDate = this.to_datepicker.value || moment().format(DEFAULT_DATE_TIME_FORMAT);
      return moment(currentDate).utc();
    },
    getFormattedTimelapseEnd() {
      return this.getTimelapseEnd().format(DEFAULT_DATE_TIME_FORMAT);
    },
    getFormattedFixedTime(time) {
      if (!time) {
        return null;
      }
      return moment(time, DEFAULT_TIME_FORMAT).utc().format(DEFAULT_TIME_FORMAT);
    },
    onSubmitClick() {
      if (!this.isAllowToCreateCustomTimelapse) {
        this.error = this.$t('timelapses_menu.custom_menu.errors.not_allowed_timelapse');
        return;
      }
      this.error = null;
      this.isLoading = true;
      const payload = {
        fps: DEFAULT_TIMELAPSE_FPS,
        resolution: this.resolution_options.selected,
        length: this.duration.value,
        start: this.getFormattedTimelapseStart(),
        end: this.getFormattedTimelapseEnd(),
        day_range: this.hour_range.selected,
        show_timestamps: this.isShowTime,
        show_project_name: this.isShowProject,
        show_projectview_name: this.isShowCamera,
        projectview: this.projectviews.selected.id,
        name: this.timelaseName,
        include_saturday: this.isIncludeSaturday,
        include_sunday: this.isIncludeSunday,
        tmix: this.isShowTmix,
      };
      if (this.isShowTiltShift) {
        const ratio = this.initialImageSize.height / this.imageHeight;
        payload.tilt_shift = true;
        payload.ts_dof = Math.round(Math.round(this.tsDof * ratio) / 2);
        payload.ts_focus_height = Math.round(this.tsFocusHeight * ratio);
      }
      const selectedFilter = this.filter_options.selected;
      if (selectedFilter !== FILTER_NONE) {
        payload[selectedFilter] = true;
      }
      if (this.hour_range.selected === HOUR_RANGE_FIXED) {
        payload.fixed_time_from = this.getFormattedFixedTime(this.hour_range_from_datepicker.value);
        payload.fixed_time_to = this.getFormattedFixedTime(this.hour_range_to_datepicker.value);
      }
      if (this.logo.library.selected && this.isAllowToAddLogo) {
        payload.user_logo = this.logo.library.selected;
        payload.logo_position = this.logo.position.selected;
      }
      this.createTimelapse(payload).then((response) => {
        this.$emit('scheduled', { data: response });
      }).catch((e) => {
        const { status } = e.response;
        if (status === 500) {
          this.error = this.$t('timelapses_menu.custom_menu.errors.error_500');
          return;
        }
        this.error = getErrorFromResponse(e);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    async initPreviewImage() {
      const { selected: selectedProjectview } = this.projectviews;
      if (!selectedProjectview.latest_image_details) {
        return;
      }

      this.updatePreviewImage({ is_pending: true, image: null });
      this.toggleDateFieldsActive(false);
      if (selectedProjectview.earliest_image) {
        const imageResponse = await this.getImages({ id__in: selectedProjectview.earliest_image });
        const {
          [selectedProjectview.earliest_image]: earliestImageResponse = null,
        } = imageResponse.normData;
        if (earliestImageResponse) {
          this.setStartDateFromImage(earliestImageResponse);
        }
        this.setEndDateFromImage(selectedProjectview.latest_image_details);
        this.updatePreviewImage({
          ...selectedProjectview.latest_image_details,
          is_pending: false,
        });
        this.toggleDateFieldsActive(true);
      }
    },
    async initCameras() {
      if (isEmpty(this.allCompanies)) {
        await this.fetchAllCompanies();
      }
      Promise.all([
        this.fetchProjects({ no_limit: true }).then((response) => {
          const projectsWithCompany = map(response.normData, (project) => {
            const company = this.allCompanies[project.company] || null;
            const projectAndCompany = company ? `${company.name} / ` : '';
            return {
              ...project,
              company_details: company,
              company_project_name: `${projectAndCompany}${project.name}`,
            };
          });
          this.projects = {
            ...this.projects,
            isLoading: false,
            list: sortBy(
              projectsWithCompany,
              (project) => [project.company_project_name.toLowerCase()],
            ),
          };
        }),
        this.fetchProjectViews({ no_limit: true }).then((response) => {
          this.projectviews = {
            ...this.projectviews,
            isLoading: false,
            list: sortBy(
              response.normData,
              (projectview) => projectview.name.toLowerCase(),
            ),
          };
        }),
      ]).finally(async () => {
        const imagesIdsToLoad = compact(map(
          this.projectviews.list,
          ({ latest_image: latestImage = null }) => latestImage,
        ));
        if (imagesIdsToLoad.length) {
          const batchSize = 100;
          const numberOfRequests = Math.ceil(imagesIdsToLoad.length / batchSize);
          const allResponses = await Promise.all(
            times(numberOfRequests, async (index) => {
              const start = index * batchSize;
              const end = Math.min((index + 1) * batchSize, imagesIdsToLoad.length);
              const batchIds = imagesIdsToLoad.slice(start, end);
              const batchResponse = await this.getImages({
                id__in: join(batchIds),
                limit: batchIds.length,
              });
              return batchResponse;
            }),
          );
          const imagesResponse = {
            dataList: flatMap(allResponses, (response) => response.dataList),
            normData: Object.assign({}, ...allResponses.map((response) => response.normData)),
          };
          forEach(this.projectviews.list, (projectview) => {
            const { latest_image: latestImage = null } = projectview;
            if (!latestImage || !includes(imagesResponse.dataList, latestImage)) {
              return;
            }
            projectview.latest_image_details = imagesResponse.normData[latestImage];
          });
        }
        const [selected = null] = this.projects.list;
        this.projects = {
          ...this.projects,
          selected,
          isLoading: false,
        };
        this.projectviews = {
          ...this.projectviews,
          isLoading: false,
        };
      });
    },
    initCalendars() {
      const formDatepickerId = 'editor__parameters__parameter__content__calendars__calendar__date_from';
      const toDatepickerId = 'editor__parameters__parameter__content__calendars__calendar__date_to';

      const clearButton = {
        content: this.$t('timelapses_menu.buttons.clear'),
        onClick: (dp) => {
          dp.clear();
          if (dp.$el.classList.contains(formDatepickerId)) {
            this.from_datepicker.value = null;
          } else if (dp.$el.classList.contains(toDatepickerId)) {
            this.to_datepicker.value = null;
          }
        },
      };

      this.from_datepicker.instance = new AirDatepicker(
        `#${formDatepickerId}`,
        {
          locale: localeEn,
          position: 'bottom center',
          timepicker: true,
          dateFormat: 'dd/MM/yyyy',
          timeFormat: 'HH:mm',
          buttons: [clearButton],
          onSelect: (dp) => {
            if (!dp.date) {
              this.from_datepicker.value = null;
              return;
            }
            this.from_datepicker.value = moment(dp.date).format(DEFAULT_DATE_TIME_FORMAT);
            this.to_datepicker.instance.update({
              minDate: dp.date,
            });
            this.updateMaxDuration();
          },
        },
      );
      this.to_datepicker.instance = new AirDatepicker(
        `#${toDatepickerId}`,
        {
          locale: localeEn,
          position: 'bottom center',
          timepicker: true,
          dateFormat: 'dd/MM/yyyy',
          timeFormat: 'HH:mm',
          buttons: [clearButton],
          onSelect: (dp) => {
            if (!dp.date) {
              this.to_datepicker.value = null;
              return;
            }
            this.to_datepicker.value = moment(dp.date).format(DEFAULT_DATE_TIME_FORMAT);
            this.from_datepicker.instance.update({
              maxDate: dp.date,
            });
            this.updateMaxDuration();
          },
        },
      );
      this.hour_range_from_datepicker.instance = new AirDatepicker(
        '#editor__parameters__parameter__hour_range__manual__ranges__range__from',
        {
          locale: localeEn,
          position: 'bottom center',
          timepicker: true,
          onlyTimepicker: true,
          selectedDates: [new Date().setHours(6, 0, 0)],
          timeFormat: 'HH:mm',
          onShow: () => {
            if (!this.hour_range_to_datepicker.instance.visible) {
              return;
            }
            this.hour_range_to_datepicker.instance.hide();
          },
          onSelect: (dp) => {
            clearTimeout(this.hourRangeTimeoutId);
            const { date, formattedDate } = dp;
            this.hour_range_to_datepicker.instance.update({
              minHours: date.getHours(),
              minMinutes: date.getMinutes(),
            });
            this.hour_range_from_datepicker.value = formattedDate;
            this.hourRangeTimeoutId = setTimeout(
              this.updateMaxDuration,
              FIXED_RANGE_TIME_UPDATE_TIMEOUT,
            );
          },
        },
      );
      this.hour_range_to_datepicker.instance = new AirDatepicker(
        '#editor__parameters__parameter__hour_range__manual__ranges__range__to',
        {
          locale: localeEn,
          position: 'bottom center',
          timepicker: true,
          onlyTimepicker: true,
          selectedDates: [new Date().setHours(18, 0, 0)],
          timeFormat: 'HH:mm',
          onShow: () => {
            if (!this.hour_range_from_datepicker.instance.visible) {
              return;
            }
            this.hour_range_from_datepicker.instance.hide();
          },
          onSelect: (dp) => {
            clearTimeout(this.hourRangeTimeoutId);
            const { formattedDate } = dp;
            this.hour_range_to_datepicker.value = formattedDate;
            this.hourRangeTimeoutId = setTimeout(
              this.updateMaxDuration,
              FIXED_RANGE_TIME_UPDATE_TIMEOUT,
            );
          },
        },
      );
    },
    updatePreviewImage(data) {
      this.previewImage = {
        ...this.previewImage,
        ...data,
      };
    },
    async startCanvas() {
      if (!this.previewImage) {
        return;
      }
      const fakeImage = await this.getFakeImage();
      fakeImage.addEventListener('error', () => {
        alert('An error occurred');
      });
      fakeImage.addEventListener('load', () => {
        fakeImage.remove();
      });
    },
    initializeOpenCV() {
      if (typeof cv === 'undefined') {
        return;
      }
      if (!cv.onRuntimeInitialized) {
        cv.onRuntimeInitialized = () => {
          this.opencvReady = true;
        };
      }
    },
    saveImageElement() {
      this.imageElement = this.$refs.editorCameraPictureRef.$refs.refCameraPicture.$refs.image;
    },
    async getFakeImage() {
      const imageTag = this.imageElement;
      if (this.$refs.editorCanvasRef) {
        const canvasElement = this.$refs.editorCanvasRef.$refs.canvasPreview;
        imageTag.width = canvasElement.width;
        imageTag.height = canvasElement.height;
      }
      const createFakeImage = (imgElement) => {
        const fakeImage = new Image();
        fakeImage.crossOrigin = 'anonymous';
        fakeImage.src = `${this.previewImage.image}&not_from_cache_please`;
        fakeImage.onload = () => {
          if (this.filter_options.selected === 'warm') {
            this.applyWarmFilter(fakeImage, 'outputCanvas');
          } else if (this.filter_options.selected === 'cold') {
            this.applyColdFilter(fakeImage, 'outputCanvas');
          } else if (this.filter_options.selected === 'gotham') {
            this.applyGothamFilter(fakeImage, 'outputCanvas');
          }
        };
        fakeImage.width = imgElement.width;
        fakeImage.height = imgElement.height;
        return fakeImage;
      };
      return new Promise((resolve, reject) => {
        if (imageTag.width > 0) {
          resolve(createFakeImage(imageTag));
          return;
        }
        imageTag.addEventListener('error', () => {
          reject(new Error('Original image loading error!'));
        });
        imageTag.addEventListener('load', () => {
          resolve(createFakeImage(imageTag));
        });
      });
    },
    applyLut(channel, table) {
      const lut = new Uint8Array(256);
      for (let i = 0; i < lut.length; i += 1) {
        lut[i] = Math.min(255, Math.max(0, Math.round(table[i])));
      }
      for (let y = 0; y < channel.rows; y += 1) {
        for (let x = 0; x < channel.cols; x += 1) {
          const pixelValue = channel.ucharPtr(y, x)[0];
          channel.ucharPtr(y, x)[0] = lut[pixelValue];
        }
      }
    },
    createSetUp(src) {
      const dst = new cv.Mat();
      const rgbaPlanes = new cv.MatVector();
      cv.split(src, rgbaPlanes);
      const R = rgbaPlanes.get(0);
      const G = rgbaPlanes.get(1);
      const B = rgbaPlanes.get(2);
      return [dst, rgbaPlanes, R, G, B];
    },
    setAndDelete(src, rgbaPlanes, dst, R, G, B, canvas) {
      rgbaPlanes.set(0, R);
      rgbaPlanes.set(1, G);
      rgbaPlanes.set(2, B);
      cv.merge(rgbaPlanes, dst);
      cv.imshow(canvas, dst);
      src.delete();
      B.delete();
      G.delete();
      R.delete();
      rgbaPlanes.delete();
      dst.delete();
    },
    applyWarmFilter(imageElement, canvasId) {
      const canvas = document.getElementById(canvasId);
      if (canvas && cv && imageElement) {
        const src = cv.imread(imageElement);
        const [dst, rgbaPlanes, R, G, B] = this.createSetUp(src);
        this.applyLut(R, this.increaseTable);
        this.applyLut(B, this.decreaseTable);
        this.setAndDelete(src, rgbaPlanes, dst, R, G, B, canvas);
      }
    },
    applyColdFilter(imageElement, canvasId) {
      const canvas = document.getElementById(canvasId);
      if (canvas && cv && imageElement) {
        const src = cv.imread(imageElement);
        const [dst, rgbaPlanes, R, G, B] = this.createSetUp(src);
        this.applyLut(B, this.increaseTable);
        this.applyLut(R, this.decreaseTable);
        this.setAndDelete(src, rgbaPlanes, dst, R, G, B, canvas);
      }
    },
    applyGothamFilter(imageElement, canvasId) {
      const canvas = document.getElementById(canvasId);
      if (canvas && cv && imageElement) {
        const src = cv.imread(imageElement);
        const [dst, rgbaPlanes, R, G, B] = this.createSetUp(src);
        this.applyLut(R, this.midtone_contrast_increase);
        this.applyLut(B, this.lowermids_increase);
        this.applyLut(B, this.uppermids_decrease);
        this.setAndDelete(src, rgbaPlanes, dst, R, G, B, canvas);
      }
    },
    setEndDateFromImage({ date: imageDate }) {
      let imageDateObj = Date.parse(imageDate);
      this.setMaxDates(imageDateObj);

      if (!imageDateObj) {
        imageDateObj = Date();
      }
      this.to_datepicker.instance.selectDate(imageDateObj);
    },
    setStartDateFromImage({ date: imageDate }) {
      let imageDateObj = Date.parse(imageDate);
      this.setMinDates(imageDateObj);

      if (!imageDateObj) {
        imageDateObj = Date.parse(this.getFormattedTimelapseStart());
      }
      this.from_datepicker.instance.selectDate(imageDateObj);
    },
    setMinDates(date) {
      if (!date) {
        return;
      }
      this.from_datepicker.instance.update({
        minDate: date,
      });
      this.to_datepicker.instance.update({
        minDate: date,
      });
    },
    setMaxDates(date) {
      if (!date) {
        return;
      }
      this.from_datepicker.instance.update({
        maxDate: date,
      });
      this.to_datepicker.instance.update({
        maxDate: date,
      });
    },
    updateMaxDuration() {
      let payload = {
        fps: DEFAULT_TIMELAPSE_FPS,
        start_date: this.from_datepicker.value,
        end_date: this.to_datepicker.value,
        day_range: this.hour_range.selected,
        projectview: this.projectviews.selected?.id,
      };
      const isPayloadValid = every(payload, (item) => !!item);
      if (!isPayloadValid) {
        return;
      }

      payload = {
        ...payload,
        include_saturday: this.isIncludeSaturday,
        include_sunday: this.isIncludeSunday,
        fixed_time_from: this.getFormattedFixedTime(this.hour_range_from_datepicker.value),
        fixed_time_to: this.getFormattedFixedTime(this.hour_range_to_datepicker.value),
      };
      const payloadHash = md5(JSON.stringify(payload));
      this.max_duration.activeHash = payloadHash;
      this.duration.isActive = false;
      this.max_duration.error = null;

      if (payloadHash in this.max_duration.hashList) {
        this.setMaxDuration(this.max_duration.hashList[payloadHash]);
        return;
      }

      this.getMaxDuration(payload).then((response) => {
        this.max_duration.hashList[payloadHash] = response;

        // if we activate another request while current request hadn't been finished
        if (this.max_duration.activeHash !== payloadHash) {
          return;
        }
        this.setMaxDuration(this.max_duration.hashList[payloadHash]);
      }).catch(() => {
        this.duration.max = null;
        this.setMaxDuration({ max_duration: 0 });
        this.duration.isActive = true;
      });
    },
    setMaxDuration({ max_duration: maxDuration }) {
      this.duration.max = maxDuration < MAX_TIMELAPSE_DURATION
        ? maxDuration : MAX_TIMELAPSE_DURATION;
      if (this.duration.value === 0) {
        this.duration.value = DEFAULT_TIMELAPSE_DURATION;
      }
      if (maxDuration < this.duration.value) {
        this.duration.value = maxDuration;
      }
      if (maxDuration < MIN_TIMELAPSE_DURATION) {
        this.max_duration.error = this.$tc(
          'components.custom_timelapse.composer.form.errors.not_enough_images',
          MIN_TIMELAPSE_DURATION,
        );
        return;
      }
      this.duration.isActive = true;
    },
    uploadNewLogo() {
      if (!this.isAllowToAddLogo) {
        this.error = this.$t('timelapses_menu.custom_menu.errors.not_add_logo');
        return;
      }
      this.logo.newLogo.isUploading = true;
      const form = new FormData();

      form.append('user', this.user.id);
      form.append('logo', this.logo.newLogo.file, this.logo.newLogo.file.name);

      this.uploadLogo(form).then((response) => {
        this.logo.newLogo = {
          ...this.newLogo,
          file: null,
          error: null,
        };
        const { id, logo } = response;
        this.logo.library.list.push({ id, logo });
        this.logo.library.selected = this.logo.library.list.at(-1).id;
      }).catch((error) => {
        const errorData = error?.response?.data;
        if (!errorData) {
          this.error = error;
          return;
        }
        this.error = toString(map(errorData, (errorList) => toString(errorList)));
        this.logo.newLogo.error = true;
      }).finally(() => {
        this.logo.newLogo.isUploading = false;
      });
    },
    onDurationKeyPress(e) {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        e.preventDefault();
      }
    },
    onDurationChange() {
      if (this.duration.value < MIN_TIMELAPSE_DURATION) {
        this.duration.value = MIN_TIMELAPSE_DURATION;
      } else if (this.duration.value > this.maxDuration) {
        this.duration.value = this.maxDuration;
      }
    },
    onUploadLogoClickHandler() {
      const { upload_logo: uploadLogoComponent } = this.$refs;
      const fileInput = uploadLogoComponent
        && uploadLogoComponent.$el.querySelector('input[type=file]');
      if (!uploadLogoComponent || !fileInput) {
        this.error = this.$t('timelapses_menu.custom_menu.errors.please_reload');
        return;
      }
      if (this.logo.newLogo.error) {
        this.error = null;
        this.logo.newLogo.error = false;
      }
      fileInput.click();
    },
    ...mapActions({
      fetchAllCompanies: 'fetchAllCompanies',
      fetchProjects: 'listProjects',
      fetchProjectViews: 'listProjectviews',
      getImages: 'listImages',
      createTimelapse: 'createCustomtimelapses',
      fetchLogos: 'listLogos',
      getMaxDuration: 'fetchMaxDuration',
      uploadLogo: 'createLogos',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.calculateFooterTop);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .editor {
    display: flex;
    flex-flow: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .fixed-position {
      position: fixed;
      top: 10vh;
      right: 13vw;
      width: 37vw;
      z-index: 1000;

      @media only screen and (orientation: portrait){
        position: static;
        width: 100%;
      }
      @media (max-height: 900px) {
        position: static;
        width: 100%;
      }
    }
    @media (orientation: portrait) {
      flex-flow: column;
    }
    @media only screen and (orientation: landscape) and (max-width: 900px){
      flex-flow: column;
    }

    @media only screen and (max-width: 900px) {
      font-size: 1.6em;
    }

    > div {
      width: 50%;

      @media (orientation: portrait) {
        width: 100%;
      }
      @media only screen and (orientation: landscape) and (max-height: 900px){
        width: 100%;
      }

      .editor__parameters__parameter {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: flex-end;
        margin-bottom: 5vh;

        &:last-child {
          margin-bottom: 0;
        }

        .editor__parameters__parameter__caption {
          position: relative;
          font-size: 1.75em;
          padding-bottom: 1.6vh;
          margin-bottom: 3.7vh;

          @media (orientation: portrait) {
            margin-bottom: 1.5vh;
          }
          @media only screen and (max-width: 450px) {
            margin-bottom: 2.5vh;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 3.8vw;
            height: 1px;
            background-color: $default-blue-ultra-dark-color;
          }
        }

        .editor__parameters__parameter__content {
          width: 70%;

          @media (orientation: portrait) {
            width: 100%;
          }
          @media only screen and (max-width: 450px) {
            width: 100%;
          }
          @media screen and (max-width: 900px) {
            font-size: 1.4em;
          }

          .editor__parameters__parameter__content__logo {
            display: flex;
            flex-flow: wrap;

            > * {
              margin-right: .5vw;

              &:last-child {
                margin-right: 0;
              }
            }

            input[type=button] {
              border: 1px solid $default-blue-ultra-dark-color;
              width: calc(50% - 0.5vw);
              margin-right: .5vw;
              line-height: 1.3;

              @media (orientation: portrait) {
                width: 30vw;
              }
              @media only screen and (max-width: 450px) {
                width: 30vw;
              }

              &:disabled {
                opacity: .3;
              }
            }

            .v-input {
              width: 50%;
              margin-right: 0;

              @media (orientation: portrait) {
                width: auto;
                margin-right: .5vw;
              }
              @media only screen and (max-width: 450px) {
                width: auto;
                margin-right: .5vw;
              }
            }

            .editor__parameters__parameter__content__logo___file_input {
              position: absolute;
              top: 0;
              left: 0;
              width: 0;
              height: 0;
              visibility: hidden;
            }

            .editor__parameters__parameter__content__logo__position {
              max-width: calc(50% - 0.5vw);
              margin: 2vh .5vw 0 0;

              @media (orientation: portrait), only screen and (max-width: 450px) {
                margin: 0;
              }
            }

            .editor__parameters__parameter__content__logo__select {
              picture {
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                height: 2vh;
                max-height: 18px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .editor__parameters__parameter__content__logo__recommendation {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50%;
              margin-top: 2vh;
              font-size: 0.8em;
              color: #0875DD;
              white-space: nowrap;

              &.disabled {
                opacity: .3;
              }

              @media only screen and (max-width: 900px) {
                width: 100%;
              }
            }
          }

          .editor__parameters__parameter__content__description {
            color: $default-blue-color;
            margin-bottom: 3vh;

            .editor__parameters__parameter__content__description__error {
              margin-top: 1vh;
              color: $fail-color;
            }
          }

          .editor__parameters__parameter__content__duration {
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: flex-start;

            label, input {
              white-space: nowrap;
              margin-right: 0;
              flex: 1;
            }

            input:disabled {
              opacity: .3;
            }
          }
          .editor__parameters__parameter__content__tiltshift {
            margin-bottom: 1vh;
            margin-top: 1vh;
            .tilt-shift-slider-wrapper {
              padding-left: 2vw;
              padding-right: 2vw;
              .tilt-shift-slider {
                border: none;
              }
            }
          }
          .editor__parameters__parameter__content__resolution {
            display: flex;
            flex-flow: nowrap;
            flex-direction: row;
            margin-top: 1vh;
            .editor__parameters__parameter__content__resolution__field_class {
              margin-right: 0;
            }
            label, .v-select {
              white-space: nowrap;
              margin-right: 0;
              flex: 1;
            }
          }

          input[type=text] {
            width: 100%;
            color: $default-blue-ultra-dark-color;
            border: 1px solid $default-blue-ultra-dark-color;
            font-size: 1.1em;
            text-align: center;

            @media (orientation: portrait) {
              padding: .5vh 0;
            }

            &:focus {
              outline: none;
            }

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: $default-blue-ultra-dark-color;
              text-align: center;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $default-blue-ultra-dark-color;
              text-align: center;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              color: $default-blue-ultra-dark-color;
              text-align: center;
            }

            &.editor__parameters__parameter__content__name {
              border: none;
              border-bottom: 1px solid $default-blue-ultra-dark-color;
              text-align: left;
              margin-bottom: 3vh;

              &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                text-align: left;
              }

              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                text-align: left;
              }

              &::-ms-input-placeholder { /* Microsoft Edge */
                text-align: left;
              }

              &.editor__parameters__parameter__content__name__alert {
                border-color: $fail-color;
                animation-name: editor__parameters__parameter__content__name__alert__animation;
                animation-duration: 1s;
                animation-iteration-count: 3;

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: $fail-color;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: $fail-color;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                  color: $fail-color;
                }
              }

              @keyframes editor__parameters__parameter__content__name__alert__animation {
                0%   {border-color: $fail-color;}
                50%  {border-color: $default-blue-dark-color}
                100% {border-color: $fail-color;}
              }
            }
          }

          &::v-deep {
            .v-input {
              font-size: 1.1em;
              font-family: $body-font-family;
              padding: 0;
              margin: 0 .5vw 0 0;
              border: 1px solid $default-blue-ultra-dark-color;

              &:last-child {
                margin-right: 0;
              }

              &.v-input--is-disabled {
                opacity: .3;
              }

              .v-input__slot:before, .v-input__slot:after {
                content: none;
                display: none;
              }

              .v-select__selections {
                .v-select__selection--comma {
                  position: absolute;
                  margin: 0;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);
                  color: $default-blue-ultra-dark-color;
                  line-height: 1.5;

                  &:after {
                    content:'\f0140';
                    font-family: $main-font-family, "Material Design Icons", sans-serif;
                  }
                }

                input {
                  font-family: $main-font-family, "Material Design Icons", sans-serif;
                  line-height: 1;
                  max-height: initial;
                  padding: 0;

                  @media (orientation: portrait) {
                    width: 20vw;
                    padding: .5vh 0;
                  }
                  @media only screen and (max-width: 450px) {
                    width: 20vw;
                    padding: .5vh 0;
                  }

                  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $default-blue-ultra-dark-color;
                    text-align: center;
                    opacity: 1; /* Firefox */
                  }

                  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $default-blue-ultra-dark-color;
                    text-align: center;
                  }

                  &::-ms-input-placeholder { /* Microsoft Edge */
                    color: $default-blue-ultra-dark-color;
                    text-align: center;
                  }
                }
              }
            }
          }

          &.editor__parameters__parameter__content_general {
            .editor__parameters__parameter__content_general__camera::v-deep {
              position: relative;
              display: flex;
              flex-flow: nowrap;
              align-items: center;
              justify-content: flex-start;

              .v-input {
                width: 100%;
              }

              .camera__projectview__select {
                .camera__projectview__select__selected {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  justify-content: center;
                  white-space: nowrap;

                  picture {
                    position: relative;
                    height: 2vh;
                    max-height: 18px;
                    margin-right: .5vw;

                    img {
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }
              }
            }
          }

          .editor__parameters__parameter__content__calendars {
            display: flex;
            flex-flow: nowrap;

            .editor__parameters__parameter__content__calendars__calendar {
              width: 50%;
              margin-right: .5vw;

              > input:disabled {
                opacity: .3;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }

          .editor__parameters__parameter__content__checkboxes {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 3vh;
            .editor__parameters__parameter__content__checkboxes__checkbox {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 50%;
              margin-right: .5vw;

              &:last-child {
                margin-right: 0;
              }

              label {
                cursor: pointer;
                display: flex;

                  &:before {
                    content: "";
                    display: block;
                    font-size: 1.2em;
                    width: 1em;
                    height: 1em;
                    border: 1px solid $default-blue-ultra-dark-color;

                    @media only screen and (max-width: 900px) {
                      font-size: 1.4em;
                      width: 1.2em;
                      height: 1.2em;
                    }
                  }
              }

              input[type=checkbox] {
                cursor: pointer;
                opacity: 0;
                position: absolute;
              }

              input[type=checkbox]:checked + label::before {
                font-size: 1.2em;
                content: "\f11b";
                -webkit-text-stroke: 2px $default-blue-ultra-dark-color;

                @media only screen and (max-width: 900px) {
                 font-size: 1.4em;
                }

              }

              .editor__parameters__parameter__content__checkboxes__checkbox__title {
                margin-left: 0.5vw;

                @media only screen and (max-width: 900px) {
                    margin-left: 1.5vw;
                }
              }
            }
          }
        }

        &.editor__parameters__parameter__optional {
          .editor__parameters__parameter__content__checkboxes {
            flex-flow: wrap;
            justify-content: space-between;

            .editor__parameters__parameter__content__checkboxes__checkbox {
              width: auto;
              margin-bottom: 3vh;
            }
          }
        }

        &.editor__parameters__parameter__hour_range {
          .editor__parameters__parameter__content::v-deep .v-input {
            width: calc(50% - 0.5vw);
          }
        }
      }

      .editor__parameters__parameter__hour_range__manual {
        margin-top: 4vh;

        &.inactive {
          display: none;
        }

        .editor__parameters__parameter__hour_range__manual__ranges {
          display: flex;
          flex-flow: nowrap;

          .editor__parameters__parameter__hour_range__manual__ranges__range {
            display: flex;
            flex-flow: nowrap;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-start;
            margin-right: 2vw;

            &:last-child {
              margin-right: 0;
            }

            label {
              margin-right: 1vw;
            }
          }
        }
      }

      &.editor__preview {
        &::v-deep {
          .button-submit {
            margin-top: 5vh;

            .base-button {
              padding: 0 3vw;

              @media (orientation: portrait) {
                width: 100%;
                font-size: 1.6em;
              }
            }
          }
        }

        .editor__preview__error {
          color: $fail-color;
        }
      }
    }
  }

  .editor__parameters__parameter__content__logo__select__item {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 3vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  ::v-deep .camera__projectview__select__item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    align-items: center;

    picture {
      position: relative;
      display: flex;
      max-width: 7vw;
      margin-right: 0.5vw;
      padding: 1vh 0;
      box-sizing: border-box;

      @media (orientation: portrait), only screen and (max-width: 900px) {
        max-width: 14vw;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .editor__preview {

    @media (orientation: portrait), only screen and (max-width: 450px) {
      margin-top: 2vh;
    }

    .editor__preview__camera_picture {

      margin-top: 5vh;
      margin-bottom: 5vh;

      &.editor-camera-picture {
        max-height: none;
        margin-bottom: 10vh;
      }
    }
    .editor__preview__no-camera {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: min(32vh, 32vw);
    }
  }
</style>
