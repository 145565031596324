import supportedLocales from '@/config/supported-locales';

export default function getSupportedLocales() {
  const annotatedLocales = [];
  Object.keys(supportedLocales).forEach((code) => {
    annotatedLocales.push({
      code,
      details: supportedLocales[code],
    });
  });

  return annotatedLocales;
}

export function supportedLocalesInclude(locale) {
  return Object.keys(supportedLocales).includes(locale);
}
