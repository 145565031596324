import { findIndex, forEach } from 'lodash';
import moment from 'moment-timezone';

const isNewDatesBefore = (existsFrom, newTo) => newTo < existsFrom;
const isNewDatesAfter = (existsTo, newFrom) => existsTo < newFrom;

const mergeFromTo = (newPeriod, existsPeriod) => {
  const newMomentFrom = moment.utc(newPeriod.from);
  const newMomentTo = moment.utc(newPeriod.to);

  const existsPeriodsMomentFrom = moment.utc(existsPeriod.from);
  const existsPeriodsMomentTo = moment.utc(existsPeriod.to);

  const mergedPeriodFrom = newMomentFrom < existsPeriodsMomentFrom
    ? newPeriod.from : existsPeriod.from;
  const mergedPeriodTo = newMomentTo > existsPeriodsMomentTo ? newPeriod.to : existsPeriod.to;

  return { mergedPeriodFrom, mergedPeriodTo };
};

const mergeImages = (newPeriod, existsPeriod) => {
  const mergedImages = existsPeriod.images;
  forEach(newPeriod.images, (image, rowNumber) => {
    if (!(rowNumber in existsPeriod.images)) {
      mergedImages[rowNumber] = image;
    }
  });

  return mergedImages;
};

export const groupByCameraViews = (images) => {
  const cameraviews = {};
  forEach(images, (image) => {
    if (!(image.cameraview in cameraviews)) {
      cameraviews[image.cameraview] = {};
    }
    cameraviews[image.cameraview][image.row_number] = image;
  });
  return cameraviews;
};

export const findIndexToMergeByDates = (from, to, stateImagesList) => {
  const newMomentFrom = moment.utc(from);
  const newMomentTo = moment.utc(to);

  return findIndex(stateImagesList, (existsPeriod) => {
    const existsPeriodsMomentFrom = moment.utc(existsPeriod.from);
    const existsPeriodsMomentTo = moment.utc(existsPeriod.to);

    return !(isNewDatesBefore(existsPeriodsMomentFrom, newMomentTo)
      || isNewDatesAfter(existsPeriodsMomentTo, newMomentFrom));
  });
};

export const mergePeriods = (newPeriod, existsPeriod) => {
  const { mergedPeriodFrom, mergedPeriodTo } = mergeFromTo(newPeriod, existsPeriod);
  return {
    from: mergedPeriodFrom,
    to: mergedPeriodTo,
    images: mergeImages(newPeriod, existsPeriod),
  };
};

export default groupByCameraViews;
