<template>
  <div class="tags-line">
    <div class="tags-line__tags-list">
      <underline-button class="tags-line__tags-list__tag" :active="tag.active" :text="tag.name"
                        @click.native="toggleTagActivity(tag)"
                        v-for="tag in tagsToShow" :key="tag.id" />
    </div>
    <div class="tags-line__paging">
        <span class="tags-line__paging__arrow tags-line__paging__arrow-prev"
              :class="{ disabled: !isPrevPageActive }" @click="onPrevPageClickHandler">
            <i class="mdi mdi-chevron-left" />
        </span>
      <span class="tags-line__paging__arrow tags-line__paging__arrow-next"
            :class="{ disabled: !isNextPageActive }" @click="onNextPageClickHandler">
            <i class="mdi mdi-chevron-right" />
      </span>
    </div>
  </div>
</template>

<script>
import UnderlineButton from '@/components/v3/Helpers/UnderlineButton';

export default {
  name: 'TagsLine',
  props: {
    tags: {
      type: Array,
      required: true,
    },
    countToShow: {
      type: Number,
      default: 7,
      validator: (value) => value > 0,
    },
    pageStep: {
      type: Number,
      default: 1,
      validator: (value) => value > 0,
    },
  },
  components: {
    UnderlineButton,
  },
  data() {
    return {
      tagsList: this.tags,
      paging: {
        page: 1,
      },
    };
  },
  watch: {
    tags(newTags) {
      this.tagsList = newTags;
    },
  },
  computed: {
    totalPages() {
      const tagsExceptShowing = this.tagsList.length - this.countToShow;
      if (tagsExceptShowing <= 0) {
        return 1;
      }
      return Math.ceil(tagsExceptShowing / this.pageStep) + 1;
    },
    tagsToShowFrom() {
      return this.paging.page > 1 ? (this.paging.page - 1) * this.pageStep : 0;
    },
    tagsToShowTo() {
      return this.paging.page > 1 ? this.countToShow + this.tagsToShowFrom : this.countToShow;
    },
    tagsToShow() {
      return this.tagsList.slice(this.tagsToShowFrom, this.tagsToShowTo);
    },
    isPrevPageActive() {
      return this.paging.page > 1;
    },
    isNextPageActive() {
      return this.paging.page < this.totalPages;
    },
  },
  methods: {
    toggleTagActivity(tag) {
      this.$set(tag, 'active', !tag.active);
      this.$emit('tagsChanged', this.tagsList, tag);
    },
    onPrevPageClickHandler() {
      if (!this.isPrevPageActive) {
        return;
      }
      this.paging.page -= 1;
    },
    onNextPageClickHandler() {
      if (!this.isNextPageActive) {
        return;
      }
      this.paging.page += 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.tags-line {
  width: 100%;

  .tags-line__tags-list {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;

    .tags-line__tags-list__tag {
      margin-right: 0.53vw;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tags-line__paging {
    position: absolute;
    top: 0;
    right: -1vw;
    transform: translateX(100%);

    .tags-line__paging__arrow {
      cursor: pointer;

      &.disabled {
        opacity: .5;
        cursor: default;
      }
    }
  }
}
</style>
