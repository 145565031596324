export const fullscreenNotSupportMixin = {
  data: () => ({
    $_fullscreenMixin: {
      eventName: null,
    },
  }),
  methods: {
    $_fullscreenMixin_exitFullScreenClickHandler() {
      if (!this.isFullScreen) {
        return;
      }
      this.toggleFullscreen(false);
    },
    $_fullscreenMixin_fullScreenClickHandler() {
      this.toggleFullscreen(true);
    },
  },
};

export default fullscreenNotSupportMixin;
