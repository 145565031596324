const isTouchDevice = () => (('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0));

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.top <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getErrorFromResponse = (data) => {
  let errorDetails = null;
  let errorMessage = null;
  if (data?.response?.data) {
    errorDetails = Object.values(data.response.data).join('. ');
    errorMessage = data.response.data?.message;
  }
  const errorSystemMessage = data?.message;

  return errorDetails || errorMessage || errorSystemMessage;
};

export default isTouchDevice;
