<template>
  <div class="camera-picture" :style="cssProps">
    <message :text="error.text" :type="error.type" v-if="error.isShow" />
    <div class="camera-picture__picture"
         :class="{'camera-picture__picture-loading': !isShowCameraImage && isShowImageLoading,
         'fill-by-height': fillByHeight}">
      <picture :class="{'tilt-shift': isApplyTiltShift}"
               :style="{
                  '--strip-height': applyDof + 'px',
                  '--strip-top': `calc(${applyFocusHeight}px - ${applyDof / 2}px)`}">
        <img
          :src="imageDetails.image"
          :data-src="imageDetails.image"
          :alt="imageDetails.alt || 'Image'"
          @load="onImgLoadHandler"
          @click="onImageClickHandler"
          ref="image"
          :class="filterClass"
          v-if="isShowCameraImage"
        />
        <ImagePreloader v-else-if="isShowImageLoading || imageDetails.is_pending"
                        class="camera-picture__picture__loading__preloader"/>
        <img
          class="no-camera"
          :src="noCameraImg"
          :data-src="noCameraImg"
          alt="no-image"
          @click="onNoImageClickHandler"
          @load="onNoCameraImageLoadHandler"
          v-else
        />
        <ShareEmailPopup :image-id="imageDetails.id"
                         :title="$t('components.share_email_popup.share')"
                         @close="onShareCloseHandler" v-if="isAllowToShare && actions.share.isShow"
        />
        <ImageTags :image-id=imageDetails.id :image-tags="imageTags" @change="onTagsChangedHandler"
                   @clickOutside="onTagsCloseHandler" ref="imageTags" @error="onTagsErrorHandler"
                   v-if="isAllowToTagging && actions.tags.isShow"/>
      </picture>
    </div>
    <div class="camera-picture__actions" v-if="isShowActions">
      <div class="camera-picture__actions__date">
        <span v-if="isShowDate">{{ imageDate }}</span>
      </div>
      <div class="camera-picture__actions__buttons">
        <RecognizeImage :object-id="this.imageDetails.id" @error="onRequestRecognitionErrorHandler"
                        @requested="onImageRecognitionrequestedHandler"
                        v-if="isAllowToRecognize && recognize"
                        class="camera-picture__actions__buttons_ai"/>
        <i class="mdi mdi-rotate-180"
           :class="[userImageTagsIds.length ? 'mdi-label' : 'mdi-label-outline']"
           @click="onTagsClickHandler" v-if="isAllowToTagging && tags" />
        <i class="mdi" @click="onLikeClickHandler" v-if="isAllowToLike && like"
           :class="[
             imageDetails.like ? 'mdi-cards-heart' : 'mdi-cards-heart-outline',
             actions.like.isRequesting ? 'disabled' : '',
             ]"
            />
        <i class="mdi mdi-share-variant-outline" @click="onShareClickHandler"
           v-if="isAllowToShare && share"/>
        <Download download-action="getImageDownload" :object-id="this.imageDetails.id"
                  @error="onDownloadErrorHandler" v-if="isAllowToDownload && download" />
      </div>
    </div>
  </div>
</template>

<script>
import ShareEmailPopup from '@/components/v3/Share/ShareEmailPopup';
import ImageTags from '@/components/v3/Tags/ImageTags';
import wheelzoom, { MIN_ZOOM } from '@/utils/wheelzoom';
import { mapActions, mapState } from 'vuex';
import { map, filter, find } from 'lodash';
import moment from 'moment-timezone';
import { tagsMixin, errorMixin, permissionMixin } from '@/mixins/v3';
import { fullscreenMixin } from '@/mixins/v3/fullscreen/fullscreenMixin';
import Message from '@/components/v3/Message';
import Download from '@/components/v3/Download';
import RecognizeImage from '@/components/v3/RecognizeImage';
import { getErrorFromResponse } from '@/helpers';
import PERMISSIONS from '@/constants/v3';
import ImagePreloader from '@/components/v3/Helpers/ImagePreloader';
import { SUPPORT_URL } from '@/constants/v3/menu';

const noCameraImg = require('@/assets/images/dashboardNg/no_camera.svg');

export default {
  name: 'CameraPicture',
  props: {
    applyDof: {
      type: Number,
    },
    applyFocusHeight: {
      type: Number,
    },
    applyTiltShift: {
      type: Boolean,
      default: false,
    },
    applyFilter: {
      type: String,
      default: '',
    },
    imageDetails: {
      type: Object,
      required: true,
    },
    showPreloader: {
      type: Boolean,
      default: true,
    },
    maxZoom: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0,
    },
    zoomStep: {
      type: Number,
      default: 0.1,
      validator: (value) => value > 0,
    },
    zoomKey: {
      type: String,
      default: 'alt',
    },
    resetZoomOnInit: {
      type: Boolean,
      default: true,
    },
    isShowDate: {
      type: Boolean,
      default: true,
    },
    tags: {
      type: Boolean,
      default: true,
    },
    like: {
      type: Boolean,
      default: true,
    },
    share: {
      type: Boolean,
      default: true,
    },
    download: {
      type: Boolean,
      default: true,
    },
    recognize: {
      type: Boolean,
      default: false,
    },
    isHideAllActions: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
    },
    formatDate: {
      type: String,
      default: 'dd DD MMM YYYY HH:mm',
    },
    fillByHeight: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImagePreloader,
    Message,
    Download,
    ImageTags,
    RecognizeImage,
    ShareEmailPopup,
  },
  mixins: [tagsMixin, errorMixin, permissionMixin, fullscreenMixin],
  emits: [
    'click',
    'wheelZoomInit',
    'wheelZoomChanged',
    'wheelZoomPositionChanged',
    'wheelZoomReset',
    'tagsChanged',
    'imageRecognitionRequested',
    'updateHeight',
    'initialImageSize',
  ],
  data: () => ({
    noCameraImg,
    zoomImage: null,
    isLoadedImage: false,
    zoomValue: null,
    imageTags: null,
    actions: {
      share: {
        isShow: false,
      },
      like: {
        isRequesting: false,
      },
      tags: {
        isShow: false,
      },
    },
    initialImageSize: {
      height: null,
      width: null,
    },
  }),
  watch: {
    imageDetails: {
      handler() {
        this.imageTags = this.imageDetails.tags;
      },
      deep: true,
      immediate: true,
    },
    tags(newState) {
      if (this.actions.tags.isShow && !newState) {
        this.actions.tags.isShow = false;
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.emitHeight);
    if (this.$refs.image) {
      this.$emit('cameraPictureMounted');
    }
  },
  computed: {
    isApplyTiltShift() {
      return this.applyTiltShift;
    },
    filterClass() {
      return this.applyFilter;
    },
    cssProps() {
      return {
        '--img-height': this.height || 'auto',
      };
    },
    isShowImageLoading() {
      return this.imageDetails.is_pending;
    },
    isShowCameraImage() {
      return !this.imageDetails.is_pending && !!this.imageDetails.image;
    },
    isShowActions() {
      return !this.isHideAllActions && this.isShowCameraImage;
    },
    imageDate() {
      return `${moment(this.imageDetails.date).format(this.formatDate)}`;
    },
    isAllowToDownload() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.download_image);
    },
    isAllowToShare() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.share_image);
    },
    isAllowToAddLike() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_like);
    },
    isAllowToRemoveLike() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.delete_like);
    },
    isAllowToLike() {
      return this.isAllowToAddLike || this.isAllowToRemoveLike;
    },
    isAllowToAddTag() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_tag);
    },
    isAllowToRecognize() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_image_area_identifier);
    },
    isAllowToAddTagImage() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_taggedimage);
    },
    isAllowToRemoveTagImage() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.delete_taggedimage);
    },
    isAllowToTagging() {
      return this.isAllowToAddTag || this.isAllowToAddTagImage || this.isAllowToRemoveTagImage;
    },
    error() {
      return this.$_errorMixin_data;
    },
    ...mapState({
      likes: (state) => state.likes,
      tagsList: (state) => state.tags,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    emitHeight() {
      this.$nextTick(() => {
        if (this.$refs.image) {
          const height = this.$refs.image.clientHeight;
          this.$emit('updateHeight', height);
          const img = new Image();
          img.src = this.imageDetails.image;
          this.initialImageSize.width = img.naturalWidth;
          this.initialImageSize.height = img.naturalHeight;
          this.$emit('initialImageSize', this.initialImageSize);
        }
      });
    },
    onLikeClickHandler() {
      if (!this.isAllowToLike) {
        this.$_errorMixin_showError('You are not allowed to like');
        return;
      }
      if (this.actions.like.isRequesting) {
        return;
      }

      if (this.imageDetails.like) {
        this.deleteImageLike();
        return;
      }
      this.addImageLike();
    },
    addImageLike() {
      if (!this.isAllowToAddLike) {
        this.$_errorMixin_showError('You are not allowed to like');
        return;
      }
      this.actions.like.isRequesting = true;
      const payload = {
        user: this.user.id,
        object_content_type: 'image',
        object_id: this.imageDetails.id,
      };
      this.imageDetails.like = true;
      this.addLike(payload).catch((e) => {
        this.imageDetails.like = false;
        this.$_errorMixin_showError(getErrorFromResponse(e));
      }).finally(() => { this.actions.like.isRequesting = false; });
    },
    deleteImageLike() {
      if (!this.isAllowToRemoveLike) {
        this.$_errorMixin_showError('You are not allowed to unlike');
        return;
      }
      this.actions.like.isRequesting = true;
      const likeId = find(this.likes, (like) => (
        like.user === this.user.id
        && like.object_content_type === 'image'
        && like.object_id === this.imageDetails.id
      )) || {};
      if (!likeId?.id) {
        this.$_errorMixin_showError('Error while removing like. Please, refresh the page '
          + 'and try again.');
        this.actions.like.isRequesting = false;
        return;
      }
      this.imageDetails.like = false;
      this.deleteLike(likeId.id).catch((e) => {
        this.imageDetails.like = true;
        this.$_errorMixin_showError(getErrorFromResponse(e));
      }).finally(() => { this.actions.like.isRequesting = false; });
    },
    onNoCameraImageLoadHandler() {
      this.isLoadedImage = true;
    },
    onImgLoadHandler(e) {
      this.emitHeight();
      if (!this.zoomImage) {
        const wheelZoomObj = wheelzoom.init();
        this.zoomImage = wheelZoomObj(e.target, {
          zoom: this.zoomStep,
          zoomKey: this.zoomKey.toLocaleLowerCase(),
          maxZoom: this.maxZoom,
          resetZoomOnInit: this.resetZoomOnInit,
        });
        window.addEventListener('wheelzoom.zoomChanged', this.onWheelzoomZoomChanged);
        window.addEventListener('wheelzoom.positionChanged', this.onWheelzoomPositionChanged);
        window.addEventListener('wheelzoom.zoomReset', this.onWheelzoomZoomReset);
        this.$emit('wheelZoomInit', this.zoomImage);
      }
      this.isLoadedImage = true;
    },
    onWheelzoomZoomChanged(data) {
      if (data.detail.img === this.$refs.image) {
        this.$emit('wheelZoomChanged', {
          img: data.detail.img,
          zoom: data.detail.zoom,
          min: MIN_ZOOM,
        });
      }
    },
    onWheelzoomPositionChanged(data) {
      if (data.detail.img !== this.$refs.image) {
        return;
      }

      this.$emit('wheelZoomPositionChanged', {
        img: data.detail.img,
        posX: data.detail.bgPosX,
        posY: data.detail.bgPosY,
      });
    },
    onWheelzoomZoomReset(data) {
      if (data.detail.img !== this.$refs.image) {
        return;
      }

      this.$emit('wheelZoomReset', {
        img: data.detail.img,
        zoom: data.detail.zoom,
      });
    },
    onShareClickHandler() {
      if (!this.isAllowToShare) {
        this.$_errorMixin_showError('You are not allowed to share');
        return;
      }
      this.actions.share.isShow = !this.actions.share.isShow;
    },
    onShareCloseHandler() {
      this.actions.share.isShow = false;
    },
    onTagsCloseHandler(e) {
      if (this.isAllowToAddTagImage || this.isAllowToRemoveTagImage) {
        this.onTagsChangedHandler(e);
      }
      this.actions.tags.isShow = false;
    },
    onTagsClickHandler() {
      if (!this.isAllowToTagging) {
        return;
      }
      const imageTags = this.$refs?.imageTags || {};
      const imageTagsData = imageTags?.$data;
      if (imageTagsData && imageTagsData.unmount.isScheduled) {
        imageTags.unscheduleUnmount();
        return;
      }
      this.actions.tags.isShow = !this.actions.tags.isShow;
    },
    onTagsChangedHandler({ tags }) {
      if (!this.isAllowToAddTagImage && !this.isAllowToRemoveTagImage) {
        return;
      }
      this.imageTags = [
        ...this.protectedImageTagsIds,
        ...map(
          filter(tags, (tag) => tag.id),
          (tag) => tag.id,
        ),
      ];
      this.$emit('tagsChanged', { image: this.imageDetails, tags });
    },
    onTagsErrorHandler({ text }) {
      this.$_errorMixin_showError(text);
    },
    onDownloadErrorHandler(e) {
      this.$_errorMixin_showError(getErrorFromResponse(e));
    },
    onImageClickHandler() {
      this.$emit('click');
    },
    onNoImageClickHandler() {
      if (this.$router.history.current.hash === '#photo_show') {
        return;
      }
      if (this.isFullScreen) {
        this.$_fullscreenMixin_exitFullScreenClickHandler();
      }
      this.$router.push(SUPPORT_URL.path);
    },
    onImageRecognitionrequestedHandler() {
      this.$emit('imageRecognitionRequested');
    },
    onRequestRecognitionErrorHandler(e) {
      this.$_errorMixin_showError(getErrorFromResponse(e));
    },
    ...mapActions({
      addLike: 'createLikes',
      deleteLike: 'destroyLikes',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('wheelzoom.zoomChanged', this.onWheelzoomZoomChanged);
    window.removeEventListener('wheelzoom.positionChanged', this.onWheelzoomPositionChanged);
    window.removeEventListener('wheelzoom.zoomReset', this.onWheelzoomZoomReset);
    window.removeEventListener('resize', this.emitHeight);
  },
};
</script>

<style lang="scss">
  .v-menu__content--fixed {
    z-index: 1000000000!important;
  }
</style>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/helpers.scss';

  .camera-picture {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    .sepia_noir {
      filter: sepia(100%) contrast(150%);
    }
    .greyscale {
      filter: grayscale(100%) contrast(100%);
    }
    .enhance {
      filter: saturate(140%) contrast(130%) brightness(110%);
    }
    .tilt-shift {
      position: relative;
      overflow: hidden;
      height: var(--img-height);
      filter: saturate(200%) contrast(140%);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: var(--strip-top);
        background: inherit;
        background-size: cover;
        backdrop-filter: blur(2px);
      }
      &:after {
        content: '';
        position: absolute;
        top: calc(var(--strip-top) + var(--strip-height));
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: inherit;
        background-size: cover;
        backdrop-filter: blur(2px);
      }
    }
    .camera-picture__picture {
      position: relative;
      height: var(--img-height);

      &.camera-picture__picture-loading {
        width: 100%;
      }

      &.fill-by-height {
        width: 100%;

        picture {
          img {
            width: auto;
            min-width: 100%;
            max-height: none;
            height: 100%;
            object-fit: cover;
          }

          .camera-picture__picture__loading__preloader {
            height: 100%;
          }
        }
      }

      picture {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: var(--img-height);

        img {
          width: 100%;
          max-height:80vh;
          object-fit: scale-down;
        }

        .camera-picture__picture__loading__preloader {
          height: 50vh;
          width: 100%;
          object-fit: contain;

          @media only screen and (max-width: 1090px) {
              height: 18vh;
          }
          @media only screen and (orientation: portrait) and (max-width: 500px) {
            height: 18vh;
          }
          @media only screen and (orientation: landscape) and (max-width: 900px) {
            height: 32vh;
          }
        }
        .no-camera {
          max-height: 32vh;

          @media only screen and (orientation: portrait) and (max-width: 500px) {
            max-height: 12vh;
          }
          @media only screen and (orientation: portrait) and (max-width: 900px) {
            height: 18vh;
          }
        }
      }
    }

    .camera-picture__actions {
      @include media-actions;
    }

    .camera-picture__actions__buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      & > * {
        font-size: 1.5em;

        @media only screen and (max-width: 900px) {
          font-size: 2.2em;
          margin-left: 2vw;
        }
      }
      .camera-picture__actions__buttons_ai {
        @media only screen and (max-width: 900px) {
          display: none;
        }
      }
    }

    &.full-screen {
      .camera-picture__actions__date {
        margin-left: 3vw;
      }

      .camera-picture__actions__buttons {
        margin-right: 3vw;
      }
    }
  }
</style>
