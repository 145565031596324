<template>
  <div class="tabs" :class="classList">
    <div class="tabs__left-side">
      <div class="tabs__item" :class="{ active: menuItem.code === activeTab.code }"
           @click="onMenuClickHandler(menuItem.code)"
           v-for="(menuItem, index) in menuButtons" :key="`menu-button-${index}`">
        <div v-if="menuItem.iconHtml" v-html="menuItem.iconHtml"></div>
        <span v-else>{{ menuItem.name }}</span>
      </div>
    </div>
    <div class="tabs__right-side" v-if="actionsList.length">
      <div class="tabs__component"
           v-for="actionItem in actionsList" :key="actionItem.code">
        <component :is="actionItem.component" v-bind="actionItem.props || {}"
                   v-on="actionItem.events || {}"/>
      </div>
    </div>
    <slot v-else-if="hasDefaultSlot" />
  </div>
</template>

<script>
import { find, findIndex, includes } from 'lodash';
import { setDocumentTitle } from '@/utils/i18n/document';

const MENU_STYLES = ['blue-background'];

export default {
  name: 'TabsMenu',
  emits: ['menuHashInit', 'activeTabChanged', 'menuPathInit'],
  props: {
    menuList: {
      type: Array,
      default: () => [],
    },
    initActiveIndex: {
      type: Number,
      default: 0,
    },
    actionsList: {
      type: Array,
      default: () => [],
    },
    allowHash: {
      type: Boolean,
      default: true,
    },
    allowSetTitle: {
      type: Boolean,
      default: true,
    },
    template: {
      type: String,
      validator(value) {
        return includes(MENU_STYLES, value);
      },
    },
  },
  data() {
    return {
      menuButtons: this.menuList,
      routePath: this.$route.matched,
      activeIndex: this.initActiveIndex,
    };
  },
  mounted() {
    if (this.allowHash) {
      this.initHash(this.$router?.currentRoute?.hash);
    }
  },
  watch: {
    initActiveIndex(newIndex) {
      if (newIndex === this.activeIndex) {
        return;
      }
      this.activeIndex = newIndex;
      this.$emit('activeTabChanged', this.activeTab, newIndex);
    },
    activeTab: {
      handler(newActiveTab) {
        if (!this.allowSetTitle) {
          return;
        }

        let mainTitle = this.$parent.$route?.meta?.title;
        if (!mainTitle) {
          const title = this.$t('app.title');
          const componentName = this.$parent.$options?.name;
          mainTitle = `${title} | ${componentName}`;
        }

        const tabName = newActiveTab.name;
        setDocumentTitle(`${mainTitle} - ${tabName}`);
      },
      immediate: true,
    },
  },
  computed: {
    activeTab() {
      return this.menuButtons[this.activeIndex];
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    classList() {
      const hasSlotClass = !this.actionsList.length && this.hasDefaultSlot;
      return [this.template, hasSlotClass ? 'slot' : ''];
    },
  },
  methods: {
    initHash(hash) {
      const menuByHash = hash && find(
        this.menuButtons,
        (menuButton) => `#${menuButton.code}` === hash,
      );
      if (!menuByHash) {
        this.$emit('menuHashInit', this.activeTab, this.activeIndex);
        this.$router.push({ hash: this.activeTab.code }).catch(() => {});
        return;
      }
      if (menuByHash.code !== this.activeTab.code) {
        this.goToTab(menuByHash.code);
      }
      this.$emit('menuHashInit', this.activeTab, this.activeIndex);
    },
    onMenuClickHandler(code) {
      this.goToTab(code);
      if (this.allowHash) {
        this.$router.push({ hash: this.activeTab.code }).catch(() => {});
      } else {
        this.$router.push({ name: code }).catch(() => {});
      }
    },
    goToTab(code) {
      const newMenuIndex = findIndex(
        this.menuButtons,
        (menuButton) => menuButton.code === code,
      );
      if (newMenuIndex < 0) {
        return;
      }
      this.activeIndex = newMenuIndex;
      this.$emit('activeTabChanged', this.activeTab, this.activeIndex);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6.5vh;

  @media only screen and (max-width: 900px) {
    font-size: 1.8em;
    flex-flow: column-reverse;
  }

  &:not(.slot) {
    @media (orientation: portrait) {
      flex-flow: column-reverse;
      align-items: flex-start;
      margin-bottom: 4.5vh;

      .tabs__left-side {
        width: 100%;
      }
    }
  }

  &.blue-background {
    width: 100vw;
    position: relative;
    left: -1 * $page-global-padding-horizontal;
    padding: 3.45vh $page-global-padding-horizontal;
    color: $default-white-color;
    background-color: $default-blue-color;

    @media (orientation: portrait) {
      left: -1 * $page-global-portrait-padding-horizontal;
      padding: 1.5vh $page-global-portrait-padding-horizontal;
    }

    .tabs__left-side {
      .tabs__item {
        &:after {
            background-color: $default-white-color;
          }
        }
      }
    }

  .tabs__left-side {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
      flex-wrap: wrap;
    }
    @media only screen and (width < 500px) and (height < 500px) {
      min-height: 20vh;
    }
    @media only screen and (orientation: landscape) and (height < 500px) {
      min-height: 30vh;
    }

    .tabs__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9vw;
      padding-bottom: 1.3vh;
      margin-right: .53vw;
      cursor: pointer;

      @media (orientation: portrait) {
        margin-right: 1.5vw;
        padding: 0 1.5vw 1.3vh 1.5vw;
        width: 14.5vw;
      }
      @media screen and (max-width: 900px) {
        width: 20vw;
        margin-top: 3vh;
        flex-basis: 30.333333%;
      }

      &:last-child {
        margin-right: 0;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $default-blue-ultra-dark-color;
      }

      &.active {
        font-weight: 600;

        @media screen and (max-width: 900px) {
          text-align: center;
        }

        &:after {
          height: 2px;
        }
      }
    }
  }

  .tabs__right-side {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (orientation: portrait) {
      width: 100%;
      margin-bottom: 1.8vh;
    }

    @media only screen and (width < 500px) and (height < 500px) {
    margin-bottom: 3vh;
    }

    .tabs__component {
      position: relative;
      min-width: 10vw;
      margin-right: .53vw;
      padding-bottom: 1.3vh;

      @media (orientation: portrait) {
        margin-right: 1.5vw;
        padding: 0 1.5vw 1.3vh 1.5vw;
      }

      &:last-child {
        margin-right: 0;

        @media screen and (max-width: 900px) {
          display: none;
        }
      }

      &:empty {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $default-blue-ultra-dark-color;
      }
    }
  }
}
</style>
