export const COGNITO_ERROR_WRAPPER = '**';

export const authMixin = {
  mounted() {
    if (this.$refs.form) {
      this.$refs.form.addEventListener('keydown', this.$_authMixin_onKeyDownHandler);
    }
  },
  data: () => ({
    /*
      The property must be overwritten in the parent

      Base structure:
        form: {
          username: {
            value: null,
            error: null,
          },
        }
     */
    form: {},
    $_authMixin: {
      loading: false,
      isFormValid: false,
    },
  }),
  computed: {
    $_authMixin_submitDisabledState() {
      return !this.$_authMixin_data.isFormValid || this.$_authMixin_loading;
    },
    $_authMixin_data: {
      get() {
        return this.$data.$_authMixin;
      },
      set(newValue) {
        this.$data.$_authMixin = {
          ...this.$data.$_authMixin,
          ...newValue,
        };
      },
    },
    $_authMixin_loading: {
      get() {
        return this.$data.$_authMixin.loading;
      },
      set(newValue) {
        this.$data.$_authMixin.loading = newValue;
      },
    },
    $_authMixin_formErrorMessages() {
      return {
        valueMissing: this.$t('auth.errors.field_required'),
        typeMismatch: this.$t('auth.errors.invalid_email'),
      };
    },
    $_authMixin_isFormValid: {
      get() {
        return this.$data.$_authMixin.isFormValid;
      },
      set(newValue) {
        this.$data.$_authMixin.isFormValid = newValue;
      },
    },
  },
  methods: {
    $_authMixin_onFormChange(form) {
      this.$_authMixin_data = {
        isFormValid: form.checkValidity(),
      };
    },
    $_authMixin_validate(e) {
      e.preventDefault();
      const input = e.target;
      const inputId = input.getAttribute('id');

      if (!(inputId in this.form)) {
        return;
      }

      this.form[inputId].error = null;
      input.setCustomValidity('');
      if (!input.checkValidity()) {
        const validationResult = input.validity;
        if (validationResult.valueMissing) {
          input.setCustomValidity(this.$_authMixin_formErrorMessages.valueMissing);
        } else if (input.type === 'email' && validationResult.typeMismatch) {
          input.setCustomValidity(this.$_authMixin_formErrorMessages.typeMismatch);
        }

        this.form[inputId].error = input.validationMessage;
      }
    },
    $_authMixin_submitForm(e) {
      e.preventDefault();
      if (this.$_authMixin_loading || !this.$_authMixin_data.isFormValid) {
        return false;
      }
      this.$_authMixin_loading = true;
      return true;
    },
    $_authMixin_getCognitoErrorText(errorMessage) {
      const startIndex = errorMessage.indexOf(COGNITO_ERROR_WRAPPER);
      const endIndex = errorMessage.lastIndexOf(COGNITO_ERROR_WRAPPER);

      if (startIndex < 0 || endIndex < 0 || startIndex === endIndex) {
        return errorMessage;
      }
      return errorMessage.substring(startIndex + COGNITO_ERROR_WRAPPER.length, endIndex);
    },
    $_authMixin_onKeyDownHandler(e) {
      // keyCode 13 is Enter key
      if (e.keyCode !== 13) {
        return;
      }

      e.preventDefault();
      this.$_authMixin_onFormChange(this.$refs.form);
      this.$refs.form.dispatchEvent(new Event('submit', { cancelable: true }));
    },
  },
  beforeDestroy() {
    if (this.$refs.form) {
      this.$refs.form.removeEventListener('keydown', this.$_authMixin_onKeyDownHandler);
    }
  },
};

export default authMixin;
