<template>
  <div class="share-email-popup">
    <div class="share-email-popup__window">
      <div class="share-email-popup__title">{{ title }}</div>
      <div class="share-email-popup__form" v-if="!isSent">
        <div class="share-email-popup__fields">
          <div class="share-email-popup__fields__field">
            <v-text-field id="email" single-line dense flat hide-details v-model="email.value"
                          :placeholder="$t('components.share_email_popup.form.placeholders.email')"
            />
            <label for="email">{{ email.error }}</label>
          </div>
          <div class="share-email-popup__fields__field">
            <v-text-field id="message" single-line dense flat hide-details v-model="message.value"
                         :placeholder="$t('components.share_email_popup.form.placeholders.message')"
            />
            <label for="message">{{ message.error }}</label>
          </div>
        </div>
        <ButtonSubmit class="share-email-popup__window__send" @click="onSubmitForm"
                      :is-show-loading="isLoading" :is-disabled="!isButtonActive"
                      :title="$t('components.share_email_popup.form.placeholders.submit')" />
        <div class="share-email-popup__error">{{ error }}</div>
      </div>
      <div class="share-email-popup__sucess" v-if="isSent">Success!</div>
      <i class="share-email-popup__close mdi mdi-window-close" @click="onCloseClickHandler"/>
    </div>
  </div>
</template>

<script>
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { permissionMixin } from '@/mixins/v3';

import { mapActions } from 'vuex';
import PERMISSIONS from '@/constants/v3';

export default {
  name: 'ShareEmailPopup',
  props: {
    imageId: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  mixins: [permissionMixin],
  components: {
    ButtonSubmit,
  },
  data: () => ({
    isLoading: false,
    isSent: false,
    error: null,
    email: {
      value: null,
      error: null,
    },
    message: {
      value: null,
      error: null,
    },
  }),
  watch: {
    emailValue(newValue) {
      this.email.error = null;
      if (!/.+@.+\..+/.test(newValue)) {
        this.email.error = this.$t('components.share_email_popup.form.errors.invalid_email');
      }
    },
    messageValue(newValue) {
      this.message.error = null;
      if (newValue.length > 255) {
        this.message.error = this.$t('components.share_email_popup.form.errors.text_too_long');
      }
    },
  },
  computed: {
    isButtonActive() {
      return this.isFormValid && !this.isLoading;
    },
    emailValue() {
      return this.email.value;
    },
    messageValue() {
      return this.message.value;
    },
    isFormValid() {
      return this.emailValue && this.messageValue && !this.email.error && !this.message.error;
    },
    isAllowToShare() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.share_image);
    },
  },
  methods: {
    onCloseClickHandler() {
      this.$emit('close');
    },
    onSubmitForm() {
      if (!this.isAllowToShare) {
        this.error = 'You are not allowed to share';
        return;
      }

      this.isLoading = true;
      this.error = null;

      const payload = {
        id: this.imageId,
        params: {
          email: this.emailValue,
          message: this.messageValue,
        },
      };
      this.shareImage(payload).then(() => {
        this.isSent = true;
      }).catch((error) => {
        this.error = error.message;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    ...mapActions({
      shareImage: 'postImageShare',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.share-email-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);

  .share-email-popup__window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 85%;
    padding: 2.3vh 4.1vw;
    background-color: $default-light-color;
    cursor: default;

    @media (orientation: portrait) {
      padding: 1vh 2vw;
    }

    .share-email-popup__title {
      position: relative;
      font-size: 2em;
      padding-bottom: 1.5vh;
      margin-bottom: 3.1vh;

      @media (orientation: portrait) {
        padding-bottom: 1vh;
        margin-bottom: 1.5vh;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 15%;
        height: 1px;
        background-color: $default-blue-dark-color;
      }
    }

    .share-email-popup__error {
      color: $fail-color;
    }

    .share-email-popup__sucess {
      text-align: center;
      font-size: 3em;
      color: $success-color;
    }

    .share-email-popup__fields::v-deep {
      width: 100%;
      margin-bottom: 2.5vh;

      @media (orientation: portrait) {
        margin-bottom: 1.5vh;
      }

      .share-email-popup__fields__field {
        position: relative;
        width: 100%;
        padding-bottom: 3vh;

        @media (orientation: portrait) {
          padding-bottom: 2vh;
        }

        label {
          position: absolute;
          margin-top: .5vh;
          font-size: .7em;
          color: $fail-color;
        }

        .v-input {
          font-family: $main-font-family, "Material Design Icons", sans-serif;
          font-size: 1em;
          padding-top: 0;

          .v-input__slot {
            padding-bottom: 1.55vh;

            &:before {
              border-color: $default-blue-ultra-dark-color;
            }

            &:after {
              background-color: $default-blue-color;
            }
          }

          &.v-text-field {
            margin: 0;
          }

          .v-text-field__slot {
            color: $default-blue-ultra-dark-color;

            input {
              padding: 0;
              line-height: 1;
              color: $default-blue-ultra-dark-color;

              &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: $default-blue-ultra-dark-color;
                opacity: 1; /* Firefox */
              }

              &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: $default-blue-ultra-dark-color;
              }

              &::-ms-input-placeholder { /* Microsoft Edge */
                color: $default-blue-ultra-dark-color;
              }
            }
          }
        }
      }
    }

    .share-email-popup__window__send {
      width: 100%;

      ::v-deep {
        .base-button {
          width: 100%;
        }
      }

      @media (orientation: portrait) {
        height: 1vh;
      }
    }

    .share-email-popup__close {
      position: absolute;
      top: 1.07vh;
      right: 0.6vw;
      font-size: 1.7em;
      cursor: pointer;
    }
  }
}
</style>
