<template>
  <form class="auth-form" ref="form" @submit.prevent="submitForm"
        @change="$_authMixin_onFormChange($refs.form)">
    <div class="auth-form__caption">
      {{ $t('auth.titles.register') }}
    </div>
    <div class="auth-form__inputs">
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="first_name" type="text" :placeholder="$t('auth.placeholders.first_name')"
                 v-model="form.first_name.value" @input="$_authMixin_validate" required autofocus />
        </div>
        <label for="first_name">{{ form.first_name.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="last_name" type="text" :placeholder="$t('auth.placeholders.last_name')"
                 v-model="form.last_name.value" @input="$_authMixin_validate" required />
        </div>
        <label for="last_name">{{ form.last_name.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="email" type="email" :placeholder="$t('auth.placeholders.email')"
                 v-model="form.email.value" @input="$_authMixin_validate" required />
        </div>
        <label for="email">{{ form.email.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="password" type="password" :placeholder="$t('auth.placeholders.password')"
                 v-model="form.password.value" @input="$_authMixin_validate" required />
        </div>
        <label for="password">{{ form.password.error }}</label>
      </div>
    </div>
    <ButtonSubmit :title="$t('auth.buttons.create_account')" @click="submitForm"
                  :is-show-loading="$_authMixin_loading"
                  :is-disabled="$_authMixin_submitDisabledState"
                  class="auth-form__submit_button"/>
    <div class="auth-form__actions">
      <router-link :to="{ name: 'login' }">
        {{ $t('auth.captions.already_have_account') }} {{ $t('auth.buttons.login') }}
      </router-link>
    </div>
    <slot />
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

export default {
  name: 'Register',
  components: {
    ButtonSubmit,
  },
  mixins: [AuthMixin],
  data() {
    return {
      form: {
        first_name: {
          value: null,
          error: null,
        },
        last_name: {
          value: null,
          error: null,
        },
        email: {
          value: this.$route.query.email,
          error: null,
        },
        password: {
          value: null,
          error: null,
        },
      },
    };
  },
  methods: {
    submitForm(e) {
      if (!this.$_authMixin_submitForm(e)) {
        return;
      }

      const payload = {
        attributes: {
          given_name: this.form.first_name.value,
          family_name: this.form.last_name.value,
        },
        username: this.form.email.value.toLowerCase(),
        password: this.form.password.value,
      };
      this.register(payload)
        .then(() => {
          this.$router.push({
            name: 'registerConfirm',
            query: { email: this.form.email.value.toLowerCase(), ...this.$route.query },
          });
        })
        .catch((error) => {
          this.$emit('apiError', { message: error.message });
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
    },
    ...mapActions({
      register: 'register',
    }),
  },
  beforeDestroy() {
    this.$emit('clearApiError');
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/auth.scss';

.auth-form {
  .auth-form__inputs {
    @media (orientation: landscape) and (max-height: 500px) {
      margin-bottom: 2vh;
    }
  }
}

</style>
