<template>
  <div class="image-details" v-if="isAllowToViewAI">
    <div class="image-details__container">
        <div class="image-details__container__image" v-if="isImage">
            <CameraPictureWithRecognitions :image-details="isImage"
                                           :is-show-boxes="isShowBoxes"
                                           :is-show-labels="isShowLabels"
                                           v-slot="slotProps"
                                           :level-of-confidence="isConfidenceLevel"
                                           :sendLabelState="sendCheckedLabels"
                                           :sendModelState="sendCheckedModels"
                                           :areaId="areaId"
                                           @initialLabels="initialLabels"
                                           @initialModels="initialModels"
            @noSignificantObjectsDetected="noSignificantObjectsDetected"
                                           :key="routeKey">
              <camera-picture
                :image-details="slotProps.image"
                :max-zoom="zoom.maxValue"
                :zoom-step="zoom.step / 100"
                :reset-zoom-on-init="false"
                :recognize="slotProps.recognize"
                @wheelZoomInit="$_zoomMixin_addImage"
                @wheelZoomPositionChanged="$_zoomMixin_setImagesPosition"
                @wheelZoomChanged="onPicturesZoomChangeHandler"
                @imageRecognitionRequested="slotProps.imageRecognitionRequested"
              />
            </CameraPictureWithRecognitions>
            <CameraPreloader class="images-preloader" v-if="isShowLoading"/>
          </div>
      <div class="image-details__container__panel">
        <i class="image-details__container__panel__close mdi mdi-close-circle-outline"
           @click="goBack" v-if="!isLoading"/>
        <div class="image-details__container__panel__nav">
          <div class="image-details__container__panel__nav_button"
               v-if="isPreviousImage && !isShowLoading"
               @click="goToImage(previousImage)">
            {{ $t('ai.button.previous')}}
          </div>
          <div class="image-details__container__panel__nav_button"
               v-if="isNextImage && !isShowLoading"
               @click="goToImage(nextImage)">
            {{ $t('ai.button.next')}}
          </div>
        </div>
        <div class="image-details__container__panel__text" v-if="isAllowToViewAI && !isLoading">
          {{ $t('ai.titles.boxes_labels')}}
        </div>
        <CameraTimeTravelAIPanel @showBoxesChanged="(e) => isShowBoxes = e.value"
                                 @showLabelsChanged="(e) => isShowLabels = e.value"
                                 v-if="isAllowToViewAI && !isLoading"/>
        <div class="image-details__container__panel__data" v-if="isFullyLoaded">
          <div class="image-details__container__panel__data_label" v-if="models.length">
            {{ $t('ai.titles.models')}}:
          </div>
          <div class="checkboxes" v-for="item in models" :key="item.model">
            <div class="checkboxes__checkbox">
              <input
                type="checkbox"
                :id="`checkbox-${item.model}`"
                v-model="item.value" @change="changeSelectedBoxes">
              <label :for="`checkbox-${item.model}`" />
              <div class="checkboxes__checkbox__title">
                {{ item.model }}
              </div>
            </div>
          </div>
          <div class="image-details__container__panel__data_label" v-if="labels.length">
            {{ $t('ai.titles.labels')}}:
          </div>
          <div class="checkboxes" v-for="(item, index) in labels" :key="index" >
            <div class="checkboxes__checkbox">
              <input
                type="checkbox"
                :id="`checkbox-${index}`"
                v-model="item.value" @change="changeSelectedBoxes">
              <label :for="`checkbox-${index}`" />
              <div class="checkboxes__checkbox__title">
                {{ item.label }}
              </div>
            </div>
          </div>
          <div class="image-details__container__panel__data_select"
               @click="personSelect" v-if="showPersonSelect">
            {{ $t('ai.button.only_persons')}}
          </div>
        </div>
        <div class="image-details__container__panel__slider" v-if="isFullyLoaded">
          <v-slider
            vertical
            :color="color"
            :track-color="trackColor"
            thumb-label="always"
            :thumb-size="24"
            v-model="confidenceLevel"
            :step="0.01"
            :min="0"
            :max="1"
            @mouseup="setConfidenceLevel">
          </v-slider>
          <v-subheader style="margin-bottom: 1vh;">
            {{ $t('ai.confidence_level')}}
          </v-subheader>
        </div>
        <div v-if="!isFullyLoaded && noObjects" class="image-details__container__panel__no_objects">
          {{ $t('ai.no_objects')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraPictureWithRecognitions
  from '@/components/v3/Cameras/CameraPictureWithRecognitions';
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import CameraTimeTravelAIPanel from '@/components/v3/Helpers/CameraTimeTravelAIPanel';
import { mapActions, mapState } from 'vuex';
import {
  map, filter, isEmpty, sortBy,
} from 'lodash';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import moment from 'moment-timezone';
import { zoomMixin } from '@/mixins';
import PERMISSIONS from '@/constants/v3';
import permissionMixin from '../../../mixins/v3/permissionMixin';

export default {
  name: 'ImageDetails',
  components: {
    CameraPictureWithRecognitions,
    CameraTimeTravelAIPanel,
    CameraPicture,
    CameraPreloader,
  },
  mixins: [zoomMixin, permissionMixin],
  data: () => ({
    isLoading: false,
    imageDetails: null,
    isShowBoxes: false,
    isShowLabels: false,
    color: '#043067',
    trackColor: '#1654A299',
    confidenceLevel: 0,
    updateConfidenceLevel: 0,
    labels: [],
    models: [],
    sendCheckedLabels: [],
    sendCheckedModels: [],
    showPersonSelect: false,
    labelsLoaded: false,
    modelsLoaded: false,
    showSelect: false,
    areaId: 0,
    nextImage: null,
    previousImage: null,
    noObjects: false,
  }),
  mounted() {
    this.reset();
    this.getData();
  },
  watch: {
    currentImageId() {
      this.sendCheckedLabels = [];
      this.sendCheckedModels = [];
      this.confidenceLevel = 0;
      this.getData();
    },
  },
  methods: {
    reset() {
      if (this.labelsLoaded) {
        this.labelsLoaded = false;
      }
      this.sendCheckedLabels = [];
      this.sendCheckedModels = [];
      this.noObjects = false;
      this.confidenceLevel = 0;
    },
    goToImage(image) {
      if (this.isShowLoading) {
        return;
      }
      this.imageDetails = image;
      this.$router.push({
        name: 'areas',
        params: {
          projectId: this.projectId,
          projectViewId: this.projectViewId,
          imageId: image.id,
        },
      }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    },
    personSelect() {
      const onlyPerson = map(this.labels, (label) => {
        if (label.label !== 'person') {
          label.value = false;
        } else {
          label.value = true;
        }
        return label;
      });
      this.sendCheckedLabels = onlyPerson;
      this.sendCheckedModels = this.models;
    },
    changeSelectedBoxes() {
      this.sendCheckedModels = this.models;
      this.sendCheckedLabels = this.labels;
    },
    initialLabels(labels) {
      this.labels = labels;

      const labelPerson = filter(this.labels, (label) => label.label === 'person');
      if (!isEmpty(labelPerson)) {
        this.showPersonSelect = true;
      }

      if (this.labels.length) {
        this.labelsLoaded = true;
      }
    },
    initialModels(models) {
      this.models = models;

      if (this.models.length) {
        this.modelsLoaded = true;
      }
    },
    noSignificantObjectsDetected(value) {
      this.noObjects = value;
    },
    setConfidenceLevel() {
      this.updateConfidenceLevel = this.isConfidenceLevel;
      this.sendCheckedModels = this.models;
      this.sendCheckedLabels = this.labels;
    },
    onPicturesZoomChangeHandler(data) {
      const swiperTouchMove = this.swiper.instance.allowTouchMove;
      if (data.zoom !== data.min && swiperTouchMove) {
        this.swiper.instance.allowTouchMove = false;
      } else if (data.zoom === data.min && !swiperTouchMove) {
        this.swiper.instance.allowTouchMove = true;
      }
    },
    formatDate(date) {
      return `${moment(date).format('dd DD MMM YYYY HH:mm')} CEST`;
    },
    async getData() {
      if (Number(this.$route.params.areaId)) {
        this.areaId = Number(this.$route.params.areaId);
      }
      this.isLoading = true;
      const payload = this.imageDetails ? this.imageDetails.id : Number(this.$route.params.imageId);
      await this.retrieveImages(payload).then((response) => {
        this.imageDetails = response;
      }).finally(() => {
        const currentImage = this.imageDetails;
        this.fetchClosestImages({
          date: currentImage.date,
          cameraviews: currentImage.cameraview,
          before: 1,
          after: 1,
        }).then((response) => {
          const imagesList = sortBy(response, (image) => image.id);
          [this.previousImage] = filter(imagesList, (image) => image.id > currentImage.id);
          [this.nextImage] = filter(imagesList, (image) => image.id < currentImage.id);
        });
        this.isLoading = false;
      });
    },
    goBack() {
      this.$router.push({
        name: 'camera',
        params: {
          projectId: this.projectId,
          projectViewId: this.projectViewId,
          imageDate: this.imageDetails.date,
          cameraView: this.imageDetails.cameraview,
        },
      });
    },
    ...mapActions({
      retrieveImages: 'retrieveImages',
      fetchClosestImages: 'fetchClosestImages',
    }),
  },
  computed: {
    isConfidenceLevel() {
      return this.confidenceLevel;
    },
    routeKey() {
      return this.$route.path;
    },
    currentImageId() {
      return Number(this.$route.params.imageId);
    },
    projectViewId() {
      return this.$route.params.projectViewId || null;
    },
    projectId() {
      return this.$route.params.projectId || null;
    },
    isNextImage() {
      return this.nextImage;
    },
    isPreviousImage() {
      return this.previousImage;
    },
    isImage() {
      return this.imageDetails;
    },
    isFullyLoaded() {
      return (this.isShowLabels || this.isShowBoxes)
        && this.labelsLoaded && this.modelsLoaded && !this.isShowLoading;
    },
    isShowLoading() {
      return this.isLoading;
    },
    isAllowToViewAI() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_image_area_identifier);
    },
    zoom: {
      get() {
        return this.$data.$_zoomMixin;
      },
      set(newValue) {
        this.$data.$_zoomMixin = {
          ...this.$data.$_zoomMixin,
          ...newValue,
        };
      },
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/helpers.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.theme--light.v-subheader {
  color: $default-blue-ultra-dark-color;;
}
.image-details {
  font-weight: 600;

  .image-details__container {
    position: relative;
    width: 100%;

    .image-details__container__panel {
      position: absolute;
      top: 0;
      right: -11vw;
      display: flex;
      flex-direction: column;

      .camera-time-travel-ai-panel {
        right: 0;
        position: relative;
      }

      .image-details__container__panel__close {
        position: relative;
        font-size: 2em;
        color: $default-blue-color;
        cursor: pointer;
      }

      .image-details__container__panel__text {
        font-size: 0.8em;
        margin-left: 1vw;
        margin-top: 2vh;
      }

      .image-details__container__panel__data {
        font-size: 0.8em;
        position: relative;

        .image-details__container__panel__data_label {
          margin-top: 1vh;
          margin-left: 1vw;
          margin-bottom:2vh;
        }

        .checkboxes {
          margin-top: 1vh;
          font-size: 0.8em;

          .checkboxes__checkbox {
            margin-top: 1vh;
          }

          .checkboxes__checkbox__title {
            margin-left: 1vw;
          }
        }

        .image-details__container__panel__data_select {
          border-bottom: 1px solid $default-blue-dark-color;
          margin-left: 1vw;
          margin-top: 2vh;
          padding-right: 0;
          padding-bottom: 0.5vh;
          cursor: pointer;
          display: inline-block;
        }
      }

      .image-details__container__panel__slider {
        margin-top: 3vh;
        ::v-deep .v-input__slider.v-input__slider--vertical {
          width: 50%;
        }
        ::v-deep .v-subheader {
          font-weight: 600;
        }
      }

      .image-details__container__panel__nav {
        margin-top: 3vh;
        margin-bottom: 3vh;
        display: flex;

        .image-details__container__panel__nav_button {
          margin-left: 1vw;
          font-size: 0.8em;
          cursor: pointer;
          border-bottom: 1px solid $default-blue-dark-color;
          padding-bottom: 0.5vh;
        }
      }

      .images-preloader {
        position: absolute;
        z-index: 100;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        background-color: #ffffff;
        padding: 0 10px 14px;
      }
      .image-details__container__panel__no_objects {
        font-size: 0.8em;
        margin-left: 1.2vw;
        margin-top:2vh
      }
    }
  }

  .image-details__info {
    display: flex;

    .image-details__info__nav {
      flex: 1;
      display: flex;
      margin-left: 3vw;
      margin-top: 3vh;
      align-items: center;
      justify-content: center;

      .image-details__info__nav_button {
        cursor: pointer;
        border-bottom: 1px solid $default-blue-dark-color;
        padding-bottom: 0.5vh;
      }
    }

    .image-details__info__confidence {
      flex: 2;
    }

    .image-details__slider {
      .image-details__slider_wrapper {
        width: 100%;
      }
    }
    .image-details__checkboxes__button {
      margin-top: 1vh;
      font-size: 0.8em;
      min-width: 8vw;
    }
  }
  .disabled {
    color: $default-disabled-color;
    cursor: default;
  }

  .camera-picture__button {
    margin-top: 3vh;
    width: 10%;
  }
}
</style>
