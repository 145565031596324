<template>
  <div class="camera_details">
    <ExitFullscreen />
    <div class="camera_details__actions" :class="{ camera_details__hidden: isFullScreen }">
      <div class="camera_details__actions__left-side">
        <div class="camera_details__actions__search" :class="{disabled: !datepicker.isActive}">
          <input type="text"
                 :placeholder="$t('cameras_menu.camera_details.search_by_date') +' 󰅀'"
                 :disabled="!datepicker.isActive"
                 id="camera_details__actions__search__datetime-picker"
                  autocomplete="off"/>
        </div>
        <div class="camera_details__actions__sort">
          <v-select :items="stepItems" item-text="text" item-value="value" append-icon=""
                    :placeholder="$t('cameras_menu.camera_details.scroll_by') +' 󰅀'"
                    single-line v-model="scrollByStep" hide-details
                    @change="changedSelection"/>
        </div>
        <div class="camera_details__actions__sort">
          <v-select :items="sortByItems" item-text="text" item-value="value" append-icon=""
                    :placeholder="$t('cameras_menu.camera_details.sort_by') +' 󰅀'"
                    single-line v-model="sortBy" hide-details />
        </div>
      </div>
      <div class="camera_details__actions__right-side">
        <div class="camera_details__actions__right-side__component">
          <Fullscreen />
        </div>
      </div>
    </div>
    <div class="camera_details__images" v-if="totalPages">
      <div class="camera_details__images__project_name_and_livestream">
        <div>{{ projectAndCompanyName }}</div>
        <LivestreamAnimation class="camera_details__images__livestream" title="View livestream"
          v-if="isLivestreamAvailable" @click.native="onLivestreamClickHandler"/>
      </div>
      <div class="camera_details__images__camera">
        <div class="camera_details__images__camera__name">{{ projectView.name }}
        <span v-if="!cameraActive">({{ $t('messages.finished') }}) </span></div>
        <div class="camera_details__images__camera_paging">
          <span class="camera_details__images__camera_paging__first"
                :class="{'camera_details__images__camera_paging__disabled': !isActivePrevPage}"
                @click="onFirstPageClickHandler">
            <i class="mdi mdi-chevron-double-left"></i>
          </span>
          <span class="camera_details__images__camera_paging__prev"
                :class="{'camera_details__images__camera_paging__disabled': !isActivePrevPage}"
                @click="onPreviousPageClickHandler">
            <i class="mdi mdi-chevron-left" />
          </span>
          <span
            class="camera_details__images__camera_paging__page" v-if="isDefaultState">
            {{ $t('cameras_menu.camera_details.page') }} {{ this.images.page }} / {{ totalPages }}
          </span>
          <span class="camera_details__images__camera_paging__page" v-else >
            {{ this.images.page }} / {{ totalPages }} {{ `${this.scrollByStep}s` }}
          </span>
          <span class="camera_details__images__camera_paging__next"
                :class="{'camera_details__images__camera_paging__disabled': !isActiveNextPage}"
                @click="onNextPageClickHandler">
            <i class="mdi mdi-chevron-right" />
          </span>
          <span class="camera_details__images__camera_paging__last"
                :class="{'camera_details__images__camera_paging__disabled': !isActiveNextPage}"
                @click="onLastPageClickHandler">
            <i class="mdi mdi-chevron-double-right"></i>
          </span>
        </div>
      </div>
      <div class="camera_details__images__list" v-if="!images.isLoading">
        <div class="camera_details__images__list__image"
             @click="onCameraImageClickHandler(idx, $event, image)"
             v-for="(image, idx) in images.list" :key="image.id">
          <div class="camera_details__images__list__image__picture"
               :class="{ highlighted: image.id === searchByDateImageId }">
            <picture>
              <img :src="image.image" :alt="`image_${image.id}`" />
            </picture>
          </div>
          <div class="camera_details__images__list__image__date">
            <router-link
              v-if="isAllowToViewAI"
              class="camera_details__images__list__image__link"
              @click.stop
              :to="{ name: 'areas', params: { imageId: image.id } }"
              append><i class="mdi mi-eye"></i></router-link>
            {{ formatDate(image.date) }}</div>
        </div>
      </div>
    </div>
    <CameraTimeTravel :images="images.list" v-if="fullScreenGallery.isShow"
                      :initial-slide-index="images.timeTravelIndex"
                      :initial-image="fullScreenGallery.initialClickedImage"
                      :is-ascending="isAscending"
                      :get-next-images="getSliderNextImages"
                      :get-prev-images="getSliderPrevImages"
                      @close="onCloseFullScreenGalleryHandler"
    />
    <Livestream v-if="livestream.isShow && isLivestreamAvailable"
                :livestreams="projectView.livestream_hls" :poster="livestreamPreview"
                :projectView="projectView"
                @close="onCloseLivestreamHandler"/>
    <camera-preloader v-if="images.isLoading" />
    <no-cameras :header="$t('no_camera.no_images')" v-if="isShowNoImages" />
  </div>
</template>

<script>
import CameraTimeTravel from '@/pages/v3/Cameras/CameraTimeTravel';
import Fullscreen from '@/components/v3/Helpers/Menu/Fullscreen';
import NoCameras from '@/components/v3/Cameras/NoCameras';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';

import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import { mapActions, mapState } from 'vuex';
import {
  CAMERA_IMAGES_PER_REQUEST_LIMIT,
  DETAIL_CAMERA_FILTERING_BY_DATE_TIMEOUT,
  DETAIL_CAMERA_PAGING_TIMEOUT,
} from '@/constants/v3/cameras';
import {
  orderBy, omit, isEmpty, compact, join,
} from 'lodash';
import moment from 'moment-timezone';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import LivestreamAnimation from '@/components/v3/Livestream/LivestreamAnimation';
import Livestream from '@/components/v3/Livestream';
import ExitFullscreen from '@/components/v3/Helpers/ExitFullscreen';
import md5 from 'md5';

const SORT_ASC = 'asc';
const SORT_DESC = 'desc';

const SORTS = {
  SORT_ASC: 'date',
  SORT_DESC: '-date',
};

export default {
  name: 'CameraDetails',
  mixins: [permissionMixin],
  components: {
    ExitFullscreen,
    LivestreamAnimation,
    CameraTimeTravel,
    CameraPreloader,
    Fullscreen,
    Livestream,
    NoCameras,
  },
  data() {
    return {
      scrollByStep: null,
      stepItems: [
        {
          text: this.$t('cameras_menu.camera_time_travel.options.default'),
          value: 'default',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.hour'),
          value: 'hour',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.day'),
          value: 'day',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.week'),
          value: 'week',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.month'),
          value: 'month',
        },
      ],
      sortBy: null,
      sortByItems: [
        {
          text: this.$t('cameras_menu.camera_time_travel.options.ascending'),
          value: SORT_ASC,
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.descending'),
          value: SORT_DESC,
        },
      ],
      company: {},
      project: {},
      cameraview: {},
      projectView: {},
      images: {
        isLoading: true,
        totalCount: undefined,
        page: 1,
        list: [],
        error: null,
        timeTravelIndex: 0,
      },
      pagingTimeoutId: null,
      fullScreenGallery: {
        isShow: false,
        initialPage: 1,
        initialImageId: null,
        loadedImages: [],
        initialClickedImage: null,
      },
      livestream: {
        isShow: false,
      },
      datepicker: {
        isActive: false,
        instance: null,
        error: null,
        timeoutId: null,
      },
      goToImageHash: null,
      searchByDateImageId: null,
      earliestImageDate: null,
      latestImageDate: null,
      changingStep: false,
      changingOrder: false,
      moveNext: true,
      stepAmount: 1,
      newIndex: 0,
    };
  },
  async mounted() {
    this.getProjectAndProjectViewDetails();
    this.$nextTick(() => {
      this.initCalendar();
    });
    if (this.$route.params.imageDate) {
      this.projectView.cameraview = this.$route.params.cameraView;
      this.goToImagePageByDate(moment(this.$route.params.imageDate).utc());
    }
  },
  watch: {
    async projectView() {
      const idsToLoad = compact([
        this.projectView.earliest_image,
        this.projectView.latest_image,
      ]);
      await this.fetchImages({ id__in: join(idsToLoad), ordering: '-date' }).then((response) => {
        const {
          [this.projectView.earliest_image]: earliestResponse = null,
          [this.projectView.latest_image]: latestResponse = null,
        } = response.normData;
        const [earliestImage, latestImage] = Object.values(response.normData);
        this.earliestImageDate = earliestImage.date;
        this.latestImageDate = latestImage.date;
        if (earliestResponse) {
          this.datepicker.instance.update({
            minDate: Date.parse(earliestResponse.date),
          });
        }

        if (latestResponse) {
          this.datepicker.instance.update({
            maxDate: Date.parse(latestResponse.date),
          });
        }
      }).finally(() => {
        this.datepicker.isActive = true;
      });
    },
    ordering() {
      this.datepicker.instance.clear();
      if (!this.isDefaultState) {
        this.changingOrder = true;
      }
      this.images.page = 1;
      this.loadNextImages();
    },
  },
  computed: {
    cameraActive() {
      return this.cameraview.active;
    },
    isNewIndex() {
      return this.newIndex;
    },
    isActiveNextPage() {
      return !this.images.isLoading && this.images.page < this.totalPages;
    },
    isActivePrevPage() {
      return !this.images.isLoading && this.images.page > 1;
    },
    isShowNoImages() {
      return !this.images.isLoading && !this.totalImagesLoaded;
    },
    totalImagesLoaded() {
      return this.images.list.length;
    },
    totalPages() {
      return this.calculatePages();
    },
    isAscending() {
      return this.sortBy === SORT_ASC;
    },
    nextImagesOffset() {
      return (this.images.page - 1) * CAMERA_IMAGES_PER_REQUEST_LIMIT;
    },
    ordering() {
      return this.sortBy === SORT_ASC ? SORTS.SORT_ASC : SORTS.SORT_DESC;
    },
    imagesPayload() {
      return {
        cameraview: this.projectView.cameraview,
        offset: this.nextImagesOffset,
        limit: CAMERA_IMAGES_PER_REQUEST_LIMIT,
        ordering: this.ordering,
      };
    },
    livestreamPreview() {
      return this.images.list.length ? [this.images.list]?.image : null;
    },
    goNext() {
      return this.moveNext;
    },
    projectAndCompanyName() {
      const { name: companyName = null } = this.company;
      const { name: projectName = null } = this.project;
      if (!projectName) {
        return null;
      }

      const companyNamePart = companyName ? `${companyName} / ` : '';
      return `${companyNamePart}${projectName}`;
    },
    isLivestreamAvailable() {
      return this.isAllowToViewLivestream && this.projectView.can_livestream;
    },
    isAllowToViewLivestream() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_livestream);
    },
    isAllowToViewAI() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_image_area_identifier);
    },
    referenceImageDate() {
      return moment(this.images.list[0].date);
    },
    isChangingSteps() {
      return this.changingStep;
    },
    isChangingOrder() {
      return this.changingOrder;
    },
    isDefaultState() {
      return this.scrollByStep === 'default' || this.scrollByStep === null;
    },
    searchDate() {
      return moment(this.referenceImageDate.add(this.stepAmount, `${this.scrollByStep}s`))
        .utc()
        .format('YYYY-MM-DDTHH:mm');
    },
    scrollPayload() {
      const before = this.isAscending ? 0 : 15;
      const after = this.isAscending ? 15 : 0;
      return {
        date: this.searchDate,
        cameraviews: this.projectView.cameraview,
        before,
        after,
      };
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
      companies: (state) => state.companies,
      projects: (state) => state.projects,
      projectViews: (state) => state.projectviews,
      isFullScreen: (state) => state.isFullscreenMode,
      allCameraViews: (state) => state.extra.allCameraViews,
    }),
  },
  methods: {
    preparePayload() {
      if (this.isChangingSteps || this.isChangingOrder) {
        this.stepAmount = 0;
        return this.scrollPayload;
      }
      if (this.goNext) {
        this.stepAmount = -1;
        if (this.isAscending) {
          this.stepAmount = 1;
        }
        return this.scrollPayload;
      }
      this.stepAmount = 1;
      if (this.isAscending) {
        this.stepAmount = -1;
      }
      return this.scrollPayload;
    },
    async loadMoreImages() {
      const [beforeNumber, afterNumber] = this.isAscending ? [15, 0] : [0, 15];
      const earliestOrLatest = this.isAscending ? this.latestImageDate : this.earliestImageDate;
      const payload = {
        date: moment(earliestOrLatest)
          .utc()
          .format('YYYY-MM-DDTHH:mm'),
        cameraviews: this.projectView.cameraview,
        before: beforeNumber,
        after: afterNumber,
      };
      await this.fetchClosestImages(payload).then((response) => {
        const newList = this.isAscending ? response.reverse() : response;
        this.newIndex = response.length + 1;
        this.updateImages({ list: newList });
      }).catch(() => {
        this.updateImages({ list: [] });
      }).finally(() => {
        this.updateImages({ isLoading: false });
      });
    },
    imagesByStep() {
      const fetchClosestImagesPayload = this.preparePayload();
      this.fetchClosestImages(fetchClosestImagesPayload).then((response) => {
        let newList = response;
        if (this.isAscending) {
          newList = response.reverse();
        }
        this.updateImages({ list: newList });
      }).catch(() => {
        this.updateImages({ list: [] });
      }).finally(() => {
        this.updateImages({ isLoading: false });
        if (!this.goNext) {
          this.moveNext = true;
        }
        if (this.isChangingSteps) {
          this.changingStep = false;
        }
      });
    },
    calculatePages() {
      const totalImages = this.images.totalCount || 0;
      const dateEarliest = moment(this.earliestImageDate);
      const dateLatest = moment(this.latestImageDate);
      if (!this.isDefaultState) {
        return 1 + Math.ceil(dateLatest.diff(dateEarliest, `${this.scrollByStep}s`, true));
      }
      return Math.ceil(totalImages / CAMERA_IMAGES_PER_REQUEST_LIMIT);
    },
    setRightPage() {
      const latest = moment(this.latestImageDate);
      const reference = moment(this.referenceImageDate);
      const earliest = moment(this.earliestImageDate);
      const newPage = (this.isAscending)
        ? Math.abs(Math.ceil(earliest.diff(reference, `${this.scrollByStep}s`, true)))
        : Math.ceil(latest.diff(reference, `${this.scrollByStep}s`, true));
      this.images.page = Math.min(newPage + 1, this.totalPages);
    },
    jumpToStartOrEnd(date) {
      const [beforeNumber, afterNumber] = this.isAscending ? [0, 15] : [15, 0];
      const payload = {
        date: moment(date)
          .utc()
          .format('YYYY-MM-DDTHH:mm'),
        cameraviews: this.projectView.cameraview,
        before: beforeNumber,
        after: afterNumber,
      };
      this.fetchClosestImages(payload).then((response) => {
        this.updateImages({ list: response });
      }).catch(() => {
        this.updateImages({ list: [] });
      }).finally(() => {
        this.updateImages({ isLoading: false });
      });
    },
    changedSelection() {
      this.changingStep = true;
      this.updateImages({ isLoading: true });
      if (this.isDefaultState) {
        if (this.images.page === 1) {
          const payload = {
            date: moment(this.latestImageDate)
              .utc()
              .format('YYYY-MM-DDTHH:mm'),
            cameraviews: this.projectView.cameraview,
            before: 15,
            after: 0,
          };
          this.fetchClosestImages(payload)
            .then((response) => {
              this.updateImages({ list: response });
            })
            .catch(() => {
              this.updateImages({ list: [] });
            })
            .finally(() => {
              this.updateImages({ isLoading: false });
            });
          return;
        }
        this.calculatePageByDate(moment(this.referenceImageDate));
        return;
      }
      this.setRightPage();
      this.imagesByStep();
    },
    getSliderNextImages({ date }) {
      const filter = this.ordering === SORTS.SORT_ASC ? 'date__gt' : 'date__lt';
      return new Promise((resolve, reject) => {
        this.getImagesForSlider(date, filter, this.ordering).then((images) => {
          if (images.length) {
            this.fullScreenGallery.loadedImages.push(...images);
          }
          resolve(images);
        }).catch((error) => reject(error));
      });
    },
    getSliderPrevImages({ date }) {
      const filter = this.ordering === SORTS.SORT_ASC ? 'date__lt' : 'date__gt';
      const order = this.ordering === SORTS.SORT_ASC ? SORTS.SORT_DESC : SORTS.SORT_ASC;
      return new Promise((resolve, reject) => {
        this.getImagesForSlider(date, filter, order).then((images) => {
          if (images.length) {
            this.fullScreenGallery.loadedImages.unshift(...images);
          }
          resolve(images);
        }).catch((error) => reject(error));
      });
    },
    getImagesForSlider(date, filter, order) {
      const payload = {
        ...omit(this.imagesPayload, ['offset']),
        [filter]: date,
        ordering: order,
      };
      return new Promise((resolve, reject) => this.fetchImages(payload).then((response) => {
        const imageList = orderBy(response.normData, (image) => moment(image.date).unix());
        if (this.sortBy !== SORT_ASC) {
          imageList.reverse();
        }
        resolve(imageList);
      }).catch((error) => reject(error)));
    },
    async getProjectAndProjectViewDetails() {
      const projectId = this.$route.params.projectId || null;
      const projectViewId = this.$route.params.projectViewId || null;

      if (!projectId || !projectViewId) {
        this.updateImages({ isLoading: false });
        return;
      }

      await Promise.all([
        this.projects[projectId] ? projectId : this.retrieveProjects(projectId),
        this.projectViews[projectViewId] ? projectViewId : this.retrieveProjectView(projectViewId),
      ]).then(() => {
        this.project = this.projects[projectId];
        this.projectView = this.projectViews[projectViewId];
        this.loadCompany();
        this.loadCameraView();
        this.loadNextImages();
      }).catch(() => {
        this.updateImages({ isLoading: false });
      });
    },
    async loadCameraView() {
      const { cameraview: camerviewId } = this.projectView;
      if (!camerviewId) {
        return;
      }
      await this.retrieveCameraViews(camerviewId).then((cameraview) => {
        this.cameraview = cameraview;
      });
    },
    loadCompany() {
      const { company: companyId } = this.project;
      if (!companyId) {
        return;
      }
      const companiesState = isEmpty(this.allCompanies) ? this.companies : this.allCompanies;
      if (companyId in companiesState) {
        this.company = companiesState[companyId];
        return;
      }
      this.retrieveCompanies(companyId).then((company) => {
        this.company = company;
      });
    },
    loadNextImages() {
      this.updateImages({ isLoading: true });
      if (this.isDefaultState) {
        this.fetchImages(this.imagesPayload).then((payload) => {
          const imageList = orderBy(payload.normData, (image) => moment(image.date).unix());
          if (this.sortBy !== SORT_ASC) {
            imageList.reverse();
          }
          this.updateImages({
            totalCount: payload.response.data.count,
            list: imageList,
          });
        }).catch(() => {
          this.updateImages({ list: [] });
        }).finally(() => {
          this.updateImages({ isLoading: false });
        });
      } else if (this.isChangingOrder) {
        const earliestOrLatestImageDate = this.isAscending
          ? this.earliestImageDate : this.latestImageDate;
        this.jumpToStartOrEnd(earliestOrLatestImageDate);
        this.changingOrder = false;
      } else {
        this.changingOrder = false;
        this.imagesByStep();
      }
    },
    formatDate(date) {
      return `${moment(date).format('dd DD MMM YYYY HH:mm')} CEST`;
    },
    initCalendar() {
      this.datepicker.instance = new AirDatepicker(
        '#camera_details__actions__search__datetime-picker',
        {
          locale: localeEn,
          timepicker: true,
          dateFormat: 'dd/MM/yyyy',
          timeFormat: 'HH:mm',
          onSelect: (dp) => {
            const { date: selectedDate = null } = dp;
            clearTimeout(this.datepicker.timeoutId);

            if (!selectedDate) {
              this.searchByDateImageId = null;
              return;
            }

            this.datepicker.timeoutId = setTimeout(() => {
              this.goToImagePageByDate(moment(selectedDate.toISOString()).utc());
            }, DETAIL_CAMERA_FILTERING_BY_DATE_TIMEOUT);
          },
        },
      );
    },
    async calculatePageByDate(date) {
      this.updateImages({ isLoading: true });
      const fetchClosestImagesPayload = {
        date: date.format('YYYY-MM-DDTHH:mm'),
        cameraviews: this.projectView.cameraview,
        before: 0,
        after: 0,
      };
      Promise.all([
        this.fetchImages({ cameraview: this.projectView.cameraview, limit: 1 }),
        this.fetchClosestImages(fetchClosestImagesPayload),
      ]).then((data) => {
        const [actualImagesState, [desiredImage]] = data;
        if (!actualImagesState || !desiredImage) {
          this.updateImages({ isLoading: false });
          return;
        }
        const { response: { data: { count: imagesCount } } } = actualImagesState;
        const imagePositionDesc = imagesCount - desiredImage.row_number + 1;
        const expectedPageNumber = this.sortBy === SORT_ASC ? Math.ceil(
          desiredImage.row_number / CAMERA_IMAGES_PER_REQUEST_LIMIT,
        ) : Math.ceil(imagePositionDesc / CAMERA_IMAGES_PER_REQUEST_LIMIT);
        this.images.page = expectedPageNumber > 0 ? expectedPageNumber : 1;
        this.loadNextImages();
      }).catch(() => {
        this.updateImages({ isLoading: false });
      });
    },
    async goToImagePageByDate(date) {
      if (!this.isDefaultState) {
        this.scrollByStep = null;
      }
      this.updateImages({ isLoading: true });
      const fetchClosestImagesPayload = {
        date: date.format('YYYY-MM-DDTHH:mm'),
        cameraviews: this.projectView.cameraview,
        before: 0,
        after: 0,
      };
      const goToImageHash = md5(JSON.stringify(fetchClosestImagesPayload));
      this.goToImageHash = goToImageHash;
      Promise.all([
        this.fetchImages({ cameraview: this.projectView.cameraview, limit: 1 }),
        this.fetchClosestImages(fetchClosestImagesPayload),
      ]).then((data) => {
        // if the user choose a new date multiple times be sure that we show the last actual state
        if (this.goToImageHash !== goToImageHash) {
          return;
        }
        const [actualImagesState, [desiredImage]] = data;
        if (!actualImagesState || !desiredImage) {
          this.updateImages({ isLoading: false });
          return;
        }
        const { response: { data: { count: imagesCount } } } = actualImagesState;
        const imagePositionDesc = imagesCount - desiredImage.row_number + 1;
        const expectedPageNumber = this.sortBy === SORT_ASC ? Math.ceil(
          desiredImage.row_number / CAMERA_IMAGES_PER_REQUEST_LIMIT,
        ) : Math.ceil(imagePositionDesc / CAMERA_IMAGES_PER_REQUEST_LIMIT);
        this.searchByDateImageId = desiredImage.id;
        this.images.page = expectedPageNumber > 0 ? expectedPageNumber : 1;
        this.loadNextImages();
      }).catch(() => {
        this.updateImages({ isLoading: false });
      });
    },
    onCloseFullScreenGalleryHandler({ image }) {
      this.updateImages({ isLoading: true });
      if (!this.isDefaultState) {
        this.scrollByStep = null;
        this.loadNextImages();
      }
      this.goToImagePageByDate(moment(image.date).utc());
      this.fullScreenGallery.isShow = false;
      this.updateImages({ isLoading: false });
    },
    async onCameraImageClickHandler(slideIndex, event, image) {
      if (event.target.tagName === 'I') {
        return;
      }
      this.images.timeTravelIndex = slideIndex;
      if (this.images.list.length === 1) {
        await this.loadMoreImages();
        this.images.timeTravelIndex = this.isNewIndex;
      }
      this.fullScreenGallery = {
        ...this.fullScreenGallery,
        loadedImages: this.images.list,
        initialPage: this.images.page,
        initialImageId: this.images.list[0].id,
        initialClickedImage: image,
        isShow: true,
      };
    },
    onCloseLivestreamHandler() {
      this.livestream.isShow = false;
    },
    onLivestreamClickHandler() {
      if (!this.isLivestreamAvailable) {
        return;
      }
      this.startStream({ id: this.projectView.cameraview });
      this.livestream.isShow = true;
    },
    onFirstPageClickHandler() {
      if (!this.isActivePrevPage) {
        return;
      }
      this.images.page = 1;

      clearTimeout(this.pagingTimeoutId);
      this.pagingTimeoutId = setTimeout(() => {
        if (!this.isDefaultState) {
          this.jumpToStartOrEnd(this.isAscending ? this.earliestImageDate : this.latestImageDate);
        } else {
          this.loadNextImages();
        }
      }, DETAIL_CAMERA_PAGING_TIMEOUT);
    },
    onPreviousPageClickHandler() {
      if (!this.isActivePrevPage) {
        return;
      }
      this.images.page -= 1;
      this.moveNext = false;

      clearTimeout(this.pagingTimeoutId);
      this.pagingTimeoutId = setTimeout(() => {
        this.loadNextImages();
      }, DETAIL_CAMERA_PAGING_TIMEOUT);
    },
    onNextPageClickHandler() {
      if (!this.isActiveNextPage) {
        return;
      }
      this.images.page += 1;

      clearTimeout(this.pagingTimeoutId);
      this.pagingTimeoutId = setTimeout(() => {
        this.loadNextImages();
      }, DETAIL_CAMERA_PAGING_TIMEOUT);
    },
    onLastPageClickHandler() {
      if (!this.isActiveNextPage) {
        return;
      }
      this.images.page = this.totalPages;
      clearTimeout(this.pagingTimeoutId);
      this.pagingTimeoutId = setTimeout(() => {
        if (!this.isDefaultState) {
          this.jumpToStartOrEnd(this.isAscending ? this.latestImageDate : this.earliestImageDate);
        } else {
          this.loadNextImages();
        }
      }, DETAIL_CAMERA_PAGING_TIMEOUT);
    },
    updateImages(data) {
      this.images = {
        ...this.images,
        ...data,
      };
    },
    ...mapActions({
      retrieveCompanies: 'retrieveCompanies',
      retrieveProjectView: 'retrieveProjectviews',
      retrieveProjects: 'retrieveProjects',
      fetchClosestImages: 'fetchClosestImages',
      fetchImages: 'listImages',
      startStream: 'postStartStream',
      retrieveCameraViews: 'retrieveCameraviews',
    }),
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .camera_details {
    width: 100%;

    .camera_details__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 7vh;

      @media (orientation: portrait) {
        margin-bottom: 4vh;
      }
      @media screen and (max-width: 900px) {
        font-size: 2em;
      }

      .camera_details__actions__left-side {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > * {
          margin-right: 0.53vw;

          &:last-child {
            margin-right: 0;
          }
        }

        .camera_details__actions__search::v-deep {
          position: relative;
          padding-bottom: 1.3vh;
          width: 10.2vw;
          font-size: 1.1em;

          @media (orientation: portrait) {
            width: 20vw;
            padding-bottom: 1vh;
          }
          @media only screen and (max-width: 900px) {
            margin-right: 3vw;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -1px;
            width: 100%;
            height: 1px;
            background-color: $default-blue-ultra-dark-color;
          }

          &.disabled {
            opacity: .3;
          }

          input {
            font-family: $main-font-family, "Material Design Icons", sans-serif;
            text-align: center;
            color: $default-blue-ultra-dark-color;
            width: 100%;

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: $default-blue-ultra-dark-color;
              text-align: center;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: $default-blue-ultra-dark-color;
              text-align: center;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              color: $default-blue-ultra-dark-color;
              text-align: center;
            }

            &:focus {
              outline: none;
            }
          }
        }

        .camera_details__actions__sort::v-deep {
          width: 10.2vw;

          @media (orientation: portrait) {
            width: 16vw;
          }

          .v-input {
            font-size: 1.1em;
            font-family: $body-font-family;
            padding: 0;
            margin: 0;

            .v-input__slot:before {
              border-color: $default-blue-ultra-dark-color;
              background-color: $default-blue-ultra-dark-color;
            }

            .v-input__slot:after {
              border-color: $default-blue-color;
              background-color: $default-blue-color;
            }

            .v-select__selections {
              .v-select__selection--comma {
                padding-bottom: 2vh;
                position: absolute;
                margin: 0;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: $default-blue-ultra-dark-color;
              }

              input {
                font-family: $main-font-family, "Material Design Icons", sans-serif;
                padding: 0 0 1.3vh;
                line-height: 1;
                max-height: initial;

                @media (orientation: portrait) {
                  width: 20vw;
                  padding-bottom: 1vh;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                  opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .camera_details__actions__right-side {
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 900px) {
          display: none;
        }

        .camera_details__actions__right-side__component {
          position: relative;
          min-width: 10vw;
          margin-right: 0.53vw;
          padding-bottom: 1.3vh;

          @media (orientation: portrait) {
            margin-right: 1.5vw;
            padding: 0 1.5vw 1.3vh 1.5vw;
          }

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $default-blue-ultra-dark-color;
          }
        }
      }
    }

    .camera_details__hidden {
      display: none;
    }
  }

  .camera_details__images {
    .camera_details__images__project_name_and_livestream {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.8vh;
      font-size: 2em;

      .camera_details__images__livestream {
        top: 50%;
        cursor: pointer;
      }
      @media only screen and (max-width: 900px) {
        font-size: 2.2em;
      }
    }

    .camera_details__images__camera {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: 2.2vh;

      @media only screen and (max-width: 900px) {
        margin-top: 3vh;
        margin-bottom: 3vh;
      }

      .camera_details__images__camera__name {
        position: relative;
        font-size: 1.4em;
        line-height: 1;
        padding-left: 5vw;

        @media only screen and (max-width: 900px) {
          font-size: 2em;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          width: 3.9vw;
          height: 1px;
          background-color: $default-blue-ultra-dark-color;
        }
      }

      .camera_details__images__camera_paging {

        @media only screen and (max-width: 900px) {
          font-size: 2.4em;
        }

        .camera_details__images__camera_paging__prev,
        .camera_details__images__camera_paging__next,
        .camera_details__images__camera_paging__first,
        .camera_details__images__camera_paging__last{
          cursor: pointer;
        }

        .camera_details__images__camera_paging__disabled {
          opacity: .5;
          cursor: default;
        }
      }
    }

    .camera_details__images__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: .521vw;
      row-gap: 2.77778vh;

      @media screen and (max-width: 1250px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 640px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .camera_details__images__list__image {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        cursor: pointer;

        .camera_details__images__list__image__picture {
          width: 100%;

          &.highlighted {
            box-shadow: 0 0 1.1vw $default-blue-color;
          }

          picture {
            display: flex;
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: scale-down;
            }
          }
        }

        .camera_details__images__list__image__date {
          margin-top: 2vh;
          color: $default-blue-color;
          font-weight: 500;

          @media only screen and (max-width: 900px) {
            font-size: 1.4em;
          }

          .camera_details__images__list__image__link {
            color: inherit;
            @media only screen and (max-width: 900px) {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
