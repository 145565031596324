<template>
  <div class="user_preferences_page">
    <v-alert :type="message.type" :value="message.isShow">
      {{ message.text }}
    </v-alert>
    <form class="change-settings-form" ref="form"
          @submit.prevent="submitForm"
          @change="$_authMixin_onFormChange($refs.form)">
      <div class="password_settings">
        <div class="settings_title">
          {{ $t('my_csite_menu.settings_menu.preferences.titles.language')}}
        </div>
        <div class="language__container">
          <p class="change-settings-form__text">
            {{ $t('my_csite_menu.settings_menu.preferences.language_text')}}
          </p>
          <div class="language-switcher">
            <LanguageSwitcher />
          </div>
        </div>
        <div class="settings_title">
          {{ $t('my_csite_menu.settings_menu.preferences.titles.password')}}
        </div>
        <div class="change-settings-form__inputs">
          <div class="change-settings-form__input">
            <div class="change-settings-form__input__wrapper">
              <input
                id="old_password"
                type="password"
                :placeholder="$t('my_csite_menu.settings_menu.preferences.' +
                 'form.old_password')"
                v-model="form.old_password.value"
                @input="$_authMixin_validate">
              <i class="mdi mdi-check hide"/>
            </div>
          </div>
          <div class="change-settings-form__input">
            <div class="change-settings-form__input__wrapper">
              <input
                id="new_password"
                type="password"
                :placeholder="$t('my_csite_menu.settings_menu.preferences.' +
                 'form.new_password')"
                v-model="form.new_password.value"
                @input="$_authMixin_validate">
              <i class="mdi mdi-check hide"/>
            </div>
          </div>
          <div class="change-settings-form__input">
            <div class="change-settings-form__input__wrapper">
              <input
                id="new_password_confirmation"
                type="password"
                :placeholder="$t('my_csite_menu.settings_menu.preferences.' +
                 'form.password_confirmation')"
                v-model="form.new_password_confirmation.value"
                @input="$_authMixin_validate"
                @change="repeatPasswordValidate">
              <i class="mdi mdi-check hide"/>
            </div>
          </div>
        </div>
      </div>
      <ButtonSubmit :title="$t('buttons.save_changes')"
                    @click="submitForm"
                    :is-show-loading="$_authMixin_loading"
                    :is-disabled="$_authMixin_submitDisabledState"
                    class="button_submit"/>
    </form>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { mapState } from 'vuex';
import LanguageSwitcher from '@/components/v3/Helpers/LanguageSwitcher';

export default {
  name: 'SettingsPreferences',
  mixins: [AuthMixin],
  components: {
    LanguageSwitcher,
    ButtonSubmit,
  },
  data() {
    return {
      form: {
        old_password: {
          value: null,
          error: null,
        },
        new_password: {
          value: null,
          error: null,
        },
        new_password_confirmation: {
          value: null,
          error: null,
        },
      },
      message: {
        isShow: false,
        type: 'error',
        text: null,
      },
      formErrorMessages: {
        passwordsNotMatch: this.$t('settings.change_password.errors.passwords_not_match'),
      },
    };
  },
  computed: {
    ...mapState({
      cognitoUser: (state) => state.auth.activeUser,
      user: (state) => state.activeUser,
    }),
    currentData() {
      return {
        notification_emails: this.user.notification_emails,
        weekly_email_updates: this.user.weekly_email_updates,
        monthly_email_updates: this.user.monthly_email_updates,
        daily_email_updates: this.user.daily_email_updates,
      };
    },
  },
  methods: {
    repeatPasswordValidate(e) {
      this.$_authMixin_validate(e);
      if (this.form.new_password.value !== this.form.new_password_confirmation.value) {
        const input = e.target;
        const inputId = input.getAttribute('id');
        input.setCustomValidity(this.formErrorMessages.passwordsNotMatch);
        this.form[inputId].error = input.validationMessage;
      }
    },
    submitForm(e) {
      this.$_authMixin_submitForm(e);
      const data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        weekly_email_updates: this.currentData.weekly_email_updates,
        monthly_email_updates: this.currentData.monthly_email_updates,
        daily_email_updates: this.currentData.daily_email_updates,
        notification_emails: this.currentData.notification_emails,
        id: this.user.id,
        email: this.user.email,
      };
      this.$store
        .dispatch('updateUsers', { id: this.user.id, data })
        .then(() => {
          this.$store.dispatch('getCurrentAuthenticatedUser', { bypassCache: true });
          this.message = {
            isShow: true,
            type: 'success',
            text: this.$t('my_csite_menu.settings_menu.preferences.settings.success'),
          };
          setTimeout(() => {
            this.message = {
              isShow: false,
              type: 'error',
              text: this.$t('my_csite_menu.settings_menu.preferences.settings.error'),
            };
          }, 3000);
        }).catch(() => {
          this.message = {
            isShow: true,
            type: 'error',
            text: this.$t('my_csite_menu.settings_menu.preferences.settings.error'),
          };
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
      if (this.form.old_password.value && this.form.new_password.value) {
        const payload = {
          user: this.$store.state.auth.activeUser,
          oldPassword: this.form.old_password.value,
          newPassword: this.form.new_password.value,
        };
        this.$store
          .dispatch('changePassword', payload)
          .then(() => {
            this.$store.dispatch('getCurrentAuthenticatedUser', { bypassCache: true });
            this.message = {
              isShow: true,
              type: 'success',
              text: this.$t('settings.change_password.changed_successfully_message'),
            };
            this.$refs.form.reset();
          })
          .catch((error) => {
            if (error.message) {
              this.errorMessage = error.message;
            } else {
              this.errorMessage = this.$t('my_csite_menu.settings_menu.preferences.password.error');
            }
          })
          .finally(() => {
            this.$_authMixin_loading = false;
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/v3/cSiteIcons.css';
@import '~@/sass/mixins/v3/settings.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.user_preferences_page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }
  .change-settings-form {
    @media only screen and (max-width: 900px) {
      font-size: 1.4em;
      padding-left: 5vw;
      padding-right: 5vw;
    }
    .password_settings {
      margin-bottom: 2vh;
      .language__container {
        width: fit-content;
        margin-bottom: 3.6vh;
        .language-switcher {
          text-align: center;
        }
      }
    }

    .change-settings-form__input {
      padding-top: 1vh;
      padding-bottom: 1vh;

      @media (orientation: landscape) and (max-height: 250px) {
        padding-top: 3vh;
        padding-bottom: 3vh;
        margin-top: 3vh;
      }

      .change-settings-form__input__wrapper {
        position: relative;
        width: 100%;
        display: flex;
        border-bottom: 1px solid $default-disabled-color;

        @media only screen and (min-width: 600px) {
          width: 50%;
        }
        @media (orientation: landscape) and (max-height: 250px) {
          font-size: 1.6em;
        }

        input {
          padding-top: 0.8vh;
          padding-bottom: 0.8vh;
          width: 100%;
          line-height: 1;
          color: $default-blue-ultra-dark-color;

          &:focus, &:active {
            outline: none;
            background-color: rgba(8, 117, 221, 0.1);
            padding-left: 1vh;
          }

          &::placeholder {
            color: $default-blue-ultra-dark-color;
            opacity: 1;
          }
        }
      }
    }
    .change-settings-form__text {
      margin-top: 2vh;
      margin-bottom: 3vh;

      @media (orientation: landscape) and (max-height: 250px) {
        padding-top: 3vh;
        font-size: 1.6em;
      }
    }
    .notification_settings {
      margin-bottom: 3vh;
    }
  }
}
</style>
