export const ACTIVATE_NAVIGATIONDRAWER = 'Activate navigation drawer';
export const DEACTIVATE_NAVIGATIONDRAWER = 'Deactivate navigation drawer';
export const SET_LOADING = 'Loading ON/OFF';
export const SET_ACTIVEUSER = 'Set active user';
export const SET_AJAXERROR = 'Set ajax error';
export const SET_ACTIVE_MENU = 'Set active menu button';
export const TOGGLE_FULLSCREEN = 'Toggle full screen';
export const TOGGLE_MAIN_LAYOUT_PADDING = 'Toggle main layout padding';

export function getMutationTypes(endpoint) {
  /*
    Create mutation types 'set, get, create, update' based
    on the endpoints name.
  */

  const mutations = {
    REQUEST_MUTATION: `${endpoint.name} request pending.`,
    LIST_SUCCESS_MUTATION: `${endpoint.name} list successful`,
    RETRIEVE_SUCCESS_MUTATION: `${endpoint.name} retrieve successful`,
    CREATE_SUCCESS_MUTATION: `${endpoint.name} create successful`,
    UPDATE_SUCCESS_MUTATION: `${endpoint.name} update successful`,
    PATCH_SUCCESS_MUTATION: `${endpoint.name} patch successful`,
    DESTROY_SUCCESS_MUTATION: `${endpoint.name} destroy successful`,
    ERROR_MUTATION: `${endpoint.name} request failed`,
  };
  if (endpoint.actions !== undefined) {
    endpoint.actions.forEach((action) => {
      mutations[`${action.mutationName}_REQUEST_MUTATION`] = `${action.mutationName} request pending`;
      mutations[`${action.mutationName}_ERROR_MUTATION`] = `${action.mutationName} request failed`;
      mutations[`${action.mutationName}_GET_MUTATION`] = `${action.mutationName} get action successful`;
      mutations[`${action.mutationName}_POST_MUTATION`] = `${action.mutationName} post action successful`;
      mutations[`${action.mutationName}_PATCH_MUTATION`] = `${action.mutationName} patch action successful`;
      mutations[`${action.mutationName}_PUT_MUTATION`] = `${action.mutationName} put action successful`;
      mutations[`${action.mutationName}_DELETE_MUTATION`] = `${action.mutationName} delete action successful`;
    });
  }

  return mutations;
}
