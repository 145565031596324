// eslint-disable-next-line import/no-cycle
import autoGeneratedActions from '@/store/autoGeneratedActions';
import { SET_LOADING } from '@/store/mutation-types';
import {
  GET_ALL_COMPANIES, GET_ALL_PROJECTS, GET_ALL_PROJECT_VIEWS, GET_ALL_CAMERA_VIEWS, GET_ALL_GROUPS,
  GET_ALL_AI_MODELS,
} from './mutation-types';

export default {
  async fetchAllCompanies({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listCompanies({ commit }, { no_limit: true }).then((response) => {
      commit(GET_ALL_COMPANIES, response);
      return response;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async fetchAllProjects({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listProjects({ commit }, { no_limit: true }).then((response) => {
      commit(GET_ALL_PROJECTS, response);
      return response;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async fetchAllProjectViews({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listProjectviews({ commit }, { no_limit: true })
      .then((response) => {
        commit(GET_ALL_PROJECT_VIEWS, response);
        return response;
      }).finally(() => {
        commit(SET_LOADING, false);
      });
  },
  async fetchAllCameraViews({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listCameraviews({ commit }, { no_limit: true })
      .then((response) => {
        commit(GET_ALL_CAMERA_VIEWS, response);
        return response;
      }).finally(() => {
        commit(SET_LOADING, false);
      });
  },
  async fetchAllGroups({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listGroups({ commit }, { no_limit: true })
      .then((response) => {
        commit(GET_ALL_GROUPS, response);
        return response;
      }).finally(() => {
        commit(SET_LOADING, false);
      });
  },
  async fetchAllAIModels({ commit }) {
    commit(SET_LOADING, true);
    return autoGeneratedActions.listModelalgorithms({ commit }, { no_limit: true })
      .then((response) => {
        commit(GET_ALL_AI_MODELS, response);
        return response;
      }).finally(() => {
        commit(SET_LOADING, false);
      });
  },
};
