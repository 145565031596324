const englishIcon = require('@/assets/images/languages/english.png');

export default {
  en: {
    caption_translation_code: 'settings.language.languages.english',
    icon: englishIcon,
  },
  nl: {
    caption_translation_code: 'settings.language.languages.dutch',
    icon: '',
  },
  fr: {
    caption_translation_code: 'settings.language.languages.french',
    icon: '',
  },
  de: {
    caption_translation_code: 'settings.language.languages.german',
    icon: '',
  },
};
