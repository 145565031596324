<template>
  <div class="popup">
    <div class="popup__box">
      <div class="popup__box__title">
        {{ $t('add_membership_popup.title') }} {{ addToCompany.name }}
      </div>
      <div class="popup__box__subtext">
        {{ $t('add_membership_popup.reminder') }}
      </div>
      <v-alert class="popup__box__message"
               :type="message.type" :value="message.isShow" v-if="message.isShow">
        {{ $t(message.text) }}
      </v-alert>
      <div class="popup__box__form row">
        <div class="col-md-8">
          <v-text-field id="email" single-line dense flat hide-details
            v-model="emailToAddCompanyMembership.value"
            :placeholder="$t('components.share_email_popup.form.placeholders.email')"/>
          <label for="email">{{ emailToAddCompanyMembership.error }}</label>
        </div>
        <div class="col-md-4">
          <v-select :items="optionsMembersCompany.list"
                    item-text="text"
                    item-value="value"
                    append-icon=""
                    :label="selectRoleLabel"
                    single-line
                    v-model="optionsMembersCompany.selected"
                    hide-details
                    class="no-padding-select"/>
        </div>
      </div>
      <div class="popup__box__button">
        <ButtonSubmit :title="$t('cameras_menu.map_tab.button_add')"
                      :is-show-loading="isLoading"
                      @click="addNewCompanyMembership"/>
      </div>
      <div class="popup__box__icon">
        <i class="icon__close mdi mdi-window-close" @click="onCloseClickHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { mapActions } from 'vuex';

export default {
  name: 'AddCompanyMemberPopup',
  components: { ButtonSubmit },
  props: ['addToCompany'],
  data() {
    return {
      selectRoleLabel: this.$t('my_csite_menu.projects_menu.users.select_role'),
      isLoading: false,
      message: {
        isShow: false,
        type: 'error',
        text: null,
      },
      emailToAddCompanyMembership: {
        value: null,
        error: null,
      },
      optionsMembersCompany: {
        list: [
          {
            value: 'company admin',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.company_admin')),
          },
          {
            value: 'company member',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.company_member')),
          },
        ],
        selected: null,
      },
    };
  },
  methods: {
    capitalize(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async addNewCompanyMembership() {
      this.isLoading = true;
      try {
        if (!this.optionsMembersCompany.selected) {
          this.message.isShow = true;
          this.message.type = 'error';
          this.message.text = this.$t('add_membership_popup.select_role');
          setTimeout(() => {
            this.message.isShow = false;
          }, 3000);
          return;
        }
        const isCompanyAdmin = this.optionsMembersCompany.selected.includes('admin');
        const response = await this.fetchUsers(
          {
            email: this.emailToAddCompanyMembership.value,
            limit: 1,
          },
        );
        if (Object.keys(response.normData).length === 0) {
          this.message.type = 'info';
          this.message.text = this.$t('add_membership_popup.info_message');
          this.message.isShow = true;
          await this.sendInvitation(this.emailToAddCompanyMembership.value, this.addToCompany.id,
            isCompanyAdmin);
          setTimeout(() => {
            this.message.isShow = false;
            this.$emit('membershipAdded');
          }, 5000);
          return;
        }
        const userId = Object.keys(response.normData)[0];
        if (userId) {
          const payload = {
            member: userId,
            company: this.addToCompany.id,
            roles: [],
            company_admin: isCompanyAdmin,
          };
          await this.createCompanymemberships(payload);
          this.$emit('membershipAdded');
        }
      } catch (error) {
        this.message.isShow = true;
        this.message.type = 'error';
        this.message.text = this.$t('add_membership_popup.error_message');
        setTimeout(() => {
          this.message.isShow = false;
        }, 3000);
      } finally {
        this.isLoading = false;
      }
    },
    async sendInvitation(emailAddress, companyId, isAdmin) {
      const payload = {
        id: companyId,
        params: {
          email: emailAddress,
          is_owner: isAdmin,
        },
      };
      await this.postCompanyInvite(payload);
    },
    onCloseClickHandler() {
      this.$emit('close');
    },
    ...mapActions({
      fetchUsers: 'listUsers',
      createCompanymemberships: 'createCompanymemberships',
      postCompanyInvite: 'postCompanyInvite',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);

  .popup__box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    max-height: 90%;
    margin-top: 10vh;
    padding: 2.5vh;
    background-color: $default-background-color;
    cursor: default;

    @media only screen and (max-width: 900px) {
      max-height: 80%;
      min-height: 80%;
      max-width: 100%;
    }

    .popup__box__title {
      padding-left: 1.5vw;
      padding-top: 1.5vh;
      padding-bottom: 2vh;
      font-size: 2em;
      font-weight: bold;
      color: $default-blue-dark-color;
    }
    .popup__box__subtext {
      display: flex;
      margin-left: 2vw;
      margin-right: 2vw;
      margin-bottom: 4vh;
    }
    .popup__box__message {
      margin-left: 2.5vw;
      margin-right: 3.5vw;
      margin-bottom: 4vh;
    }
    .popup__box__form {
      display: flex;
      margin-left: 2vw;
      margin-right: 3vw;
      margin-bottom: 2vh;
    }
    .popup__box__button {
      margin-left: 2.5vw;
    }
  }
}

.icon__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5vh;
  padding-top: 1.5vh;
  opacity: 0.4;
}
</style>
