// eslint-disable-next-line import/no-cycle
import csite from '@/services/csite';
import { SET_LOADING } from '@/store/mutation-types';

export default {
  async sendSubmitTicket({ commit }, { id, payload }) {
    commit(SET_LOADING, true);
    return csite.users.submit_ticket.post({ id, params: payload }).then((result) => {
      const { data = [] } = result;
      return data;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
};
