<template>
  <div class="settings">
    <Menu class="settings__menu" :menu-list="permissionMenuList"
          :init-active-index="activeIndex" :allow-hash="true"
          :allow-set-title="true"
          @activeTabChanged="onTabChanged"
          @menuHashInit="onTabChanged"
          :class="{ settings__menu__hidden: isFullScreen }" />
    <component :is="activeComponent" v-bind="activeComponentProps" v-on="activeComponentEvents"
               v-if="activeComponent" />
  </div>
</template>
<script>
import Menu from '@/components/v3/TabsMenu';
import Preferences from '@/components/v3/Profile/Preferences';
import Support from '@/components/v3/Profile/Support';
import Faq from '@/components/v3/Profile/Faq';
import CookiesSettings from '@/components/v3/Profile/CookiesSettings';
import { mapState } from 'vuex';
import Emails from '@/components/v3/Profile/Emails';
import { filter, map, some } from 'lodash';

export default {
  name: 'Profile',
  components: {
    Menu,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('my_csite_menu.settings_menu.tabs.preferences'),
          code: 'preferences',
          component: Preferences,
        },
        {
          name: this.$t('my_csite_menu.settings_menu.tabs.mails'),
          code: 'emails',
          component: Emails,
        },
        {
          name: this.$t('my_csite_menu.settings_menu.tabs.support'),
          code: 'support',
          component: Support,
        },
        {
          name: this.$t('my_csite_menu.settings_menu.tabs.faq'),
          code: 'faq',
          component: Faq,
        },
        {
          name: this.$t('my_csite_menu.settings_menu.tabs.cookies'),
          code: 'cookies',
          component: CookiesSettings,
        },
      ],
      activeIndex: 0,
      activeMenuTab: null,
    };
  },
  beforeMount() {
    if (!this.activeMenuTab) {
      [this.activeMenuTab] = this.permissionMenuList;
    }
  },
  watch: {
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
  },
  computed: {
    activeComponent() {
      return this.activeMenuTabForRender?.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    activeComponentEvents() {
      return this.activeMenuTabForRender?.events || {};
    },
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission))),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    onTabChanged(newTab, newIndex) {
      this.activeIndex = newIndex;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
.tabs {
  margin-bottom: 2vh;
}

.settings {
  position: relative;
  width: 100%;

  .settings__menu {
    position: relative;
    width: 100vw;
    left: -$page-global-padding-horizontal;
    padding: 2.1vh $page-global-padding-horizontal;

    @media (orientation: portrait) {
      left: -$page-global-portrait-padding-horizontal;
      padding: 2.1vh $page-global-portrait-padding-horizontal;
    }
    @media screen and (max-width: 900px) {
      font-size: 2.2em;
    }
  }

  .settings__menu__hidden {
    display: none;
  }
}
</style>
