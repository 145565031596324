<template>
  <div class="make_own_timelapse">
    <Menu class="make_own_timelapse__menu" :menu-list="permissionMenuList"
          :init-active-index="activeIndex" :allow-hash="true" :allow-set-title="false"
          @activeTabChanged="onTabChanged"
          :class="{ make_own_timelapse__menu__hidden: isFullScreen }" />
    <component :is="activeComponent" v-bind="activeComponentProps" v-on="activeComponentEvents"
               :key="$route.hash" v-if="activeComponent" />
  </div>
</template>

<script>
import Timelapses from '@/components/v3/Timelapses';
import Editor from '@/components/v3/Timelapses/MakeOwnTimelapse/Editor';
import Logos from '@/components/v3/Timelapses/MakeOwnTimelapse/Logos';
import Menu from '@/components/v3/TabsMenu';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import { CATEGORY_CUSTOM } from '@/constants/v3/timelapses';
import { mapState } from 'vuex';
import {
  filter, forEach, map, some,
} from 'lodash';
import { TIMELAPSES_URL } from '@/constants/v3/menu';

export default {
  name: 'MakeOwnTimelapse',
  mixins: [permissionMixin],
  props: {
    searchBy: {
      type: String,
      default: '',
    },
  },
  components: {
    Menu,
  },
  data() {
    return {
      TIMELAPSES_URL,
      menuList: [
        {
          name: this.$t('timelapses_menu.custom_menu.tabs.editor'),
          code: 'editor',
          component: Editor,
          events: {
            scheduled: () => { this.activeIndex = 1; },
          },
          permissions: [
            PERMISSIONS.add_custom_timelapse,
          ],
        },
        {
          name: this.$t('timelapses_menu.custom_menu.tabs.library'),
          code: 'library',
          component: Timelapses,
          props: {
            timelapseCategory: CATEGORY_CUSTOM,
            isShowFinishedOnly: false,
            isShowStatus: true,
            searchBy: this.searchBy,
            isRemovable: true,
          },
          permissions: [
            PERMISSIONS.add_custom_timelapse,
            PERMISSIONS.view_custom_timelapse,
          ],
        },
        {
          name: this.$t('timelapses_menu.custom_menu.tabs.logos'),
          code: 'logos',
          component: Logos,
          permissions: [
            PERMISSIONS.add_logo,
            PERMISSIONS.delete_logo,
          ],
        },
      ],
      activeIndex: 0,
      activeMenuTab: null,
    };
  },
  beforeMount() {
    if (this.$route.hash === '#custom') {
      this.$nextTick(() => {
        this.$router.push({ path: `${this.TIMELAPSES_URL.path}`, hash: 'library' });
      });
    }
    if (!this.activeMenuTab) {
      [this.activeMenuTab] = this.permissionMenuList;
    }
  },
  watch: {
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
    searchBy: {
      handler(newValue) {
        forEach(this.permissionMenuList, (menuItem) => {
          const props = menuItem?.props || {};
          if ('searchBy' in props) {
            menuItem.props.searchBy = newValue;
          }
        });
      },
      immediate: true,
    },
    '$route.hash': function (newHash) {
      if (!newHash) {
        return;
      }
      const page = newHash.replace('#', '');
      if (page === 'custom') {
        this.$nextTick(() => {
          if (this.$route.path !== this.TIMELAPSES_URL.path) {
            this.$router.push({ path: this.TIMELAPSES_URL.path, hash: 'library' });
          }
        });
        this.setActiveComponent('library');
      } else {
        this.setActiveComponent(page);
      }
    },
  },
  computed: {
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(
            menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission),
          )),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    activeComponent() {
      return this.activeMenuTabForRender?.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    activeComponentEvents() {
      return this.activeMenuTabForRender?.events || {};
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    onTabChanged(newTab, newIndex) {
      this.activeIndex = newIndex;
    },
    setActiveComponent(tabCode) {
      const tab = this.menuList.find((tabObj) => tabObj.code === tabCode);
      if (tab) {
        this.activeMenuTab = tab;
        this.activeIndex = this.menuList.indexOf(tab);
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .make_own_timelapse {
    position: relative;
    width: 100%;

    .make_own_timelapse__menu {
      position: relative;
      width: 100vw;
      left: -$page-global-padding-horizontal;
      padding: 2.1vh $page-global-padding-horizontal;
      background-color: $default-light-color;

      @media (orientation: portrait) {
        left: -$page-global-portrait-padding-horizontal;
        padding: 2.1vh $page-global-portrait-padding-horizontal;
      }
      @media screen and (max-width: 900px) {
        font-size: 2.2em;
      }
    }

    .make_own_timelapse__menu__hidden {
      display: none;
    }
  }
</style>
