<template>
  <div class="project-views-list">
    <v-select v-model="value" :items="items" dense flat hide-details multiple
              :placeholder="placeholder" :no-data-text="noDataText" append-icon=""
              :item-text="itemText" :item-value="itemValue" :loading="isLoading" ref="menuRef">
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0" class="selected-projectview">
          <span v-if="item === '(blank)'" class="selected-projectview">
          {{ item }}
          </span>
          <span v-else>
            {{ item[itemText] }}{{ value.length > 1 ? ', ...' : ''}}
          </span>
        </span>
        <span v-if="index === 1" class="grey--text text-caption">
          (+{{ value.length - 1 }} other{{ value.length > 2 ? 's' : ''}})
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  find, isEmpty, isNull, map, sortBy,
} from 'lodash';
import utils from '@/utils';
import { mapActions, mapState } from 'vuex';

const DELAY_CHANGED_TIMEOUT = 2000; // 2s

export default {
  name: 'ProjectViewsList',
  emits: ['change', 'delay_change'],
  props: {
    placeholder: {
      type: String,
      default: 'Cameras 󰅀',
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: 'search_title',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    selectedValues: {
      type: Array,
      default: () => ([]),
    },
    backendSearchAction: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      value: [],
      isLoading: false,
      items: null,
      timeoutId: null,
      isInitWithInitialData: false,
    };
  },
  mounted() {
    this.loadData();

    this.$watch(
      () => this.$refs.menuRef.isMenuActive,
      (value) => {
        this.$emit('menuState', value);
      },
    );
  },
  watch: {
    value(newValue, oldValue) {
      if (!this.isInitWithInitialData || utils.isArrayEquals(newValue, oldValue)) {
        return;
      }
      this.$emit('change', { value: newValue.length ? newValue : [] });
      this.delayChangedValue(newValue);
    },
  },
  computed: {
    searchAction() {
      return this.backendSearchAction || this.getAllProjectViewsWithProject;
    },
    isDataLoaded() {
      return !isNull(this.items);
    },
    noDataText() {
      if (this.isDataLoaded) {
        return 'No cameras found!';
      }
      return 'Loading, please wait';
    },
    ...mapState({
      allProjectViews: (state) => state.extra.allProjectViews,
      allProjects: (state) => state.extra.allProjects,
      allCompanies: (state) => state.extra.allCompanies,
    }),
  },
  methods: {
    loadData() {
      if (!this.isDataLoaded && !this.isLoading) {
        this.isLoading = true;
        this.searchAction().then(async (projectviews) => {
          this.items = sortBy(
            map(projectviews),
            (item) => item[this.itemText].toLowerCase(),
          );
          this.items.unshift('(blank)');
          this.value = this.selectedValues;
          await this.$nextTick();
          this.isInitWithInitialData = true;
        }).catch(() => {
          this.items = [];
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    async getAllProjectViewsWithProject() {
      if (isEmpty(this.allProjects)) {
        await this.fetchAllProjects();
      }
      return new Promise((resolve, reject) => {
        if (!isEmpty(this.allProjectViews)) {
          resolve(this.addProjectToProjectViews());
          return;
        }
        this.fetchAllProjectViews().then(async () => {
          resolve(this.addProjectToProjectViews());
        }).catch(reject);
      });
    },
    async addProjectToProjectViews() {
      if (isEmpty(this.allCompanies)) {
        await this.fetchAllCompanies();
      }
      return map(this.allProjectViews, (projectview) => {
        const projectviewProject = find(
          this.allProjects,
          (project) => project.id === projectview.project,
        );
        const searchTitleProject = projectviewProject ? `${projectviewProject.name} - ` : '';
        projectview.project_details = projectviewProject || null;
        if (projectviewProject && projectviewProject.company) {
          const companyName = find(
            this.allCompanies,
            (company) => company.id === projectviewProject.company,
          );

          if (companyName) {
            projectview.search_title = `${companyName.name} /
            ${searchTitleProject}${projectview.name}`;
          } else {
            projectview.search_title = `${searchTitleProject}${projectview.name}`;
          }
        } else {
          projectview.search_title = `${searchTitleProject}${projectview.name}`;
        }

        return projectview;
      });
    },
    delayChangedValue(newValue) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.timeoutId = null;
        this.$emit('delay_change', { value: newValue.length ? newValue : [] });
      }, DELAY_CHANGED_TIMEOUT);
    },
    ...mapActions({
      fetchAllProjects: 'fetchAllProjects',
      fetchAllProjectViews: 'fetchAllProjectViews',
      fetchAllCompanies: 'fetchAllCompanies',
    }),
  },
  beforeDestroy() {
    if (!this.timeoutId) {
      return;
    }
    clearTimeout(this.timeoutId);
    this.$emit('delay_change', { value: this.value.length ? this.value : [] });
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.project-views-list::v-deep {
  position: absolute;
  font-family: $main-font-family, "Material Design Icons", sans-serif;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 1.5rem 1vw;
  background-color: $default-blue-dark-color-opacity-60;
  z-index: 1000000000;

  @media (orientation: portrait), screen and (max-width: 900px) {
    width: 50vw;
  }

  .v-input {
    font-family: $main-font-family, "Material Design Icons", sans-serif;
    font-size: 1.75em;
    padding-top: 0;

    .v-input__slot:after,
    .v-input__slot:before {
      content: none;
    }

    &.v-text-field {
      margin: 0;
    }

    .v-select__slot {
      color: $default-white-color;

      input {
        padding: 0;
        text-align: center;
        line-height: 1.1;
        color: $default-white-color;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $default-white-color;
          text-align: center;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $default-white-color;
          text-align: center;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $default-white-color;
          text-align: center;
        }
      }

      .selected-projectview {
        overflow: hidden;
        white-space: nowrap;
        color: $default-white-color;
        line-height: 1;
      }

      .text-caption {
        margin-left: .3vw;
        font-size: 0.75em!important;
      }
    }

    .v-progress-linear {
      transform: translateY(1.3vh);
    }
  }
}
</style>
