import { filter, includes, map } from 'lodash';

export const tagsMixin = {
  computed: {
    validTags() {
      return filter(this.tagsList, (tag) =>
        tag.user === this.user.id && !tag.project && !tag.system_tag);
    },
    validTagsIds() {
      return map(this.validTags, (tag) => tag.id);
    },
    protectedImageTagsIds() {
      return filter(
        this.imageTags,
        (tag) => !includes(this.validTagsIds, tag),
      );
    },
    userImageTagsIds() {
      return filter(
        this.imageTags,
        (tag) => !includes(this.protectedImageTagsIds, tag),
      );
    },
    userImageTags() {
      return filter(
        this.validTags,
        (tag) => includes(this.userImageTagsIds, tag.id),
      );
    },
  },
};

export default tagsMixin;
