<template>
  <div class="popup">
    <div class="popup__box">
      <div class="popup__box__title">
        {{ $t('delete_membership_popup.title') }} {{ companyOfMember.name }}
      </div>
      <div class="popup__box__subtext">
        {{ $t('delete_membership_popup.subtext') }}
      </div>
      <div class="popup__box__subtext">
        {{ $t('delete_membership_popup.confirm_one') }}
        {{ memberToDelete.last_name.toUpperCase() }} {{ memberToDelete.first_name.toUpperCase() }}
        {{ $t('delete_membership_popup.confirm_two') }} {{ companyOfMember.name }}
        {{ $t('delete_membership_popup.confirm_three') }}?
      </div>
      <div class="row popup__box__buttons">
          <div class="col-md-2"></div>
          <ButtonSubmit class="col-md-2"
            :title="$t('delete_membership_popup.yes')" :is-show-loading="isLoading"
                        @click="deleteCompanyMembership"/>
        <div class="col-md-2"></div>
          <ButtonSubmit class="col-md-2" :title="$t('delete_membership_popup.no')"
                        @click="onCloseClickHandler"/>
      </div>
      <div class="popup__box__icon">
        <i class="icon__close mdi mdi-window-close" @click="onCloseClickHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { mapActions } from 'vuex';

export default {
  name: 'DeleteMemberConfirm',
  components: { ButtonSubmit },
  props: ['companyOfMember', 'memberToDelete'],
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async deleteCompanyMembership() {
      this.isLoading = true;
      const response = await this.fetchCompanyMemberships(
        { member: this.memberToDelete.id, company: this.companyOfMember.id },
      );
      const [companyMembershipId] = response.dataList;
      await this.destroyCompanymemberships(companyMembershipId).finally(() => {
        this.$emit('membershipDeleted');
        this.isLoading = false;
      });
    },
    onCloseClickHandler() {
      this.$emit('close');
    },
    ...mapActions({
      fetchUsers: 'listUsers',
      destroyCompanymemberships: 'destroyCompanymemberships',
      fetchCompanyMemberships: 'listCompanymemberships',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);

  .popup__box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    max-height: 90%;
    margin-top: 10vh;
    padding: 2.5vh;
    background-color: $default-background-color;
    cursor: default;

    @media only screen and (max-width: 900px) {
      max-height: 80%;
      min-height: 80%;
      max-width: 100%;
    }

    .popup__box__title {
      padding-left: 1.5vw;
      padding-top: 1.5vh;
      padding-bottom: 2vh;
      font-size: 2em;
      font-weight: bold;
      color: $default-blue-dark-color;
    }
    .popup__box__subtext {
      display: flex;
      margin-left: 2vw;
      margin-right: 2vw;
      margin-bottom: 4vh;
    }
    .popup__box__buttons {
      margin-top: 6vh;
    }
  }
}

.icon__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5vh;
  padding-top: 1.5vh;
  opacity: 0.4;
}
</style>
