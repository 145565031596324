<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
} from './utils/i18n/document';

export default {
  mounted() {
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        setDocumentLang(newLocale);
        setDocumentDirectionPerLocale(newLocale);
      },
      { immediate: true },
    );
  },
};
</script>
