<template>
  <div class="logout" @click="logout">
    <div class="logout__login-details">{{ userEmail }}</div>
    <div class="logout__button">{{ $t(title) }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Logout',
  props: {
    title: {
      type: String,
      default: 'auth.buttons.logout',
    },
  },
  computed: {
    userEmail() {
      return this.user && this.user?.email;
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    ...mapActions({
      logout: 'logout',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
  .logout {
    display: flex;
    align-items: center;
    justify-content: center;

    .logout__login-details {
      margin-right: 1.1vw;

      @media only screen and (max-width: 900px) {
        margin-right: 4vw;
      }
    }

    .logout__button {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 9vw;
      padding-bottom: 1.3vh;
      cursor: pointer;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $default-white-color;
      }
    }
  }
</style>
