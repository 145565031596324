<template>
  <div class="faq_page">
    <div class="settings_title">
      {{ $t('my_csite_menu.settings_menu.faq.titles.faq')}}
    </div>
    <ul class="faq_page__questions" >
      <li class="faq_page__questions__question" v-for="question in questions"
          :key="question.id"  :id="question.id" @click="showAnswer">
        <i class="mdi mi-circle-arrow-right faq_page__questions__question__icon"></i>
        <strong>{{ question.question }}</strong>
        <div class="faq_page__questions__answer hide">{{ question.answer }}</div>
      </li>
    </ul>
    <ul class="faq_page__questions">
      <li class="faq_page__questions__question">
        <div @click="showAnswerLink">
          <i class="mdi mi-circle-arrow-right faq_page__questions__question__icon"></i>
          <strong>{{ $t('my_csite_menu.settings_menu.faq.questions.q11') }}</strong>
        </div>
        <div class="faq_page__questions__answer hide">
          {{ $t('my_csite_menu.settings_menu.faq.answers.a11') }}
           <a href="https://app.c-site.eu" target="_blank">
          {{ $t('my_csite_menu.settings_menu.cookies.link')}}</a>.</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Faq',

  data() {
    return {
      questions: [
        {
          id: 1,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q1'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a1'),
        },
        {
          id: 2,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q2'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a2'),
        },
        {
          id: 3,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q3'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a3'),
        },
        {
          id: 4,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q4'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a4'),
        },
        {
          id: 5,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q5'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a5'),
        },
        {
          id: 6,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q6'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a6'),
        },
        {
          id: 7,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q7'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a7'),
        },
        {
          id: 8,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q8'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a8'),
        },
        {
          id: 9,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q9'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a9'),
        },
        {
          id: 10,
          question: this.$t('my_csite_menu.settings_menu.faq.questions.q10'),
          answer: this.$t('my_csite_menu.settings_menu.faq.answers.a10'),
        },
      ],
    };
  },
  methods: {
    showAnswerLink(event) {
      if (event.target.classList.contains('mdi')) {
        event.target.classList.toggle('mi-circle-arrow-right');
        event.target.classList.toggle('mi-circle-arrow-down');
        event.target.parentNode.nextElementSibling.classList.toggle('hide');
      } else if (event.target.parentNode.children[0].classList.contains('mdi')) {
        event.target.parentNode.children[0].classList.toggle('mi-circle-arrow-right');
        event.target.parentNode.children[0].classList.toggle('mi-circle-arrow-down');
        event.target.parentNode.nextElementSibling.classList.toggle('hide');
      }
    },
    showAnswer(event) {
      if (event.target.classList.contains('mdi')) {
        event.target.classList.toggle('mi-circle-arrow-right');
        event.target.classList.toggle('mi-circle-arrow-down');
        event.target.parentNode.children[1].nextElementSibling.classList.toggle('hide');
      } else if (
        event.target.parentNode.children[0].classList.contains('mdi')) {
        event.target.parentNode.children[0].classList.toggle('mi-circle-arrow-right');
        event.target.parentNode.children[0].classList.toggle('mi-circle-arrow-down');
        event.target.parentNode.children[2].classList.toggle('hide');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/settings.scss';
@import '~@/sass/v3/variables.scss';
@import '~@/sass/v3/cSiteIcons.css';

.faq_page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }
  .faq_page__title {
    position: relative;
    font-size: 1.4em;
    padding-bottom: 2vh;
    padding-top: 3.6vh;
    margin-bottom: 4vh;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 4.3vw;
      height: 1px;
      background-color: $default-disabled-color;
    }
  }
  .faq_page__questions {
    list-style-type: none;

    .faq_page__questions__question {
      cursor: pointer;
      margin-bottom: 3vh;
      margin-top: 2vh;
      margin-left: 1.5vh;

      .faq_page__questions__question__icon {
        padding-right: 1vh;
      }
    }

    .faq_page__questions__answer {
      line-height: 1.5;
      padding-left: 3vh;
      padding-top: 2vh;
      margin-right: 8vh;
      margin-left: 2vh;
    }
  }
}

.hide {
  display: none;
}

a:visited {
  color:$default-blue-ultra-dark-color;
}

@media only screen and (max-width: 900px) {
  .faq_page {
    font-size: 1.4em;
    padding-right: 2vw;
  }
}

</style>
