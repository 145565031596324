import { render, staticRenderFns } from "./NoItemsYet.vue?vue&type=template&id=020803b9&scoped=true&"
import script from "./NoItemsYet.vue?vue&type=script&lang=js&"
export * from "./NoItemsYet.vue?vue&type=script&lang=js&"
import style0 from "./NoItemsYet.vue?vue&type=style&index=0&id=020803b9&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "020803b9",
  null
  
)

export default component.exports