<template>
  <div class="lifestream-animation">
    <span class="lifestream-animation__s1"></span>
    <span class="lifestream-animation__s2"></span>
    <span class="lifestream-animation__s3"></span>
    <span class="lifestream-animation__s4"></span>
  </div>
</template>

<script>
export default {
  name: 'LifestreamAnimation',
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
.lifestream-animation {
  $base-circles-size: .8vw;
  $base-animation-delay: 1s;
  $box-shadow: $base-circles-size * 0.8;

  @media (orientation: portrait) {
    $base-circles-size: 1.2vw;
    $box-shadow: $base-circles-size * 1.2;
  }

  position: relative;
  margin-left: $base-circles-size * 4;

  > span {
    display: block;
    position: absolute;
    left: 50%;
    opacity: 0;
    border-radius: $base-circles-size * 3;
    box-shadow: 0 0 $box-shadow rgba(8, 117, 221, 0.5);
    animation: lifestream-animation $base-animation-delay infinite alternate ease-in-out;

   &.lifestream-animation__s1, .lifestream-animation__s2,
    &.lifestream-animation__s3, .lifestream-animation__s4 {
     border: 2px solid rgba(8, 117, 221, .3);
     box-shadow: 0 0 $box-shadow rgba(15,117,221,.3);
   }

    &.lifestream-animation__s1 {
      $s1_size: $base-circles-size;
      $s1_margin: -$s1_size / 2;

      width: $s1_size;
      height: $s1_size;
      margin-top: $s1_margin;
      margin-left: $s1_margin;
      animation-delay: $base-animation-delay * 0.15;
    }

    &.lifestream-animation__s2 {
      $s2_size: $base-circles-size * 2;
      $s2_margin: -$s2_size / 2;

      width: $s2_size;
      height: $s2_size;
      margin-top: $s2_margin;
      margin-left: $s2_margin;
      animation-delay: $base-animation-delay * 0.3;
    }

    &.lifestream-animation__s3 {
      $s3_size: $base-circles-size * 3;
      $s3_margin: -$s3_size / 2;

      width: $s3_size;
      height: $s3_size;
      margin-top: $s3_margin;
      margin-left: $s3_margin;
      animation-delay: $base-animation-delay * 0.45;
    }

    &.lifestream-animation__s4 {
      $s4_size: $base-circles-size * 4;
      $s4_margin: -$s4_size / 2;

      width: $s4_size;
      height: $s4_size;
      margin-top: $s4_margin;
      margin-left: $s4_margin;
      animation-delay: $base-animation-delay * 0.6;
    }
  }
}

@-webkit-keyframes lifestream-animation {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-moz-keyframes lifestream-animation {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-o-keyframes lifestream-animation {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes lifestream-animation {
  from {opacity: 0;}
  to {opacity: 1;}
}
</style>
