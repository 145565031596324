<template>
  <div class="logos">
    <div class="logos__upload" v-if="isAllowToAddLogo">
      <v-file-input
        class="logos__upload__input"
        :rules="newLogo.rules"
        accept="image/png, image/jpeg, image/jpg, image/gif"
        :placeholder="$t('timelapses_menu.custom_menu.placeholders.pick_logo')"
        prepend-icon="mdi-camera"
        :label="$t('timelapses_menu.custom_menu.placeholders.new_logo')"
        :messages="$t('timelapses_menu.custom_menu.other_text.recommended_size')"
        v-model="newLogo.file"
        @update:error="onNewLogoErrorStateUpdate"
      ></v-file-input>
      <ButtonSubmit class="logos__upload__button"
                    :title="$t('timelapses_menu.buttons.upload')" @click="onSubmitClick"
                    :is-show-loading="newLogo.isUploading" :is-disabled="isSubmitButtonDisabled" />
    </div>
    <div class="logos__error" v-if="newLogo.error">{{ newLogo.error }}</div>
    <div class="logos__list" v-if="!isLoading">
      <div class="logos__list__logo" v-for="logo in logosList" :key="logo.id">
        <picture>
          <img :src="logo.logo" :alt="`logo_${logo.id}`">
        </picture>
        <i class="mdi mdi-close-circle-outline logos__list__logo__close" v-if="isAllowToRemoveLogo"
           :class="{disabled: isRemoving}" @click="onRemoveLogoClick(logo.id)" />
      </div>
    </div>
    <camera-preloader class="logos__preloader" v-else />
  </div>
</template>

<script>

import { map, sortBy, toString } from 'lodash';
import { mapActions, mapState } from 'vuex';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import { MAX_LOGO_SIZE } from '@/constants/v3/timelapses';

export default {
  name: 'Logos',
  mixins: [permissionMixin],
  components: {
    ButtonSubmit,
    CameraPreloader,
  },
  data() {
    return {
      newLogo: {
        file: null,
        isUploading: false,
        error: false,
        rules: [
          (value) => !value || value.size < MAX_LOGO_SIZE
            || this.$t('timelapses_menu.custom_menu.errors.size_to_big'),
        ],
      },
      isLoading: false,
      isRemoving: false,
    };
  },
  mounted() {
    this.loadLogos();
  },
  computed: {
    logosList() {
      return sortBy(this.logos, (logo) => logo.id).reverse();
    },
    isSubmitButtonDisabled() {
      return !this.newLogo.file || this.newLogo.isUploading || this.newLogo.error;
    },
    isAllowToAddLogo() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_logo);
    },
    isAllowToRemoveLogo() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.delete_logo);
    },
    ...mapState({
      logos: (state) => state.logos,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    loadLogos() {
      this.isLoading = true;
      const fetchLogosPayload = {
        user: this.user.id,
        limit: 10000,
        ordering: '-id',
      };
      this.fetchLogos(fetchLogosPayload).finally(() => {
        this.isLoading = false;
      });
    },
    onNewLogoErrorStateUpdate(errorState) {
      this.newLogo.error = errorState;
    },
    onRemoveLogoClick(logoId) {
      if (!this.isAllowToRemoveLogo) {
        this.newLogo.error = this.$t('timelapses_menu.custom_menu.errors.not_remove_logo');
        return;
      }

      if (this.isRemoving) {
        return;
      }

      this.isRemoving = true;
      this.removeLogo(logoId).finally(() => {
        this.isRemoving = false;
      });
    },
    onSubmitClick() {
      if (!this.isAllowToAddLogo) {
        this.newLogo.error = this.$t('timelapses_menu.custom_menu.errors.not_add_logo');
        return;
      }

      this.newLogo.isUploading = true;
      const form = new FormData();

      form.append('user', this.user.id);
      form.append('logo', this.newLogo.file, this.newLogo.file.name);

      this.uploadLogo(form).then(() => {
        this.newLogo = {
          ...this.newLogo,
          file: null,
          error: null,
        };
      }).catch((error) => {
        const errorData = error?.response?.data;
        if (!errorData) {
          this.newLogo.error = error;
          return;
        }
        this.newLogo.error = toString(map(errorData, (errorList) => toString(errorList)));
      }).finally(() => {
        this.newLogo.isUploading = false;
      });
    },
    ...mapActions({
      fetchLogos: 'listLogos',
      uploadLogo: 'createLogos',
      removeLogo: 'destroyLogos',
    }),
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .logos {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;

    .logos__preloader {
      width: 100%;
    }

    .logos__upload {
      display: flex;
      flex-flow: nowrap;
      align-items: flex-start;
      width: 100%;

      .logos__upload__input {
        max-width: 17vw;
        margin-right: 2vw;
        @media only screen and (orientation: landscape) and (max-width: 900px) {
          max-width: none;
        }
        @media (orientation: portrait) {
          max-width: none;
        }
      }

      .logos__upload__button {
        margin: 16px 0 0 0;

        ::v-deep {
          .base-button {
            height: 4.5vh;

            @media (orientation: portrait) {
              height: auto;
              min-height: 32px;
            }

            @media screen and (max-width: 900px) {
              font-size: 1.4em;
            }
          }
        }
      }
    }

    .logos__error {
      font-size: 1.2em;
      color: $fail-color;
    }

    .logos__list {
      display: flex;
      flex-flow: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-top: 2vw;

      .logos__list__logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 16.8vw;
        height: 16.8vw;
        padding: 1.6vw;
        margin-right: 1vw;
        border: 1px solid $default-disabled-color;

        .logos__list__logo__close {
          position: absolute;
          display: flex;
          top: 0;
          right: 0;
          font-size: 2em;
          cursor: pointer;
          color: $fail-color;
          background-color: $default-white-color;
          border-radius: 50px;
          transform: translate3d(50%, -50%, 0);
          opacity: 0;
          transition: opacity .15s linear;

          &:hover {
            opacity: 1;
          }

          &.disabled {
            filter: grayscale(1);
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          .logos__list__logo__close {
            opacity: 1;
          }
        }

        picture {
          display: flex;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }
        }
      }
    }
  }
</style>
