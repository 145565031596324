<template>
  <div class="timelapses_list" ref="timelapses" :key="`timelapses_list_${timelapses.totalCount}`">
    <div class="timelapses_list__camera"
         v-for="timelapse in timelapseList" :key="`timelapse_${timelapse.id}`">
      <div class="timelapses_list__camera__header" :class="[getTimelapseStatusClass(timelapse)]">
        <div class="timelapses_list__camera__header__project_name">
          {{ getTimelapseTitle(timelapse) }}
        </div>
        <div class="timelapses_list__camera__header__status" v-if="isShowStatus"
             :class="{ status_changed: timelapse.status_changed }"
             :key="`status_${timelapse.id}_${timelapse.status}_${timelapse.status_changed}`">
          ({{ formatTimelapseStatus(timelapse.status) }})
        </div>
      </div>
      <div class="timelapses_list__camera__error" v-if="isShowTimelapseError(timelapse)">
        {{ timelapse.error_message }}
      </div>
      <div class="timelapses_list__camera__timelapse" :id="`video_${timelapse.id}`"
           @mouseenter="showTheControls(timelapse.id)"
           @mouseleave="hideTimelapseInfo(timelapse.id)">
        <video class="timelapses_list__camera__timelapse__video"
               :class="{'full-screen': isFullScreen}"
               width="100%" muted preload="metadata" playsinline
               :poster="getTimelapsePoster(timelapse)"
               :key="`${timelapse.name}_${timelapse.camera}_${timelapse.video}`"
               :id="`timelapse_${timelapse.id}`"
               @click="playPauseVideo(timelapse.id);"
               @timeupdate="updateProgressBar(timelapse.id)"
               @loadedmetadata="videoLoaded(timelapse.id)">
          <source :src="timelapse.video_file_url" type="video/mp4" v-if="timelapse.video_file_url">
          Your browser does not support the video tag.
        </video>
        <div v-if="hoveredVideoId === timelapse.id" class="timelapse_information"
             style="display: grid; grid-template-columns: auto auto; column-gap: 10px;
             align-items: start;">
            <div>Project:</div>
            <div>{{ timelapse.project_details.name }} </div>
            <div>Camera:</div>
            <div>{{ timelapse.projectview_details.name }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.start') }}:</div>
            <div>{{ formatTimeDate(timelapse.start) }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.end') }}:</div>
            <div>{{ formatTimeDate(timelapse.end) }}</div>
            <div v-if="timelapse.logo">Logo</div>
            <div v-if="timelapse.logo">{{ logoPositionInfo(timelapse) }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.include_saturday') }}:</div>
            <div>{{ timelapse.include_saturday ? $t('delete_membership_popup.yes') :
              $t('delete_membership_popup.no') }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.include_sunday') }}:</div>
            <div>{{ timelapse.include_sunday ? $t('delete_membership_popup.yes') :
              $t('delete_membership_popup.no') }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.placeholders.hour_range') }}:</div>
            <div>{{ hourRangeInfo(timelapse) }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.other_text.resolution') }}:</div>
            <div>{{ resolutionInfo(timelapse)}}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.length') }}:</div>
            <div>{{ timelapse.length }}
               {{ $t('time.seconds') }}</div>
            <div>Fps:</div>
            <div>{{ timelapse.fps }}</div>
            <div>{{ $t('timelapses_menu.custom_menu.checkboxes.active_filter') }}:</div>
            <div>{{ activeFilterName(timelapse) }}
              <span v-if="timelapse.tmix">- Fluid</span></div>
            <div v-if="user.is_staff">Made by:</div>
            <div v-if="user.is_staff">{{ timelapse.owner  }}</div>
        </div>
        <div class="timelapses_list__camera__timelapse__controls" :id="`controls_${timelapse.id}`">
          <div id='btnPlayPause' class="button" @click='playPauseVideo(timelapse.id);'>
            <i class="mdi mdi-play icon-color" :id="`start_${timelapse.id}`"></i>
          </div>
          <div class="button progress-bar" :id="`progressBar_${timelapse.id}`"
          @click="searchVideo(timelapse.id, $event)">
            <div class="progress" :id="`progress_${timelapse.id}`"></div>
            <i class="mdi mdi-circle icon-color"></i>
          </div>
          <div :id="`time_text_${timelapse.id}`" style="padding-right: 0.2vw"
               class="timelapses_list__camera__timelapse__controls_text">
            {{ convertSecondsToMinutes(startTime) }}
          </div>
          <div v-if="timelapseLoaded(timelapse.id)"
               :id="`time_${timelapse.id}`"
               class="timelapses_list__camera__timelapse__controls_text">
            / {{ formatDuration(timelapse.duration) }}</div>
          <div id='btnFullScreen' class="button"
               @click='toggleFullScreenFunc(timelapse.id)'>
            <i class="mdi mdi-arrow-expand-all"></i>
          </div>
        </div>
      </div>
      <div class="timelapses_list__camera__actions">
        <div class="timelapses_list__camera__actions__left-side">
          <div class="timelapses_list__camera__actions__left-side__date">
            {{ timelapseDateRange(timelapse) }}
          </div>
          <router-link class="timelapses_list__camera__actions__left-side__camera-name"
                       :to="routerTo(timelapse)" v-if="projectViewName(timelapse)">
            {{ projectViewName(timelapse) }}
          </router-link>
        </div>
        <i class="mdi mdi-delete"
           v-if="isAllowToRemove(timelapse)" :class="{disabled: timelapses.isRemoving}"
           @click="onRemoveTimelapseClick(timelapse.id)" />
        <div class="timelapses_list__camera__actions__buttons"
             v-if="isShowDownload(timelapse)">
          <Download download-action="getTimelapseDownload" :object-id="timelapse.id"/>
        </div>
      </div>
    </div>
    <camera-preloader v-if="timelapses.isLoading" />
    <no-cameras v-if="isShowNoCameras"/>
  </div>
</template>

<script>
import {
  sortBy, includes, map, difference, uniq, compact, filter, join, capitalize, isNull, indexOf,
  forEach, findIndex, isEmpty, find,
} from 'lodash';
import TIMELAPSES_PER_REQUEST_LIMIT, {
  CATEGORY_CUSTOM,
  AVAILABLE_CATEGORIES,
  LOAD_NEW_TIMELAPSES_WHEN_LEFT_NUM,
} from '@/constants/v3/timelapses';
import STATUSES_LIST, { STATUS_ERROR, STATUS_FINISHED } from '@/helpers/timelapses';
import { mapActions, mapState } from 'vuex';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import NoCameras from '@/components/v3/Cameras/NoCameras';
import Download from '@/components/v3/Download';
import moment from 'moment-timezone';
import { CAMERA_DETAIL_URL } from '@/constants/v3/menu';
import { isInViewport } from '@/helpers';
import { permissionMixin } from '@/mixins/v3';
import { fullscreenMixin } from '@/mixins/v3/fullscreen/fullscreenMixin';
import PERMISSIONS from '@/constants/v3';

const timelapseReadySoonGif = require('@/assets/images/v3/timelapse_ready_soon.gif');

const CHECK_TIMELAPSE_STATUS_INTERVAL = 7000; // 7s

export default {
  name: 'Timelapses',
  props: {
    timelapseCategory: {
      type: String,
      required: true,
      validator: (value) => includes(AVAILABLE_CATEGORIES, value),
    },
    searchBy: {
      type: Number,
      default: null,
    },
    isShowFinishedOnly: {
      type: Boolean,
      default: true,
    },
    isShowStatus: {
      type: Boolean,
      default: false,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [permissionMixin, fullscreenMixin],
  components: {
    Download,
    NoCameras,
    CameraPreloader,
  },
  data() {
    return {
      loadedVideos: {},
      startTime: 0,
      extraFullScreen: false,
      timelapseReadySoonGif,
      scrollValue: 0,
      timelapses: {
        isLoading: false,
        totalCount: undefined,
        list: [],
        isRemoving: false,
        error: null,
      },
      timelapseStatusesMonitorTimeoutId: null,
      cameraDetailPageName: CAMERA_DETAIL_URL.name,
      hoveredVideoId: null,
    };
  },
  mounted() {
    this.timelapses.list.forEach((tl) => {
      this.addVideoEventListeners(tl);
    });
    this.loadNextTimelapses();
    window.addEventListener('scroll', this.checkIfNeedsToStartLoadingTimelapses);
  },
  watch: {
    timelapseList(updatedTimelapsesList, oldTimelapsesList) {
      const oldTimelapsesIds = map(
        oldTimelapsesList,
        (oldTimelapse) => oldTimelapse.id,
      );
      const updatedTimelapsesIds = map(
        updatedTimelapsesList,
        (updatedTimelapse) => updatedTimelapse.id,
      );
      const newTimelapsesIds = difference(updatedTimelapsesIds, oldTimelapsesIds);
      if (newTimelapsesIds.length) {
        this.loadProjectViewsTimelapses(newTimelapsesIds);
        this.loadCompaniesTimelapses(newTimelapsesIds);
      }
    },
    timelapseCategory() {
      this.timelapses = {
        ...this.timelapses,
        totalCount: undefined,
        list: [],
        error: null,
      };
      this.loadNextTimelapses();
    },
    searchBy() {
      this.timelapses = {
        ...this.timelapses,
        totalCount: undefined,
        list: [],
        error: null,
      };
      this.loadNextTimelapses();
    },
    isFullScreen() {
      if (!this.isFullScreen) {
        this.extraFullScreen = false;
      }
    },
  },
  computed: {
    timelapseMonitorStatuses() {
      return filter(
        STATUSES_LIST,
        (status) => !includes([STATUS_ERROR, STATUS_FINISHED], status),
      );
    },
    timelapsesToMonitorStatus() {
      return filter(
        this.timelapseList,
        ({ status }) => includes(this.timelapseMonitorStatuses, status),
      );
    },
    isLoadedAllTimelapses() {
      return this.timelapses.totalCount - this.totalTimelapsesLoaded > 0;
    },
    totalTimelapsesLoaded() {
      return this.timelapses.list.length;
    },
    timelapseList() {
      return sortBy(this.timelapses.list, (timelapse) => timelapse.id).reverse();
    },
    isShowNoCameras() {
      return this.timelapses.totalCount === 0 && !this.timelapses.isLoading;
    },
    isSearchActive() {
      return !isNull(this.searchBy);
    },
    fetchTimelapsesMethodName() {
      return `fetch${capitalize(this.timelapseCategory)}Timelapses`;
    },
    fetchTimelapses() {
      return this[this.fetchTimelapsesMethodName];
    },
    removeAction() {
      const categoryUppercaseName = this.timelapseCategory.charAt(0).toUpperCase()
        + this.timelapseCategory.slice(1);
      return `destroy${categoryUppercaseName}timelapses`;
    },
    isUserHasPermissionToRemove() {
      const permissionToCheck = `delete_${this.timelapseCategory}_timelapse`;
      return this.$_permissionMixin_hasPermission(PERMISSIONS[permissionToCheck]);
    },
    isAllowToDownload() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.download_timelapse);
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
      isFullScreen: (state) => state.isFullscreenMode,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    logoPositionInfo(timelapse) {
      const logoPosition = {
        top_left:
          this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.t_left'),
        top_right:
          this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.t_right'),
        bottom_left:
          this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.b_left'),
        bottom_right:
          this.$t('timelapses_menu.custom_menu.select_menus.logo_position_select.b_right'),
      };
      return logoPosition[timelapse.logo_position];
    },
    hourRangeInfo(timelapse) {
      const hourRange = {
        smart:
          this.$t('timelapses_menu.custom_menu.select_menus.hour_range_select.automatic_daylight'),
        fixed: `${timelapse.fixed_time_from} - ${timelapse.fixed_time_to}`,
        all: this.$t('timelapses_menu.custom_menu.select_menus.hour_range_select.all'),
      };
      return hourRange[timelapse.day_range];
    },
    resolutionInfo(timelapse) {
      const resolutionMap = {
        '1080p': 'Full HD',
        '1520p': '4MP',
        '2160p': '8MP',
        '1920p': 'Full HD vertical',
        '2688p': '4MP vertical',
        '3840p': '8MP vertical',
      };
      return resolutionMap[timelapse.resolution] || timelapse.resolution;
    },
    activeFilterName(timelapse) {
      const filterNames = {
        sepia_noir: 'Sandstone',
        greyscale: 'Slate',
        enhance: 'Spark',
        tilt_shift: 'Maquette',
        warm: 'Warm',
        cold: 'Cold',
        gotham: 'Brick',
      };

      const activeFilterKey = Object.keys(filterNames).find((key) => timelapse[key]);
      return filterNames[activeFilterKey] || 'None';
    },
    showTimelapseInfo(videoId) {
      this.hoveredVideoId = videoId;
    },
    hideTimelapseInfo() {
      this.hoveredVideoId = null;
    },
    addVideoEventListeners() {
      this.timelapses.list.forEach((timelapse) => {
        const player = document.getElementById(`timelapse_${timelapse.id}`);
        if (player) {
          player.addEventListener('loadedmetadata', () => {
            this.videoLoaded(timelapse.id);
          });
          this.$set(timelapse, 'player', player);
        }
      });
    },
    removeVideoEventListeners() {
      this.timelapses.list.forEach((timelapse) => {
        if (timelapse.player) {
          timelapse.player.removeEventListener('loadedmetadata', () => {
            this.videoLoaded(timelapse.id);
          });
          this.$delete(timelapse, 'player');
        }
      });
    },
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
      return `${minutes}:${formattedSeconds}`;
    },
    videoLoaded(id) {
      const player = document.getElementById(`timelapse_${id}`);
      if (player && !Number.isNaN(player.duration)) {
        const timelapseIndex = this.timelapses.list.findIndex((tl) => tl.id === id);
        if (timelapseIndex !== -1) {
          this.$set(this.timelapses.list[timelapseIndex], 'duration', Math.ceil(player.duration));
        }
      }
      this.loadedVideos[id] = true;
    },
    timelapseLoaded(id) {
      return !!this.loadedVideos[id];
    },
    searchVideo(id, event) {
      const progressBar = document.getElementById(`progressBar_${id}`);
      const player = document.getElementById(`timelapse_${id}`);
      const progress = document.getElementById(`progress_${id}`);
      const percent = event.offsetX / progressBar.offsetWidth;
      player.currentTime = percent * player.duration;
      progress.style.width = `${percent}%`;
    },
    playTime(id) {
      const player = document.getElementById(`timelapse_${id}`);
      if (player) {
        const { currentTime } = player;
        const decimalPart = currentTime - Math.floor(currentTime);
        const timeDifference = player.duration - currentTime;
        if (decimalPart > 0.5 || timeDifference < 1) {
          return Math.ceil(currentTime);
        }
        return Math.floor(currentTime);
      }
      return 0;
    },
    showTheControls(id) {
      const controls = document.getElementById(`controls_${id}`);
      let timer;
      document.addEventListener('mousemove', () => {
        clearTimeout(timer);
        controls.style.display = 'flex';
        timer = setTimeout(() => {
          controls.style.display = 'none';
        }, 5000);
      });
      this.showTimelapseInfo(id);
    },
    convertSecondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
      return `${minutes}: ${formattedSeconds} `;
    },
    delay(id, duration) {
      return new Promise((resolve) => setTimeout(() => {
        resolve();
      }, duration));
    },
    toggleFullScreenFunc(id) {
      const controls = document.getElementById(`video_${id}`);
      if (this.isFullScreen && this.extraFullScreen) {
        this.extraFullScreen = false;
        this.$_fullscreenMixin_exitFullScreenClickHandler();
        this.toggleFullscreen(false);
        return;
      }
      this.extraFullScreen = true;
      this.toggleFullscreen(true);
      controls.requestFullscreen();
    },
    async updateProgressBar(id) {
      const timeText = document.getElementById(`time_text_${id}`);
      if (timeText) {
        timeText.textContent = this.convertSecondsToMinutes(this.playTime(id));
      }
      const player = document.getElementById(`timelapse_${id}`);

      player.addEventListener('ended', () => {
        const startButton = document.getElementById(`start_${id}`);
        startButton.classList.remove('mdi-pause');
        startButton.classList.add('mdi-play');
        player.pause();
      });
      const progress = document.getElementById(`progress_${id}`);
      const width = (player.currentTime / player.duration) * 100;

      if (Math.floor(player.currentTime) < player.duration) {
        progress.style.width = `${width}%`;
      }
      if (!player.ended) {
        await this.delay(id, 1000);
      }
    },
    playPauseVideo(id) {
      const player = document.getElementById(`timelapse_${id}`);
      const startButton = document.getElementById(`start_${id}`);
      if (player.paused || player.ended) {
        startButton.classList.remove('mdi-play');
        startButton.classList.add('mdi-pause');
        player.play();
      } else {
        startButton.classList.remove('mdi-pause');
        startButton.classList.add('mdi-play');
        player.pause();
      }
    },
    timelapseDateRange(timelapse) {
      if (timelapse.category === 'daily') {
        return `${this.utcFormatDate(timelapse.end)}`;
      }
      return `${this.formatDate(timelapse.start)} - ${this.utcFormatDate(timelapse.end)}`;
    },
    monitorTimelapsesStatus() {
      clearTimeout(this.timelapseStatusesMonitorTimeoutId);
      this.timelapseStatusesMonitorTimeoutId = null;
      if (!this.timelapsesToMonitorStatus.length) {
        return;
      }
      this.timelapseStatusesMonitorTimeoutId = setTimeout(() => {
        const timelapseIdsToCheck = map(this.timelapsesToMonitorStatus, ({ id }) => id);
        if (!timelapseIdsToCheck.length) {
          return;
        }
        this.fetchTimelapses({ id__in: timelapseIdsToCheck.join(',') }).then((response) => {
          const { dataList, normData } = response;
          forEach(dataList, (timelapseId) => {
            const timelaseIndex = findIndex(
              this.timelapses.list,
              ({ id }) => id === timelapseId,
            );
            if (timelaseIndex < 0) {
              return;
            }
            const currentStatus = this.timelapses.list[timelaseIndex].status;
            const newStatus = normData[timelapseId].status;
            if (currentStatus === newStatus) {
              return;
            }
            this.timelapses.list[timelaseIndex].status_changed = false;
            // let vue to rerender before update status
            setTimeout(() => {
              const timelapseState = this.timelapses.list[timelaseIndex];
              const newTimelapseState = {
                ...timelapseState,
                ...normData[timelapseId],
                status_changed: timelapseState.status !== normData[timelapseId].status,
              };
              this.$set(this.timelapses.list, timelaseIndex, newTimelapseState);
            }, 500);
          });
        }).finally(() => {
          this.monitorTimelapsesStatus();
        });
      }, CHECK_TIMELAPSE_STATUS_INTERVAL);
    },
    loadNextTimelapses() {
      this.updateTimelapses({ isLoading: true });
      const fetchTimelapsesPayload = {
        offset: this.totalTimelapsesLoaded,
        limit: TIMELAPSES_PER_REQUEST_LIMIT,
        ordering: '-date_created',
      };
      if (this.isShowFinishedOnly) {
        fetchTimelapsesPayload.status = 'finished';
      }
      if (this.isSearchActive) {
        fetchTimelapsesPayload.project = this.searchBy;
      }
      this.fetchTimelapses(fetchTimelapsesPayload).then((payload) => {
        const timelapses = map(payload.normData, (timelapse) => ({
          ...timelapse,
          projectview_details: null,
          project_details: null,
          company_details: null,
        }));
        this.updateTimelapses({
          totalCount: payload.response.data.count,
          list: [
            ...this.timelapses.list,
            ...timelapses,
          ],
        });
      }).finally(() => {
        this.updateTimelapses({ isLoading: false });
        this.monitorTimelapsesStatus();
      });
    },
    async loadCompaniesTimelapses(timelapseIds) {
      if (isEmpty(this.allCompanies)) {
        await this.fetchAllCompanies();
      }
      const projectsIdsToLoad = uniq(compact(map(
        filter(
          this.timelapseList,
          (timelase) => includes(timelapseIds, timelase.id),
        ),
        (timelase) => timelase.project,
      )));
      if (!projectsIdsToLoad.length) {
        return;
      }
      await this.fetchProjects({ id__in: join(projectsIdsToLoad), no_limit: true })
        .then((response) => {
          this.timelapses.list = map(this.timelapses.list, (timelapse) => {
            if (includes(response.dataList, timelapse.project)) {
              const projectCompany = find(
                this.allCompanies,
                (company) => company.id === response.normData[timelapse.project].company,
              );
              timelapse.project_details = response.normData[timelapse.project];
              timelapse.company_details = projectCompany;
            }
            return timelapse;
          });
        });
    },
    loadProjectViewsTimelapses(timelapseIds) {
      const projectViewsIdsToLoad = uniq(compact(map(
        filter(
          this.timelapseList,
          (timelase) => includes(timelapseIds, timelase.id),
        ),
        (timelase) => timelase.projectview,
      )));
      if (!projectViewsIdsToLoad.length) {
        return;
      }
      this.fetchProjectViews({ id__in: join(projectViewsIdsToLoad) })
        .then((payload) => {
          this.timelapses.list = map(this.timelapses.list, (timelapse) => {
            if (includes(payload.dataList, timelapse.projectview)) {
              timelapse.projectview_details = payload.normData[timelapse.projectview];
              timelapse.status_changed = false;
            }
            return timelapse;
          });
        });
    },
    updateTimelapses(data) {
      this.timelapses = {
        ...this.timelapses,
        ...data,
      };
    },
    formatTimeDate(dateStr) {
      const date = new Date(dateStr);
      const formatter = new Intl.DateTimeFormat('en', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
      const parts = formatter.formatToParts(date);
      const result = {};
      parts.forEach(({ type, value }) => {
        result[type] = value;
      });
      return `${result.hour}:${result.minute} ${result.day}/${result.month}/${result.year}`;
    },
    formatDate(date) {
      return moment(date).format('dd DD MMM YYYY');
    },
    utcFormatDate(date) {
      return moment.utc(date).format('dd DD MMM YYYY');
    },
    formatTimelapseStatus(status) {
      const statusNumber = indexOf(this.timelapseMonitorStatuses, status) + 1;
      if (statusNumber < 1) {
        return `${this.$t(status)}`;
      }
      return `${this.$t(status)} - ${this.$t('step')} ${statusNumber}
      ${this.$t('of')} ${this.timelapseMonitorStatuses.length}`;
    },
    getTimelapseTitle(timelapse) {
      let title = timelapse.name;
      const companyName = timelapse.company_details?.name;
      if (timelapse.category !== CATEGORY_CUSTOM && companyName) {
        title = `${companyName} / ${title}`;
      }
      return title;
    },
    projectViewName(timelapse) {
      return timelapse.projectview_details?.name || null;
    },
    isShowDownload(timelapse) {
      return this.isAllowToDownload && timelapse.status === 'finished' && timelapse.video_file_url;
    },
    routerTo(timelapse) {
      return {
        name: this.cameraDetailPageName,
        params: {
          projectId: timelapse.project,
          projectViewId: timelapse.projectview,
        },
      };
    },
    checkIfNeedsToStartLoadingTimelapses(e) {
      if (!this.isLoadedAllTimelapses
        || !this.$refs.timelapses
        || this.timelapses.isLoading
        || e.deltaY <= 0) {
        return;
      }

      const nodes = this.$refs.timelapses.querySelectorAll(':scope > *');
      if (!nodes.length) {
        return;
      }
      const nodeToCheckVisibility = nodes[nodes.length - LOAD_NEW_TIMELAPSES_WHEN_LEFT_NUM];
      if (isInViewport(nodeToCheckVisibility)) {
        this.loadNextTimelapses();
      }
    },
    getTimelapseStatusClass(timelapse) {
      switch (timelapse.status) {
        case 'finished':
        case 'error':
          return `timelapse_status__${timelapse.status}`;
        default:
          return 'timelapse_status__progress';
      }
    },
    getTimelapsePoster(timelapse) {
      if (!timelapse.video_file_url) {
        return this.timelapseReadySoonGif;
      }
      return timelapse.thumbnail_url;
    },
    isShowTimelapseError(timelapse) {
      return this.isShowStatus && timelapse.status === 'error' && !!timelapse.error_message;
    },
    // The user can remove only his own timelpase. The admin can remove anything.
    isAllowToRemove(timelapse) {
      return this.isRemovable && this.isUserHasPermissionToRemove
        && (this.$_permissionMixin_isAdmin || timelapse.owner === this.$_permissionMixin_user.id);
    },
    onRemoveTimelapseClick(timelapseId) {
      if (!this.isAllowToRemove) {
        this.timelapses.error = `You are not allowed to remove ${this.timelapseCategory} timelases`;
        return;
      }

      if (this.timelapses.isRemoving) {
        return;
      }

      this.timelapses.isRemoving = true;
      this.$store.dispatch(this.removeAction, timelapseId).then(() => {
        const remainingTimelapses = forEach(filter(
          this.timelapses.list,
          (timelapse) => timelapse.id !== timelapseId,
        ), (timelapse) => {
          timelapse.status_changed = false;
        });
        this.timelapses = {
          ...this.timelapses,
          totalCount: this.timelapses.totalCount - 1,
          list: remainingTimelapses,
        };
      }).finally(() => {
        this.timelapses.isRemoving = false;
      });
    },
    ...mapActions({
      fetchDailyTimelapses: 'listDailytimelapses',
      fetchWeeklyTimelapses: 'listWeeklytimelapses',
      fetchMonthlyTimelapses: 'listMonthlytimelapses',
      fetchStpTimelapses: 'listStptimelapses',
      fetchCustomTimelapses: 'listCustomtimelapses',
      fetchProjectViews: 'listProjectviews',
      fetchProjects: 'listProjects',
      fetchAllCompanies: 'fetchAllCompanies',
      toggleFullscreen: 'toggleFullscreen',
    }),
  },
  beforeDestroy() {
    this.timelapses.list.forEach((tl) => {
      this.removeVideoEventListeners(tl);
    });
    window.removeEventListener('scroll', this.checkIfNeedsToStartLoadingTimelapses);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/buttons.scss';
  @import '~@/sass/mixins/v3/helpers.scss';

  .timelapses_list {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 55.3vw;
    max-width: 100%;

    @media (orientation: portrait) {
      width: 100%;
    }

    @media only screen and (max-width: 900px) {
      font-size: 1.4em;
    }
    .timelapse_information {
      position: absolute;
      bottom: 8vh;
      left: 50%;
      transform: translateX(-50%);
      background-color: black;
      color: white;
      padding: 2vh 1vw;
      z-index: 20;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s, visibility 0.3s;
    }
    .timelapses_list__camera__timelapse:hover .timelapse_information {
      opacity: 1;
      visibility: visible;
    }

    .timelapses_list__camera {
      width: 100%;
      margin-bottom: 6.5vh;

      &:last-child {
        margin-bottom: 0;
      }

      .timelapses_list__camera__header {
        display: flex;
        flex-flow: nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        font-size: 2em;
        margin-bottom: 1.8vh;

        .timelapses_list__camera__header__status {
          font-size: .8em;
          margin-left: .5vw;
          opacity: 1;
          will-change: opacity;

          &.status_changed {
            animation: timelapse_status__blink 1s linear 10;
          }
        }

        &.timelapse_status__finished .timelapses_list__camera__header__status {
          color: $success-color;
        }

        &.timelapse_status__error .timelapses_list__camera__header__status {
          color: $fail-color;
        }

        &.timelapse_status__progress .timelapses_list__camera__header__status {
          color: $attention-color;
        }
      }

      @keyframes timelapse_status__blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.0;
        }
        100% {
          opacity: 1;
        }
      }

      .timelapses_list__camera__error {
        color: $fail-color;
        font-size: 1em;
        margin: -.8vh 0 3vh 0;

        & + .timelapses_list__camera__timelapse {
          .timelapses_list__camera__timelapse__video {
            max-height: 40vh;

            @media only screen and (max-width: 900px) {
              max-height: 20vh;
            }
          }
        }
      }
      .timelapses_list__camera__timelapse {
        position: relative;
        display: inline-block;
        width: 100%;

        .timelapses_list__camera__timelapse__video {
          max-height: 80vh;
          height: auto;
          max-width: 100%;

          &.full-screen {
            max-height: 100%;
          }

          @media only screen and (max-width: 900px) {
            max-height: 90vh;
          }
        }
        .timelapses_list__camera__timelapse__controls {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.8);
          opacity: 0;
          transition: opacity 0.2s ease-in-out;
          visibility: hidden;
          display: flex;
          .timelapses_list__camera__timelapse__controls_text {
            color: white;
            padding-top: 1.2vh;
            margin: 0;
            font-size: 0.8em;
            align-items: center;
            white-space: nowrap;
          }
          #btnFullScreen {
            padding-left: 0.5vw;
          }
          .button {
            color: white;
            padding: 0.6vh;
            margin: 0;
            cursor: pointer;
            font-size: 1.4em;
            align-items: center;
            white-space: nowrap;
            &.progress-bar {
              display: flex;
              width: 100%;
            }
            .icon-color {
              color: white;
            }
          }
          .progress {
            margin-top: 0.6vh;
            margin-bottom: 0.6vh;
            height: 1vh;
            background-color: rgba(8, 117, 221, 0.7);
          }
        }

        .timelapses_list__camera__timelapse__remove {
          position: absolute;
          display: flex;
          top: 0;
          right: 0;
          font-size: 2em;
          cursor: pointer;
          color: $fail-color;
          background-color: $default-white-color;
          border-radius: 50px;
          transform: translate3d(50%, -50%, 0);
          opacity: 0;
          transition: opacity .15s linear;

          &:hover {
            opacity: 1;
          }

          &.disabled {
            filter: grayscale(1);
          }
        }

        &:hover {
          .timelapses_list__camera__timelapse__controls {
            opacity: 1;
            visibility: visible;
          }
          .timelapses_list__camera__timelapse__remove {
            opacity: 1;
          }
        }
      }

      .timelapses_list__camera__actions {
        @include media-actions;
        font-weight: 400;

        .timelapses_list__camera__actions__left-side {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .timelapses_list__camera__actions__left-side__date {
            font-size: 1.3em;
            color: $default-blue-ultra-dark-color;

            @media screen and (max-width: 900px) {
              font-size: 1em;
            }
          }

          a {
            position: relative;
            font-weight: 500;
            text-decoration: none;
            color: $default-blue-color;
            font-size: 1.2em;
            margin-left: 0.4vw;
            padding-left: 4.3vw;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 2px;
              width: 3.9vw;
              height: 1px;
              background-color: $default-blue-ultra-dark-color;
            }

            &:hover {
              color: rgba(8, 118, 221, 0.5);
            }
          }
        }

        @include mi-icons;
      }
      .mdi-delete {
        margin-left: auto;
        margin-right: 0;
        font-size: 1.5em;
      }
      .timelapses_list__camera__actions__buttons {
        font-size: 1.5em;

        @media only screen and (max-width: 900px) {
          font-size: 2.2em;
          margin-right: 1.5vw;
        }
      }
    }
  }
</style>
