// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    closestImages: {},
    scroll: {},
  },
  actions,
  mutations,
};
