<template>
  <div class="pictures_grouped_by_tags">
    <Message :text="error.text" :type="error.type" v-if="error.isShow" />
    <div class="pictures_grouped_by_tags__buttons-list">
      <TagsLine :tags="tagsList" @tagsChanged="onTagsChanged" v-if="isShowTagsLine"/>
    </div>
    <div v-if="!isComponentLoading">
      <PicturesGroupedByTags class="pictures_grouped_by_tags__list"
                              :images="picture.imagesList"
                              :tag="picture.tag" @removed="onTagRemoved"
                              v-for="(picture, idx) in groupedByTagsActiveImages" :key="idx"/>

    </div>
    <CameraPreloader v-if="isComponentLoading" />
    <NoItemsYet
      v-if="isShowNoCameras"
      :header="$t('tags_likes.no_tags')"
      text="tags_likes.tags_text"
      icon="mdi mdi-rotate-180 mdi-label-outline"/>
  </div>
</template>

<script>
import TagsLine from '@/components/v3/Helpers/TagsLine';
import PicturesGroupedByTags from '@/components/v3/Cameras/PicturesGroupedByTags';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import Message from '@/components/v3/Message';
import { errorMixin, permissionMixin } from '@/mixins/v3';
import {
  filter, includes, map, forEach, findIndex, some,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { getErrorFromResponse } from '@/helpers';
import PERMISSIONS from '@/constants/v3';
import NoItemsYet from '@/components/v3/Helpers/NoItemsYet';

export const LOCAL_STORAGE_ACTIVE_TAGS_KEY = 'my_tags__active';

export default {
  name: 'PicturesGroupedByTagsList',
  mixins: [errorMixin, permissionMixin],
  components: {
    Message,
    TagsLine,
    NoItemsYet,
    CameraPreloader,
    PicturesGroupedByTags,
  },
  data: () => ({
    isImagesLoading: true,
    isTagsLoading: true,
    tagsList: [],
    picturesList: [],
  }),
  mounted() {
    if (!this.isAllowToTag) {
      this.error = {
        ...this.error,
        text: this.$t('tags_likes.tags_no_permission'),
        isShow: true,
      };
      return;
    }
    this.getUserTags();
  },
  watch: {
    activeTagsIds(newActiveTagsIds) {
      localStorage[LOCAL_STORAGE_ACTIVE_TAGS_KEY] = JSON.stringify(newActiveTagsIds);
    },
  },
  computed: {
    activeTags() {
      return filter(this.tagsList, ({ active }) => active);
    },
    activeTagsIds() {
      return map(this.activeTags, ({ id }) => id);
    },
    activeImages() {
      return filter(this.picturesList, ({ tags }) =>
        some(this.activeTagsIds, (tag) => includes(tags, tag)));
    },
    groupedByTagsActiveImages() {
      return map(this.activeTags, (tag) => {
        const imagesList = filter(this.activeImages, ({ tags }) => includes(tags, tag.id));
        return { tag, imagesList };
      });
    },
    isShowTagsLine() {
      return !this.isComponentLoading && this.tagsList.length;
    },
    isComponentLoading() {
      return this.isTagsLoading || this.isImagesLoading;
    },
    isShowNoCameras() {
      return !this.isComponentLoading && !this.tagsList.length;
    },
    isAllowToTag() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_taggedimage);
    },
    error() {
      return this.$_errorMixin_data;
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    getUserTags() {
      this.fetchUserTags({
        user: this.user.id,
        system_tag: false,
        project__isnull: true,
        limit: 10000,
      }).then((response) => {
        this.tagsList = map(response.normData, (tag) => ({ ...tag, active: false }));
        this.setDefaultActiveTags();
        this.getImagesByTags();
      }).catch((e) => {
        this.$_errorMixin_showError(getErrorFromResponse(e));
      }).finally(() => {
        this.isTagsLoading = false;
      });
    },
    setDefaultActiveTags() {
      if (!this.tagsList.length) {
        return;
      }
      if (!localStorage[LOCAL_STORAGE_ACTIVE_TAGS_KEY]) {
        this.tagsList[0].active = true;
        return;
      }
      try {
        const tagsToSetActive = JSON.parse(localStorage[LOCAL_STORAGE_ACTIVE_TAGS_KEY]);
        forEach(this.tagsList, (tag) => {
          if (includes(tagsToSetActive, tag.id)) {
            tag.active = true;
          }
        });
      } catch (e) {
        this.$_errorMixin_showError(getErrorFromResponse(e));
      }
      const isAnyActiveTags = some(this.tagsList, (tag) => tag.active);
      if (!isAnyActiveTags) {
        this.tagsList[0].active = true;
      }
    },
    getImagesByTags() {
      if (!this.tagsList.length) {
        this.picturesList = [];
      }
      const payload = {
        tags__in: map(this.tagsList, (tag) => tag.id).join(),
      };
      this.fetchTaggedImages(payload).then((taggedImagesResponse) => {
        const imagesIdsList = map(
          taggedImagesResponse.normData,
          (taggedimage) => taggedimage.image,
        );
        if (!imagesIdsList.length) {
          return;
        }
        this.fetchImages({ id__in: imagesIdsList.join() }).then((imagesResponse) => {
          this.picturesList = map(imagesResponse.normData, (image) => image);
        }).catch((e) => {
          this.$_errorMixin_showError(getErrorFromResponse(e));
        }).finally(() => {
          this.isImagesLoading = false;
        });
      }).catch((e) => {
        this.$_errorMixin_showError(getErrorFromResponse(e));
        this.isImagesLoading = false;
      });
    },
    onTagRemoved({ tag }) {
      this.tagsList = filter(this.tagsList, (existsTag) => existsTag.id !== tag.id);
    },
    onTagsChanged(tags) {
      forEach(tags, (tag) => {
        const existsTagIndex = findIndex(
          this.tagsList,
          (existsTag) => existsTag.id === tag.id,
        );
        if (existsTagIndex > -1) {
          this.tagsList[existsTagIndex].active = tag.active;
        }
      });
    },
    ...mapActions({
      fetchUserTags: 'listTags',
      fetchTaggedImages: 'listTaggedimages',
      fetchImages: 'listImages',
    }),
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .pictures_grouped_by_tags {
    position: relative;
    width: 55.3vw;
    max-width: 100%;
    margin: 0 auto;

    @media (orientation: portrait) {
      width: 100%;
    }

    .pictures_grouped_by_tags__buttons-list {
      position: relative;
      display: flex;
      flex-flow: nowrap;
      width: 100%;
      margin-bottom: 7.5vh;
    }

    .pictures_grouped_by_tags__list {
      margin-bottom: 6vh;
    }
  }
</style>
