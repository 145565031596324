import Auth from '@aws-amplify/auth';

import {
  SET_LOADING,
} from '@/store/mutation-types';
import {
  CURRENTUSER_REQUEST,
  CURRENTUSER_SUCCESS,
  CURRENTUSER_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
} from './mutation-types';

Auth.configure({
  userPoolId: process.env.VUE_APP_USERPOOLID,
  userPoolWebClientId: process.env.VUE_APP_USERPOOL_APP_CLIENT_ID,
});

export default {
  async changePassword({ commit }, { user, oldPassword, newPassword }) {
    commit(SET_LOADING, true);
    return Auth.changePassword(user, oldPassword, newPassword).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async forceChangePassword({ commit }, { user, newPassword }) {
    commit(SET_LOADING, true);
    return Auth.completeNewPassword(user, newPassword).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async confirmSignUp({ commit }, { username, code, options }) {
    commit(SET_LOADING, true);
    return Auth.confirmSignUp(username, code, options).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async forgotPassword({ commit }, username) {
    commit(SET_LOADING, true);
    return Auth.forgotPassword(username).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async forgotPasswordSubmit({ commit }, { username, code, password }) {
    commit(SET_LOADING, true);
    return Auth.forgotPasswordSubmit(username, code, password).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async getCurrentAuthenticatedUser({ commit }, bypassCache = false) {
    commit(CURRENTUSER_REQUEST);
    return Auth.currentAuthenticatedUser({ bypassCache })
      .then((user) => {
        commit(CURRENTUSER_SUCCESS, user);
        return user;
      })
      .catch((error) => {
        commit(CURRENTUSER_ERROR);
        throw error;
      });
  },
  async login({ commit }, credentials) {
    commit(LOGIN_REQUEST);
    return Auth.signIn(credentials.email, credentials.password)
      .then((user) => {
        commit(LOGIN_SUCCESS);
        return user;
      })
      .catch((error) => {
        commit(LOGIN_ERROR);
        throw error;
      });
  },
  async logout({ dispatch }) {
    Auth.signOut();
    dispatch('setActiveUser', null);
    // Redirect the page to the login screen so
    // all items in store are removed.
    window.location.href = '/';
  },
  async register({ commit }, signUpParams) {
    commit(SET_LOADING, true);
    return Auth.signUp(signUpParams).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async updateUser({ commit }, { user, data }) {
    commit(SET_LOADING, true);
    return Auth.updateUserAttributes(user, data).finally(() => {
      commit(SET_LOADING, false);
    });
  },
};
