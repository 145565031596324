import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import PlaceOutline from '../components/icons/PlaceOutline';
import LabelOutline from '../components/icons/LabelOutline';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    placeOutline: {
      component: PlaceOutline,
    },
    labelOutline: {
      component: LabelOutline,
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#318bb9',
        secondary: '#13455a',
        accent: '#64ae97',
      },
    },
  },
});
