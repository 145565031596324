export const MIN_ZOOM = 100;

export default {
  /*  eslint no-param-reassign: ["error", { "props": false }] */
  init() {
    const defaults = {
      zoom: 0.10,
      maxZoom: 0,
      initialZoom: 1,
      initialX: 0.5,
      initialY: 0.5,
      zoomKey: 'altKey',
      resetZoomOnInit: true,
      grabWheelOver: [], // selectors to grab wheel over
    };

    const main = (img, options) => {
      if (!img || !img.nodeName || img.nodeName !== 'IMG') {
        return;
      }

      const settings = {};

      let width;
      let height;
      let bgWidth;
      let bgHeight;
      let bgPosX;
      let bgPosY;
      let bgZoom;
      let previousEvent;
      let transparentSpaceFiller;

      let resizeObserver;
      let contentBoxSize = {
        inlineSize: null,
        blockSize: null,
      };

      const touches = {
        isTouchZoomActive: false,
        lastScaleValue: 0,
      };

      function setSrcToBackground(imgToSet) {
        imgToSet.style.backgroundRepeat = 'no-repeat';
        const imageSrc = imgToSet.dataset.src || imgToSet.src;
        imgToSet.style.backgroundImage = `url("${imageSrc}")`;
        const wBtoa = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="${imgToSet.naturalWidth}" height="${imgToSet.naturalHeight}"></svg>`);
        transparentSpaceFiller = `data:image/svg+xml;base64,${wBtoa}`;
        imgToSet.src = transparentSpaceFiller;
      }

      function updateBgStyle() {
        if (bgPosX > 0) {
          bgPosX = 0;
        } else if (bgPosX < width - bgWidth) {
          bgPosX = width - bgWidth;
        }

        if (bgPosY > 0) {
          bgPosY = 0;
        } else if (bgPosY < height - bgHeight) {
          bgPosY = height - bgHeight;
        }

        img.style.backgroundSize = `${bgWidth}px  ${bgHeight}px`;
        img.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;
      }

      function reset(entries) {
        const entry = entries && entries.length ? entries[0] : {};
        if (entry.contentBoxSize) {
          // Firefox implements `contentBoxSize` as a single content rect, rather than an array
          const actualContentBoxSize = Array.isArray(entry.contentBoxSize)
            ? entry.contentBoxSize[0] : entry.contentBoxSize;

          if (contentBoxSize.inlineSize === actualContentBoxSize.inlineSize
            && contentBoxSize.blockSize === actualContentBoxSize.blockSize) {
            return;
          }

          contentBoxSize = {
            inlineSize: actualContentBoxSize.inlineSize,
            blockSize: actualContentBoxSize.blockSize,
          };
        }

        const initial = Math.max(settings.initialZoom, 1);
        const computedStyle = window.getComputedStyle(img, null);

        width = Math.ceil(parseFloat(computedStyle.width));
        height = Math.ceil(parseFloat(computedStyle.height));

        bgWidth = width * initial;
        bgHeight = height * initial;
        bgPosX = -(bgWidth - width) * settings.initialX;
        bgPosY = -(bgHeight - height) * settings.initialY;
        bgZoom = 0;
        updateBgStyle();
        const event = new CustomEvent('wheelzoom.zoomReset', {
          detail: { zoom: MIN_ZOOM, img },
        });
        window.dispatchEvent(event);
      }

      function setAbsoluteZoom(newZoom, newWidth, newHeight, posX, posY) {
        bgWidth = newWidth;
        bgHeight = newHeight;
        bgZoom = newZoom;
        bgPosX = posX;
        bgPosY = posY;

        updateBgStyle();
      }

      function onSetAbsoluteZoom({ detail: zoomDetails }) {
        const {
          newZoom,
          newWidth,
          newHeight,
          posX,
          posY,
        } = zoomDetails;
        setAbsoluteZoom(newZoom, newWidth, newHeight, posX, posY);
      }

      function zoom(zoomStep, posX, posY, isWheel = false, isFireEvent = true) {
        let newZoomStep = zoomStep;
        // Zoom in percents
        const absoluteZoom = Math.round(MIN_ZOOM + bgZoom * 100);
        let newAbsoluteZoom = Math.round(MIN_ZOOM + (bgZoom + newZoomStep) * 100);
        const rect = img.getBoundingClientRect();
        const offsetX = posX - rect.left - window.scrollX;
        const offsetY = posY - rect.top - window.scrollY;

        // Record the offset between the bg edge and cursor:
        const bgCursorX = offsetX - bgPosX;
        const bgCursorY = offsetY - bgPosY;

        // Use the previous offset to get the percent offset between the bg edge and cursor:
        const bgRatioX = bgCursorX / bgWidth;
        const bgRatioY = bgCursorY / bgHeight;

        // Update the bg size:
        if (newZoomStep > 0) {
          if (settings.maxZoom) {
            if (absoluteZoom >= settings.maxZoom) {
              return;
            }
            if (newAbsoluteZoom > settings.maxZoom) {
              do {
                newZoomStep -= settings.zoom;
                newAbsoluteZoom = Math.round(MIN_ZOOM + (bgZoom + newZoomStep) * 100);
              } while (newAbsoluteZoom > settings.maxZoom);
            }
          }
          bgWidth += bgWidth * newZoomStep;
          bgHeight += bgHeight * newZoomStep;
          bgZoom += newZoomStep;
        } else {
          newZoomStep *= -1;
          bgWidth -= bgWidth * newZoomStep;
          bgHeight -= bgHeight * newZoomStep;
          bgZoom -= newZoomStep;
        }

        // Take the percent offset and apply it to the new size:
        bgPosX = offsetX - (bgWidth * bgRatioX);
        bgPosY = offsetY - (bgHeight * bgRatioY);

        // Prevent zooming out beyond the starting size
        if (bgWidth <= width || bgHeight <= height) {
          reset();
        } else {
          updateBgStyle();
        }

        if (isFireEvent) {
          const event = new CustomEvent('wheelzoom.zoomChanged', {
            detail: {
              zoom: Math.round(MIN_ZOOM + bgZoom * 100),
              wheel: isWheel,
              bgPosX,
              bgPosY,
              bgWidth,
              bgHeight,
              img,
            },
          });
          window.dispatchEvent(event);
        }
      }

      function onzoom(e) {
        const {
          isFireEvent,
          zoom: newZoom,
          posX = window.innerWidth / 2,
          posY = window.innerHeight / 2,
        } = e.detail;
        zoom(newZoom, posX, posY, false, isFireEvent);
      }

      function onwheel(e) {
        let deltaY = 0;
        if (!e[settings.zoomKey]) return;
        e.preventDefault();
        if (e.deltaY) { // FireFox 17+ (IE9+, Chrome 31+?)
          deltaY = e.deltaY;
        } else if (e.wheelDelta) {
          deltaY = -e.wheelDelta;
        }
        const newZoom = deltaY < 0 ? settings.zoom : -settings.zoom;
        zoom(newZoom, e.pageX, e.pageY, true);
      }

      function onTouchZoom(e) {
        e.preventDefault();
        e.stopPropagation();

        const dist = Math.hypot(e.touches[0].pageX - e.touches[1].pageX,
          e.touches[0].pageY - e.touches[1].pageY);
        const direction = e.scale > touches.lastScaleValue ? 1 : -1;
        const zoomValue = dist * settings.zoom * 0.002;
        const newZoom = direction > 0 ? zoomValue : -zoomValue;
        touches.lastScaleValue = e.scale;

        zoom(newZoom, e.pageX, e.pageY, true);
      }

      function onGestureStart() {
        touches.isTouchZoomActive = true;
      }

      function onGestureEnd() {
        touches.isTouchZoomActive = false;
      }

      function drag(e) {
        e.preventDefault();
        if (previousEvent) {
          bgPosX += (e.pageX - previousEvent.pageX);
          bgPosY += (e.pageY - previousEvent.pageY);
        }
        previousEvent = e;
        updateBgStyle();

        const event = new CustomEvent('wheelzoom.positionChanged', {
          detail: { bgPosX, bgPosY, img },
        });
        window.dispatchEvent(event);
      }

      function setAbsolutePosition(posX, posY) {
        bgPosX = posX;
        bgPosY = posY;
        updateBgStyle();
      }

      function onSetAbsolutePosition({ detail: positionDetails }) {
        const {
          posX,
          posY,
        } = positionDetails;
        setAbsolutePosition(posX, posY);
      }

      function onTouchStart() {
        previousEvent = null;
      }

      function onTouchDrag(e) {
        e.preventDefault();
        e.stopPropagation();

        drag(e);
      }

      function removeDrag() {
        document.removeEventListener('mouseup', removeDrag);
        document.removeEventListener('mousemove', drag);
      }

      // Make the background draggable
      function draggable(e) {
        if (bgZoom <= 0) {
          return;
        }
        e.preventDefault();
        previousEvent = e;
        document.addEventListener('mousemove', drag);
        document.addEventListener('mouseup', removeDrag);
      }

      function onTouchMove(e) {
        const currentZoom = Math.round(MIN_ZOOM + bgZoom * 100);
        if (touches.isTouchZoomActive) {
          onTouchZoom(e);
        } else if (currentZoom > MIN_ZOOM) {
          onTouchDrag(e);
        }
      }

      function load() {
        const initial = Math.max(settings.initialZoom, 1);

        if (img.src === transparentSpaceFiller) return;

        const computedStyle = window.getComputedStyle(img, null);

        width = parseInt(computedStyle.width, 10);
        height = parseInt(computedStyle.height, 10);

        if (bgWidth === undefined || bgHeight === undefined || settings.resetZoomOnInit) {
          bgWidth = width * initial;
          bgHeight = height * initial;
        }
        if (bgPosX === undefined || bgPosY === undefined || settings.resetZoomOnInit) {
          bgPosX = -(bgWidth - width) * settings.initialX;
          bgPosY = -(bgHeight - height) * settings.initialY;
        }
        bgZoom = 0;
        setSrcToBackground(img);

        img.style.backgroundSize = `${bgWidth}px ${bgHeight}px`;
        img.style.backgroundPosition = `${bgPosX}px ${bgPosY}px`;
        img.addEventListener('wheelzoom.reset', reset);
        [img, ...settings.grabWheelOver].forEach((item) => item.addEventListener('wheel', onwheel));
        img.addEventListener('mousedown', draggable);
        img.addEventListener('wheelzoom.zoom', onzoom);
        img.addEventListener('wheelzoom.absolute_zoom', onSetAbsoluteZoom);
        img.addEventListener('wheelzoom.absolute_position', onSetAbsolutePosition);

        // touch zoom
        img.addEventListener('touchstart', onTouchStart);
        img.addEventListener('gesturestart', onGestureStart);
        img.addEventListener('touchmove', onTouchMove);
        img.addEventListener('gestureend', onGestureEnd);

        resizeObserver = new ResizeObserver(reset);
        resizeObserver.observe(img);
      }

      const destroy = (originalProperties) => {
        // eslint-disable-next-line prefer-rest-params
        img.removeEventListener('wheelzoom.destroy', destroy);
        img.removeEventListener('wheelzoom.reset', reset);
        img.removeEventListener('load', load);
        img.removeEventListener('mouseup', removeDrag);
        img.removeEventListener('mousemove', drag);
        img.removeEventListener('mousedown', draggable);
        img.removeEventListener('wheelzoom.zoom', onzoom);
        img.removeEventListener('wheelzoom.absolute_zoom', onSetAbsoluteZoom);
        img.removeEventListener('wheelzoom.absolute_position', onSetAbsolutePosition);
        [img, ...settings.grabWheelOver].forEach(
          (item) => item.removeEventListener('wheel', onwheel),
        );
        img.removeEventListener('touchstart', onTouchStart);
        img.removeEventListener('gesturestart', onGestureStart);
        img.removeEventListener('touchmove', onTouchMove);
        img.removeEventListener('gestureend', onGestureEnd);
        resizeObserver.unobserve(img);

        img.style = {
          ...img.style,
          backgroundImage: originalProperties.backgroundImage,
          backgroundRepeat: originalProperties.backgroundRepeat,
          backgroundSize: originalProperties.backgroundSize,
          backgroundPosition: originalProperties.backgroundPosition,
        };
        img.src = img.dataset.src || originalProperties.src;
      };

      const currentImgDestroy = destroy.bind(null, {
        backgroundImage: img.style.backgroundImage,
        backgroundRepeat: img.style.backgroundRepeat,
        backgroundSize: img.style.backgroundSize,
        backgroundPosition: img.style.backgroundPosition,
        src: img.src,
      });

      img.addEventListener('wheelzoom.destroy', currentImgDestroy);

      const zoomOptions = options || {};
      const availableZoomKeys = ['alt', 'ctrl', 'shift'];
      if (zoomOptions.zoomKey !== undefined && availableZoomKeys.includes(zoomOptions.zoomKey)) {
        zoomOptions.zoomKey += 'Key';
      } else {
        zoomOptions.zoomKey = defaults.zoomKey;
      }

      Object.keys(defaults).forEach((key) => {
        settings[key] = zoomOptions[key] !== undefined ? zoomOptions[key] : defaults[key];
      });

      if (typeof settings.grabWheelOver === 'object' && settings.grabWheelOver !== null) {
        Object.keys(settings.grabWheelOver).forEach((key) => {
          settings.grabWheelOver[key] = document.querySelector(settings.grabWheelOver[key]);
        });
      }

      const callback = (mutationsList) => {
        Object.values(mutationsList).forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-src') {
            const preloadImg = new Image();
            preloadImg.src = img.dataset.src;
            preloadImg.onload = () => { setSrcToBackground(img); };
          }
        });
      };
      const observer = new MutationObserver(callback);
      observer.observe(img, { attributes: true });

      if (img.complete) {
        load();
      }

      img.addEventListener('load', load);
    };

    // Do nothing in IE9 or below
    if (typeof window.btoa !== 'function') {
      return (elements) => elements;
    }
    return (elements, options) => {
      if (elements && elements.length) {
        Array.prototype.forEach.call(elements, (node) => {
          main(node, options);
        });
      } else if (elements && elements.nodeName) {
        main(elements, options);
      }
      return elements;
    };
  },
};

export const wheelChangeValue = (e) => {
  let deltaY = 0;
  if (e.deltaY) { // FireFox 17+ (IE9+, Chrome 31+?)
    deltaY = e.deltaY;
  } else if (e.wheelDelta) {
    deltaY = -e.wheelDelta;
  }

  return Math.round((-1 * deltaY) / Math.abs(deltaY));
};
