export const MIN_ZOOM_VALUE = 100;

export const PRE_PROCESS_ZOOM_CLASS = 'zoom-pre-process';

export const zoomMixin = {
  data: () => ({
    $_zoomMixin: {
      value: MIN_ZOOM_VALUE,
      minValue: MIN_ZOOM_VALUE,
      maxValue: 500,
      appendIcon: 'mdi-plus',
      prependIcon: 'mdi-minus',
      step: 10,
      isChanged: false,
      posX: window.innerWidth / 2,
      posY: window.innerHeight / 2,
      width: null,
      height: null,
      images: [],
    },
  }),
  mounted() {
    window.addEventListener('wheelzoom.zoomChanged',
      this.$_zoomMixin_onWheelzoomZoomChanged);
  },
  watch: {
    $_zoomMixin_zoomValue(newValue, oldValue) {
      if (this.zoom.isChanged) {
        this.zoom.isChanged = false;
        return;
      }
      const event = new CustomEvent('wheelzoom.zoom', {
        detail: { zoom: (newValue - oldValue) / 100 },
      });
      this.zoom.images.forEach((zoomImage) => zoomImage.dispatchEvent(event));
    },
  },
  computed: {
    $_zoomMixin_data: {
      get() {
        return this.$data.$_zoomMixin;
      },
      set(newValue) {
        this.$data.$_zoomMixin = {
          ...this.$data.$_zoomMixin,
          ...newValue,
        };
      },
    },
    $_zoomMixin_zoomValue() {
      return this.$_zoomMixin_data.value;
    },
  },
  methods: {
    $_zoomMixin_setZoomValue(value) {
      this.$_zoomMixin_data = { value };
    },
    $_zoomMixin_addImage(image) {
      if (!this.$_zoomMixin_data.images.find((existsImage) => existsImage === image)) {
        image.classList.add(PRE_PROCESS_ZOOM_CLASS);
        this.$_zoomMixin_data.images.push(image);
        setTimeout(() => {
          this.$_zoomMixin_setImageZoom(image);
          image.classList.remove(PRE_PROCESS_ZOOM_CLASS);
        }, 200);
      }
    },
    $_zoomMixin_setImagesPosition(data) {
      const event = new CustomEvent('wheelzoom.absolute_position', {
        detail: {
          posX: data.posX,
          posY: data.posY,
        },
      });
      this.$_zoomMixin_data = {
        posX: data.posX, posY: data.posY,
      };
      this.$_zoomMixin_data.images
        .filter((zoomImage) => zoomImage !== data.img)
        .forEach((zoomImage) => zoomImage.dispatchEvent(event));
    },
    $_zoomMixin_setImageZoom(image) {
      if (this.$_zoomMixin_data.value <= MIN_ZOOM_VALUE) {
        return;
      }
      const eventAbsoluteZoom = new CustomEvent('wheelzoom.absolute_zoom', {
        detail: {
          newZoom: (this.$_zoomMixin_data.value - MIN_ZOOM_VALUE) / 100,
          newWidth: this.$_zoomMixin_data.width,
          newHeight: this.$_zoomMixin_data.height,
          posX: this.$_zoomMixin_data.posX,
          posY: this.$_zoomMixin_data.posY,
        },
      });
      this.$_zoomMixin_data.images
        .filter((zoomImage) => zoomImage === image)
        .forEach((zoomImage) => zoomImage.dispatchEvent(eventAbsoluteZoom));
    },
    $_zoomMixin_onWheelzoomZoomChanged(e) {
      const {
        img, zoom, bgWidth, bgHeight, wheel: isChanged, bgPosX: posX, bgPosY: posY,
      } = e.detail;
      const event = new CustomEvent('wheelzoom.absolute_zoom', {
        detail: {
          newZoom: (zoom - this.$_zoomMixin_data.value) / 100,
          newWidth: bgWidth,
          newHeight: bgHeight,
          posX,
          posY,
        },
      });

      this.$_zoomMixin_data = {
        width: bgWidth, height: bgHeight, posX, posY, isChanged, value: zoom,
      };

      this.$_zoomMixin_data.images
        .filter((zoomImage) => zoomImage !== img)
        .forEach((zoomImage) => zoomImage.dispatchEvent(event));
    },
  },
  beforeDestroy() {
    window.removeEventListener('wheelzoom.zoomChanged',
      this.$_zoomMixin_onWheelzoomZoomChanged);
  },
};

export default zoomMixin;
