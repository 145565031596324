<template>
  <div class="camera-picture-feed-list">
    <div class="camera-picture-feed-list__image" v-for="image in images" :key="image.src">
      <div class="camera-picture-feed-list__image__project_name">
        {{ getProjectAndCompanyName(image) }}
      </div>
      <div class="camera-picture-feed-list__image__camera_name">{{ image.projectview_name }}</div>
      <CameraPicture :image-details="image" :tags="false" :like="false" />
    </div>
    <NoCameras v-if="!images.length"/>
  </div>
</template>

<script>
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import NoCameras from '@/components/v3/Cameras/NoCameras';

export default {
  name: 'CameraPictureFeedList',
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  components: {
    NoCameras,
    CameraPicture,
  },
  methods: {
    getProjectAndCompanyName(image) {
      let projectAndCompanyName = image.project_name;
      if (image.company_name) {
        projectAndCompanyName = `${image.company_name} / ${projectAndCompanyName}`;
      }
      return projectAndCompanyName;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .camera-picture-feed-list {
    width: 100%;

    .camera-picture-feed-list__image {
      width: 100%;
      margin-bottom: 4.8vh;

      &:last-child {
        margin-bottom: 0;
      }

      .camera-picture-feed-list__image__project_name {
        font-size: 2em;
        margin-bottom: 1.8vh;
      }

      .camera-picture-feed-list__image__camera_name {
        position: relative;
        font-size: 1.4em;
        line-height: 1;
        padding-left: 5vw;
        margin-bottom: 2.3vh;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 2px;
          width: 3.9vw;
          height: 1px;
          background-color: $default-blue-ultra-dark-color;
        }
      }
    }
  }
</style>
