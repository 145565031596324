<template>
  <div class="editor-camera-picture">
    <CameraPicture v-if="!useCanvas"
      :image-details="this.imageDetails"
      :is-hide-all-actions="true"
      :apply-filter="applyFilter"
      :apply-dof="applyDof"
      :apply-tilt-shift="applyTiltShift"
      :apply-focus-height="applyFocusHeight"
      @updateHeight="handleHeight"
      @initialImageSize="handleSize"
      @cameraPictureMounted="onCameraPictureMounted"
      ref="refCameraPicture"/>
    <div v-else class="editor-camera-picture-canvas">
      <canvas id="outputCanvas" ref="canvasPreview">
      </canvas>
    </div>

    <div class="editor-camera-picture__caption" v-if="isShowCaption">
      <div class="editor-camera-picture__caption__left-side">
        <div class="editor-camera-picture__caption__left-side__project-name"
             v-if="isShowProjectName">{{ projectName }}</div>
        <div class="editor-camera-picture__caption__left-side__camera-name"
             v-if="isShowCameraName">{{ cameraName }}</div>
      </div>
      <div class="editor-camera-picture__caption__right-side" v-if="isShowTime">
        {{ imageDate }}
      </div>
    </div>
    <picture v-if="isShowLogo" :class="logoPosition">
      <img :src="this.logoUrl" alt="logo">
    </picture>
    <picture class="bottom_right csite_logo" v-if="isImage && !isShowLogo">
      <img :src="csiteLogo" alt="C-Site Logo">
    </picture>
  </div>
</template>

<script>
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import moment from 'moment-timezone';

const csiteIcon = require('@/assets/images/dashboardNg/c-site_logo_white.svg');

export default {
  name: 'EditorCameraPicture',
  data() {
    return {
      csiteLogo: csiteIcon,
      cameraPictureHeight: 0,
    };
  },
  emits: ['updateHeight', 'initialImageSize'],
  props: {
    applyTiltShift: {
      Boolean,
      default: false,
    },
    applyDof: {
      type: Number,
    },
    applyFocusHeight: {
      type: Number,
    },
    imageDetails: {
      type: Object,
      required: true,
    },
    projectName: {
      type: String,
      default: null,
    },
    cameraName: {
      type: String,
      default: null,
    },
    logoUrl: {
      type: String,
      default: null,
    },
    logoPosition: {
      type: String,
      required: false,
    },
    watermarkText: {
      type: String,
      default: 'c-site.eu',
    },
    isShowTime: {
      type: Boolean,
      default: true,
    },
    applyFilter: {
      type: String,
      default: '',
    },
    useCanvas: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CameraPicture,
  },
  methods: {
    onCameraPictureMounted() {
      if (this.$refs.refCameraPicture) {
        this.$emit('editorCameraPictureMounted');
      }
    },
    handleHeight(height) {
      this.cameraPictureHeight = height;
      this.$emit('updateHeight', this.cameraPictureHeight);
    },
    handleSize(size) {
      this.$emit('initialImageSize', size);
    },
  },
  computed: {
    isImage() {
      return !!this.imageDetails?.image;
    },
    isShowProjectName() {
      return !!this.projectName;
    },
    isShowCameraName() {
      return !!this.cameraName;
    },
    isShowCaptionLeftSide() {
      return this.isShowProjectName || this.isShowCameraName;
    },
    isShowCaption() {
      return this.isImage && (this.isShowCaptionLeftSide || this.isShowTime);
    },
    isShowLogo() {
      return !!this.logoUrl && !!this.logoPosition;
    },
    imageDate() {
      const { date } = this.imageDetails;
      return moment(date).format('ddd DD MMM YYYY HH:mm');
    },
  },
};
</script>

<style scoped lang="scss">
  .editor-camera-picture {
    $top_caption_height: 3vh;
    $logo_margin: .5vw;
    position: relative;
    .editor-camera-picture-canvas {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .editor-camera-picture__caption {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #ffffff;
      background-color: #f3f8fba1;
      line-height: $top_caption_height;
      font-size: min(1.1vh, .55vw);
      padding: 0 .5vw;

      @media only screen and (max-width: 900px) {
        font-size: min(1.5vh, 1vw);
      }

      .editor-camera-picture__caption__left-side {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .editor-camera-picture__caption__left-side__project-name + div:before {
          content: '-';
          margin: 0 .3vw;
        }
      }
    }

    picture {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8%;

      &.csite_logo {
        opacity: 0.5;
      }

      &.top_left {
        top: calc($top_caption_height + $logo_margin);
        left: $logo_margin;
      }

      &.top_right {
        top: calc($top_caption_height + $logo_margin);
        right: $logo_margin;
      }

      &.bottom_left {
        bottom: $logo_margin;
        left: $logo_margin;
      }

      &.bottom_right {
        bottom: $logo_margin;
        right: $logo_margin;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
  }
</style>
