// eslint-disable-next-line import/no-cycle
import csite from '@/services/csite';
import { SET_LOADING } from '@/store/mutation-types';

export default {
  async fetchMaxDuration({ commit }, payload) {
    commit(SET_LOADING, true);
    return csite.timelapses.max_duration.get({ params: payload }).then((result) => {
      const { data = [] } = result;
      return data;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
};
