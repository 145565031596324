<template>
  <div class="cameras">
    <ExitFullscreen />
    <Menu :class="{ cameras__menu__hidden: isFullScreen }" :menu-list="menuList"
          :actions-list="activeActionsList" @activeTabChanged="onTabChanged"
          class="cameras__menu__mobile"/>
    <component class="cameras__layout" :is="activeComponent" v-bind="activeComponentProps"
               v-if="activeComponent && isComponentActive"/>
  </div>
</template>

<script>
import Feed from '@/components/v3/Cameras/Feed';
import Dashboard from '@/components/v3/Cameras/Dashboard';
import Map from '@/components/v3/Cameras/MapView/Map';
import Menu from '@/components/v3/TabsMenu';
import Search from '@/components/v3/Helpers/Menu/Search';
import Fullscreen from '@/components/v3/Helpers/Menu/Fullscreen';
import DashboardLayoutsDropdown from '@/components/v3/Helpers/Menu/DashboardLayoutsDropdown';
import { mapActions, mapState } from 'vuex';

import {
  filter, find, forEach, includes, isEmpty,
} from 'lodash';
import ExitFullscreen from '@/components/v3/Helpers/ExitFullscreen';
import { updateUserFrontendSettingsMixin } from '@/mixins';
import { SLIDESHOW, SLIDESHOW_LAYOUT } from '@/constants/v3/users';

const SEARCH_ACTION_CODE = 'search';
const FULLSCREEN_ACTION_CODE = 'fullscreen';
const DASHBOARD_LAYOUTS_DROPDOWN_ACTION_CODE = 'dashboard_layouts_dropdown';

export default {
  name: 'Cameras',
  mixins: [updateUserFrontendSettingsMixin],
  components: {
    ExitFullscreen,
    Menu,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('cameras_menu.tabs.feed'),
          code: 'feed',
          component: Feed,
          props: { projectId: null },
          actions: [SEARCH_ACTION_CODE, FULLSCREEN_ACTION_CODE],
        },
        {
          name: this.$t('cameras_menu.tabs.photo_show'),
          code: 'photo_show',
          component: Dashboard,
          actions: [DASHBOARD_LAYOUTS_DROPDOWN_ACTION_CODE, FULLSCREEN_ACTION_CODE],
        },
        {
          name: this.$t('cameras_menu.tabs.map'),
          code: 'map',
          component: Map,
          props: { projectId: null },
          actions: [SEARCH_ACTION_CODE, FULLSCREEN_ACTION_CODE],
        },
      ],
      actionsList: [
        {
          name: 'DashboardLayoutsDropdown',
          code: DASHBOARD_LAYOUTS_DROPDOWN_ACTION_CODE,
          component: DashboardLayoutsDropdown,
          events: {
            change: this.onCamerasLayoutChange,
          },
        },
        {
          name: this.$t('cameras_menu.buttons.search'),
          code: SEARCH_ACTION_CODE,
          component: Search,
          props: {
            placeholder: this.$t('cameras_menu.buttons.search_glass'),
            backendSearchAction: this.getProjectsWithCompany,
            itemText: 'search_title',
          },
          events: {
            change: this.onSearchChange,
          },
        },
        {
          name: 'Fullscreen',
          code: FULLSCREEN_ACTION_CODE,
          component: Fullscreen,
        },
      ],
      projectProp: null,
      activeMenuTab: null,
      searchByProjectId: null,
      isComponentActive: true,
    };
  },
  beforeMount() {
    [this.activeMenuTab] = this.menuList;
  },
  computed: {
    activeActionsList() {
      return filter(
        this.actionsList,
        (action) => includes(this.activeComponentActions, action.code),
      );
    },
    activeComponent() {
      return this.activeMenuTab.component;
    },
    activeComponentProps() {
      const { props = {} } = this.activeMenuTab;
      if ('projectId' in props) {
        props.projectId = this.searchByProjectId;
      }
      return props;
    },
    activeComponentActions() {
      return this.activeMenuTab?.actions;
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
      allCompanies: (state) => state.extra.allCompanies,
    }),
  },
  methods: {
    onTabChanged(newTab) {
      this.activeMenuTab = newTab;
    },
    onSearchChange({ value: projectId }) {
      this.searchByProjectId = projectId;
    },
    onCamerasLayoutChange({ value }) {
      this.changeCamerasLayout(value);
      const userSettings = this.$_updateUserFrontendSettingsMixin_FrontendSettings;
      const { [SLIDESHOW]: userSettingsSlideshow = {} } = userSettings;
      const { [SLIDESHOW_LAYOUT]: slideshowLayout = null } = userSettingsSlideshow;
      if (value === slideshowLayout) {
        return;
      }
      this.$_updateUserFrontendSettingsMixin_updateUserFrontendSettings(
        SLIDESHOW,
        { [SLIDESHOW_LAYOUT]: value },
        2000,
      );
    },
    getProjectsWithCompany(options) {
      return new Promise((resolve, reject) => {
        this.fetchProjects(options).then(async (response) => {
          if (isEmpty(this.allCompanies)) {
            await this.fetchAllCompanies();
          }
          forEach(response.normData, (project, id) => {
            const projectCompany = find(
              this.allCompanies,
              (company) => company.id === project.company,
            );
            const searchTitleCompany = projectCompany ? `${projectCompany.name} / ` : '';
            response.normData[id].company_details = projectCompany || null;
            response.normData[id].search_title = `${searchTitleCompany}${project.name}`;
          });
          resolve(response);
        }).catch(reject);
      });
    },
    async forceRerender() {
      this.isComponentActive = false;
      await this.$nextTick();
      this.isComponentActive = true;
    },
    ...mapActions({
      fetchProjects: 'listProjects',
      fetchAllCompanies: 'fetchAllCompanies',
      changeCamerasLayout: 'setCamerasLayout',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

  .cameras {
    width: 100%;

    .cameras__menu__hidden {
      display: none;
    }

    .cameras__menu__mobile {
      @media screen and (max-width: 500px) {
        font-size: 2.1em;
      }
      @media screen and (width > 500px) and (max-width: 900px) {
        font-size: 1.5em;
      }
    }
  }
</style>
