import {
  compact, difference, filter, find, flatMap, groupBy, includes, isEmpty, join, map, sortBy, uniq,
} from 'lodash';
import CAMERAS_PER_REQUEST_LIMIT from '@/constants/v3/cameras';
import md5 from 'md5';
import { mapActions, mapState } from 'vuex';

export const projectViewsMixin = {
  data: () => ({
    $_projectViewsMixin: {
      projectViews: {
        extraPayload: {},
        isLoading: false,
        totalCount: undefined,
        list: [],
      },
      lastActiveRequestPayload: null,
    },
  }),
  watch: {
    $_projectViewsMixin_projectViewsList(updatedProjectViewsList, oldProjectViewsList) {
      const oldProjectViewsIds = map(
        oldProjectViewsList,
        (oldProjectView) => oldProjectView.id,
      );
      const updatedProjectViewsIds = map(
        updatedProjectViewsList,
        (updatedProjectView) => updatedProjectView.id,
      );
      const newProjectViewsIds = difference(updatedProjectViewsIds, oldProjectViewsIds);
      if (newProjectViewsIds.length) {
        this.$_projectViewsMixin_loadProjectsForProjectViews(newProjectViewsIds);
        this.$_projectViewsMixin_loadImagesForProjectViews(newProjectViewsIds);
        this.$_projectViewsMixin_loadCameraViewsForProjectViews(newProjectViewsIds);
      }
    },
  },
  computed: {
    $_projectViewsMixin_data: {
      get() {
        return this.$data.$_projectViewsMixin;
      },
      set(newValue) {
        this.$data.$_projectViewsMixin = {
          ...this.$data.$_projectViewsMixin,
          ...newValue,
        };
      },
    },
    $_projectViewsMixin_projectViews() {
      return this.$_projectViewsMixin_data.projectViews;
    },
    $_projectViewsMixin_projectViewsList() {
      const grouped = groupBy(
        this.$_projectViewsMixin_projectViews.list, (projectview) => projectview.project,
      );
      const sortedGroups = sortBy(grouped, (group) => group[0].project).reverse();
      const resultByName = flatMap(sortedGroups, (group) =>
        sortBy(group, (pv) => pv.name));
      const resultByDetails = sortBy(resultByName, (item) => {
        if (item.camera_details && item.camera_details.active) {
          return -1;
        }
        return 1;
      });
      return resultByDetails;
    },
    $_projectViewsMixin_totalProjectViewLoaded() {
      return this.$_projectViewsMixin_projectViewsList.length;
    },
    $_projectViewsMixin_extraPayload() {
      return this.$_projectViewsMixin_projectViews.extraPayload;
    },
    $_projectViewsMixin_isLoadedAllProjectViews() {
      const projectViewsTotalCount = this.$_projectViewsMixin_projectViews.totalCount;
      if (!projectViewsTotalCount) {
        return false;
      }
      return projectViewsTotalCount - this.$_projectViewsMixin_totalProjectViewLoaded < 1;
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
    }),
  },
  methods: {
    $_projectViewsMixin_updateProjectViews(data) {
      this.$_projectViewsMixin_data.projectViews = {
        ...this.$_projectViewsMixin_data.projectViews,
        ...data,
      };
    },
    $_projectViewsMixin_resetProjectViews() {
      this.$_projectViewsMixin_updateProjectViews({
        totalCount: undefined,
        list: [],
        error: null,
      });
      this.$_projectViewsMixin_loadNextProjectViews();
    },
    async $_projectViewsMixin_loadNextProjectViews() {
      if (this.$_projectViewsMixin_isLoadedAllProjectViews) {
        return;
      }
      this.$_projectViewsMixin_updateProjectViews({ isLoading: true });
      if (isEmpty(this.allCompanies)) {
        await this.fetchAllCompanies();
      }
      const fetchProjectViewsPayload = {
        ...this.$_projectViewsMixin_extraPayload,
        offset: this.$_projectViewsMixin_totalProjectViewLoaded,
        limit: CAMERAS_PER_REQUEST_LIMIT,
        ordering: '-id',
      };
      this.$_projectViewsMixin_data.lastActiveRequestPayload = md5(fetchProjectViewsPayload);
      if (fetchProjectViewsPayload.id__in === '(blank)') {
        this.$_projectViewsMixin_updateProjectViews({ isLoading: false });
        return;
      }
      this.fetchProjectViews(fetchProjectViewsPayload).then((payload) => {
        const { lastActiveRequestPayload } = this.$_projectViewsMixin_data;
        if (lastActiveRequestPayload !== md5(fetchProjectViewsPayload)) {
          return;
        }
        const projectViews = map(payload.normData, (projectView) => ({
          ...projectView,
          project_details: null,
          camera_details: null,
          latest_image_details: {
            is_pending: !!projectView.latest_image,
          },
        }));
        this.$_projectViewsMixin_updateProjectViews({
          totalCount: payload.response.data.count,
          list: [
            ...this.$_projectViewsMixin_projectViewsList,
            ...projectViews,
          ],
        });
      }).catch(() => {
      }).finally(() => {
        this.$_projectViewsMixin_updateProjectViews({ isLoading: false });
      });
    },
    $_projectViewsMixin_loadCameraViewsForProjectViews(projectViewsIds) {
      const projectsIdsToLoad = uniq(compact(map(
        filter(
          this.$_projectViewsMixin_projectViewsList,
          (projectView) => includes(projectViewsIds, projectView.id),
        ),
        (projectView) => projectView.cameraview,
      )));
      if (!projectsIdsToLoad.length) {
        return;
      }
      this.fetchCameraViews({ id__in: join(projectsIdsToLoad) })
        .then((payload) => {
          const projectViewsList = map(this.$_projectViewsMixin_projectViewsList,
            (projectView) => {
              if (includes(payload.dataList, projectView.cameraview)) {
                projectView.camera_details = payload.normData[projectView.cameraview];
              }
              return projectView;
            });
          this.$_projectViewsMixin_updateProjectViews({ list: projectViewsList });
        });
    },
    $_projectViewsMixin_loadProjectsForProjectViews(projectViewsIds) {
      const projectsIdsToLoad = uniq(compact(map(
        filter(
          this.$_projectViewsMixin_projectViewsList,
          (projectView) => includes(projectViewsIds, projectView.id),
        ),
        (projectView) => projectView.project,
      )));
      if (!projectsIdsToLoad.length) {
        return;
      }
      this.fetchProjects({ id__in: join(projectsIdsToLoad) })
        .then((payload) => {
          const projectViewsList = map(this.$_projectViewsMixin_projectViewsList,
            (projectView) => {
              if (includes(payload.dataList, projectView.project)) {
                const projectViewCompany = find(
                  this.allCompanies,
                  (company) => company.id === payload.normData[projectView.project].company,
                );
                projectView.project_details = {
                  ...payload.normData[projectView.project],
                  company_details: projectViewCompany,
                };
              }
              return projectView;
            });
          this.$_projectViewsMixin_updateProjectViews({ list: projectViewsList });
        });
    },
    $_projectViewsMixin_loadImagesForProjectViews(projectViewsIds) {
      const imagesIdsToLoad = uniq(compact(map(
        filter(
          this.$_projectViewsMixin_projectViewsList,
          (projectView) => includes(projectViewsIds, projectView.id),
        ),
        (projectView) => projectView.latest_image,
      )));
      if (!imagesIdsToLoad.length) {
        return;
      }
      this.fetchImages({ id__in: join(imagesIdsToLoad) })
        .then((payload) => {
          const projectViewsList = map(this.$_projectViewsMixin_projectViewsList, (projectView) => {
            if (includes(payload.dataList, projectView.latest_image)) {
              projectView.latest_image_details = payload.normData[projectView.latest_image];
            }
            return projectView;
          });
          this.$_projectViewsMixin_updateProjectViews({ list: projectViewsList });
        }).finally(() => {
          const projectViewsList = map(this.$_projectViewsMixin_projectViewsList, (projectView) => {
            if (includes(projectViewsIds, projectView.id)) {
              projectView.latest_image_details.is_pending = false;
            }
            return projectView;
          });
          this.$_projectViewsMixin_updateProjectViews({ list: projectViewsList });
        });
    },
    ...mapActions({
      fetchProjects: 'listProjects',
      fetchProjectViews: 'listProjectviews',
      fetchAllCompanies: 'fetchAllCompanies',
      fetchCameraViews: 'listCameraviews',
    }),
  },
};

export default projectViewsMixin;
