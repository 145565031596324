export const COUNTRIES_LIST = [
  { code: 'BE', name: 'Belgium' },
  { code: 'DE', name: 'Denmark' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  { code: 'UK', name: 'United Kingdom' },
  { code: 'IT', name: 'Italy' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'PL', name: 'Poland' },
  { code: 'ES', name: 'Spain' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'AT', name: 'Austria' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'US', name: 'United States' },
];

export default COUNTRIES_LIST;
