<template >
  <div class="new_platform_popup" ref="popUp"
       @click="$_popupMixin_closePopUp" id="new_platform_popup"
       :class="{'hide-popup': !$_popupMixin_showPopup}">
    <div class="new_platform_popup__image">
      <picture>
        <img :src="operator" alt="operator" />
      </picture>
    </div>
    <div class="new_platform_popup__content">
      <div>{{ $t('components.new_platform.questions')}}</div>
      <div>{{ $t('components.new_platform.happy')}}</div>
      <div>{{ $t('components.new_platform.contact')}}
        <a href="mailto:support@c-site.eu">support@c-site.eu</a></div>
      <div>{{ $t('components.new_platform.or')}}
        <a href="tel:+3292791260">+32 9 279 12 60</a></div>
    </div>
    <i class="mdi mi-close"></i>
  </div>
</template>

<script>
import popupMixin from '@/mixins/v3/popupMixin';

const operator = require('@/assets/images/v3/icons/operator.png');

export default {
  name: 'NewPlatformPopup',
  mixins: [popupMixin],
  data: () => ({
    operator,
    popupName: 'close_new_platform_pop_up',
  }),
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.new_platform_popup {
  position: fixed;
  display: flex;
  flex-flow: nowrap;
  bottom: 0;
  right: 0;
  width: 25vw;
  height: 8vh;
  padding: 3vh 1vw;
  box-sizing: content-box;
  background-color: $default-light-color;
  border-radius: 0.75vw;

  &.hide-popup {
    display: none;
  }

  @media (orientation: portrait) {
    flex-flow: column;
  }

  @media only screen and (min-width: 600px) and (max-width: 900px) {
    width: 36vw;
    border: 1px solid $default-blue-ultra-dark-color;
    height: 15vh;
  }
  @media only screen and (max-width: 600px){
    width: 36vw;
    border: 1px solid $default-blue-ultra-dark-color;
    height: 11vh;
  }

  .new_platform_popup__image {
    @media (orientation: portrait) {
      height: 3vh;
      margin-bottom: 1vh;
    }

    picture {
      display: flex;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .new_platform_popup__content {
    text-align: center;
    margin-right: 2vw;
    margin-left: 1vw;
    font-size: .9em;
    line-height: 1.3;

    div {
      white-space: nowrap;

      a {
        text-decoration: none;
        font-weight: 600;
        color: $default-blue-ultra-dark-color;

        &:active, &:visited, &:focus {
          text-decoration: none;
          color: $default-blue-ultra-dark-color;
        }
      }
    }

    @media screen and (max-width: 900px) {
      line-height: 1.5;
      font-size: 1.6em;
    }
  }

  i {
    margin: 1.5vh;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      font-size: 1.8em;
    }
  }
}
</style>
