import { MENU_BUTTONS as LEFT_SIDEBAR_MENU_BUTTONS } from '@/constants/primaryMenuLeftSidebar';
import { MENU_BUTTONS as BOTTOM_SIDEBAR_MENU_BUTTONS } from '@/constants/primaryMenuBottomSidebar';

export const PROJECTS_URL = {
  name: 'projects',
  path: '/projects',
};

export const LAYOUT_URL = {
  name: 'dashboard',
  path: '/dashboard',
};
export const CAMERAS_URL = {
  name: 'cameras',
  path: '/cameras',
};
export const TIMELAPSES_URL = {
  name: 'timelapses',
  path: '/timelapses',
};

export const SHARING_URL = {
  name: 'sharing',
  path: '/sharing',
};

export const MENU = {
  [LEFT_SIDEBAR_MENU_BUTTONS.projects]: {
    route_name: PROJECTS_URL.name,
    route_path: PROJECTS_URL.path,
    has_submenu: false,
  },
  [LEFT_SIDEBAR_MENU_BUTTONS.layout]: {
    route_name: LAYOUT_URL.name,
    route_path: LAYOUT_URL.path,
    has_submenu: true,
  },
  [BOTTOM_SIDEBAR_MENU_BUTTONS.sharing]: {
    route_name: SHARING_URL.name,
    route_path: SHARING_URL.path,
    has_submenu: false,
  },
  [BOTTOM_SIDEBAR_MENU_BUTTONS.cameras]: {
    route_name: CAMERAS_URL.name,
    route_path: CAMERAS_URL.path,
    has_submenu: false,
  },
  [BOTTOM_SIDEBAR_MENU_BUTTONS.timelapses]: {
    route_name: TIMELAPSES_URL.name,
    route_path: TIMELAPSES_URL.path,
    has_submenu: true,
  },
  [BOTTOM_SIDEBAR_MENU_BUTTONS.support]: {
    route_name: null,
    route_path: null,
    has_submenu: false,
  },
  [BOTTOM_SIDEBAR_MENU_BUTTONS.settings]: {
    route_name: null,
    route_path: null,
    has_submenu: false,
  },
};

export const DEFAULT_HOME_URL = {
  route_name: MENU[LEFT_SIDEBAR_MENU_BUTTONS.layout].route_name,
  route_path: MENU[LEFT_SIDEBAR_MENU_BUTTONS.layout].route_path,
};

export default MENU;
