const TIMELAPSES_PER_REQUEST_LIMIT = 4;
// should be less than TIMELAPSES_PER_REQUEST_LIMIT
export const LOAD_NEW_TIMELAPSES_WHEN_LEFT_NUM = 2;
export const MAX_LOGO_SIZE = 1048576; // 1 MB

export const CATEGORY_DAILY = 'daily';
export const CATEGORY_WEEKLY = 'weekly';
export const CATEGORY_MONTHLY = 'monthly';
export const CATEGORY_STP = 'stp';
export const CATEGORY_CUSTOM = 'custom';
export const AVAILABLE_CATEGORIES = [CATEGORY_DAILY, CATEGORY_WEEKLY, CATEGORY_MONTHLY,
  CATEGORY_STP, CATEGORY_CUSTOM];

export default TIMELAPSES_PER_REQUEST_LIMIT;
