<template>
  <div class="layout_v3" :class="{'touch-device': isTouchDevice, 'fixed': isShowLayoutPreloader,
       'no-padding': !isMainLayoutPadding}">
    <div class="main_menu" :class="{ 'main_menu_hidden': isFullScreen }">
      <router-link class="main_menu__left-column" :to="{ name: 'v3' }" title="Home" append>
        <picture>
          <img :src="logo" alt="C-SITE Logo" />
        </picture>
      </router-link>
      <div class="main_menu__menu">
        <Menu/>
      </div>
      <router-link class="main_menu__right-column"
                   :class="{ 'router-link-active': isSettingsActive }"
                   :to="{ path: MY_CSITE_URL.path + '/projects' }"
                   :title="$t(MY_CSITE_URL.title)"
                   @click.native="navigateToPreferences"
                   append>{{ $t(MY_CSITE_URL.title) }}</router-link>
    </div>
    <div class="content">
      <router-view v-if="!isShowLayoutPreloader"/>
    </div>
    <CookiesPopup />
    <Footer v-if="!isFullScreen" />
    <NewPlatformPopup v-if="!isFullScreen"/>
    <FullscreenPreloader :is-show="isShowLayoutPreloader"
                         :error-text="fullscreenPreloaderErrorText" />
  </div>
</template>

<script>
import Menu from '@/components/v3/Header/Menu';
import Footer from '@/components/v3/Footer';
import NewPlatformPopup from '@/components/v3/Helpers/NewPlatformPopup';
import FullscreenPreloader from '@/components/v3/Helpers/FullscreenPreloader';
import { MY_CSITE_URL } from '@/constants/v3/menu';
import isTouchDevice from '@/helpers';
import { mapActions, mapState } from 'vuex';
import CookiesPopup from '@/components/v3/Helpers/CookiesPopup';

const logo = require('@/assets/images/v3/c-site_logo.svg');

export default {
  name: 'MainLayout',
  components: {
    CookiesPopup,
    Menu,
    Footer,
    NewPlatformPopup,
    FullscreenPreloader,
  },
  data: () => ({
    logo,
    MY_CSITE_URL,
    isTouchDevice: isTouchDevice(),
    error: {
      isShow: false,
      text: 'Failed to load user data!<br/> Please, try again later...',
    },
  }),
  mounted() {
    const html = document.querySelector('html');
    html.classList.add('v3');

    if (!this.user) {
      this.getCurrentUser(this.cognitoUser.username)
        .then((user) => {
          this.setActiveUser(user);
        })
        .catch(() => { this.error.isShow = true; });
    }

    document.addEventListener('keydown', this.onKeyDownHandler);
  },
  computed: {
    isSettingsActive() {
      const tabsList = ['profile', 'likes-and-tags', 'projects'];
      if (tabsList.includes(this.$route.name)) {
        return true;
      }
      return false;
    },
    isShowLayoutPreloader() {
      return !this.user?.id;
    },
    fullscreenPreloaderErrorText() {
      const { isShow, errorText } = this.error;
      return isShow && errorText ? errorText : '';
    },
    ...mapState({
      cognitoUser: (state) => state.auth.activeUser,
      user: (state) => state.activeUser,
      isFullScreen: (state) => state.isFullscreenMode,
      isMainLayoutPadding: (state) => state.isMainLayoutPadding,
    }),
  },
  methods: {
    navigateToPreferences() {
      this.$router.push(
        `${this.MY_CSITE_URL.path}/projects`,
      ).catch(() => {});
    },
    onKeyDownHandler(e) {
      // keyCode 27 is Escape key
      if (this.isFullScreen && e.keyCode === 27) {
        this.toggleFullscreen(false);
      }
    },
    ...mapActions({
      getCurrentUser: 'retrieveUsers',
      setActiveUser: 'setActiveUser',
      toggleFullscreen: 'toggleFullscreen',
    }),
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDownHandler);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/v3/cSiteIcons.css';
  @import '~@/sass/mixins/v3/buttons.scss';

  .layout_v3 {
    overflow: scroll;
    overflow-x: hidden;

    font-family: $body-font-family;
    font-size: $default-font-size;
    font-weight: $default-font-weight;
    line-height: $default-line-height;
    color: $default-blue-ultra-dark-color;
    background-color: $default-background-color;
    padding: $page-global-padding-vertical $page-global-padding-horizontal 0;
    width: 100%;
    min-height: 100%;

    &.fixed {
      position: fixed;
      overflow: hidden;
    }

    &.no-padding {
      padding: 0;
      margin: 0;

      .content {
        padding: 0;
        margin: 0;
      }
    }

    @media (orientation: portrait) {
      font-size: $default-portrait-font-size;
      padding: $page-global-portrait-padding-vertical $page-global-portrait-padding-horizontal 0;
    }

    .main_menu {
      --margin-bottom: 11vh;

      @media only screen and (max-width: 500px) {
        --margin-bottom: 4vh;
        font-size: 2em;
        grid-template-columns: 12vw 1fr 12vw;
      }
      @media only screen and (width > 500px) and (max-width: 900px) {
        --margin-bottom: 6vh;
        font-size: 1.5em;
        grid-template-columns: 7vw 1fr 12vw;
      }
      @media only screen and (width < 500px) and (height < 500px) {
        font-size: 2em;
        grid-template-columns: 2fr;
        --margin-bottom: 6vh;
      }

      display: grid;
      grid-template-columns: 9vw 1fr 9vw;
      margin-bottom: var(--margin-bottom);

      &.main_menu_hidden {
        display: none;
      }

      .main_menu__left-column {

        @media only screen and (width < 500px) and (height < 500px) {
          margin-top: 3vh;
        }

        picture {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 7.85vw;

          @media only screen and (max-width: 900px) {
            max-width: 16vw;
          }
          @media only screen and (width < 500px) and (height < 500px) {
            max-width: 20vw;
            margin-bottom: 3vh;
            margin-left: auto;
            margin-right: auto;
          }

          > img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
          }

          @media only screen and (max-width: 900px) {
            width: 16vw;
            margin-top: 1vh;
          }
          @media only screen and (width < 500px) and (height < 500px) {
            width: 25vw;
          }

        }
      }
      .main_menu__menu {
        width: 100%;
        height: 100%;
        @media only screen and (width < 500px) and (height < 500px) {
          margin-top: 1vh;
          height: 70%;
        }
      }

      .main_menu__right-column {
        position: relative;
        @include blue_button_full_size;
        text-decoration: none;

        @media screen and (max-width: 900px) {
          width: 16vw;
          text-align: center;
        }
        @media only screen and (width < 500px) and (height < 500px) {
          width: 30vw;
          margin-left: auto;
          margin-right: auto;
          padding: 2.5vh;
        }

        &.router-link-active:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 1px;
          width: 100%;
          height: calc(var(--margin-bottom) + 2px);
          transform: translateY(100%);
          background-color: $default-blue-color;
        }
      }
    }

    .content {
      width: 100%;
      margin-bottom: 15vh;

      i[class^="mi-"]:before, i[class*=" mi-"]:before {
        font-family: $body-font-family;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
</style>
