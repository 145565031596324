import { mapState } from 'vuex';
import { MENU } from '@/constants/menu';

export const sidebarMixin = {
  computed: {
    ...mapState({
      primaryActiveButton: (state) => state.activeMenu,
    }),
    isShowSubmenu() {
      return Object.values(this.buttons).includes(this.primaryActiveButton)
        && MENU[this.primaryActiveButton].has_submenu;
    },
  },
  methods: {
    isShowSubmenuForPrimaryMenu(primaryMenuKey) {
      return this.isShowSubmenu && this.primaryActiveButton === primaryMenuKey;
    },
  },
};

export default sidebarMixin;
