<template>
  <div class="image-tags" :class="{'fake-close': unmount.isScheduled}" @click="onClickHandler">
    <v-container fluid>
      <v-combobox
        v-model="selectedTags"
        :filter="filter"
        :hide-no-data="!search"
        :items="error.isShow ? [] : tags"
        item-value="name"
        return-object
        :search-input.sync="search"
        hide-selected
        :label="$t('tags_likes.search_tag')"
        multiple
        small-chips
        solo
        :disabled="error.isShow"
      >
        <template v-slot:no-data v-if="isAllowToAddNewTags">
          <v-list-item>
            <span class="subheading">{{ $t('tags_likes.create')}}</span>
            <v-chip
              :color="`${colors.new} lighten-3`"
              label
              small
            >
              {{ search }}
            </v-chip>
          </v-list-item>
        </template>
        <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip
            v-if="item === Object(item)"
            v-bind="attrs"
            :color="`${item.color} lighten-3`"
            :input-value="selected"
            label
            small
          >
          <span class="pr-2">
            {{ item.name }}
          </span>
            <v-icon
              small
              @click="parent.selectItem(item)"
              v-if="isAllowToUntagImage"
            >
              $delete
            </v-icon>
          </v-chip>
        </template>
        <template v-slot:item="{ index, item }">
          <v-chip
            :color="`${item.color} lighten-3`"
            dark
            label
            small
          >
            {{ item.name }}
          </v-chip>
        </template>
      </v-combobox>
    </v-container>
  </div>
</template>

<script>
import {
  filter, map, includes, find, compact,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { tagsMixin, permissionMixin, SHOW_ERROR_TIME } from '@/mixins/v3';
import { getErrorFromResponse } from '@/helpers';
import PERMISSIONS from '@/constants/v3';

export const IMAGE_TAGS_UPDATE_TIMEOUT = 500;

export default {
  name: 'ImageTags',
  mixins: [tagsMixin, permissionMixin],
  emits: ['change', 'error', 'clickOutside'],
  props: {
    imageId: {
      type: Number,
      required: true,
    },
    imageTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      colors: {
        new: 'green',
        exists: 'blue',
        selected: 'orange',
      },
      tags: [
        { header: this.$t('tags_likes.select_tag') },
      ],
      selectedTags: [],
      search: null,
      updateImageTagsTimeoutId: null,
      creatingTagQueue: [],
      updateImageTagsRequesting: false,
      unmount: {
        isReadyForUnmount: true,
        isScheduled: false,
      },
      error: {
        isShow: false,
        timeoutId: null,
      },
    };
  },
  mounted() {
    this.tags = [
      ...this.tags,
      ...map(this.validTags, (tag) => ({ ...tag, color: this.colors.exists })),
    ];
    this.selectedTags = [
      ...this.selectedTags,
      ...map(this.userImageTags, (tag) => ({ ...tag, color: this.colors.selected })),
    ];
  },
  watch: {
    validTags: {
      handler(newValidTags) {
        const existsTagsName = map(this.tags, (existsTag) => existsTag.name);
        const tagsNotExists = map(filter(
          newValidTags,
          ({ name: tagName }) => !includes(existsTagsName, tagName),
        ), (tagNotExists) => ({ ...tagNotExists, color: this.colors.exists }));
        this.tags = [
          ...this.tags,
          ...tagsNotExists,
        ];
      },
    },
    selectedTags(tags, prevTags) {
      if (tags.length === prevTags.length) return;
      this.selectedTags = compact(map(tags, (tag) => {
        let activeTag = tag;
        if (typeof activeTag === 'string') {
          if (!this.isAllowToAddNewTags) {
            this.showError('You are not allowed to creat new tags');
            return null;
          }
          const existsTag = find(
            this.validTags,
            (validTag) => validTag.name === activeTag,
          );
          activeTag = existsTag || { name: activeTag, color: this.colors.exists };
          if (!activeTag.id) {
            this.createNewTag(activeTag.name);
            this.tags.push(activeTag);
          }
        }

        return {
          ...activeTag,
          color: this.colors.selected,
        };
      }));
      this.scheduleUpdateImageTags();
    },
    creatingTagQueue: {
      // link tags when creating tags queue is empty and haven't added anything new
      handler(newCreatingTagQueue) {
        if (newCreatingTagQueue.length) {
          return;
        }
        this.scheduleUpdateImageTags();
      },
      deep: true,
    },
  },
  computed: {
    isAllowToAddNewTags() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_tag);
    },
    isAllowToTagImage() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_taggedimage);
    },
    isAllowToUntagImage() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.delete_taggedimage);
    },
    ...mapState({
      tagsList: (state) => state.tags,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    filter(item, queryText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(item.name).toString().toLowerCase();
      const query = hasValue(queryText).toString().toLowerCase();

      return text.indexOf(query) > -1;
    },
    showError(text) {
      this.$emit('error', { text });
      clearTimeout(this.error.timeoutId);
      this.error = {
        ...this.error,
        isShow: true,
        timeoutId: setTimeout(() => { this.error.isShow = false; }, SHOW_ERROR_TIME),
      };
    },
    createNewTag(tagName) {
      if (!this.isAllowToAddNewTags) {
        this.showError('You are not allowed to creat new tags');
        return;
      }
      this.creatingTagQueue.push(tagName);
      const payload = {
        name: tagName,
        user: this.user.id,
        system_tag: false,
        project: null,
      };
      this.createTag(payload).then((newTag) => {
        this.selectedTags = this.selectedTags.map(
          (selectedTag) => (newTag.name === selectedTag.name
            ? { ...newTag, color: this.colors.selected } : selectedTag),
        );
        this.tags = this.tags.map(
          (tag) => (newTag.name === tag.name
            ? { ...newTag, color: this.colors.exists } : tag),
        );
      }).catch((e) => {
        this.showError(getErrorFromResponse(e));
      }).finally(() => {
        this.creatingTagQueue = filter(this.creatingTagQueue, (tag) => tag !== tagName);
      });
    },
    scheduleUpdateImageTags() {
      this.unmount.isReadyForUnmount = false;
      clearTimeout(this.updateImageTagsTimeoutId);
      this.updateImageTagsTimeoutId = setTimeout(
        () => {
          // if creating is still in progress for some tags - wait for it
          if (this.creatingTagQueue.length) {
            return;
          }
          if (this.updateImageTagsRequesting) {
            this.scheduleUpdateImageTags();
            return;
          }
          this.updateImageTagsTimeoutId = null;
          this.updateImageTags();
        },
        IMAGE_TAGS_UPDATE_TIMEOUT,
      );
    },
    async getActualImagesTags() {
      const linkedTagsToImage = await this.fetchImageTags({ image: this.imageId }).catch((e) => {
        this.showError(getErrorFromResponse(e));
      });
      if (!linkedTagsToImage || !('normData' in linkedTagsToImage)) {
        return [];
      }
      return filter(
        linkedTagsToImage.normData,
        (linkedTagToImage) => !includes(this.protectedImageTagsIds, linkedTagToImage.tag),
      );
    },
    async updateImageTags() {
      this.updateImageTagsRequesting = true;
      const userSelectedTagsState = this.selectedTags;
      const linkedTagsToImage = await this.getActualImagesTags();

      Promise.all([
        this.linkImageToTags(linkedTagsToImage),
        this.unlinkImageFromTags(linkedTagsToImage, userSelectedTagsState),
      ]).then(() => {
        this.$emit('change', { tags: this.selectedTags });
      }).catch((e) => {
        this.showError(getErrorFromResponse(e));
      }).finally(() => {
        this.updateImageTagsRequesting = false;
        if (!this.updateImageTagsTimeoutId) {
          this.unmount.isReadyForUnmount = true;
          if (this.unmount.isScheduled) {
            this.$emit('clickOutside', { tags: this.selectedTags });
          }
        }
      });
    },
    async linkImageToTags(linkedTagsToImage) {
      const userImageTagsIds = map(
        linkedTagsToImage,
        (linkedTagToImage) => linkedTagToImage.tag,
      );
      const tagsLinkToImage = map(filter(
        this.selectedTags,
        (tag) => tag.id && !includes(userImageTagsIds, tag.id),
      ), (tag) => ({ tag: tag.id, image: this.imageId }));
      if (!tagsLinkToImage.length) {
        return false;
      }
      if (!this.isAllowToTagImage) {
        this.showError('You are not allowed to tag this image');
        return false;
      }
      return this.linkTagsToImage(tagsLinkToImage);
    },
    async unlinkImageFromTags(linkedTagsToImage, userSelectedTagsState) {
      const userSelectedTagsIds = map(
        filter(userSelectedTagsState, (selectedTag) => selectedTag.id),
        (selectedTag) => selectedTag.id,
      );
      const tagsIdsUnlinkFromImage = map(filter(
        linkedTagsToImage,
        (linkedTagToImage) => !includes(userSelectedTagsIds, linkedTagToImage.tag),
      ), (linkedTagToImage) => linkedTagToImage.id);
      if (!tagsIdsUnlinkFromImage.length) {
        return false;
      }
      if (!this.isAllowToUntagImage) {
        this.showError('You are not allowed to remove tags from this image');
        return false;
      }
      return Promise.all(map(
        tagsIdsUnlinkFromImage,
        (tagIdUnlinkFromImage) => this.unlinkTagsFromImage(tagIdUnlinkFromImage),
      ));
    },
    unscheduleUnmount() {
      this.unmount.isScheduled = false;
    },
    onClickHandler(e) {
      if (!e.target.classList.contains('image-tags')) return;
      if (!this.unmount.isReadyForUnmount) {
        this.unmount.isScheduled = true;
        return;
      }
      this.$emit('clickOutside', { tags: this.selectedTags });
    },
    ...mapActions({
      createTag: 'createTags',
      fetchImageTags: 'listTaggedimages',
      linkTagsToImage: 'createTaggedimages',
      unlinkTagsFromImage: 'destroyTaggedimages',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.image-tags {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);

  &.fake-close {
    display: none;
  }
}
.subheading {
  margin-right: .5vw;
}
</style>
