<template>
  <div class="dashboard_cameras_list" :class="`dashboard_cameras_list__${camerasLayout}`"
       ref="dashboard_cameras_list" :style="cssProps">
    <div v-for="n in camerasCount" :key="`camera_${n}`">
      <Slideshow :selected-cameras="getUserSlideshowSettings(n)"
                 @project_views_change="onSlideshowProjectViewsChangeHandler(n, $event)"/>
    </div>
  </div>
</template>

<script>
import {
  THREE_SCREENS,
  FOUR_SCREENS,
  EIGHT_SCREENS,
  NINE_SCREENS,
  SIXTEEN_SCREENS,
} from '@/constants/v3/dashboard';
import { mapActions, mapState } from 'vuex';
import Slideshow from '@/components/v3/Cameras/Slideshow';
import { updateUserFrontendSettingsMixin } from '@/mixins';
import { SLIDESHOW, SLIDESHOW_SELECTED_CAMERAS } from '@/constants/v3/users';

const CONTAINER_RATIO_MULTIPLIER = 1.78;

export default {
  name: 'Dashboard',
  mixins: [updateUserFrontendSettingsMixin],
  components: {
    Slideshow,
  },
  data() {
    return {
      componentHeight: 0,
    };
  },
  mounted() {
    this.updateComponentHeight();
  },
  watch: {
    isFullScreen: {
      handler(value) {
        this.toggleMainLayoutPadding(!value);
      },
      immediate: true,
    },
  },
  computed: {
    cssProps() {
      return {
        '--component-height': this.isFullScreen ? '100vh' : `${this.componentHeight}px`,
      };
    },
    camerasCount() {
      switch (this.camerasLayout) {
        case THREE_SCREENS:
          return 3;
        case FOUR_SCREENS:
          return 4;
        case EIGHT_SCREENS:
          return 8;
        case NINE_SCREENS:
          return 9;
        case SIXTEEN_SCREENS:
          return 16;
        default:
          return 1;
      }
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
      camerasLayout: (state) => state.dashboard.camerasLayout,
    }),
  },
  methods: {
    updateComponentHeight() {
      const cameraListInstance = this.$refs.dashboard_cameras_list;
      // const rect = cameraListInstance.getBoundingClientRect();
      // const newComponentHeight = window.innerHeight - rect.top;
      const maxComponentHeight = cameraListInstance.clientWidth / CONTAINER_RATIO_MULTIPLIER;
      // this.componentHeight = newComponentHeight <= maxComponentHeight
      //   ? newComponentHeight : Math.ceil(maxComponentHeight);
      this.componentHeight = maxComponentHeight;
    },
    getUserSlideshowSettings(index) {
      const userSettings = this.$_updateUserFrontendSettingsMixin_FrontendSettings;
      const { [SLIDESHOW]: userSettingsSlideshow = {} } = userSettings;
      const { [SLIDESHOW_SELECTED_CAMERAS]: slideshowSelectedCameras = {} } = userSettingsSlideshow;

      return slideshowSelectedCameras[index] || [];
    },
    onSlideshowProjectViewsChangeHandler(index, { value: camerasIds = null }) {
      const userSettings = this.$_updateUserFrontendSettingsMixin_FrontendSettings;
      const { [SLIDESHOW]: userSettingsSlideshow = {} } = userSettings;
      const { [SLIDESHOW_SELECTED_CAMERAS]: slideshowSelectedCameras = {} } = userSettingsSlideshow;
      if (camerasIds && camerasIds.includes('(blank)')) {
        slideshowSelectedCameras[index] = ['(blank)'];
      } else {
        slideshowSelectedCameras[index] = camerasIds;
      }
      this.$_updateUserFrontendSettingsMixin_updateUserFrontendSettings(
        SLIDESHOW,
        { [SLIDESHOW_SELECTED_CAMERAS]: slideshowSelectedCameras },
        5000,
      );
    },
    ...mapActions({
      toggleMainLayoutPadding: 'toggleMainLayoutPadding',
    }),
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/buttons.scss';

  ::v-deep .layout_v3 {
    padding: 0;
  }

  .dashboard_cameras_list {
    position: relative;
    display: grid;
    grid-gap: 3vh .5vw;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(23vh, auto);
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 100%;
    height: auto;

    div {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    @media only screen and (min-width: 500px) {
      grid-gap: .5vw;
      grid-auto-rows: initial;
      height: var(--component-height);

      &.dashboard_cameras_list__three_screens {
        grid-template-columns: repeat(3, 1fr);
      }

      &.dashboard_cameras_list__four_screens {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      &.dashboard_cameras_list__eight_screens {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);

        div:first-child {
          grid-row-start: 1;
          grid-row-end: 4;
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }

      &.dashboard_cameras_list__nine_screens {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
      }

      &.dashboard_cameras_list__sixteen_screens {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(4, 1fr);
      }
    }
  }
</style>
