<template>
  <div class="projects_files_page">
    <CameraPreloader v-if="isLoading"
                     text="files.loading"/>
    <div class="projects_files__no_projects" v-if="!filteredList.length && !isLoading">
      <div class="projects_files__no_projects__text">
        {{ $t('files.no_files') }}
      </div>
    </div>
    <div v-else class="projects_files__project"
         v-for="(project, index) in filteredList" :key="index">
        <div class="projects_files__project__name">
          {{ project.name }}
        </div>
        <div class="row_wrapper">
          <div class="row projects_files__project__row">
              <div class="col-md-2">
                {{ $t('files.camera_view') }}
              </div>
              <div class="col-md-10 projects_files__project__col">
                {{ $t('files.title') }}
              </div>
          </div>
          <div class="row projects_files__project__cameraview"
               v-for="view in project.cameraviews" :key="view.id">
            <div class="col-md-2 projects_files__project__cameraview__name" v-if="view.files">
              {{ view.name }}
            </div>
            <div class="col-md-10 projects_files__project__cameraview__files" v-if="view.files">
                <div class="row spacing">
                  <div class="projects_files__project__cameraview__files_list">
                    <div class="projects_files__project__cameraview__files_list_div"
                      v-for="(file, index) in view.files" :key="file.id">
                      <a class="projects_files__project__cameraview__files_link"
                         :href="file.file">{{ $t('files.part') }} {{ index + 1}}</a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import { mapActions, mapState } from 'vuex';
import {
  filter, map,
} from 'lodash';

export default {
  name: 'Files',
  components: {
    CameraPreloader,
  },
  mounted() {
    this.loadingProjectsList();
  },
  data: () => ({
    projectsList: [],
    isLoading: false,
  }),
  computed: {
    filteredList() {
      return filter(this.projectsList, (project) => {
        const viewsWithFiles = filter(project.cameraviews, (view) => 'files' in view);
        return viewsWithFiles.length > 0;
      });
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    async loadingProjectsList() {
      this.isLoading = true;
      const membershipResponse = await this.fetchProjectMemberships({ member: this.user.id });
      if (!membershipResponse.dataList.length) {
        this.isLoading = false;
        return;
      }

      const projectIds = map(membershipResponse.normData, (membership) => membership.project);
      const projectPromises = projectIds.map(async (projectId) => {
        const projectResponse = await this.fetchProjects({ id__in: projectId });
        const [project] = Object.values(projectResponse.normData);
        const cameraviewResponse = await this.fetchCameraViews({ project: project.id });
        project.cameraviews = Object.values(cameraviewResponse.normData);
        const cameraViewPromises = map(project.cameraviews, async (view) => {
          const zipRequestsResponse = await this.fetchZipRequests(
            { cameraview: view.id, status: 'finished' },
          );
          if (Object.keys(zipRequestsResponse.normData).length === 0) {
            return;
          }
          const [request] = Object.values(zipRequestsResponse.normData);

          const zipArchivesResponse = await this.fetchZipArchives({ zip_request: request.id });
          const [cameraViewWithRequest] = filter(project.cameraviews,
            (cameraview) => cameraview.id === view.id);
          cameraViewWithRequest.files = Object.values(zipArchivesResponse.normData).map(
            (obj) => obj,
          );
        });

        await Promise.all(cameraViewPromises);
        return project;
      });

      this.projectsList = await Promise.allSettled(projectPromises)
        .then((results) => results.filter((result) => result.status === 'fulfilled').map(
          (result) => result.value,
        )).finally(() => {
          this.isLoading = false;
        });
    },
    ...mapActions({
      fetchZipRequests: 'listZiprequests',
      fetchZipArchives: 'listZiparchives',
      fetchCameraViews: 'listCameraviews',
      fetchProjectMemberships: 'listProjectmemberships',
      fetchProjects: 'listProjects',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.projects_files_page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;
  .projects_files__no_projects {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .projects_files__no_projects__text {
      margin-top: 5vh;
      font-size: 1.4em;
      font-weight: 500;
      margin-bottom: 3vh;
    }
  }
  .projects_files__project {
    margin-bottom: 3vh;
    .projects_files__project__name {
      margin-bottom: 3vh;
      font-size: 1.2em;
      font-weight: 600;
    }
    .row_wrapper {
      margin-left: 2vw;
      .projects_files__project__row {
        border-bottom: 1px solid $default-blue-dark-color;
        .projects_files__project__col {
          padding-left: 0;
        }
      }
    }
    .projects_files__project__cameraview {
      .projects_files__project__cameraview__name {
        margin-top: 2vh;
        padding-top: 0;
      }
      .projects_files__project__cameraview__files {
        margin-top: 2vh;
        padding: 0;
        .spacing {
          margin: 0;
          display: flex;
          justify-content: space-between
        }
        .projects_files__project__cameraview__files_list {
          margin-bottom: 2vh;
          display: flex;
          flex-wrap: wrap;
          .projects_files__project__cameraview__files_list_div {
            display: flex;
            align-items: start;
            justify-content: start;
            min-width: 4.5vw;
          }

          .projects_files__project__cameraview__files_link {
            color: inherit;
            margin-bottom: 1vh;
          }
        }
        .projects_files__project__cameraview__files_all {
          cursor: pointer;
        }
      }
    }
  }
}

</style>
