<template>
  <div>
    <v-alert outlined :type="message.type" :value="message.isShow">
      {{ message.text }}
    </v-alert>
    <div class="support_page">
      <form
        class="support_form"
        ref="form"
        @submit.prevent="submitForm">
        <div class="settings_title">
          {{ $t('my_csite_menu.settings_menu.support.titles.send_message')}}
        </div>
        <div class="support_form__inputs">
          <div class="support_form__inputs__subject">
            <input
              id="subject"
              type="text"
              :placeholder="$t('my_csite_menu.settings_menu.support.' +
                 'form.subject')"
              v-model="form.formSubject.value">
          </div>
          <div class="support_form__inputs__message">
          <textarea
            rows="10"
            class="support_form__inputs__message__textarea"
            :placeholder="$t('my_csite_menu.settings_menu.support.' +
                 'form.message')"
            v-model="form.formMessage.value"></textarea>
          </div>
        </div>
        <ButtonSubmit
          :title="$t('buttons.send')"
          @click="submitForm"/>
      </form>
      <div class="company_information">
        <div class="company_information__box">
          <div class="company_information__box__name">Asoreco NV</div>
          <div class="company_information__box__address">
            Sluis 2B/0001<br>
            9810 Eke, Belgium<br>
          </div>
          <a href="tel:+32092791260" class="company_information__box__telephone">
            +32 (0)9 279 12 60
          </a>
          <p>{{ $t('my_csite_menu.settings_menu.support.text')}}</p>
          <a href="mailto:support@c-site.eu">support@c-site.eu</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Support.vue',
  components: {
    ButtonSubmit,
  },
  data() {
    return {
      form: {
        formSubject: {
          value: '',
          error: null,
        },
        formMessage: {
          value: '',
          error: null,
        },
      },
      message: {
        isShow: false,
        type: 'error',
        text: null,
      },
      loading: false,
      ticketType: 'SUPPORT',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      const formData = {
        summary: this.form.formSubject.value,
        description: this.form.formMessage.value,
        type: this.ticketType,
      };
      this.loading = true;
      this.sendSubmitTicket({
        id: this.user.id,
        payload: formData,
      })
        .then(() => {
          this.message = {
            isShow: true,
            type: 'success',
            text: this.$t('support.submit_ticket.send_successfully_message'),
          };
          setTimeout(() => {
            this.message.isShow = false;
          }, 3000);
        })
        .catch((error) => {
          this.message = {
            isShow: true,
            type: 'error',
            text: error.message,
          };
          setTimeout(() => {
            this.message.isShow = false;
          }, 3000);
        })
        .finally(() => {
          this.loading = false;
          this.form.formSubject.value = '';
          this.form.formMessage.value = '';
        });
    },
    ...mapActions({
      sendSubmitTicket: 'sendSubmitTicket',
    }),
  },
};
</script>
<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/settings.scss';

.support_page{
  display: flex;
  flex-direction: row;
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }

  .support_form {
    flex: 1;
    display: inline-block;
    padding-right: 3vh;
    .support_form__inputs__subject {
      position: relative;
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      border-bottom: 1px solid $default-disabled-color;

      input {
        width: 100%;
        padding: 0;
        line-height: 1;
        color: $default-blue-ultra-dark-color;

        &:focus, &:active {
          outline: none;
        }
      }
    }

    .support_form__inputs__message {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $default-disabled-color;

      textarea {
        color: $default-blue-ultra-dark-color;
        resize: none;

        &:focus {
          outline: none !important;
        }
      }
    }
  }

  .company_information {
    flex: 1;
    display: inline-block;
    padding-left: 5vh;
    padding-bottom: 3vw;
    padding-right: 5vh;
  }
  .company_information__box {
    background-color: $default-light-color;
    padding-left: 4vh;
    padding-bottom: 4vh;

    .company_information__box__name {
      position: relative;
      font-size: 2em;
      padding-bottom: 2vh;
      padding-top: 3.6vh;
    }

    .company_information__box__address {
      margin-bottom: 3vh;
    }

    .company_information__box__telephone {
      text-decoration: none;
    }
  }
}
::placeholder {
  color: $default-blue-ultra-dark-color;
  opacity: 1;
}

@media (orientation: portrait ) {
  .support_page {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .support_page {
    font-size: 1.6em;
    padding-left: 10vw;
    padding-right: 10vw;
  }
}

@media (orientation: landscape) and (max-height: 400px) {
  .support_page {
    flex-direction: column;
    font-size: 1.8em;
  }
  .support_form__inputs {
    width: 80%;
  }
  .company_information {
    margin-top: 3vh;
    width: 80%;
  }
}

</style>
