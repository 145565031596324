<template>
  <div class="fullscreen" @click="$_fullscreenMixin_fullScreenClickHandler">
    <div class="text">{{ $t(text) }}</div>
    <i class="mdi mdi-open-in-new" />
  </div>
</template>

<script>
import { fullscreenMixin } from '@/mixins/v3/fullscreen/fullscreenMixin';

export default {
  name: 'Fullscreen',
  mixins: [fullscreenMixin],
  props: {
    text: {
      type: String,
      default: 'cameras_menu.buttons.fullscreen',
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.fullscreen {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  width: 100%;
  cursor: pointer;

  .text {
    margin-right: 3px;
  }
}
</style>
