<template>
  <div class="camera-time-travel" :class="{'full-screen': isFullScreen}" ref="componentContainer">
    <div class="camera-time-travel__container">
      <div class="camera-time-travel__container__info">
        <div class="camera-time-travel__container__info__caption">
          {{ $t('cameras_menu.camera_time_travel.help_text')}}
        </div>
        <Fullscreen class="camera-time-travel__container__info__fullscreen"/>
      </div>
      <div class="camera-time-travel__gallery">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, index) in imagesWithLikesList"
                 :key="`${forceRerenderIdx}_${image.id}`">
              <CameraPictureWithRecognitions :image-details="image"
                                             :is-show-boxes="isShowBoxes"
                                             :is-show-labels="isShowLabels"
                                             v-slot="slotProps"
                                             :level-of-confidence="isConfidenceLevel"
                                             :sendLabelState="sendCheckedLabels"
                                             :sendModelState="sendCheckedModels"
                                             :areaId="areaId"
                                             @initialLabels="initialLabels"
                                             @initialModels="initialModels"
                                 @noSignificantObjectsDetected="noSignificantObjectsDetected"
              v-if="index === swiper.instance.activeIndex">
                <camera-picture
                  :image-details="slotProps.image"
                  :max-zoom="zoom.maxValue"
                  :zoom-step="zoom.step / 100"
                  :reset-zoom-on-init="false"
                  :like="isShowLikes"
                  :tags="isShowTags"
                  :recognize="slotProps.recognize"
                  :class="{'full-screen': isFullScreen}"
                  @wheelZoomInit="$_zoomMixin_addImage"
                  @wheelZoomPositionChanged="$_zoomMixin_setImagesPosition"
                  @wheelZoomChanged="onPicturesZoomChangeHandler"
                  @imageRecognitionRequested="slotProps.imageRecognitionRequested"
                />
              </CameraPictureWithRecognitions>
              <CameraPreloader class="images-preloader" v-if="isShowLoading"/>
              <i
                @click="onLeftArrowClickHandler"
                class="mdi mi-circle-arrow-left swiper-slide__arrow-icon left"
                :class="{disabled: !isLeftArrowActive}"
                title="Forward" v-if="!isHideLeftArrow"/>
              <i
                @click="onRightArrowClickHandler"
                class="mdi mi-circle-arrow-right swiper-slide__arrow-icon right"
                :class="{disabled: !isRightArrowActive}"
                title="Back" v-if="!isHideRightArrow"/>
            </div>
          </div>
        </div>
        <div class="step_selection__wrapper" >
          <div class="step_selection__wrapper_label">
            {{ $t('cameras_menu.camera_time_travel.take_steps')}}
          </div>
          <div class="step_selection__wrapper_options">
            <v-select @change="changedSelection"
                      :items="stepItems"
                      item-text="text" item-value="value" append-icon=""
                      single-line v-model="scrollStepSelected"
            />
          </div>
        </div>
        <i class="camera-time-travel__close mdi mdi-close-circle-outline"
           @click="onCloseClickHandler"/>
        <div class="camera-time-travel__panel" >
          <div class="camera-time-travel__panel__text" v-if="isAllowToViewAI && !isLoading">
            {{ $t('ai.titles.boxes_labels')}}
          </div>
          <CameraTimeTravelAIPanel @showBoxesChanged="(e) => isShowBoxes = e.value"
                                 @showLabelsChanged="(e) => isShowLabels = e.value"
                                 v-if="isAllowToViewAI && !isLoading"
                                 class="camera-time-travel__ai_panel"/>
          <div class="camera-time-travel__panel_results" v-if="isFullyLoaded && isAllowToViewAI">
            <div v-if="models.length">
              <div class="camera-time-travel__panel_results_models" >
                {{ $t('ai.titles.models')}}:
              </div>
              <div class="checkboxes" v-for="item in models" :key="item.model">
                <div class="checkboxes__checkbox">
                  <input
                    type="checkbox"
                    :id="`checkbox-${item.model}`"
                    v-model="item.value" @change="changeSelectedBoxes">
                  <label :for="`checkbox-${item.model}`" />
                  <div class="checkboxes__checkbox__title">{{ item.model }}</div>
                </div>
              </div>
            </div>
            <div v-if="labels.length">
              <div class="camera-time-travel__panel_results_labels" >
                {{ $t('ai.titles.labels')}}:
              </div>
              <div class="checkboxes" v-for="(item, index) in labels" :key="index" >
                <div class="checkboxes__checkbox">
                  <input
                    type="checkbox"
                    :id="`checkbox-${index}`"
                    v-model="item.value"
                    @change="changeSelectedBoxes">
                  <label :for="`checkbox-${index}`" />
                  <div class="checkboxes__checkbox__title">{{ item.label }}</div>
                </div>
              </div>
              <div class="camera-time-travel__panel__person_select" v-if="showPersonSelect"
                   @click="personSelect">
                {{ $t('ai.button.only_persons')}}
              </div>
              <div class="camera-time-travel__panel__slider" >
                <v-slider
                  vertical :color="color" :track-color="trackColor" thumb-label="always"
                  :thumb-size="24" v-model="confidenceLevel" :step="0.01" :min="0" :max="1"
                  @mouseup="setConfidenceLevel">
                </v-slider>
                <v-subheader style="color: #043067; margin-left: 1vw; font-size: 1.1em;">
                  {{ $t('ai.confidence_level')}}
                </v-subheader>
              </div>
            </div>
          </div>
          <div v-if="!isFullyLoaded && noObjects"
               class="camera-time-travel__panel__no_objects">
            {{ $t('ai.no_objects')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CameraPictureWithRecognitions
  from '@/components/v3/Cameras/CameraPictureWithRecognitions';
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import Fullscreen from '@/components/v3/Helpers/Menu/Fullscreen';
import CameraTimeTravelAIPanel from '@/components/v3/Helpers/CameraTimeTravelAIPanel';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';

import { zoomMixin } from '@/mixins';
import { swiperMixin } from '@/mixins/v3';
import permissionMixin from '@/mixins/v3/permissionMixin';

import { wheelChangeValue } from '@/utils/wheelzoom';
import { ERROR } from '@/store/loading-types';
import isTouchDevice from '@/helpers';
import {
  filter, find, isEmpty, map, sortBy,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import PERMISSIONS from '@/constants/v3';

const ALLOW_LOAD_NEW_IMAGES_BEFORE_EDGE_NEXT = 5;
const ALLOW_LOAD_NEW_IMAGES_BEFORE_EDGE_PREV = 2;

const CLOSE_EVENT = 'close';

export default {
  name: 'CameraDetails',
  props: {
    images: {
      type: Array,
      required: true,
    },
    initialSlideIndex: {
      type: Number,
      default: 0,
    },
    initialImage: {
      type: Object,
    },
    getNextImages: {
      type: Function,
      required: true,
    },
    getPrevImages: {
      type: Function,
      required: true,
    },
    isAscending: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CameraPictureWithRecognitions,
    CameraTimeTravelAIPanel,
    CameraPicture,
    Fullscreen,
    CameraPreloader,
  },
  mixins: [swiperMixin, zoomMixin, permissionMixin],
  emits: [CLOSE_EVENT],
  beforeMount() {
    const swiperOptions = {
      ...this.swiper.swiperOptions,
      allowTouchMove: this.isTouchDevice,
      initialSlide: this.initialSlideIndex,
      observer: true,
      effect: 'fade',
      lazy: {
        threshold: 50,
        observer: true,
        loadPrevNext: true,
        loadPrevNextAmount: 2,
      },
    };
    const swiperEvents = {
      on: {
        slideChange: () => {
          this.reset();
        },
        slideNextTransitionStart: () => {
          if (!this.totalImagesLoaded || !this.isAllowLoadNext) {
            return;
          }
          this.local.images.next.isLoading = true;
          const lastImage = this.local.images.list.at(-1);
          if (this.scrollStepSelected !== 'default') {
            this.loadNextScrollImages(lastImage);
            return;
          }
          this.getNextImages(lastImage)
            .then((imageList) => {
              if (!imageList.length) {
                this.local.images.next.isLast = true;
                return;
              }
              this.local.images.list.push(...imageList);
              if (this.local.images.next.awaitGoNext) {
                this.local.images.next.awaitGoNext = false;
                setTimeout(() => {
                  this.swiperSlideNext();
                }, 300);
              }
            })
            .finally(() => {
              this.local.images.next.isLoading = false;
            });
        },
        slidePrevTransitionStart: () => {
          if (!this.totalImagesLoaded || !this.isAllowLoadPrev) {
            return;
          }
          this.local.images.prev.isLoading = true;
          const [firstImage] = this.local.images.list;
          if (this.scrollStepSelected !== 'default') {
            this.loadPrevScrollImages(firstImage);
            return;
          }
          this.getPrevImages(firstImage)
            .then((imageList) => {
              if (!imageList.length) {
                this.local.images.prev.isLast = true;
                return;
              }
              const activeSlideIdx = this.swiper.instance.realIndex;
              let sliderMoveToIdx = imageList.length + activeSlideIdx;
              if (this.local.images.prev.awaitGoNext) {
                this.local.images.prev.awaitGoNext = false;
                sliderMoveToIdx -= 1;
              }
              this.local.images.list.unshift(...imageList);
              setTimeout(() => {
                this.swiper.instance.slideTo(sliderMoveToIdx, 0);
              }, 300);
            })
            .finally(() => {
              this.local.images.prev.isLoading = false;
            });
        },
      },
    };
    this.swiper = { swiperOptions, ...swiperEvents };
  },
  mounted() {
    this.local.images.list = [...this.images];
    this.getData();
    this.loadTags();
    this.loadLikes();

    const componentContainer = this.$refs?.componentContainer;
    if (componentContainer) {
      componentContainer.addEventListener('wheel', this.onWheelHandler);
    }
    const html = document.querySelector('html');
    html.classList.add('no-scroll');

    document.addEventListener('keydown', this.onKeyDownHandler);
  },
  data() {
    return {
      stepItems: [
        {
          text: this.$t('cameras_menu.camera_time_travel.options.default'),
          value: 'default',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.hour'),
          value: 'hour',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.day'),
          value: 'day',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.week'),
          value: 'week',
        },
        {
          text: this.$t('cameras_menu.camera_time_travel.options.month'),
          value: 'month',
        },
      ],
      isLoading: false,
      scrollStepSelected: 'default',
      isShowBoxes: false,
      isShowLabels: false,
      color: '#043067',
      trackColor: '#1654A299',
      confidenceLevel: 0,
      updateConfidenceLevel: 0,
      labels: [],
      models: [],
      sendCheckedLabels: [],
      sendCheckedModels: [],
      showPersonSelect: false,
      labelsLoaded: false,
      modelsLoaded: false,
      showSelect: false,
      areaId: 0,
      nextImage: null,
      previousImage: null,
      isTouchDevice: isTouchDevice(),
      isShowLikes: false,
      isShowTags: false,
      forceRerenderIdx: 0,
      local: {
        images: {
          next: {
            isLoading: false,
            isLast: false,
            awaitGoNext: false,
          },
          prev: {
            isLoading: false,
            isLast: false,
            awaitGoNext: false,
          },
          list: [],
        },
      },
      newCurrentImage: null,
      noObjects: false,
    };
  },
  watch: {
    isFullScreen() {
      this.forceRerenderIdx += 1;
    },
  },
  computed: {
    isFullyLoaded() {
      return (this.isShowLabels || this.isShowBoxes)
        && this.labelsLoaded && this.modelsLoaded && !this.isShowLoading;
    },
    isConfidenceLevel() {
      return this.confidenceLevel;
    },
    isShowLoading() {
      return this.isLoading;
    },
    swiper: {
      get() {
        return this.$data.$_swiperMixin;
      },
      set(newValue) {
        this.$_swiperMixin_swiper = {
          ...this.$data.$_swiperMixin,
          ...newValue,
        };
      },
    },
    zoom: {
      get() {
        return this.$data.$_zoomMixin;
      },
      set(newValue) {
        this.$data.$_zoomMixin = {
          ...this.$data.$_zoomMixin,
          ...newValue,
        };
      },
    },
    imagesWithLikesList() {
      if (!Object.keys(this.likes).length) {
        return this.removeDuplicates(this.local.images.list);
      }

      return map(this.removeDuplicates(this.local.images.list), (image) => ({
        ...image,
        like: !!find(
          this.likes,
          (like) => like.object_id === image.id,
        ),
      }));
    },
    totalImagesLoaded() {
      return this.local.images.list.length;
    },
    isSliderNextEdge() {
      const activeSlideIdx = this.swiper.instance.realIndex + 1;
      return activeSlideIdx === this.totalImagesLoaded;
    },
    isSliderPrevEdge() {
      return !this.swiper.instance.realIndex;
    },
    isAllowLoadNext() {
      const { next } = this.local.images;
      const activeSlideIdx = this.swiper.instance.realIndex;
      const actualDiff = this.totalImagesLoaded - activeSlideIdx;
      return !next.isLoading && !next.isLast
        && actualDiff <= ALLOW_LOAD_NEW_IMAGES_BEFORE_EDGE_NEXT;
    },
    isAllowLoadPrev() {
      const { prev } = this.local.images;
      const activeSlideIdx = this.swiper.instance.realIndex;
      return !prev.isLoading && !prev.isLast
        && activeSlideIdx <= ALLOW_LOAD_NEW_IMAGES_BEFORE_EDGE_PREV;
    },
    isRightArrowActive() {
      return !this.isSliderNextEdge;
    },
    isHideRightArrow() {
      return !this.isRightArrowActive && this.local.images.next.isLast;
    },
    isLeftArrowActive() {
      return !this.isSliderPrevEdge;
    },
    isHideLeftArrow() {
      return !this.isLeftArrowActive && this.local.images.prev.isLast;
    },
    isAllowToViewAI() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_image_area_identifier);
    },
    ...mapState({
      likes: (state) => state.likes,
      user: (state) => state.activeUser,
      isFullScreen: (state) => state.isFullscreenMode,
      loadingsState: (state) => state.loadings,
    }),
  },
  methods: {
    reset() {
      if (this.labelsLoaded) {
        this.labelsLoaded = false;
      }
      this.sendCheckedLabels = [];
      this.sendCheckedModels = [];
      this.noObjects = false;
      this.confidenceLevel = 0;
    },
    noSignificantObjectsDetected(value) {
      this.noObjects = value;
    },
    async getData() {
      if (!this.initialImage) {
        return;
      }
      this.isLoading = true;
      const payload = this.initialImage.id;
      await this.retrieveImages(payload).then((response) => {
        this.imageDetails = response;
      }).finally(() => {
        const currentImage = this.imageDetails;
        this.fetchClosestImages({
          date: currentImage.date,
          cameraviews: currentImage.cameraview,
          before: 1,
          after: 1,
        }).then((response) => {
          const imagesList = sortBy(response, (image) => image.id);
          [this.previousImage] = filter(imagesList, (image) => image.id > currentImage.id);
          [this.nextImage] = filter(imagesList, (image) => image.id < currentImage.id);
          this.newCurrentImage = currentImage;
        });
        this.isLoading = false;
      });
    },
    personSelect() {
      const onlyPerson = map(this.labels, (label) => {
        if (label.label !== 'person') {
          label.value = false;
        } else {
          label.value = true;
        }
        return label;
      });
      this.sendCheckedLabels = onlyPerson;
      this.sendCheckedModels = this.models;
    },
    changeSelectedBoxes() {
      this.sendCheckedModels = this.models;
      this.sendCheckedLabels = this.labels;
    },
    initialLabels(labels) {
      this.labels = labels;

      const labelPerson = filter(this.labels, (label) => label.label === 'person');
      if (!isEmpty(labelPerson)) {
        this.showPersonSelect = true;
      }

      if (this.labels.length) {
        this.labelsLoaded = true;
      }
    },
    initialModels(models) {
      this.models = models;

      if (this.models.length) {
        this.modelsLoaded = true;
      }
    },
    setConfidenceLevel() {
      this.updateConfidenceLevel = this.isConfidenceLevel;
      this.sendCheckedModels = this.models;
      this.sendCheckedLabels = this.labels;
    },
    removeDuplicates(array) {
      return array.filter((v, i, a) => a.findIndex((v2) => (v2.id === v.id)) === i);
    },
    async changedSelection() {
      this.local.images.next.isLast = false;
      this.local.images.prev.isLast = false;
      const currentImage = this.local.images.list[this.swiper.instance.activeIndex];
      this.isLoading = true;
      if (this.scrollStepSelected === 'default') {
        const fetchClosestImagesPayload = {
          date: currentImage.date,
          cameraviews: currentImage.cameraview,
          before: 15,
          after: 15,
        };
        await this.fetchClosestImages(fetchClosestImagesPayload).then((response) => {
          const imagesList = this.isAscending ? response.reverse() : response;
          this.local.images.list = imagesList;
          const newIndex = imagesList.findIndex((image) => image.id === currentImage.id);
          this.swiper.instance.activeIndex = newIndex;
          this.swiper.instance.realIndex = newIndex;
        }).finally(() => {
          this.isLoading = false;
        });
        return;
      }
      let [forward, back] = await Promise.all([
        this.scrollImage(
          { id: currentImage.id, params: { step: this.scrollStepSelected, direction: 'forward' } },
        ).then((response) => response.data),
        this.scrollImage(
          { id: currentImage.id, params: { step: this.scrollStepSelected, direction: 'back' } },
        ).then((response) => response.data),
      ]).finally(() => {
        this.isLoading = false;
      });
      if (this.isAscending) {
        [forward, back] = [back, forward];
      }
      if (forward.length === 1) {
        this.local.images.prev.isLast = true;
      }
      if (back.length === 1) {
        this.local.images.next.isLast = true;
      }
      const fullList = this.removeDuplicates(forward.reverse().concat(back));
      this.local.images.list = fullList;
      const newIndex = fullList.findIndex((image) => image.id === currentImage.id);
      this.swiper.instance.activeIndex = newIndex;
      this.swiper.instance.realIndex = newIndex;
    },
    async loadPrevScrollImages(firstImage) {
      this.isLoading = true;
      const direction = this.isAscending ? 'back' : 'forward';
      await this.scrollImage(
        { id: firstImage.id, params: { step: this.scrollStepSelected, direction } },
      ).then((response) => {
        if (!response.data.length || !response.data.slice(1).length) {
          this.local.images.prev.isLast = true;
          return;
        }
        if (this.local.images.prev.awaitGoNext) {
          this.local.images.prev.awaitGoNext = false;
        }
        this.local.images.list.unshift(...response.data.reverse());
        this.local.images.list = this.removeDuplicates(this.local.images.list);
        const newSliderMoveIdx = this.local.images.list.findIndex((image) =>
          image.id === firstImage.id) + this.swiper.instance.realIndex;
        this.swiper.instance.activeIndex = newSliderMoveIdx;
        this.swiper.instance.realIndex = newSliderMoveIdx;
      }).finally(() => {
        this.local.images.prev.isLoading = false;
        this.isLoading = false;
      });
    },
    async loadNextScrollImages(lastImage) {
      this.isLoading = true;
      const direction = this.isAscending ? 'forward' : 'back';
      await this.scrollImage(
        { id: lastImage.id, params: { step: this.scrollStepSelected, direction } },
      ).then((response) => {
        if (!response.data.length || !response.data.slice(1).length) {
          this.local.images.next.isLast = true;
          return;
        }
        this.local.images.list.push(...response.data.slice(1));
        if (this.local.images.next.awaitGoNext) {
          this.local.images.next.awaitGoNext = false;
          setTimeout(() => {
            this.swiperSlideNext();
          }, 300);
        }
      }).finally(() => {
        this.local.images.next.isLoading = false;
        this.isLoading = false;
      });
    },
    loadLikes() {
      const userLikesLoadingState = this.loadingsState?.likes;
      if (!userLikesLoadingState || userLikesLoadingState === ERROR) {
        this.getUserLikes({ user: this.user.id, object_content_type__model: 'image' }).then(() => {
          this.isShowLikes = true;
        });
        return;
      }
      this.isShowLikes = true;
    },
    loadTags() {
      const userTagsLoadingState = this.loadingsState?.tags;
      if (!userTagsLoadingState || userTagsLoadingState === ERROR) {
        this.getUserTags({
          user: this.user.id, system_tag: false, project__isnull: true, limit: 10000,
        }).then(() => {
          this.isShowTags = true;
        });
        return;
      }
      this.isShowTags = true;
    },
    swiperSlideNext() {
      if (!this.swiper.instance) {
        return;
      }
      if (!this.isRightArrowActive) {
        this.local.images.next.awaitGoNext = true;
      }
      this.local.images.next.awaitGoNext = true;
      this.removeDuplicates(this.local.images.list);
      this.local.images.next.awaitGoNext = true;
      this.swiper.instance.slideNext();
    },
    swiperSlidePrev() {
      if (!this.swiper.instance) {
        return;
      }
      if (!this.isLeftArrowActive) {
        this.local.images.prev.awaitGoNext = true;
      }
      this.removeDuplicates(this.local.images.list);
      this.swiper.instance.slidePrev();
    },
    onLeftArrowClickHandler() {
      this.swiperSlidePrev();
    },
    onRightArrowClickHandler() {
      if (!this.swiper.instance) {
        return;
      }
      this.swiperSlideNext();
    },
    onWheelHandler(e) {
      if (!this.swiper.instance || [e.altKey, e.ctrlKey, e.shiftKey].some((item) => item)) {
        return;
      }
      const wheelDirection = wheelChangeValue(e);
      if (wheelDirection < 0) {
        this.swiperSlideNext();
        return;
      }
      this.swiperSlidePrev();
    },
    onCloseClickHandler() {
      this.onBeforeComponentCloseHandler();
    },
    onPicturesZoomChangeHandler(data) {
      const swiperTouchMove = this.swiper.instance.allowTouchMove;
      if (data.zoom !== data.min && swiperTouchMove) {
        this.swiper.instance.allowTouchMove = false;
      } else if (data.zoom === data.min && !swiperTouchMove) {
        this.swiper.instance.allowTouchMove = true;
      }
    },
    onKeyDownHandler(e) {
      switch (e.keyCode) {
        // Escape key
        case 27:
          this.onBeforeComponentCloseHandler();
          break;
        // Left arrow key
        case 37:
          this.swiperSlidePrev();
          break;
        // Right arrow key
        case 39:
          this.swiperSlideNext();
          break;
        default:
          break;
      }
    },
    onBeforeComponentCloseHandler() {
      this.$emit(CLOSE_EVENT,
        { image: this.local.images.list[this.swiper.instance.activeIndex] });
    },
    $_swiperMixin_onSlideChange() {
      // close all tags windows if open
      if (this.isShowTags) {
        this.isShowTags = false;
        setTimeout(() => { this.isShowTags = true; }, 100);
      }
    },
    ...mapActions({
      getUserLikes: 'listLikes',
      getUserTags: 'listTags',
      scrollImage: 'getImageScroll',
      retrieveImages: 'retrieveImages',
      fetchClosestImages: 'fetchClosestImages',
    }),
  },
  beforeDestroy() {
    const componentContainer = this.$refs?.componentContainer;
    if (componentContainer) {
      componentContainer.removeEventListener('scroll', this.onWheelHandler);
    }

    const html = document.querySelector('html');
    html.classList.remove('no-scroll');
  },
};
</script>

<style lang="scss">
  html.no-scroll {
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/variables.scss';
  @import '~@/sass/mixins/v3/checkboxes.scss';

  .camera-time-travel {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .85);
    z-index: 100000000;

    &.full-screen {
      .camera-time-travel__container__info,
      .camera-time-travel__close {
        display: none;
      }
      .camera-time-travel__gallery {
        margin: 0;
      }
      .camera-time-travel__container {
        padding-top: 0;
        width: 100%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      .camera-time-travel__panel {
        display: none;
      }
    }

    .camera-time-travel__container {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 73.9585vw;
      padding-top: 6.5vh;
      font-weight: 600;

      .touch-device & {
        top: 50vh;
        transform: translateX(-50%) translateY(-50%);
        padding: 0;
      }
    }

    .camera-time-travel__container__info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .camera-time-travel__container__info__caption {
        white-space: nowrap;
        animation: hideHelpText 1s ease-out 5s;
        animation-fill-mode: forwards;
        will-change: opacity;

        .touch-device & {
          display: none;
        }

        @keyframes hideHelpText {
          0% { opacity: 1; }
          100% { opacity: 0; }
        }
      }

      .camera-time-travel__container__info__fullscreen {
        justify-content: flex-end;
      }
    }

    .camera-time-travel__gallery {
      position: relative;
      width: 100%;
      margin-top: 1.5vh;

      .swiper-slide {
        position: relative;
        visibility: hidden;
        opacity: 1!important;

        .images-preloader {
          position: absolute;
          z-index: 100;
          left: 50%;
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          background-color: #ffffff;
          padding: 0 10px 14px;
        }

        &.swiper-slide-active {
          visibility: visible;
        }

        .swiper-slide__arrow-icon {
          position: absolute;
          cursor: pointer;
          opacity: 0.4;
          color: $default-blue-dark-color;
          font-size: 3em;
          top: 35vh;

          &.disabled {
            color: $default-disabled-color;
            cursor: default;
          }

          &:hover:not(.disabled) {
            opacity: 0.9;
            color: $default-light-color;
          }
        }
        .left {
          left: 1vw;
        }
        .right {
          right: 1vw;
        }
      }

      .step_selection__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .step_selection__wrapper_label {
          margin-bottom: 2.5vh;
          margin-right: 1vw;
        }

        .step_selection__wrapper_options::v-deep {
          width: 10.2vw;

          @media (orientation: portrait) {
            width: 16vw;
          }

          .v-input {
            font-size: 1em;
            font-family: $body-font-family;
            padding: 0;
            margin: 0;

            .v-input__slot:before {
              border-color: $default-blue-ultra-dark-color;
              background-color: $default-blue-ultra-dark-color;
            }

            .v-input__slot:after {
              border-color: $default-blue-color;
              background-color: $default-blue-color;
            }

            .v-select__selections {
              .v-select__selection--comma {
                padding: 0;
                position: absolute;
                margin: 0;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: $default-blue-ultra-dark-color;
              }

              input {
                font-family: $main-font-family, "Material Design Icons", sans-serif;
                padding: 0 0 0.5vh;
                line-height: 1;
                max-height: initial;

                @media (orientation: portrait) {
                  width: 20vw;
                  padding-bottom: 1vh;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                  opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                  color: $default-blue-ultra-dark-color;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .camera-time-travel__close {
        position: absolute;
        top: 0;
        right: -.5vw;
        transform: translateX(100%);
        font-size: 2em;
        color: $default-blue-color;
        cursor: pointer;
      }
      .camera-time-travel__panel {
        position: absolute;
        top: 10%;
        right: -11vw;
        .camera-time-travel__panel__no_objects {
          font-size: 0.8em;
          margin-left: 1.2vw;
          margin-top:2vh
        }
        .camera-time-travel__panel__text {
          font-size: 0.8em;
        }
        .camera-time-travel__panel_results {
          font-size: 0.8em;

          @media only screen and (max-width: 1000px) {
            display: none;
          }
          .checkboxes {
            font-size: 0.8em;
            padding-right: 1vw;
            .checkboxes__checkbox__title {
              margin-left: 1vw;
            }
          }
          .camera-time-travel__panel__person_select {
            text-align: center;
            border : 1px solid $default-blue-ultra-dark-color;
            padding: 0.5vh;
            cursor: pointer;
            margin-bottom: 2vh;
            margin-top: 4vh;
          }
          .camera-time-travel__panel_results_models {
            margin-bottom: 2vh;
          }
          .camera-time-travel__panel_results_labels {
            margin-bottom: 2vh;
          }
        }
      }
      .swiper-slide::v-deep {
        img.zoom-pre-process {
          opacity: 0;
        }
      }

      .swiper-slide:not(.swiper-slide-active) {
        ::v-deep {
          .camera-picture__actions {
            display: none;
          }
        }
      }
    }
  }
</style>
