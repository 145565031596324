<template>
  <div class="camera-picture-with-recognitions" ref="image_container">
    <CameraPreloader class="camera-picture-with-recognitions__preloader" :text="loadingText"
                     v-if="isShowLoading"/>
    <div class="camera-picture-no-results" v-if="isWaiting">
      <span>{{ $t('messages.analyzing')}}</span>
    </div>
    <div class="camera-picture-no-results" v-if="noResults">
      <span>{{ $t('messages.no_significant')}}</span>
    </div>
    <slot :image="componentImage" :recognize="isShowRequestRecognition"
          :imageRecognitionRequested="onImageRecognitionRequestedHandler"></slot>
  </div>
</template>

<script>
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import permissionMixin from '@/mixins/v3/permissionMixin';
import PERMISSIONS from '@/constants/v3';
import { mapActions, mapState } from 'vuex';
import { forEach, filter } from 'lodash';
import { LOADING, SUCCESS } from '@/store/loading-types';

const DECIMALS_ACCURACY = 3;
const AREAS_FONT = 'monospace';
const AREAS_FONT_SIZE_DIVIDER = 10;
const AREAS_LINE_HEIGHT_DIVIDER = 100;
const CHECK_IMAGE_RECOGNIZED_INTERVAL = 15000; // 15 seconds

export default {
  name: 'CameraPictureWithRecognition',
  mixins: [permissionMixin],
  components: { CameraPreloader },
  emits: ['initialLabels', 'initialModels', 'noSignificantObjectsDetected'],
  props: {
    imageDetails: {
      type: Object,
      required: true,
    },
    isShowBoxes: {
      type: Boolean,
      default: false,
    },
    isShowLabels: {
      type: Boolean,
      default: false,
    },
    levelOfConfidence: {
      type: Number,
    },
    sendLabelState: {
      type: Array,
      default: () => [],
    },
    sendModelState: {
      type: Array,
      default: () => [],
    },
    areaId: {
      type: Number,
    },
  },
  data: () => ({
    isWaiting: false,
    noResults: false,
    isLoading: false,
    isDrawing: false,
    isRecognitionRequested: false,
    componentImage: null,
    isAreaImageReady: false,
    checkLoadingInterval: null,
    isAreasLoaded: false,
    checkIfImageRecognizedTimeoutId: null,
    isDestroyed: false,
    hasAreasOnImage: false,
    noSignificantObjectsDetected: false,
  }),
  watch: {
    imageDetails: {
      handler() {
        this.labels = [];
        this.models = [];
        this.sendCheckedLabels = [];
        this.sendCheckedModels = [];
        this.toggleRecognitions();
      },
      deep: true,
    },
    areaId: {
      handler() {
        this.toggleRecognitions();
      },
      deep: true,
    },
    sendModelState: {
      handler() {
        this.toggleRecognitions();
      },
      deep: true,
    },
    sendLabelState: {
      handler() {
        this.toggleRecognitions();
      },
      deep: true,
    },
    levelOfConfidence: {
      handler() {
        this.toggleRecognitions();
      },
      deep: true,
    },
    isAreaImageReady(newValue) {
      if (newValue) {
        this.componentImage.image = this.componentImage.extra.image_with_areas;
        return;
      }
      this.componentImage.image = this.componentImage.extra.original_image;
    },
    isShowBoxes() {
      this.toggleRecognitions();
    },
    isShowLabels() {
      this.toggleRecognitions();
    },
  },
  beforeMount() {
    this.componentImage = {
      ...this.imageDetails,
      extra: {
        original_image: this.imageDetails.image,
        image_with_areas: null,
      },
    };
  },
  mounted() {
    this.toggleRecognitions();
  },
  computed: {
    isShowLoading() {
      return this.isLoading || this.isDrawing;
    },
    loadingText() {
      if (this.isDrawing) {
        return this.$t('messages.drawing');
      }
      return this.$t('messages.loading');
    },
    isShowRecognitions() {
      return this.isAllowToViewAI && (this.isShowBoxes || this.isShowLabels);
    },
    isAllowToViewAI() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_image_area_identifier);
    },
    isLabelsLoading() {
      const { identifierlabels: identifierLabelsLoadingState = '' } = this.loadingsStates;
      return identifierLabelsLoadingState === LOADING;
    },
    imageAreas() {
      const { id: imageId } = this.componentImage;
      const imageFilteredAreas = filter(
        this.imagesAreaIdentifiers,
        (imageAreaIdentifier) => imageAreaIdentifier.image === imageId,
      );
      let filteredAreas = filter(imageFilteredAreas, (area) => {
        const label = this.identifierLabels[area.label];
        return label && area.confidence >= label.minimum_confidence_level;
      });
      if (this.areaId && filteredAreas.length) {
        return filteredAreas.filter((area) => area.id === this.areaId);
      }
      if (!this.sendLabelState.length && !this.sendModelState.length) {
        const {
          initialLabels,
          initialModels,
        } = this.createCheckBoxList(filteredAreas);
        this.$emit('initialLabels', initialLabels);
        this.$emit('initialModels', initialModels);
        return filteredAreas;
      }
      filteredAreas = this.updateImageAreas(
        this.sendLabelState, this.sendModelState, filteredAreas,
      );
      if (this.levelOfConfidence > 0) {
        filteredAreas = filter(filteredAreas,
          (area) => area.confidence >= this.levelOfConfidence);
        return filteredAreas;
      }
      return filteredAreas;
    },
    isShowRequestRecognition() {
      return (this.isShowLabels || this.isShowBoxes) && !this.isRecognitionRequested
        && this.isAreasLoaded && !this.imageAreas.length && !this.hasAreasOnImage;
    },
    ...mapState({
      imagesAreaIdentifiers: (state) => state.imageareaidentifiers,
      identifierLabels: (state) => state.identifierlabels,
      modelAlgorithms: (state) => state.modelalgorithms,
      loadingsStates: (state) => state.loadings,
    }),
  },
  methods: {
    updateImageAreas(labelsState, modelsState, allImageAreas) {
      this.hasAreasOnImage = true;
      const labelIds = labelsState
        .filter((item) => item.value)
        .map((item) => this.getLabelByName(item.label).id);
      const modelIds = modelsState
        .filter((item) => item.value)
        .map((item) => this.getModelNameByName(item.model).id);
      return allImageAreas
        .filter((imgArea) => modelIds.includes(imgArea.model))
        .filter((imgArea) => labelIds.includes(imgArea.label));
    },
    createCheckBoxList(areas) {
      const models = [
        ...new Set(areas.map((imageArea) => this.getModelNameById(imageArea.model))),
      ];
      const labels = [
        ...new Set(areas.map((imageArea) => this.getLabelById(imageArea.label).name)),
      ];
      const initialLabels = labels.map((element) => ({ label: element, value: true }));
      const initialModels = models.map((element) => ({ model: element, value: true }));
      return {
        initialLabels,
        initialModels,
      };
    },
    toggleRecognitions() {
      if (this.componentImage === null || !this.componentImage.image) {
        return;
      }
      if (!this.isShowRecognitions) {
        this.isAreaImageReady = false;
        return;
      }
      this.getImageAreaIdentifiers();
    },
    async getImageAreaIdentifiers() {
      this.loadModelAlgorithms();
      const { id: imageId } = this.componentImage;
      if (this.isShowLabels) {
        this.isLoading = true;
        await this.loadIdentifierLabels().finally(() => {
          this.isLoading = false;
        });
      }
      if (!this.imageAreas.length && !this.isAreasLoaded) {
        this.isLoading = true;
        await this.fetchImageAreaIdentifiers({ image_id: imageId }).then((response) => {
          if (response.response.data.count > 0) {
            this.hasAreasOnImage = true;
          }
          this.isAreasLoaded = true;
        }).finally(() => {
          this.isLoading = false;
        });
      }
      await this.drawAreas();
    },
    async drawAreas() {
      if (!this.imageAreas.length) {
        if (this.isRecognitionRequested) {
          this.isWaiting = false;
          this.noResults = true;
          this.noSignificantObjectsDetected = true;
          this.$emit('noSignificantObjectsDetected', this.noSignificantObjectsDetected);
          setTimeout(() => {
            this.noResults = false;
          }, 3000);
          return;
        }
        if (!this.hasAreasOnImage) {
          return;
        }
        const { id: imageId } = this.componentImage;
        const imageFilteredAreas = filter(
          this.imagesAreaIdentifiers,
          (imageAreaIdentifier) => imageAreaIdentifier.image === imageId,
        );
        const filteredAreas = filter(imageFilteredAreas, (area) => {
          const label = this.identifierLabels[area.label];
          const minimumConfidence = label && label.minimum_confidence_level !== undefined
            ? label.minimum_confidence_level
            : 0;
          return label && area.confidence >= minimumConfidence;
        });
        if (filteredAreas.length === 0) {
          this.isWaiting = false;
          this.noSignificantObjectsDetected = true;
          this.$emit('noSignificantObjectsDetected', this.noSignificantObjectsDetected);
          return;
        }
      }
      if (this.isWaiting) {
        this.isWaiting = false;
      }
      this.isDrawing = true;
      const canvas = document.createElement('canvas');
      const fakeImage = await this.getFakeImage();
      fakeImage.addEventListener('error', () => {
        this.isDrawing = false;
      });
      fakeImage.addEventListener('load', () => {
        const context = this.drawImageOnCanvas(fakeImage, canvas);
        this.drawAreasOnCanvas(context);
        this.saveDrawnImage(canvas);
        canvas.remove();
        fakeImage.remove();
        this.isDrawing = false;
      });
    },
    saveDrawnImage(canvas) {
      this.isAreaImageReady = false;
      this.$nextTick(() => {
        this.componentImage.extra.image_with_areas = canvas.toDataURL('image/jpeg');
        this.isAreaImageReady = true;
      });
    },
    async getFakeImage() {
      const { image_container: imageContainer } = this.$refs;
      const imageTag = imageContainer.querySelector('picture > img');
      const createFakeImage = (originalImage) => {
        const fakeImage = new Image();
        fakeImage.crossOrigin = 'anonymous';
        fakeImage.src = `${this.componentImage.extra.original_image}&not_from_cache_please`;
        fakeImage.width = originalImage.width;
        fakeImage.height = originalImage.height;
        return fakeImage;
      };
      return new Promise((resolve, reject) => {
        if (imageTag.width > 0) {
          resolve(createFakeImage(imageTag));
          return;
        }
        imageTag.addEventListener('error', () => {
          reject(new Error('Original image loading error!'));
        });
        imageTag.addEventListener('load', () => {
          resolve(createFakeImage(imageTag));
        });
      });
    },
    drawImageOnCanvas(image, canvas, context = null) {
      const canvasContext = context || canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      canvasContext.drawImage(image, 0, 0, image.width, image.height);
      return canvasContext;
    },
    drawAreasOnCanvas(context) {
      forEach(this.imageAreas, (imageArea) => this.drawAreaOnCanvas(imageArea, context));
    },
    drawAreaOnCanvas(imageArea, context) {
      const { canvas } = context;
      const {
        x0, y0, x1, y1,
      } = this.getRelativeCoordinates(imageArea, canvas);
      const rectangleWidth = this.roundNumberToDecimals(Math.abs(x1 - x0), DECIMALS_ACCURACY);
      const rectangleHeight = this.roundNumberToDecimals(Math.abs(y1 - y0), DECIMALS_ACCURACY);
      this.drawRectangle(imageArea, x0, y0, rectangleWidth, rectangleHeight, context);
      this.drawLabel(imageArea, x0, y0, rectangleWidth, rectangleHeight, context);
    },
    getRelativeCoordinates(imageArea, { width: actualWidth, height: actualHeight }) {
      const {
        x0, y0, x1, y1, original_image_height: originalHeight, original_image_width: originalWidth,
      } = imageArea;
      const xAxisMultiplier = this.roundNumberToDecimals(
        actualWidth / originalWidth, DECIMALS_ACCURACY,
      );
      const yAxisMultiplier = this.roundNumberToDecimals(
        actualHeight / originalHeight, DECIMALS_ACCURACY,
      );
      return {
        x0: this.roundNumberToDecimals(x0 * xAxisMultiplier, DECIMALS_ACCURACY),
        y0: this.roundNumberToDecimals(y0 * yAxisMultiplier, DECIMALS_ACCURACY),
        x1: this.roundNumberToDecimals(x1 * xAxisMultiplier, DECIMALS_ACCURACY),
        y1: this.roundNumberToDecimals(y1 * yAxisMultiplier, DECIMALS_ACCURACY),
      };
    },
    getColor(model) {
      let color;

      switch (model) {
        case 1:
          color = 'rgb(248, 95, 95, 0.7)';
          break;
        case 2:
          color = '#1654A299';
          break;
        case 3:
          color = 'rgb(4, 48, 103, 0.7)';
          break;
        case 4:
          color = '#2596be99';
          break;
        default:
          color = 'rgb(248, 154, 95, 0.7)';
      }
      return color;
    },
    drawRectangle(imageArea, topLeftX, topLeftY, width, height, context) {
      if (!this.isShowBoxes) {
        return;
      }
      const color = this.getColor(imageArea.model);

      context.beginPath();
      context.rect(topLeftX, topLeftY, width, height);
      context.lineWidth = this.getDrawLineWidth(width);
      context.strokeStyle = color;
      context.stroke();
    },
    drawLabel({ label: labelId, confidence, model },
      topLeftX, topLeftY, width, height, context) {
      if (!this.isShowLabels) {
        return;
      }

      const { name: label = null } = this.getLabelById(labelId);
      if (!label) {
        return;
      }
      const color = this.getColor(model);

      const labelConfidence = `${label} ${confidence.toFixed(2)}`;
      const fontSize = this.getDrawFontSize(width);
      context.font = `${fontSize}px ${AREAS_FONT}`;
      const textWidth = context.measureText(labelConfidence).width;
      context.beginPath();
      context.fillStyle = color;
      context.fillRect(topLeftX, (topLeftY + height), textWidth, fontSize + 5);
      context.fillStyle = '#F3F8FB';
      context.fillText(labelConfidence, topLeftX, (topLeftY + height) + fontSize);
      context.stroke();
    },
    getDrawLineWidth(imageWidth) {
      const lineWidth = Math.round(imageWidth / AREAS_LINE_HEIGHT_DIVIDER);
      return lineWidth > 1 ? lineWidth : 1;
    },
    getDrawFontSize(imageWidth) {
      const size = Math.round(imageWidth / AREAS_FONT_SIZE_DIVIDER);
      const min = 10;
      return size < min ? min : size;
    },
    getLabelById(labelId) {
      return labelId in this.identifierLabels ? this.identifierLabels[labelId] : {};
    },
    getLabelByName(name) {
      const filtered = filter(
        this.identifierLabels, (identifierLabel) => identifierLabel.name === name,
      );
      return filtered[0];
    },
    getModelNameById(modelId) {
      return modelId in this.modelAlgorithms ? this.modelAlgorithms[modelId].name : {};
    },
    getModelNameByName(model) {
      const filtered = filter(
        this.modelAlgorithms, (algorithm) => algorithm.name === model,
      );
      return filtered[0];
    },
    roundNumberToDecimals(value, decimalsCount) {
      const multiplier = 10 ** decimalsCount;
      return Math.round((value + Number.EPSILON) * multiplier) / multiplier;
    },
    async loadModelAlgorithms() {
      return new Promise((resolve, reject) => {
        this.getModelalgorithms().then(() => {
          resolve(this.modelAlgorithms);
        }).catch(reject);
      });
    },
    async loadIdentifierLabels() {
      return new Promise((resolve, reject) => {
        const { identifierlabels: identifierLabelsLoadingState = '' } = this.loadingsStates;
        if (identifierLabelsLoadingState === LOADING) {
          this.checkLoadingInterval = setInterval(() => {
            this.checkLoadingLabels(resolve, reject);
          }, 300);
          return;
        }
        if (identifierLabelsLoadingState === SUCCESS) {
          resolve(this.identifierLabels);
          return;
        }
        this.getIdentifierlabels({ limit: 10000 }).then(() => {
          resolve(this.identifierLabels);
        }).catch(reject);
      });
    },
    checkLoadingLabels(resolve, reject) {
      if (this.isLabelsLoading) {
        return;
      }
      clearInterval(this.checkLoadingInterval);
      const { identifierlabels: identifierLabelsLoadingState = '' } = this.loadingsStates;
      if (identifierLabelsLoadingState === SUCCESS) {
        resolve(this.identifierLabels);
        return;
      }
      reject(this.identifierLabels);
    },
    onImageRecognitionRequestedHandler() {
      this.isRecognitionRequested = true;
      this.checkIfImageRecognized();
    },
    checkIfImageRecognized() {
      clearTimeout(this.checkIfImageRecognizedTimeoutId);
      if (this.isDestroyed) {
        return;
      }
      const { id: imageId } = this.componentImage;
      this.fetchImageAreaIdentifiers({ image_id: imageId }).then((response) => {
        const { dataList } = response;
        if (!dataList.length) {
          this.isWaiting = true;
          setTimeout(this.checkIfImageRecognized, CHECK_IMAGE_RECOGNIZED_INTERVAL);
          return;
        }
        if (dataList.length > 0) {
          this.hasAreasOnImage = true;
          this.isRecognitionRequested = false;
        }
        this.drawAreas();
      }).catch(() => {
        setTimeout(this.checkIfImageRecognized, CHECK_IMAGE_RECOGNIZED_INTERVAL);
      });
    },
    ...mapActions({
      fetchImageAreaIdentifiers: 'listImageareaidentifiers',
      getIdentifierlabels: 'listIdentifierlabels',
      getModelalgorithms: 'listModelalgorithms',

    }),
  },
  beforeDestroy() {
    clearTimeout(this.checkIfImageRecognizedTimeoutId);
    clearInterval(this.checkLoadingInterval);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/variables.scss';

  .camera-picture-with-recognitions__preloader {
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #ffffff;
    padding: 0 10px 14px;
  }
  .camera-picture-no-results {
    display: flex;
    flex-flow: nowrap;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.3em;
    position: absolute;
    z-index: 100;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #ffffff;
    padding: 1vh;
  }
</style>
