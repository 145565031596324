import Vue from 'vue';
import endpointDefinitions from '@/services/csite/endpointDefinitions';

import {
  SET_ACTIVE_USER,
  CHANGE_DASHBOARD_CAMERAS_LAYOUT,
  CHANGE_DASHBOARD_PROJECTS_LAYOUT,
  SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS,
  TOGGLE_DASHBOARD_FULLSCREEN,
  TOGGLE_DASHBOARD_FOOTER_WEATHER,
  SET_ACTIVE_PROJECT,
  SET_TIMELAPSES_TYPE,
  TOGGLE_CUSTOM_TIMELAPSE,
  CHANGE_HOME_BUTTON_CLICKED,
} from './mutation-types';

import { getMutationTypes } from '../../mutation-types';

const usersEndpointDefinition = endpointDefinitions.find(
  (endpointDefinition) => endpointDefinition.name === 'users',
);
const usersMutationTypes = getMutationTypes(usersEndpointDefinition);

export default {
  [SET_ACTIVE_USER]: (state, payload) => {
    Vue.set(state, 'activeUser', payload);
  },
  [usersMutationTypes.UPDATE_SUCCESS_MUTATION]: (state, payload) => {
    Vue.set(state, 'activeUser', payload);
  },
  [CHANGE_DASHBOARD_CAMERAS_LAYOUT]: (state, payload) => {
    Vue.set(state, 'camerasLayout', payload);
  },
  /**
   * @deprecated since version 3
   */
  [CHANGE_DASHBOARD_PROJECTS_LAYOUT]: (state, payload) => {
    Vue.set(state, 'projectsLayout', payload);
  },
  /**
   * @deprecated since version 3
   */
  [TOGGLE_DASHBOARD_FULLSCREEN]: (state, payload) => {
    Vue.set(state, 'fullscreenMode', payload);
  },
  /**
   * @deprecated since version 3
   */
  [TOGGLE_DASHBOARD_FOOTER_WEATHER]: (state, payload) => {
    Vue.set(state, 'isShowFooterWeather', payload);
  },
  /**
   * @deprecated since version 3
   */
  [SET_ACTIVE_PROJECT]: (state, payload) => {
    Vue.set(state, 'activeProject', payload);
  },
  /**
   * @deprecated since version 3
   */
  [SET_TIMELAPSES_TYPE]: (state, payload) => {
    Vue.set(state, 'timelapsesType', payload);
  },
  /**
   * @deprecated since version 3
   */
  [TOGGLE_CUSTOM_TIMELAPSE]: (state, payload) => {
    Vue.set(state, 'isActiveCustomTimelapse', payload);
  },
  /**
   * @deprecated since version 3
   */
  [SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS]: (state, payload) => {
    const projectId = payload.projectId || null;
    const projectViewId = payload.projectViewId || null;
    Vue.set(state, 'singleProjectLayoutParams', { projectId, projectViewId });
  },
  /**
   * @deprecated since version 3
   */
  [CHANGE_HOME_BUTTON_CLICKED]: (state, payload) => {
    Vue.set(state, 'homeButtonClicked', payload);
  },
};
