<template>
  <div class="main_menu__menu">
    <base-button :title="$t(`menu.${menuItem.title}`)" :is-active="isMenuActive(menuItem.name)"
                  @click="onMenuClickHandler(menuItem.name, $event)"
                  v-for="(menuItem, index) in menuButtons" :key="index" />
  </div>
</template>

<script>
import { includes } from 'lodash';
import MENU_LIST, { AI_URL } from '@/constants/v3/menu';
import BaseButton from '@/components/v3/BaseButton';
import { mapState } from 'vuex';

export default {
  name: 'Menu',
  components: {
    BaseButton,
  },
  data() {
    return {
      AI_URL,
      routePath: this.$route.matched,
    };
  },
  computed: {
    menuButtons() {
      const buttons = [...MENU_LIST];
      if (this.user && this.user.permissions.includes('view_imageareaidentifier')) {
        buttons.push(AI_URL);
      }
      return buttons;
    },
    activeMenuName() {
      return this.$route.name;
    },
    activeMenuParentName() {
      return this.$route?.meta?.parentMenuName || null;
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    isMenuActive(name) {
      return includes([this.activeMenuName, this.activeMenuParentName], name);
    },
    onMenuClickHandler(routeName) {
      if (routeName === this.activeMenuName) {
        return;
      }
      this.$router.push({ name: routeName }).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/buttons.scss';

.main_menu__menu {
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep {
    .base-button {
      height: 100%;
      margin-right: .55vw;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
