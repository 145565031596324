import Vue from 'vue';

import { LOADING, ERROR, SUCCESS } from '@/store/loading-types';
import { getMutationTypes } from '@/store/mutation-types';
import { forEach } from 'lodash';
import endpointDefinitions from '../services/csite/endpointDefinitions';

function getMutations(endpoint) {
  const mutations = {};
  const listKey = `${endpoint.singleName}List`;
  const mutationTypes = getMutationTypes(endpoint);

  mutations[mutationTypes.REQUEST_MUTATION] = (state) => {
    state.isLoading = true;
    Vue.set(state.loadings, endpoint.name, LOADING);
  };

  mutations[mutationTypes.ERROR_MUTATION] = (state) => {
    state.isLoading = false;
    Vue.set(state.loadings, endpoint.name, ERROR);
  };

  mutations[mutationTypes.LIST_SUCCESS_MUTATION] = (state, { dataList, normData }) => {
    state.isLoading = false;
    Vue.set(state.loadings, endpoint.name, SUCCESS);
    for (let i = 0; i < dataList.length; i += 1) {
      const id = dataList[i];
      const obj = normData[id];
      // Add or update an instance
      Vue.set(state[endpoint.name], id, obj);

      // Only add the id if this does not exist in the list
      if (state[listKey].includes(id) === false) {
        state[listKey].push(id);
      }
    }
  };

  mutations[mutationTypes.RETRIEVE_SUCCESS_MUTATION] = (state, data) => {
    Vue.set(state.loadings, endpoint.name, SUCCESS);
    const obj = data;
    Vue.set(state[endpoint.name], obj.id, obj);
    if (state[listKey].includes(obj.id) === false) {
      state[listKey].push(obj.id);
    }
  };

  mutations[mutationTypes.CREATE_SUCCESS_MUTATION] = (state, data) => {
    const items = Array.isArray(data) ? data : [data];
    forEach(items, (item) => {
      if (!item.id) return;
      Vue.set(state[endpoint.name], item.id, item);
      state[listKey].push(item.id);
    });
  };

  mutations[mutationTypes.UPDATE_SUCCESS_MUTATION] = (state, data) => {
    const items = Array.isArray(data) ? data : [data];
    forEach(items, (item) => {
      if (!item.id) return;
      Vue.set(state[endpoint.name], item.id, item);

      // If the id is not in the store's list, update it.
      if (state[listKey].includes(item.id) === false) {
        state[listKey].push(item.id);
      }
    });
  };

  mutations[mutationTypes.DESTROY_SUCCESS_MUTATION] = (state, id) => {
    Vue.delete(state[endpoint.name], id);
    state[`${endpoint.singleName}List`]
      .splice(state[`${endpoint.singleName}List`].indexOf(id), 1);
  };

  if (endpoint.actions !== undefined) {
    endpoint.actions.forEach((action) => {
      mutations[mutationTypes[`${action.mutationName}_REQUEST_MUTATION`]] = (state) => {
        state.isLoading = true;
        Vue.set(state.loadings, endpoint.name, LOADING);
      };
      mutations[mutationTypes[`${action.mutationName}_GET_MUTATION`]] = (state, data) => {
        if (action.mutationCallback) action.mutationCallback('get', state, data);
      };
      mutations[mutationTypes[`${action.mutationName}_POST_MUTATION`]] = (state, data) => {
        if (action.mutationCallback) action.mutationCallback('post', state, data);
      };
      mutations[mutationTypes[`${action.mutationName}_PATCH_MUTATION`]] = (state, data) => {
        if (action.mutationCallback) action.mutationCallback('patch', state, data);
      };
      mutations[mutationTypes[`${action.mutationName}_PUT_MUTATION`]] = (state, data) => {
        if (action.mutationCallback) action.mutationCallback('put', state, data);
      };
      mutations[mutationTypes[`${action.mutationName}_DELETE_MUTATION`]] = (state, data) => {
        if (action.mutationCallback) action.mutationCallback('delete', state, data);
      };
      mutations[mutationTypes[`${action.mutationName}_ERROR_MUTATION`]] = (state) => {
        state.isLoading = false;
        state.loadings[endpoint.name] = ERROR;
      };
    });
  }
  return mutations;
}

export default endpointDefinitions.reduce(
  (accumulator, endpointDefinition) => ({ ...accumulator, ...getMutations(endpointDefinition) }),
  {},
);
