import {
  GET_ALL_COMPANIES, GET_ALL_PROJECT_VIEWS, GET_ALL_PROJECTS, GET_ALL_CAMERA_VIEWS, GET_ALL_GROUPS,
  GET_ALL_AI_MODELS,
} from './mutation-types';

export default {
  [GET_ALL_COMPANIES]: (state, payload) => {
    state.allCompanies = payload.normData;
  },
  [GET_ALL_PROJECTS]: (state, payload) => {
    state.allProjects = payload.normData;
  },
  [GET_ALL_PROJECT_VIEWS]: (state, payload) => {
    state.allProjectViews = payload.normData;
  },
  [GET_ALL_CAMERA_VIEWS]: (state, payload) => {
    state.allCameraViews = payload.normData;
  },
  [GET_ALL_GROUPS]: (state, payload) => {
    state.allGroups = payload.normData;
  },
  [GET_ALL_AI_MODELS]: (state, payload) => {
    state.allAIModels = payload.normData;
  },
};
