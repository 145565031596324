const CAMERAS_PER_REQUEST_LIMIT = 4;
export const LIKES_PER_REQUEST_LIMIT = 4;
export const CAMERA_IMAGES_PER_REQUEST_LIMIT = 16;
export const LOAD_NEW_CAMERAS_WHEN_LEFT_NUM = 2; // should be less than CAMERAS_PER_REQUEST_LIMIT

export const IMAGE_DATE_FORMAT = 'dd DD MMM YYYY HH:mm';
export const DETAIL_CAMERA_PAGING_TIMEOUT = 1000; // 1s
export const DETAIL_CAMERA_FILTERING_BY_DATE_TIMEOUT = 1000; // 1s

export default CAMERAS_PER_REQUEST_LIMIT;
