<template>
  <form class="auth-form" ref="form" @submit.prevent="submitForm"
        @change="$_authMixin_onFormChange($refs.form)">
    <div class="auth-form__caption">
      {{ $t('auth.titles.accept_invitation') }}
    </div>
    <p class="auth-form__info">{{ $t('auth.captions.accept_invitation') }}</p>
    <div class="auth-form__inputs">
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="first_name" type="text" :placeholder="$t('auth.placeholders.first_name')"
                 v-model="form.first_name.value" @input="$_authMixin_validate" required autofocus />
        </div>
        <label for="first_name">{{ form.first_name.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="last_name" type="text" :placeholder="$t('auth.placeholders.last_name')"
                 v-model="form.last_name.value" @input="$_authMixin_validate" required />
        </div>
        <label for="last_name">{{ form.last_name.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="password" type="password" :placeholder="$t('auth.placeholders.password')"
                 v-model="form.password.value" @input="$_authMixin_validate"
                 autocomplete="new-password" required />
        </div>
        <label for="password">{{ form.password.error }}</label>
      </div>
    </div>
    <ButtonSubmit :title="$t('auth.buttons.accept')" @click="submitForm"
                  :is-show-loading="$_authMixin_loading"
                  :is-disabled="$_authMixin_submitDisabledState"
                  class="auth-form__submit_button"/>
    <div class="auth-form__actions">
      <router-link :to="{ name: 'login' }">
        {{ $t('auth.captions.already_have_account') }} {{ $t('auth.buttons.login') }}
      </router-link>
    </div>
    <slot />
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

export default {
  name: 'AcceptInvitation',
  components: {
    ButtonSubmit,
  },
  mixins: [AuthMixin],
  data() {
    return {
      token: null,
      email: null,
      hasErrors: false,
      form: {
        first_name: {
          value: null,
          error: null,
        },
        last_name: {
          value: null,
          error: null,
        },
        password: {
          value: null,
          error: null,
        },
      },
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    if (!this.token) {
      this.$emit('apiError', { message: this.$t('auth.errors.token_missing') });
      return;
    }

    const [email, token] = this.token.split(':');
    if (!token || !/.+@.+/.test(email)) {
      this.$emit('apiError', { message: this.$t('auth.errors.token_invalid') });
      this.hasErrors = true;
    }
    this.email = email;
  },
  computed: {
    submitDisabledState() {
      return this.hasErrors || this.$_authMixin_submitDisabledState;
    },
  },
  methods: {
    submitForm(e) {
      if (this.hasErrors) {
        return;
      }

      if (!this.$_authMixin_submitForm(e)) {
        return;
      }

      const payload = {
        firstName: this.form.first_name.value,
        lastName: this.form.last_name.value,
        signedEmail: this.token,
        password: this.form.password.value,
      };
      this.acceptUserInvite(payload).then(() => {
        this.$router.push({ name: 'login', query: { email: this.email } });
      })
        .catch((error) => {
          let { message } = error;
          if (error.response && error.response.data) {
            message = Object.values(error.response.data).join('. ');
          }
          this.$emit('apiError', message);
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
    },
    ...mapActions({
      acceptUserInvite: 'acceptUserInvite',
    }),
  },
  beforeDestroy() {
    this.$emit('clearApiError');
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/auth.scss';

</style>
