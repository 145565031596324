<template>
  <div class="feed_cameras_list" ref="feedCamerasList">
    <Message class="feed_cameras_list__message" :text="error.text" :type="error.type"
             v-if="error.isShow" />
    <CameraDetailCard :image-details="projectView.latest_image_details"
                      :project-name="getCompanyAndProjectName(projectView)"
                      :camera-name="projectView.name" :project-id="projectView.project"
                      :camera-view="projectView.camera_details"
                      :project-view-id="projectView.id" :like="isShowLikes" :tags="isShowTags"
                      is-show-date
                      v-for="projectView in activeCameraViews"
                      :key="`feed_${projectView.id}`" />
    <CameraPreloader v-if="$_projectViewsMixin_projectViews.isLoading" />
    <div class="link_to_show_finished_projects" v-if="(inactiveCameraViews.length > 0
         || projectViewsWithLikesList > 0) &&
        !$_projectViewsMixin_projectViews.isLoading">
      <a href="#" @click="toggleInactiveCameras">
        {{ showInactiveCameras ? 'Hide your finished projects' : 'Show your finished projects' }}
      </a>
    </div>
    <div v-if="showInactiveCameras">
      <CameraDetailCard :image-details="projectView.latest_image_details"
                        :project-name="getCompanyAndProjectName(projectView)"
                        :camera-name="projectView.name" :project-id="projectView.project"
                        :camera-view="projectView.camera_details"
                        :project-view-id="projectView.id" :like="isShowLikes" :tags="isShowTags"
                        is-show-date
                        v-for="projectView in inactiveCameraViews"
                        :key="`inactive_${projectView.id}`"/>
    </div>
    <NoCameras v-if="isShowNoCameras"/>
  </div>
</template>

<script>
import { filter, find } from 'lodash';
import CameraDetailCard from '@/components/v3/Cameras/CameraDetailCard';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import NoCameras from '@/components/v3/Cameras/NoCameras';
import Message, { TYPES as MESSAGE_TYPES } from '@/components/v3/Message';
import { mapActions, mapState } from 'vuex';
import { LOAD_NEW_CAMERAS_WHEN_LEFT_NUM } from '@/constants/v3/cameras';
import { isInViewport } from '@/helpers';
import { projectViewsMixin } from '@/mixins/v3';

export default {
  name: 'Feed',
  mixins: [
    projectViewsMixin,
  ],
  components: {
    Message,
    NoCameras,
    CameraPreloader,
    CameraDetailCard,
  },
  props: {
    projectId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({
    error: {
      isShow: false,
      text: null,
      type: MESSAGE_TYPES.error,
    },
    scrollValue: 0,
    isShowLikes: false,
    isShowTags: false,
    lastActiveRequestPayload: null,
    showInactiveCameras: false,
  }),
  mounted() {
    this.initData();
    window.addEventListener('scroll', this.checkIfNeedsToStartLoadingProjectView);
  },
  watch: {
    projectId() {
      const { projectId: project } = this;
      const extraPayload = project ? { project } : {};
      this.$_projectViewsMixin_updateProjectViews({ extraPayload });
      this.$_projectViewsMixin_resetProjectViews();
    },
  },
  computed: {
    inactiveCameraViews() {
      return filter(this.projectViewsWithLikesList, (projectview) =>
        projectview.isActive !== true);
    },
    activeCameraViews() {
      return filter(this.projectViewsWithLikesList, (projectview) =>
        projectview.isActive === true);
    },
    projectViewsWithLikesList() {
      return this.$_projectViewsMixin_projectViewsList.map((projectView) => {
        const isActive = projectView.camera_details ? projectView.camera_details.active
          : (projectView.camera_details == null);
        if (!Object.keys(this.likes).length) {
          return {
            ...projectView,
            isActive,
          };
        }
        const latestImageDetailsWithLike = projectView.latest_image_details ? {
          ...projectView.latest_image_details,
          like: !!find(this.likes, (like) =>
            like.object_id === projectView.latest_image_details.id),
        } : projectView.latest_image_details;

        return {
          ...projectView,
          latest_image_details: latestImageDetailsWithLike,
          isActive,
        };
      });
    },
    isShowNoCameras() {
      return this.$_projectViewsMixin_projectViews.totalCount === 0
        && !this.$_projectViewsMixin_projectViews.isLoading;
    },
    ...mapState({
      likes: (state) => state.likes,
      user: (state) => state.activeUser,
      allCompanies: (state) => state.extra.allCompanies,
    }),
  },
  methods: {
    toggleInactiveCameras(event) {
      event.preventDefault();
      this.showInactiveCameras = !this.showInactiveCameras;
    },
    initData() {
      this.$_projectViewsMixin_loadNextProjectViews();
      this.getUserLikes();
      this.getUserTags();
    },
    checkIfNeedsToStartLoadingProjectView(e) {
      if (this.$_projectViewsMixin_isLoadedAllProjectViews
        || !this.$refs.feedCamerasList
        || this.$_projectViewsMixin_data.projectViews.isLoading
        || e.deltaY <= 0) {
        return;
      }
      const nodes = this.$refs.feedCamerasList.querySelectorAll(':scope > *');
      if (!nodes.length) {
        return;
      }
      const nodeToCheckVisibility = nodes[nodes.length - LOAD_NEW_CAMERAS_WHEN_LEFT_NUM];
      if (nodeToCheckVisibility && isInViewport(nodeToCheckVisibility)) {
        this.$_projectViewsMixin_loadNextProjectViews();
      }
    },
    getUserLikes() {
      this.fetchUserLikes({
        user: this.user.id,
        object_content_type__model: 'image',
        limit: 10000,
      }).then(() => {
        this.isShowLikes = true;
      }).catch(() => {
        this.error = {
          ...this.error,
          text: this.$t('errors.likes_load'),
          isShow: true,
        };
      });
    },
    getUserTags() {
      this.fetchUserTags({
        user: this.user.id,
        system_tag: false,
        project__isnull: true,
        limit: 10000,
      }).then(() => {
        this.isShowTags = true;
      }).catch(() => {
        this.error = {
          ...this.error,
          text: this.$t('errors.tags_load'),
          isShow: true,
        };
      });
    },
    getProjectDetails(projectView) {
      return projectView.project_details || {};
    },
    getCompanyDetails(projectDetails) {
      return projectDetails.company_details || {};
    },
    getProjectName(projectView) {
      const projectDetails = this.getProjectDetails(projectView);
      return projectDetails.name;
    },
    getCompanyName(projectView) {
      const projectDetails = this.getProjectDetails(projectView);
      const companyDetails = this.getCompanyDetails(projectDetails);
      return companyDetails.name;
    },
    getCompanyAndProjectName(projectView) {
      const projectName = this.getProjectName(projectView);
      if (!projectName) {
        return null;
      }

      let companyName = this.getCompanyName(projectView);
      if (companyName) {
        companyName += ' / ';
      }
      return `${companyName}${projectName}`;
    },
    ...mapActions({
      fetchUserLikes: 'listLikes',
      fetchUserTags: 'listTags',
      fetchAllCompanies: 'fetchAllCompanies',
      fetchProjectViews: 'listProjectviews',
      fetchCameraViews: 'listCameraviews',
      fetchProjects: 'listProjects',
      fetchImages: 'listImages',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkIfNeedsToStartLoadingProjectView);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/buttons.scss';

  .feed_cameras_list {
    position: relative;
    width: 55.3vw;
    max-width: 100%;
    margin: 0 auto;
    .link_to_show_finished_projects {
      display: flex;
      margin-top: 10vh;
      margin-bottom: 10vh;
      align-items: center;
      justify-content: center;
    }

    @media (orientation: portrait) {
      width: 100%;
    }

    .feed_cameras_list__message.error ::v-deep {
      position: fixed;
    }
  }
</style>
