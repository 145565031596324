export const fullscreenWebkitMixin = {
  data: () => ({
    $_fullscreenMixin: {
      eventName: 'webkitfullscreenchange',
    },
  }),
  methods: {
    $_fullscreenMixin_exitFullScreenClickHandler() {
      if (!this.isFullScreen || !document.webkitExitFullscreen) {
        return;
      }

      this.toggleFullscreen(false);
      document.webkitExitFullscreen();
    },
    $_fullscreenMixin_fullScreenClickHandler() {
      this.toggleFullscreen(true);
      document.documentElement.webkitRequestFullscreen();
    },
    $_fullscreenMixin_onFullScreenChangeHandler() {
      this.toggleFullscreen(!!document.webkitFullscreenElement);
    },
  },
};

export default fullscreenWebkitMixin;
