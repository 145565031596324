export const SET_ACTIVE_USER = 'Set active user';
export const CHANGE_DASHBOARD_CAMERAS_LAYOUT = 'Change dashboard cameras layout';

/**
 * @deprecated since version 3
 */
export const CHANGE_DASHBOARD_PROJECTS_LAYOUT = 'Change dashboard projects layout';
/**
 * @deprecated since version 3
 */
export const SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS = 'Set dashboard projects single layout params';
/**
 * @deprecated since version 3
 */
export const TOGGLE_DASHBOARD_FULLSCREEN = 'Toggle dashboard full screen';
/**
 * @deprecated since version 3
 */
export const TOGGLE_DASHBOARD_FOOTER_WEATHER = 'Toggle dashboard weather';
/**
 * @deprecated since version 3
 */
export const SET_ACTIVE_PROJECT = 'Set active project';
/**
 * @deprecated since version 3
 */
export const SET_TIMELAPSES_TYPE = 'Set active timelapse';
/**
 * @deprecated since version 3
 */
export const TOGGLE_CUSTOM_TIMELAPSE = 'Toggle active timelapse';
/**
 * @deprecated since version 3
 */
export const CHANGE_HOME_BUTTON_CLICKED = 'Changed state when home button clicked';

export default CHANGE_DASHBOARD_PROJECTS_LAYOUT;
