<template>
  <div class="no-items">
    <div class="no-items__header">{{ header }}</div>
    <div class="no-items__text">
      {{ $t('errors.go')}}<router-link :to="{ path: CAMERAS_URL.path }">
      {{ $t('errors.your_feed')}}</router-link>
      {{ $t(text) }}
    </div>
    <div class="no-items__icons">
      <i :class="icon"></i>
    </div>
  </div>
</template>

<script>
import { CAMERAS_URL } from '@/constants/v3/menu';

export default {
  name: 'NoItemsYet',
  props: {
    header: {
      type: String,
      default: 'There are no items yet.',
    },
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
    },
  },
  data() {
    return {
      CAMERAS_URL,
    };
  },
};
</script>

<style scoped lang="scss">

.no-items {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media only screen and (max-width: 900px) {
    font-size: 1.8em;
  }

  .no-items__header {
    margin-top: 5vh;
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 3vh;
  }
  .no-items__text {
    text-align: center;
    font-size: 1.2em;
  }

  .no-items__icons {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 4.5vh;
    font-size: 3em;
  }
}

</style>
