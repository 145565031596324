/*
  Dynamically build the API clients for each endpoint definition.

  How to use:
  client.{handler}.{method}

  Example:
  client.users.get()
*/

// eslint-disable-next-line import/no-cycle
import Resource from './resource';
import endpointDefinitions from './endpointDefinitions';

export default endpointDefinitions.reduce((acc, endpoint) => {
  // eslint-disable-next-line
  acc[endpoint.name] = new Resource(endpoint.name, endpoint.actions);
  return acc;
}, {});
