<template>
  <div :class="type" v-html="text"></div>
</template>

<script>
const TYPES = {
  error: 'error',
  success: 'success',
  warning: 'warning',
};
export { TYPES };

export default {
  name: 'Message',
  props: {
    type: {
      type: String,
      default: 'error',
    },
    text: {
      type: String,
      default: 'Oops... C-SITE has failed to load =(<br/> Please, try again later...',
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/variables';

div {
  position: absolute;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $default-text-color;
  font-size: min(1.35vw, 2.55vh);
  padding: min(1.6vh, 3.1vh);
  z-index: 100000;
}
</style>
