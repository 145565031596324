<template>
  <div class="no-cameras">
    <div class="no-cameras__header">{{ $t(this.header) }}</div>
    <div class="no-cameras__text">
      <router-link :to="{ path: SUPPORT_URL.path }">
        {{ $t('footer.titles.contact_us') }}</router-link>
      {{ $t('no_camera.part_1') }}<br /> {{ $t('no_camera.part_2') }}
    </div>
    <div class="no-cameras__icons">
      <div class="no-cameras__icons__icon">
        <picture>
          <img :src="icons.camera" alt="camera" />
        </picture>
        <div class="no-cameras__icons__icon__caption">camera</div>
      </div>
      <div class="no-cameras__icons__icon">
        <picture>
          <img :src="icons.livestream" alt="camera" />
        </picture>
        <div class="no-cameras__icons__icon__caption">livestream</div>
      </div>
      <div class="no-cameras__icons__icon">
        <picture>
          <img :src="icons.timelapse" alt="camera" />
        </picture>
        <div class="no-cameras__icons__icon__caption">timelapse</div>
      </div>
      <div class="no-cameras__icons__icon">
        <picture>
          <img :src="icons.photoDiary" alt="camera" />
        </picture>
        <div class="no-cameras__icons__icon__caption">photo diary</div>
      </div>
    </div>
  </div>
</template>

<script>
import { SUPPORT_URL } from '@/constants/v3/menu';

const camera = require('@/assets/images/v3/icons/camera.png');
const livestream = require('@/assets/images/v3/icons/livestream.png');
const timelapse = require('@/assets/images/v3/icons/timelapse.png');
const photoDiary = require('@/assets/images/v3/icons/photo_diary.png');

export default {
  name: 'NoCameras',
  props: {
    header: {
      type: String,
      default: 'no_camera.header',
    },
  },
  data() {
    return {
      SUPPORT_URL,
      icons: {
        camera,
        livestream,
        timelapse,
        photoDiary,
      },
    };
  },
};
</script>

<style scoped lang="scss">

.no-cameras {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .no-cameras__header {
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 3vh;

    @media screen and (max-width: 900px) {
      font-size: 2em;
      margin-top: 5vh;
    }
  }

  .no-cameras__text {
    text-align: center;

    @media screen and (max-width: 900px) {
      font-size: 1.8em;
    }
  }

  .no-cameras__icons {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 4.5vh;

    .no-cameras__icons__icon {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 3.2vw;
      margin-right: 3vw;

      @media screen and (max-width: 900px) {
        width: 10vw;
        margin-right: 6vw;
      }

      &:last-child {
        margin-right: 0;
      }

      picture {
        display: flex;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .no-cameras__icons__icon__caption {
        margin-top: 1vh;
        white-space: nowrap;

        @media screen and (max-width: 900px) {
          font-size: 1.4em;
        }
      }
    }
  }
}
</style>
