<template>
  <div class="likes_and_tags">
    <Menu class="likes_and_tags__menu" :menu-list="permissionMenuList"
          :init-active-index="activeIndex" :allow-hash="true" :allow-set-title="true"
          @activeTabChanged="onTabChanged"
          :class="{ likes_and_tags__menu__hidden: isFullScreen }" />
    <component :is="activeComponent" v-bind="activeComponentProps" v-on="activeComponentEvents"
               v-if="activeComponent" />
  </div>
</template>
<script>
import Menu from '@/components/v3/TabsMenu';
import { mapState } from 'vuex';
import Likes from '@/components/v3/Cameras/Likes';
import PERMISSIONS from '@/constants/v3';
import PicturesGroupedByTagsList
  from '@/components/v3/Cameras/PicturesGroupedByTags/PicturesGroupedByTagsList';
import { filter, map, some } from 'lodash';
import { permissionMixin } from '@/mixins/v3';

export default {
  name: 'TagsNLikes',
  mixins: [permissionMixin],
  components: {
    Menu,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('my_csite_menu.tabs.likes'),
          code: 'likes',
          component: Likes,
          props: { likesOnly: true },
          permissions: [PERMISSIONS.add_like],
        },
        {
          name: this.$t('my_csite_menu.tabs.tags'),
          code: 'tags',
          component: PicturesGroupedByTagsList,
          permissions: [PERMISSIONS.add_taggedimage],
        },
      ],
      activeIndex: 0,
      activeMenuTab: null,
    };
  },
  beforeMount() {
    if (!this.activeMenuTab) {
      [this.activeMenuTab] = this.permissionMenuList;
    }
  },
  watch: {
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
  },
  computed: {
    activeComponent() {
      return this.activeMenuTabForRender?.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    activeComponentEvents() {
      return this.activeMenuTabForRender?.events || {};
    },
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission))),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    onTabChanged(newTab, newIndex) {
      this.activeIndex = newIndex;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.tabs {
  margin-bottom: 2vh;
}

.likes_and_tags {
  position: relative;
  width: 100%;

  .likes_and_tags__menu {
    position: relative;
    width: 100vw;
    left: -$page-global-padding-horizontal;
    padding: 2.1vh $page-global-padding-horizontal;

    @media (orientation: portrait) {
      left: -$page-global-portrait-padding-horizontal;
      padding: 2.1vh $page-global-portrait-padding-horizontal;
    }
    @media screen and (max-width: 900px) {
      font-size: 2.2em;
    }
  }

  .likes_and_tags__menu__hidden {
    display: none;
  }
}
</style>
