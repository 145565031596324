<template>
  <div class="addProjectLocationForm modalForm">
    <div class="modalForm__window">
      <div class="modalForm__title">{{ $t(buttonTitle) }}
        {{ $t('cameras_menu.map_tab.title')}}</div>
      <v-alert :type="message.type" :value="message.isShow" v-if="message.isShow">
        {{ $t(message.text) }}
      </v-alert>
      <form
        class="modalForm__form"
        ref="form"
        @change="$_authMixin_onFormChange($refs.form)"
        @submit.prevent="submitForm">
        <div class="modalForm__form__inputs">
          <div class="modalForm__form__input">
            <select v-model="projectSelected" >
              <option v-for="(project, index) in projectList" :key="index"
                      :value="project" placeholder="Project">{{ project.name}}
              </option>
              <option :value="projectSelected" disabled hidden>
                {{ $t('cameras_menu.map_tab.form.choose_project')}}</option>
            </select>
          </div>
          <div class="modalForm__form__input">
            <input
              type="text"
              :placeholder="$t('cameras_menu.map_tab.form.street')"
              id="street"
              v-model="form.street.value"
              @input="$_authMixin_validate"
              required
            >
          </div>
          <label for="street">{{ form.street.error }}</label>
          <div class="modalForm__form__input">
            <input
              type="text"
              :placeholder="$t('cameras_menu.map_tab.form.number')"
              id="number"
              v-model="form.number.value"
              @input="$_authMixin_validate"
              required
            >
          </div>
          <label for="number">{{ form.number.error }}</label>
          <div class="modalForm__form__input">
            <input
              type="text"
              :placeholder="$t('cameras_menu.map_tab.form.post_code')"
              id="post_code"
              v-model="form.post_code.value"
              @input="$_authMixin_validate"
              required
            >
          </div>
          <label for="post_code">{{ form.post_code.error }}</label>
          <div class="modalForm__form__input">
            <input
              type="text"
              id="city"
              :placeholder="$t('cameras_menu.map_tab.form.city')"
              v-model="form.city.value"
              @input="$_authMixin_validate"
              required
            >
          </div>
          <label for="city">{{ form.city.error }}</label>
          <div class="modalForm__form__input">
            <input
              type="text"
              :placeholder="$t('cameras_menu.map_tab.form.province')"
              id="province"
              v-model="form.province.value"
              @input="$_authMixin_validate"
              required
            >
          </div>
          <label for="province">{{ form.province.error }}</label>
          <div class="modalForm__form__input">
            <select v-model="form.country_code.value">
              <option v-for="(country, index) in COUNTRIES_LIST"
                      :value="country.code"
                      :key="index">{{ $t(country.name) }}</option>
              <option :value="form.country_code.value" disabled hidden>
                {{ $t('cameras_menu.map_tab.form.choose_country')}}
              </option>
            </select>
          </div>
          <div class="modalForm__form__input">
            <input
              type="number"
              :placeholder="$t('cameras_menu.map_tab.form.longitude')"
              id="longitude"
              step="any"
              v-model="form.longitude.value"
              @input="validate_coordinates"
            >
          </div>
          <label for="longitude">{{ form.longitude.error }}</label>
          <div class="modalForm__form__input">
            <input
              step="any"
              type="number"
              :placeholder="$t('cameras_menu.map_tab.form.latitude')"
              id="latitude"
              v-model="form.latitude.value"
              @input="validate_coordinates"
            >
          </div>
          <label for="latitude">{{ form.latitude.error }}</label>
        </div>
        <div class="modalForm__button">
          <ButtonSubmit
            :is-show-loading="$_authMixin_loading"
            :is-disabled="$_authMixin_submitDisabledState"
            :title="$t(buttonTitle)"
            @click="submitForm"
          />
        </div>
        <slot />
      </form>
      <i class="icon__close mdi mdi-window-close" @click="onCloseClickHandler"/>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/authMixin';
import { COUNTRIES_LIST } from '@/constants/v3/countries';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { gmapApi } from 'vue2-google-maps';
import Vue from 'vue';
import { forEach } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'ProjectLocationForm',
  mixins: [AuthMixin],
  components: {
    ButtonSubmit,
  },
  props: {
    showForm: {
      type: Boolean,
      default: false,
    },
    projectList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  mounted() {
    Vue.$gmapApiPromiseLazy();
  },
  data() {
    return {
      COUNTRIES_LIST,
      message: {
        isShow: false,
        type: 'error',
        text: null,
      },
      projectSelected: {
        value: {},
        error: null,
      },
      form: {
        street: {
          value: null,
          error: null,
        },
        number: {
          value: null,
          error: null,
        },
        post_code: {
          value: null,
          error: null,
        },
        city: {
          value: null,
          error: null,
        },
        province: {
          value: null,
          error: null,
        },
        country_code: {
          value: null,
          error: null,
        },
        longitude: {
          value: null,
          error: null,
        },
        latitude: {
          value: null,
          error: null,
        },
      },
      buttonTitle: 'cameras_menu.map_tab.button_add',
    };
  },
  emits: ['close'],
  watch: {
    selectedProject() {
      if (!this.projectSelected) {
        return;
      }
      if (!this.projectSelected.address) {
        Object.keys(this.form).forEach((key) => {
          this.form[key].value = '';
        });
        this.buttonTitle = 'cameras_menu.map_tab.button_add';
        return;
      }
      this.buttonTitle = 'cameras_menu.map_tab.button_edit';
      const current = this.projectSelected.address;
      this.form.street.value = current.street;
      this.form.number.value = current.number;
      this.form.post_code.value = current.post_code;
      this.form.city.value = current.city;
      this.form.province.value = current.province;
      this.form.country_code.value = current.country_code;
      this.form.latitude.value = current.latitude;
      this.form.longitude.value = current.longitude;
    },
  },
  computed: {
    selectedProject() {
      return this.projectSelected;
    },
  },
  methods: {
    validate_coordinates(e) {
      e.preventDefault();
      const input = e.target;
      const inputId = input.getAttribute('id');

      if (!(inputId in this.form)) {
        return;
      }
      this.form[inputId].error = null;
      input.setCustomValidity('');

      const longRegex = new RegExp(
        '^-?((\\d|[1-9]\\d|1[0-7]\\d)(\\.\\d+)?|180(\\.0+)?)$',
      );
      const latRegex = new RegExp('^-?((90(\\.0+)?)|([1-8]?\\d(\\.\\d+)?))$');
      if (!input.value) {
        return;
      }
      if (inputId === 'longitude') {
        if (!longRegex.test(input.value.toString())) {
          input.setCustomValidity('This is not a correct longitude, '
            + 'please enter a valid number between -180 and 180 '
            + 'or leave this field empty');
        }
      } else if (inputId === 'latitude') {
        if (!latRegex.test(input.value.toString())) {
          input.setCustomValidity('This is not a correct latitude, '
            + 'please enter a valid number between -90 and 90 '
            + 'or leave this field empty');
        }
      }

      if (!input.checkValidity()) {
        this.form[inputId].error = input.validationMessage;
      }
    },
    async submitForm(e) {
      if (!this.$_authMixin_submitForm(e)) {
        return;
      }

      if (!this.form.latitude.value && !this.form.longitude.value) {
        const addressString = `${this.form.street.value} ${this.form.number.value}
        ${this.form.city.value}, ${this.form.province.value},
        ${this.form.country_code.value}`;

        const google = gmapApi();
        const geocoder = new google.maps.Geocoder();

        await geocoder.geocode({ address: addressString }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.form.latitude.value = results[0].geometry.location.lat();
            this.form.longitude.value = results[0].geometry.location.lng();
          } else {
            this.form.latitude.value = null;
            this.form.longitude.value = null;
          }
        });
      }
      const formData = new FormData();

      forEach(this.form, (field, fieldName) => {
        formData.append(`address.${fieldName}`, field.value);
      });
      formData.append('name', this.projectSelected.name);
      formData.append('company', this.projectSelected.company);
      this.postProjectsUpdate(
        { id: this.projectSelected.id, data: formData },
      ).then(() => {
        this.message = {
          isShow: true,
          type: 'success',
          text: 'cameras_menu.map_tab.success',
        };
        setTimeout(() => {
          this.$emit('close', this.showForm);
          window.location.reload();
        }, 2000);
      }).catch(() => {
        this.message = {
          isShow: true,
          type: 'error',
          text: 'cameras_menu.map_tab.error',
        };
      }).finally(() => {
        this.$_authMixin_loading = false;
      });
    },
    onCloseClickHandler() {
      this.$emit('close');
    },
    ...mapActions({
      postProjectsUpdate: 'updateProjects',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.modalForm {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modalForm__window {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 40%;
    padding: 6vh 4vw;
    background-color: $default-light-color;
    cursor: default;

    .modalForm__title {
      position: relative;
      font-size: 2em;
      padding-bottom: 2.5vh;
      margin-bottom: 3.1vh;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 43%;
        height: 1px;
        background-color: $default-blue-dark-color;
      }
    }

    .modalForm__form {
      margin-top: 3vh;

      .modalForm__form__inputs {
        .modalForm__form__input {

          margin-bottom: 0.5vh;
          margin-top: 2vh;
          position: relative;
          width: 100%;
          display: flex;
          border-bottom: 1px solid $default-disabled-color;

          input {
            width: 100%;
            padding: 0;
            line-height: 1;
            color: $default-blue-ultra-dark-color;
          }

          select {
            width: 100%;
            padding-bottom: 0.5vh;
            padding-left: 0;
            margin-left: 0;
            line-height: 1.2;
            color: $default-blue-ultra-dark-color;
          }
        }

        label {
          color: $fail-color;
          font-size: .7em;
        }
      }
    }

    .modalForm__button {
      margin-top: 5vh;
    }
  }
}

.icon__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5vh;
  padding-top: 1.5vh;
  opacity: 0.4;
}
</style>
