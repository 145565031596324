<template>
  <div class="cookies_page">
    <div class="settings_title">
      {{ $t('my_csite_menu.settings_menu.cookies.titles.cookies')}}
    </div>
    <div class="cookies_page__text">
      {{ $t('my_csite_menu.settings_menu.cookies.text')}}
      <a :href="cookieLink" target="_blank">
        {{ $t('my_csite_menu.settings_menu.cookies.link')}}</a>
    </div>
      <div class="checkboxes">
        <div class="checkboxes__checkbox" >
          <input
            type="checkbox"
            id="application_cookies"
            checked disabled>
          <label for="application_cookies" />
          <div class="checkboxes__checkbox__title">
            {{ $t('my_csite_menu.settings_menu.cookies.checkboxes.application_cookies')}}
          </div>
        </div>
        <div class="checkboxes__checkbox">
          <input
            type="checkbox"
            id="analytic_cookies"
            :checked="isAnalyticCookies"
            @change="isAnalyticCookies = !isAnalyticCookies">
          <label for="analytic_cookies" />
          <div class="checkboxes__checkbox__title">
            {{ $t('my_csite_menu.settings_menu.cookies.checkboxes.analytic_cookies')}}
          </div>
        </div>
        <div class="checkboxes__checkbox">
          <input type="checkbox"
                 id="youtube_cookies"
                 :checked="isYoutubeCookies"
                 @change="isYoutubeCookies = !isYoutubeCookies">
          <label for="youtube_cookies" />
          <div class="checkboxes__checkbox__title">
            {{ $t('my_csite_menu.settings_menu.cookies.checkboxes.youtube_cookies')}}
          </div>
        </div>
        <ButtonSubmit
          :title="$t('buttons.save_changes')"
          class="checkboxes__button" @click="saveSelection"/>
      </div>
</div>
</template>

<script>
import cookieMixin from '@/mixins/v3/cookies/cookieMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

export default {
  name: 'CookiesSettings',
  components: { ButtonSubmit },
  mixins: [cookieMixin],
  computed: {
    cookieLink() {
      const language = this.$i18n.locale;
      const subdomain = language === 'nl' ? 'www' : language;
      const baseLink = `https://${subdomain}.c-site.eu/privacy-policy`;
      return baseLink;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/settings.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.cookies_page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }
  .cookies_page__text {
    margin-bottom: 3vh;
    margin-left: 0.5vw;
  }
  .checkboxes__button {
    margin-top: 5vh;
    margin-left: 0.5vw;
  }
}

</style>
