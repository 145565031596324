<template>
  <div class="camera_detail_card">
    <div class="camera_detail_card__clicked_area"
         @click="onClickHandler(projectId, projectViewId)">
      <div class="camera_detail_card__project_name" v-if="projectName">
        {{ projectAndCompanyName }}
      </div>
      <div class="camera_detail_card__camera_name" v-if="cameraName">{{ cameraName }}
        <span v-if="cameraViewStatus"> ({{ $t('messages.finished') }})</span>
      </div>
    </div>
    <camera-picture :image-details="imageDetails" :is-show-date="isShowDate" :like="like"
                    :tags="tags" @wheelZoomChanged="onPicturesZoomChangeHandler"
                    @click="onClickHandler(projectId, projectViewId)"/>
  </div>
</template>

<script>
import CameraPicture from '@/components/v3/Cameras/CameraPicture';
import getDetailUrl from '@/helpers/menu';

export default {
  name: 'CameraDetailCard',
  components: {
    CameraPicture,
  },
  props: {
    imageDetails: {
      type: Object,
      required: true,
    },
    companyName: {
      type: String,
      required: false,
    },
    projectName: {
      type: String,
      required: false,
    },
    cameraName: {
      type: String,
      required: false,
    },
    projectId: {
      type: Number,
      required: false,
    },
    projectViewId: {
      type: Number,
      required: false,
    },
    isShowDate: {
      type: Boolean,
      default: false,
    },
    like: {
      type: Boolean,
      default: true,
    },
    tags: {
      type: Boolean,
      default: true,
    },
    cameraView: {
      type: Object,
    },
  },
  data: () => ({
    allowFollowLink: true,
  }),
  computed: {
    cameraViewStatus() {
      if (this.cameraView && !this.cameraView.active) {
        return true;
      }
      return false;
    },
    projectAndCompanyName() {
      let projectAndCompanyName = this.projectName;
      if (this.companyName) {
        projectAndCompanyName = `${this.companyName} / ${projectAndCompanyName}`;
      }
      return projectAndCompanyName;
    },
    title() {
      return this.projectName
        ? `${this.projectName}(${this.cameraName})` : this.cameraName;
    },
  },
  methods: {
    onClickHandler(projectId, projectViewId) {
      if (!projectId || !projectViewId) {
        return;
      }
      const path = getDetailUrl(projectId, projectViewId);
      if (!path || !this.allowFollowLink) {
        return;
      }
      this.$router.push({ path });
    },
    onPicturesZoomChangeHandler(data) {
      if (data.zoom !== data.min) {
        this.allowFollowLink = false;
        return;
      }
      this.allowFollowLink = true;
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .camera_detail_card {
    display: block;
    width: 100%;
    margin-bottom: 6.5vh;
    text-decoration: none;
    color: inherit;

    @media (orientation: portrait) {
      margin-bottom: 3.5vh;
    }

    &:visited,
    &:active,
    &:hover {
      text-decoration: none;
      color: inherit;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .camera_detail_card__clicked_area {
      cursor: pointer;
      padding-bottom: 2.3vh;
    }

    .camera_detail_card__project_name {
      font-size: 2em;
      margin-bottom: 1.8vh;

      @media screen and (max-width: 900px) {
        font-size: 2.4em;
      }
    }
    .camera_detail_card__camera_name {
      position: relative;
      font-size: 1.4em;
      line-height: 1;
      padding-left: 5vw;

      @media screen and (max-width: 900px) {
        font-size: 2em;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 2px;
        width: 3.9vw;
        height: 1px;
        background-color: $default-blue-ultra-dark-color;
      }
    }

    ::v-deep {
      .camera-picture__picture {
        cursor: pointer;
      }
    }
  }
</style>
