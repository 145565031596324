<template>
  <div class="search">
    <div class="search__placeholder" v-if="isShowPlaceholder" v-html="placeholder"></div>
    <v-autocomplete :single-line="singleLine" v-model="value" dense flat hide-details
                    :hide-no-data="!isDataLoaded" :no-filter="!isDataLoaded" :append-icon="null"
                    :item-text="itemText" :item-value="itemValue" :loading="isLoading"
                    :items="items" @focus="onInputFocusHandler" @blur="onInputBlurHandler"
                    :search-input.sync="inputData"/>
  </div>
</template>

<script>
import { isNull, map, sortBy } from 'lodash';

export default {
  name: 'Search',
  props: {
    placeholder: {
      type: String,
      default: 'Search',
    },
    singleLine: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: 'name',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    backendSearchAction: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      inputData: '',
      value: null,
      isInputInFocus: false,
      isLoading: false,
      items: null,
    };
  },
  watch: {
    value(newValue) {
      this.$emit('change', { value: newValue });
    },
  },
  computed: {
    isDataLoaded() {
      return !isNull(this.items);
    },
    isShowPlaceholder() {
      return this.placeholder && !this.value && !this.isInputInFocus && !this.isInputData;
    },
    isInputData() {
      return this.inputData;
    },
  },
  methods: {
    onInputFocusHandler() {
      this.isInputInFocus = true;
      if (!this.isDataLoaded && !this.isLoading) {
        this.isLoading = true;
        this.backendSearchAction({ no_limit: true }).then((response) => {
          this.items = sortBy(
            map(response.normData),
            (item) => item[this.itemText].toLowerCase(),
          );
        }).catch(() => {
          this.items = [];
        }).finally(() => {
          this.isLoading = false;
        });
      }
    },
    onInputBlurHandler() {
      this.isInputInFocus = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.search::v-deep {
  position: relative;
  font-family: $main-font-family, "Material Design Icons", sans-serif;
  width: 100%;
  margin-bottom: -0.1vh;

  @media (orientation: portrait) {
    margin-bottom: -0.25vh;
  }

  .search__placeholder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  .v-input {
    font-family: $main-font-family, "Material Design Icons", sans-serif;
    font-size: 1em;
    padding-top: 0;

    .v-input__slot:after,
    .v-input__slot:before {
      content: none;
    }

    &.v-text-field {
      margin: 0;
    }

    .v-select__slot {
      color: $default-blue-ultra-dark-color;

      input {
        min-height: 1.8vh;
        padding: 0;
        text-align: center;
        line-height: 1.1;
        color: $default-blue-ultra-dark-color;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $default-blue-ultra-dark-color;
          text-align: center;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $default-blue-ultra-dark-color;
          text-align: center;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $default-blue-ultra-dark-color;
          text-align: center;
        }
      }
    }

    .v-progress-linear {
      transform: translateY(1.3vh);
    }
  }
}
</style>
