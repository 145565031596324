// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    allCompanies: {},
    allProjects: {},
    allProjectViews: {},
    allCameraViews: {},
    allGroups: {},
    allAIModels: {},
  },
  actions,
  mutations,
};
