import Cameras from '@/pages/v3/Cameras';
import Timelapses from '@/pages/v3/Timelapses';
import MyCSite from '@/pages/v3/MyCSite';
import ImagesList from '@/pages/v3/Cameras/CameraDetails';
import Support from '@/components/v3/Profile/Support';
import ImageDetails from '@/pages/v3/Cameras/ImageDetails';
import AI from '@/pages/v3/AI';

// Ordered alphabetically
export const AI_URL = {
  title: 'Insights',
  name: 'insights',
  path: '/insights',
  component: AI,
};

export const AI_AREA_URL = {
  name: 'area',
  path: '/cameras/:projectId/:projectViewId/:imageId(\\d+)/:areaId(\\d+)',
  component: ImageDetails,
};

export const AI_IMAGE_URL = {
  name: 'areas',
  path: '/cameras/:projectId/:projectViewId/:imageId(\\d+)',
  component: ImageDetails,
};

export const CAMERAS_URL = {
  title: 'Cameras',
  name: 'cameras',
  path: '/cameras',
  component: Cameras,
};

export const CAMERA_DETAIL_URL = {
  title: 'Camera #:name',
  name: 'camera',
  path: '/cameras/:projectId/:projectViewId',
  component: ImagesList,
};

export const MY_CSITE_URL = {
  title: 'my_csite_menu.my_csite_title',
  name: 'myCSite',
  path: '/my-csite',
  component: MyCSite,
};

export const SUPPORT_URL = {
  title: 'Support',
  name: 'support',
  path: `${MY_CSITE_URL.path}/profile#support`,
  component: Support,
};

export const TIMELAPSES_URL = {
  title: 'Timelapses',
  name: 'timelapses',
  path: '/timelapses',
  component: Timelapses,
};

const Menu = [CAMERAS_URL, TIMELAPSES_URL];

export const DEFAULT_HOME_URL = {
  route_name: CAMERAS_URL.name,
  route_path: CAMERAS_URL.path,
};

export default Menu;
