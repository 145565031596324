export const STATUS_ERROR = 'error';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_QUEUED = 'queued';
export const STATUS_CONFIGURING = 'configuring';
export const STATUS_STARTING = 'starting';
export const STATUS_CREATING = 'creating';
export const STATUS_SAVING = 'saving';
export const STATUS_FINISHED = 'finished';

const STATUSES_LIST = [
  STATUS_ERROR,
  STATUS_SUBMITTED,
  STATUS_QUEUED,
  STATUS_CONFIGURING,
  STATUS_STARTING,
  STATUS_CREATING,
  STATUS_SAVING,
  STATUS_FINISHED,
];

export default STATUSES_LIST;
