<template>
<div class="map">
  <div class="map__container"
    ref="mapContainerRef">
    <GmapMap
      class="map__main"
      :style="mapDimensions"
      :center="mapCenter"
      :zoom="zoom"
      ref="mapRef"
    >
      <GmapMarker
        class="map__marker"
        :key="index"
        v-for="(marker, index) in markersList"
        :position="marker.position"
        :clickable="true"
        :draggable="false"
        :title="marker.title"
        :icon="marker.icon"
        :label="marker.label"
        @click="onMarkerClickHandler(marker)"
      />
    </GmapMap>
  </div>
  <div class="map__addButton" v-if="!isFullScreen">
    <ButtonSubmit
      v-if="showButton"
      :title="$t('cameras_menu.map_tab.button_add_edit_location')"
      class="addButton__button"
      @click="toggleProjectLocationForm"/>
  </div>
  <ProjectLocationForm
    v-if="showForm"
    :project-list="projects.list"
    @close="toggleProjectLocationForm"
  />
  <ProjectPopup
    v-if="isShowProjectPopup"
    :project-selected="projects.selected"
    :project-views-selected="loadedProjectViews.selected"
    @close="closeProjectPopup"
  />
</div>
</template>

<script>
import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';
import {
  compact, forEach, includes, isEmpty, join, map, sortBy, filter, times, flatMap,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { permissionMixin } from '@/mixins/v3';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import ProjectPopup from '@/components/v3/Cameras/MapView/ProjectPopup';
import ProjectLocationForm from '@/components/v3/Cameras/MapView/ProjectLocationForm';
import PERMISSIONS from '@/constants/v3';

const siteIcon = require('@/assets/images/v3/c-site_logo_pic_only.svg');

const ZOOM_IN = 15;
const DEFAULT_ZOOM = 9;
const DEFAULT_CENTER = { lat: 51.0823564, lng: 3.5744038 };

export default {
  name: 'Map',
  components: { ButtonSubmit, ProjectPopup, ProjectLocationForm },
  mixins: [permissionMixin],
  props: {
    projectId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    googleMapsLoaded() {
      this.googleMap = gmapApi();
    },
    projectId() {
      this.zoom = DEFAULT_ZOOM;
      this.mapCenter = DEFAULT_CENTER;
      if (!this.projectId) {
        this.projects.list = this.projectsState;
        return;
      }
      this.projects.list = filter(
        this.projectsState, (project) => project.id === this.projectId,
      );
      if (!this.projects.list.length || !this.projects.list[0].address.latitude
        || !this.projects.list[0].address.longitude) {
        return;
      }
      this.mapCenter = {
        lat: this.projects.list[0].address.latitude,
        lng: this.projects.list[0].address.longitude,
      };
      this.zoom = ZOOM_IN;
    },
    isFullScreen: {
      handler(value) {
        this.toggleMainLayoutPadding(!value);
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadData();
    this.setMapDimensions();
    Vue.$gmapApiPromiseLazy().then(() => {
      this.googleMapsLoaded = true;
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.$refs.mapRef.$mapPromise.then((mapInstance) => {
        this.googleMapsInstance = mapInstance;
        mapInstance.setOptions(
          {
            styles: [
              { featureType: 'road', stylers: [{ visibility: 'off' }] },
              {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'administrative.locality',
                elementType: 'labels.text',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'administrative.neighborhood',
                elementType: 'labels.text',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.place_of_worship',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.sports_complex',
                stylers: [{ visibility: 'off' }],
              },
            ],
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: true,
            keyboardShortcuts: false,
            mapTypeControlOptions: {
              mapTypeIds: ['satellite', 'terrain'],
            },
          },
        );
        mapInstance.addListener('zoom_changed', this.scaleSize);
      });
    });
  },
  data() {
    return {
      googleMap: null,
      zoom: DEFAULT_ZOOM,
      showForm: false,
      showPopup: false,
      showProjectPopup: false,
      googleMapsLoaded: false,
      googleMapsInstance: null,
      mapCenter: DEFAULT_CENTER,
      mapHeight: 1,
      mapWidth: 1,
      projects: {
        selected: null,
        list: [],
      },
      loadedProjectViews: {
        selected: null,
        list: [],
      },
      siteIcon,
      iconSize: 27,
      labelFont: 1.6,
    };
  },
  computed: {
    isAllowToChangeProject() {
      return includes(this.$_permissionMixin_permissions, PERMISSIONS.change_project);
    },
    isAllowToAddProject() {
      return includes(this.$_permissionMixin_permissions, PERMISSIONS.add_project);
    },
    isAllowToChangeAddress() {
      return includes(this.$_permissionMixin_permissions, PERMISSIONS.change_address);
    },
    isAllowToAddAddress() {
      return includes(this.$_permissionMixin_permissions, PERMISSIONS.add_address);
    },
    showButton() {
      return this.isAllowToChangeProject && this.isAllowToAddProject
        && this.isAllowToChangeAddress && this.isAllowToAddAddress;
    },
    isShowProjectPopup() {
      return this.showPopup && !!this.projects.selected;
    },
    mapDimensions() {
      return {
        width: `${this.mapWidth}px`,
        height: `${this.mapHeight}px`,
      };
    },
    selectedProject() {
      return this.projects.selected;
    },
    selectedProjectView() {
      return this.loadedProjectViews.selected;
    },
    markersList() {
      const projectsWithCoordinates = filter(
        this.projects.list, (project) => !!project.address
          && !!project.address.latitude && !!project.address.longitude,
      );

      const markersList = projectsWithCoordinates.map((project) => {
        const coordinates = {
          lat: Number(project.address.latitude),
          lng: Number(project.address.longitude),
        };

        return {
          position: coordinates,
          title: project.company_project_name,
          icon: {
            labelOrigin: new this.googleMap.maps.Point(0, this.iconSize + 12),
            url: this.siteIcon,
            scaledSize: new this.googleMap.maps.Size(this.iconSize, this.iconSize),
          },
          project,
          label: {
            text: project.name,
            fontSize: `${this.labelFont}em`,
            color: '#043067',
            fontWeight: '800',
          },
        };
      });
      return markersList;
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
      isFullScreen: (state) => state.isFullscreenMode,
      projectsState: (state) => state.projects,
    }),
  },
  methods: {
    toggleProjectLocationForm() {
      this.showForm = !this.showForm;
    },
    closeProjectPopup() {
      this.showPopup = false;
    },
    onResize() {
      this.setMapDimensions();
    },
    setMapDimensions() {
      this.mapHeight = window.innerHeight - this.$refs.mapContainerRef.getBoundingClientRect().top;
      this.mapWidth = this.$refs.mapContainerRef.clientWidth;
    },
    scaleSize() {
      this.zoom = this.googleMapsInstance.getZoom();
      this.iconSize = this.zoom * 3;
      this.labelFont = this.zoom * 0.18;
    },
    onMarkerClickHandler(marker) {
      this.zoom = ZOOM_IN;
      this.mapCenter = marker.position;
      this.projects.selected = marker.project;
      this.loadedProjectViews.selected = filter(
        this.loadedProjectViews.list,
        (projectView) => projectView.project === this.projects.selected.id,
      );
      this.showPopup = true;
    },
    async loadData() {
      if (isEmpty(this.allCompanies)) {
        await this.fetchAllCompanies();
      }
      Promise.all([
        this.fetchProjects({ no_limit: true }).then((response) => {
          const projectsWithCompany = map(response.normData, (project) => {
            const company = this.allCompanies[project.company] || null;
            const projectAndCompany = company ? `${company.name} / ` : '';
            return {
              ...project,
              company_details: company,
              company_project_name: `${projectAndCompany}${project.name}`,
            };
          });
          this.projects = {
            ...this.projects,
            list: sortBy(
              projectsWithCompany,
              (project) => [project.company_project_name.toLowerCase()],
            ),
          };
        }),
        this.fetchProjectViews({ no_limit: true }).then((response) => {
          this.loadedProjectViews = {
            ...this.loadedProjectViews,
            list: sortBy(
              response.normData,
              (projectview) => projectview.name.toLowerCase(),
            ),
          };
        }),
      ]).finally(async () => {
        const imagesIdsToLoad = compact(map(
          this.loadedProjectViews.list,
          ({ latest_image: latestImage = null }) => latestImage,
        ));
        if (imagesIdsToLoad.length) {
          const batchSize = 100;
          const numberOfRequests = Math.ceil(imagesIdsToLoad.length / batchSize);
          const allResponses = await Promise.all(
            times(numberOfRequests, async (index) => {
              const start = index * batchSize;
              const end = Math.min((index + 1) * batchSize, imagesIdsToLoad.length);
              const batchIds = imagesIdsToLoad.slice(start, end);
              const batchResponse = await this.getImages({
                id__in: join(batchIds),
                limit: batchIds.length,
              });
              return batchResponse;
            }),
          );
          const imagesResponse = {
            dataList: flatMap(allResponses, (response) => response.dataList),
            normData: Object.assign({}, ...allResponses.map((response) => response.normData)),
          };
          forEach(this.loadedProjectViews.list, (projectView) => {
            const { latest_image: latestImage = null } = projectView;
            if (!latestImage || !includes(imagesResponse.dataList, latestImage)) {
              return;
            }
            projectView.latest_image_details = imagesResponse.normData[latestImage];
          });
        }
      });
    },
    ...mapActions({
      fetchAllCompanies: 'fetchAllCompanies',
      fetchProjects: 'listProjects',
      fetchProjectViews: 'listProjectviews',
      getImages: 'listImages',
      toggleMainLayoutPadding: 'toggleMainLayoutPadding',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped lang="scss">
.map {
  .map__container {
    margin: 0;
    padding: 0;
    display: flex;

    .map__marker {
      cursor: pointer;
    }
  }
  .map__addButton {
    margin-top: 3vh;
  }
}
</style>
