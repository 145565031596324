<template>
  <div class="projects">
    <Menu class="projects__menu" :menu-list="permissionMenuList"
          :init-active-index="activeIndex" :allow-hash="true" :allow-set-title="true"
          @activeTabChanged="onTabChanged"
          :class="{ projects__menu__hidden: isFullScreen }" />
    <component :is="activeComponent" v-bind="activeComponentProps" v-on="activeComponentEvents"
               v-if="activeComponent" />
  </div>
</template>

<script>
import Menu from '@/components/v3/TabsMenu';
import { mapState } from 'vuex';
import Files from '@/pages/v3/MyCSite/Files';
import PERMISSIONS from '@/constants/v3';
import ProjectsList from '@/components/v3/Projects/ProjectsList';
import { filter } from 'lodash';
import { permissionMixin } from '@/mixins/v3';
import CompanyProjectsUsers from '@/components/v3/Projects/CompanyProjectsUsers';

export default {
  name: 'Projects',
  mixins: [permissionMixin],
  components: {
    Menu,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('my_csite_menu.projects_menu.tabs.list'),
          code: 'list',
          component: ProjectsList,
        },
        {
          name: this.$t('my_csite_menu.projects_menu.tabs.users'),
          code: 'users',
          component: CompanyProjectsUsers,
        },
        {
          name: this.$t('my_csite_menu.projects_menu.tabs.downloads'),
          code: 'downloads',
          component: Files,
          permission: PERMISSIONS.view_ziparchive,
        },
      ],
      activeIndex: 0,
      activeMenuTab: null,
    };
  },
  beforeMount() {
    if (!this.activeMenuTab) {
      [this.activeMenuTab] = this.permissionMenuList;
    }
  },
  watch: {
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
  },
  computed: {
    activeComponent() {
      return this.activeMenuTabForRender?.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    activeComponentEvents() {
      return this.activeMenuTabForRender?.events || {};
    },
    permissionMenuList: {
      get() {
        const filteredResults = filter(
          this.menuList,
          (menuItem) => !menuItem?.permission
            || this.$_permissionMixin_hasPermission(menuItem.permission),
        );
        const newFilteredResults = filter(
          filteredResults,
          (menuItem) => menuItem.code !== 'users' || this.user.company_project_admin,
        );
        return newFilteredResults;
      },
      set(newMenuList) {
        this.menuList = newMenuList;
      },
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    onTabChanged(newTab, newIndex) {
      this.activeIndex = newIndex;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.tabs {
  margin-bottom: 2vh;
}
.projects {
  position: relative;
  width: 100%;
  .projects__menu {
    position: relative;
    width: 100vw;
    left: -$page-global-padding-horizontal;
    padding: 2.1vh $page-global-padding-horizontal;

    @media (orientation: portrait) {
      left: -$page-global-portrait-padding-horizontal;
      padding: 2.1vh $page-global-portrait-padding-horizontal;
    }
    @media screen and (max-width: 900px) {
      font-size: 2.2em;
    }
  }

  .projects__menu__hidden {
    display: none;
  }
}
</style>
