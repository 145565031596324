import {
  fullscreenNotSupportMixin,
  fullscreenBaseMixin,
  fullscreenWebkitMixin,
  fullscreenMSMixin,
} from '../mixinTypes';

export function fullscreenMixinFactory() {
  const {
    requestFullscreen = null,
    webkitRequestFullscreen = null,
    msRequestFullscreen = null,
  } = document.documentElement;

  if (requestFullscreen) {
    return fullscreenBaseMixin;
  }
  if (webkitRequestFullscreen) {
    return fullscreenWebkitMixin;
  }
  if (msRequestFullscreen) {
    return fullscreenMSMixin;
  }
  return fullscreenNotSupportMixin;
}

export default fullscreenMixinFactory;
