<template>
  <div class="language-selector">
    <div  v-for="item in allLocales" :key="item.code"
          :class="{ 'selected': selectedLocale && selectedLocale.code === item.code }"
          @click="setLocale(item.code)">
      {{ item.code.toUpperCase() }}
      <span v-if="item.code === 'nl' || item.code === 'en' || item.code === 'fr'"
            class="vertical-line"></span>
    </div>
  </div>
</template>

<script>
import getSupportedLocales from '@/utils/i18n/supported-locales';

export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      locales: [],
      isLocalesLoaded: false,
      selectedLocale: null,
      menu: false,
    };
  },
  mounted() {
    this.fetchLocales();
    document.addEventListener('click', this.closeMenu);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeMenu);
  },
  computed: {
    activeLocale() {
      return this.locales.find((locale) => locale.code === this.$i18n.locale);
    },
    allLocales() {
      return this.locales.map((locale) => ({
        ...locale,
        captionTranslationCode: 'locale.details.caption_translation_code',
      }));
    },
  },
  methods: {
    closeMenu(event) {
      if (this.menu && !this.$refs.menu.$el.contains(event.target)) {
        this.menu = false;
      }
    },
    fetchLocales() {
      this.locales = getSupportedLocales();
      this.isLocalesLoaded = true;
      this.selectedLocale = this.activeLocale;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.selectedLocale = this.locales.find((loc) => loc.code === locale);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/variables';
@import '~@/sass/mixins/global';

.language-selector {
  display: flex;

  .vertical-line {
    border-right: 1px solid black;
    margin-left: 0.5vw;
  }
  div.selected {
    font-weight: bold;
  }
}

.language-selector div {
  margin-right: 0.5vw;
  cursor: pointer;
}
</style>
