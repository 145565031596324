<template>
  <div class="camera-preloader">
    <span class="camera-preloader__text">{{ $t(text) }}</span>
    <tea-bag-preloader />
  </div>
</template>

<script>
import TeaBagPreloader from '@/components/v3/Helpers/TeaBagPreloader';

export default {
  name: 'CameraPreloader',
  props: {
    text: {
      type: String,
      default: 'messages.loading',
    },
  },
  components: {
    TeaBagPreloader,
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.camera-preloader {
  display: flex;
  flex-flow: nowrap;
  align-items: flex-end;
  justify-content: center;
  font-size: 1.3em;
}
</style>
