<template>
  <form class="auth-form" ref="form" @submit.prevent="submitForm"
        @change="$_authMixin_onFormChange($refs.form)">
    <div class="auth-form__caption">
      {{ $t('auth.titles.reset') }}
    </div>
    <div class="auth-form__inputs">
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="email" type="email" :placeholder="$t('auth.placeholders.email')"
                 v-model="form.email.value" @input="$_authMixin_validate" required />
        </div>
        <label for="email">{{ form.email.error }}</label>
      </div>
    </div>
    <ButtonSubmit :title="$t('auth.buttons.send_code')" @click="submitForm"
                  :is-show-loading="$_authMixin_loading"
                  :is-disabled="$_authMixin_submitDisabledState"
                  class="auth-form__submit_button"/>
    <div class="auth-form__actions">
      <router-link :to="{ name: 'login' }">
        {{ $t('auth.captions.already_have_account') }} {{ $t('auth.buttons.login') }}
      </router-link>
    </div>
    <slot />
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

export default {
  name: 'ResetPassword',
  components: {
    ButtonSubmit,
  },
  mixins: [AuthMixin],
  data() {
    return {
      form: {
        email: {
          value: null,
          error: null,
        },
      },
    };
  },
  methods: {
    submitForm(e) {
      if (!this.$_authMixin_submitForm(e)) {
        return;
      }

      this.forgotPassword(this.form.email.value)
        .then(() => {
          this.$router.push({
            name: 'confirmNewPassword',
            query: { email: this.form.email.value },
          });
        })
        .catch((error) => {
          this.$emit('apiError', { message: error.message });
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
    },
    ...mapActions({
      forgotPassword: 'forgotPassword',
    }),
  },
  beforeDestroy() {
    this.$emit('clearApiError');
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/auth.scss';

</style>
