import LoginPage from '@/pages/v3/Auth/LoginPage';
import RegisterPage from '@/pages/v3/Auth/RegisterPage';
import RegisterConfirmPage from '@/pages/v3/Auth/RegisterConfirmPage';
import ResetPasswordPage from '@/pages/v3/Auth/ResetPasswordPage';
import ConfirmNewPasswordPage from '@/pages/v3/Auth/ConfirmNewPasswordPage';
import AcceptInvitationPage from '@/pages/v3/Auth/AcceptInvitationPage';

const routes = [
  {
    component: LoginPage,
    name: 'login',
    path: 'login',
  },
  {
    component: RegisterPage,
    name: 'register',
    path: 'register',
  },
  {
    component: RegisterConfirmPage,
    name: 'registerConfirm',
    path: 'confirm',
  },
  {
    component: ResetPasswordPage,
    name: 'resetPassword',
    path: 'reset',
  },
  {
    component: ConfirmNewPasswordPage,
    name: 'confirmNewPassword',
    path: 'confirm-password',
  },
  {
    component: AcceptInvitationPage,
    name: 'acceptInvitation',
    path: 'accept-invitation',
  },
];

export default routes;
