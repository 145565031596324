<template>
  <div class="ai_reports">
    <div>
      <CameraPreloader v-if="isLoading"
                       text="files.loading"/>
      <div v-if="!isLoading">
        <div v-for="report in reports" :key="report.id" class="ai_reports_list">
          <div @click="getReport(report.id)" class="ai_reports_list__item">{{ report.name }}</div>
        </div>
        <div id="full_report" v-if="showReport"></div>
      </div>
    </div>
  </div>
</template>

<script>
import * as powerbi from 'powerbi-client';
import axios from 'axios';
import { map } from 'lodash';
import { mapActions } from 'vuex';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';

export default {
  name: 'PowerBiReport',
  components: { CameraPreloader },
  data() {
    return {
      reports: [],
      reportList: [],
      datasets: [],
      groupId: null,
      showReport: false,
      accessToken: null,
      newExpirationTime: null,
      isLoading: false,
    };
  },
  async mounted() {
    await this.getAccessToken();
    this.embedTokenGenerator();
  },
  computed: {
    authHeaders() {
      return {
        Authorization: `Bearer ${this.accessToken}`,
      };
    },
  },
  methods: {
    async getAccessToken() {
      this.isLoading = true;
      await this.fetchPowerbitokens().then((response) => {
        this.accessToken = response.response.data.access_token;
        this.groupId = response.response.data.workspace_id;
        this.newExpirationTime = new Date(Date.now() + response.response.data.expires * 1000);
        const refreshIn = this.newExpirationTime - Date.now() - 10 * 60 * 1000;
        setTimeout(() => {
          this.getAccessToken();
        }, refreshIn);
      });
      this.isLoading = false;
    },
    getReport(id) {
      this.showReport = true;
      const data = { accessLevel: 'View', reports: this.reportList, datasets: this.datasets };
      const url = 'https://api.powerbi.com/v1.0/myorg/GenerateToken';
      axios.post(url, data, { headers: this.authHeaders }).then((response) => {
        const embedToken = response.data.token;
        const { models } = powerbi;
        const permissions = models.Permissions.All;
        const config = {
          type: 'report',
          id,
          tokenType: models.TokenType.Embed,
          accessToken: embedToken,
          embedUrl: 'https://app.powerbi.com/reportEmbed',
          permissions,
        };
        const container = document.getElementById('full_report');
        container.style.height = '100vh';
        const report = window.powerbi.embed(container, config);
        console.log(report);
      });
    },
    async embedTokenGenerator() {
      const reportsUrl = `https://api.powerbi.com/v1.0/myorg/groups/${this.groupId}/reports`;
      const reportResponse = await axios.get(reportsUrl, { headers: this.authHeaders });
      this.reports = reportResponse.data.value;
      this.reportList = map(reportResponse.data.value, (report) => ({ id: report.id }));

      const datasetsUrl = `https://api.powerbi.com/v1.0/myorg/groups/${this.groupId}/datasets`;
      const datasetResponse = await axios.get(datasetsUrl, { headers: this.authHeaders });
      this.datasets = map(datasetResponse.data.value, (dataset) => ({ id: dataset.id }));
    },
    ...mapActions({
      fetchPowerbitokens: 'listPowerbitokens',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.ai_reports {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }
  .ai_reports_list {
    .ai_reports_list__item {
      cursor: pointer;
      border: 1px solid $default-blue-dark-color;
      padding: 1vh;
      margin: 1vh 2vw;
      display: inline-block;
    }
  }
}
</style>
