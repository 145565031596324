<template>
  <div class="email_settings_page">
    <v-alert :type="message.type" :value="message.isShow">
      {{ message.text }}
    </v-alert>
    <form class="change-settings-form" ref="form"
          @submit.prevent="submitForm"
          @change="$_authMixin_onFormChange($refs.form)">
      <div class="activity_settings">
        <div class="settings_title">
          {{ $t('my_csite_menu.settings_menu.preferences.titles.activity_mail')}}
        </div>
        <p class="change-settings-form__text">
          {{ $t('my_csite_menu.settings_menu.preferences.text')}}
        </p>
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="daily_update"
              :checked="currentData.daily_email_updates"
              @change="currentData.daily_email_updates = !currentData.daily_email_updates">
            <label for="daily_update" />
            <div class="checkboxes__checkbox__title">
              {{ $t('my_csite_menu.settings_menu.preferences.checkboxes.daily_update')}}
            </div>
          </div>
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="weekly_update"
              :checked="currentData.weekly_email_updates"
              @change="currentData.weekly_email_updates = !currentData.weekly_email_updates">
            <label for="weekly_update" />
            <div class="checkboxes__checkbox__title">
              {{ $t('my_csite_menu.settings_menu.preferences.checkboxes.weekly_update')}}
            </div>
          </div>
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="monthly_update"
              :checked="currentData.monthly_email_updates"
              @change="currentData.monthly_email_updates = !currentData.monthly_email_updates">
            <label for="monthly_update" />
            <div class="checkboxes__checkbox__title">
              {{ $t('my_csite_menu.settings_menu.preferences.checkboxes.monthly_update')}}
            </div>
          </div>
        </div>
      </div>
      <div class="notification_settings">
        <div class="settings_title">
          {{ $t('my_csite_menu.settings_menu.preferences.titles.notification_mail')}}
        </div>
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="monthly_update"
              :checked="currentData.notification_emails"
              @change="currentData.notification_emails = !currentData.notification_emails">
            <label for="monthly_update" />
            <div class="checkboxes__checkbox__title">
              {{ $t('my_csite_menu.settings_menu.preferences.checkboxes.notification')}}
            </div>
          </div>
        </div>
      </div>
      <ButtonSubmit :title="$t('buttons.save_changes')"
                    @click="submitForm"
                    :is-show-loading="$_authMixin_loading"
                    :is-disabled="$_authMixin_submitDisabledState"
                    class="button_submit"/>
    </form>
  </div>
</template>

<script>
import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Emails',
  components: {
    ButtonSubmit,
  },
  mixins: [AuthMixin],
  data() {
    return {
      message: {
        isShow: false,
        type: 'error',
        text: null,
      },
      emailUser: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.activeUser,
    }),
    currentData() {
      return {
        notification_emails: this.user.notification_emails,
        weekly_email_updates: this.user.weekly_email_updates,
        monthly_email_updates: this.user.monthly_email_updates,
        daily_email_updates: this.user.daily_email_updates,
      };
    },
  },

  methods: {
    ...mapActions({
      setActiveUser: 'setActiveUser',
    }),
    async fetchUserData() {
      this.emailUser = await this.$store.dispatch('retrieveUsers', this.user.id);
    },
    submitForm(e) {
      this.$_authMixin_submitForm(e);
      const data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        weekly_email_updates: this.currentData.weekly_email_updates,
        monthly_email_updates: this.currentData.monthly_email_updates,
        daily_email_updates: this.currentData.daily_email_updates,
        notification_emails: this.currentData.notification_emails,
        id: this.user.id,
        email: this.user.email,
      };
      this.$store
        .dispatch('updateUsers', { id: this.user.id, data })
        .then(async () => {
          this.$store.dispatch('getCurrentAuthenticatedUser', { bypassCache: true });
          await this.fetchUserData();
          this.setActiveUser(this.emailUser);
          this.message = {
            isShow: true,
            type: 'success',
            text: this.$t('my_csite_menu.settings_menu.preferences.settings.success'),
          };
          setTimeout(() => {
            this.message = {
              isShow: false,
              type: 'error',
              text: this.$t('my_csite_menu.settings_menu.preferences.settings.error'),
            };
          }, 3000);
        })
        .catch(() => {
          this.message = {
            isShow: true,
            type: 'error',
            text: this.$t('my_csite_menu.settings_menu.preferences.settings.error'),
          };
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/v3/cSiteIcons.css';
@import '~@/sass/mixins/v3/settings.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.email_settings_page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }
  .notification_settings {
    margin-bottom: 5vh;
    margin-top: 3.6vh;
  }
}
</style>
