import { TIMEOUT_BEFORE_UPDATE_USER_FRONTEND_SETTINGS } from '@/constants/dashboard';
import { mapActions, mapState } from 'vuex';

export const updateUserFrontendSettingsMixin = {
  data: () => ({
    $_updateUserFrontendSettingsMixin: {
      updateUserFrontendSettingsTimeoutId: null,
      error: {
        isActive: false,
        text: null,
      },
    },
  }),
  computed: {
    $_updateUserFrontendSettingsMixin_FrontendSettings() {
      return this.user.frontend_settings || {};
    },
    ...mapState({
      user: (state) => state.dashboard.activeUser,
    }),
  },
  methods: {
    $_updateUserFrontendSettingsMixin_updateUserFrontendSettings(
      key, data, timeout = TIMEOUT_BEFORE_UPDATE_USER_FRONTEND_SETTINGS,
    ) {
      const mixinData = this.$data.$_updateUserFrontendSettingsMixin;
      clearTimeout(mixinData.updateUserFrontendSettingsTimeoutId);
      mixinData.updateUserFrontendSettingsTimeoutId = setTimeout(
        () => this.updateActiveUserFrontendSettings({ key, data })
          .catch((error) => {
            this.$data.$_updateUserFrontendSettingsMixin.error = {
              isActive: true,
              text: error.message,
            };
          }),
        timeout,
      );
    },
    ...mapActions({
      updateActiveUserFrontendSettings: 'updateActiveUserFrontendSettings',
    }),
  },
};

export default updateUserFrontendSettingsMixin;
