import MENU_LIST, { LAYOUT_URL } from '@/constants/menu';
import { mapActions, mapState } from 'vuex';

export const primaryMenuMixin = {
  mounted() {
    if (this.$route.name === LAYOUT_URL.name) {
      return;
    }
    this.activateButton();
  },
  watch: {
    $route() {
      if (this.isHomeButtonClicked) {
        this.setActiveMenu(null);
        return;
      }
      this.activateButton();
    },
  },
  computed: {
    ...mapState({
      primaryActiveButton: (state) => state.activeMenu,
      isHomeButtonClicked: (state) => state.dashboard.homeButtonClicked,
    }),
    activeMenuKey() {
      return Object.keys(MENU_LIST).find(
        (key) => MENU_LIST[key].route_name === this.$route.name,
      );
    },
  },
  methods: {
    activateButton() {
      const activeButton = Object.keys(this.buttons)
        .find((key) => this.$route.name === MENU_LIST[key].route_name);
      if (activeButton) {
        this.setActiveMenu(activeButton);
      }
    },
    buttonClickHandler(key) {
      this.homeButtonClick(false);
      let currentKey = key;
      if (key === this.primaryActiveButton && MENU_LIST[key].has_submenu) {
        this.setActiveMenu(null);
        return;
      }
      if (currentKey === this.primaryActiveButton) {
        if (!MENU_LIST[this.primaryActiveButton].route_name) {
          currentKey = null;
        }
      }
      this.setActiveMenu(currentKey).then(() => {
        if (MENU_LIST[key].route_name && this.$route.name !== MENU_LIST[key].route_name) {
          this.$router.push({ name: MENU_LIST[key].route_name }).catch();
        }
      });
    },
    getImgUrl(type) {
      const images = require.context('@/assets/images/dashboardNg/', false, /\.png$/);
      return images(`./${type}.png`);
    },
    ...mapActions({
      setActiveMenu: 'setActiveMenu',
      homeButtonClick: 'changeHomeButtonClicked',
    }),
  },
};

export default primaryMenuMixin;
