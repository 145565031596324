<template>
  <div class="camera-time-travel-ai-panel">
    <div class="checkboxes">
      <div class="checkboxes__checkbox" >
        <input
          type="checkbox"
          id="show_labels"
          :checked="isShowLabels"
          @change="isShowLabels = !isShowLabels">
        <label for="show_labels" />
        <div class="checkboxes__checkbox__title">
          {{ $t('ai.checkboxes.show_labels')}}
        </div>
      </div>
      <div class="checkboxes__checkbox" >
        <input
          type="checkbox"
          id="show_boxes"
          :checked="isShowBoxes"
          @change="isShowBoxes = !isShowBoxes">
        <label for="show_boxes" />
        <div class="checkboxes__checkbox__title">
          {{ $t('ai.checkboxes.show_bounding_boxes')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const SHOW_BOXES_TOGGLE_EVENT = 'showBoxesChanged';
const SHOW_LABELS_TOGGLE_EVENT = 'showLabelsChanged';

export default {
  name: 'CameraTimeTravelAIPanel',
  emits: [SHOW_BOXES_TOGGLE_EVENT, SHOW_LABELS_TOGGLE_EVENT],
  data: () => ({
    isShowBoxes: false,
    isShowLabels: false,
  }),
  watch: {
    isShowBoxes: {
      handler(value) {
        this.$emit(SHOW_BOXES_TOGGLE_EVENT, { value });
      },
      immediate: true,
    },
    isShowLabels: {
      handler(value) {
        this.$emit(SHOW_LABELS_TOGGLE_EVENT, { value });
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/checkboxes.scss';

.camera-time-travel-ai-panel {
  font-size: 0.8em;

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  .checkboxes {
    font-size: 0.8em;
    padding-top: 1vw;
    padding-right: 1vw;

    .checkboxes__checkbox__title {
      margin-left: 1vw;
    }
  }
}

</style>
