import SwiperCore, { Swiper, EffectFade } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([EffectFade]);

export const defaultSwiperPaginationClass = 'swiper-pagination';

export const swiperMixin = {
  data: () => ({
    $_swiperMixin: {
      isInitialized: false,
      instance: {},
      swiperClass: 'swiper-container',
      swiperPaginationClass: defaultSwiperPaginationClass,
      swiperPaginationTitles: [],
      swiperOptions: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      },
      on: {},
    },
  }),
  mounted() {
    if (!this.$_swiperMixin_swiper.isInitialized) {
      this.$_swiperMixin_initSwiper();
    }
  },
  computed: {
    $_swiperMixin_swiper: {
      get() {
        return this.$data.$_swiperMixin;
      },
      set(newValue) {
        this.$data.$_swiperMixin = {
          ...this.$data.$_swiperMixin,
          ...newValue,
        };
      },
    },
    $_swiperMixin_active_slide() {
      return this.$data.$_swiperMixin.instance.activeIndex;
    },
  },
  methods: {
    $_swiperMixin_initSwiper() {
      if (this.$_swiperMixin_swiper.isInitialized) {
        return;
      }

      this.$_swiperMixin_swiper = {
        isInitialized: true,
        instance: new Swiper(`.${this.$_swiperMixin_swiper.swiperClass}`,
          {
            ...this.$_swiperMixin_swiper.swiperOptions,
            on: {
              slideChange: this.$_swiperMixin_onSlideChange,
              ...this.$_swiperMixin_swiper.on,
            },
          }),
      };
    },
    $_swiperMixin_destroySwiper() {
      if (!this.$_swiperMixin_swiper.isInitialized) {
        return;
      }

      this.$_swiperMixin_swiper.instance.destroy(false, false);
      this.$_swiperMixin_swiper = { isInitialized: false };
    },
    /* Methods could be overwritten in the component */
    $_swiperMixin_onSlideChange() { },
    /* * */
  },
  beforeDestroy() {
    this.$_swiperMixin_destroySwiper();
  },
};

export default swiperMixin;
