import axios from 'axios';
import axiosRetry from 'axios-retry';
import Auth from '@aws-amplify/auth';
// eslint-disable-next-line import/no-cycle
import Store from '../../store/index';

const instance = axios.create({
  baseURL: process.env.VUE_APP_CSITEAPI_URL || 'https://c-site.asoreco.com/',
});

axiosRetry(instance, { retries: 3 });

instance.interceptors.request.use((config) => {
  // Add latest auth access token to every http request
  const newConfig = config;
  return Auth.currentSession()
    .then((session) => {
      // User is logged in. Set auth header on all requests
      newConfig.headers.Authorization = `Bearer ${session.accessToken.jwtToken}`;
      return Promise.resolve(newConfig);
    })
    .catch(() => (
      // No logged-in user: don't set auth header
      Promise.resolve(config)
    ));
});

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status !== 400) {
    Store.dispatch('setAjaxError', error);
  }

  if (error.response.data && error.response.data.detail === 'Signature has expired.') {
    localStorage.removeItem('CognitoIdentityServiceProvider.6k2qi9ld1m6e39jdleq3pm8fn0.cb9db33d-58e3-4ea1-ab3c-00b63f34e62f.accessToken');
    window.location = '/auth/login';
  }
  throw error;
});
export default instance;
