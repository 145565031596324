import moment from 'moment-timezone';

export default {
  sum(a, b) {
    return a + b;
  },
  async getImageMetaFromURL(imageURL) {
    /*
    Receives a url and returns a prmise with a javascript image object as param
    Use:
    getImageMetFromUrl(myURL).then((img) => {...})
    */
    const getMeta = (url) => new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject();
      img.src = url;
    });

    const img = await getMeta(imageURL);
    return { width: img.width, height: img.height };
  },
  iSortObjectsListByNumber({ list, key, reverse = false }) {
    /*
    Sort a list of objects by value
    import utils from '@/utils';
    */
    const compareFunction = (a, b) => (reverse ? b[key] - a[key] : a[key] - b[key]);
    return list.sort(compareFunction);
  },
  iSortObjectsList({ list, key }) {
    /*
    Sort a list of objects by value
    import utils from '@/utils';
    */
    const compareFunction = (a, b) => {
      const aLower = a[key].toString().toLowerCase();
      const bLower = b[key].toString().toLowerCase();
      let result = 0;
      if (aLower < bLower) result = -1;
      else if (bLower < aLower) result = 1;
      return result;
    };
    return list.sort(compareFunction);
  },
  sortObjectListByDateString({ list, key }) {
    /*
    Sort a list of objects by value
    import utils from '@/utils';
    */
    const compareFunction = (a, b) => {
      const aLower = moment(a[key]);
      const bLower = moment(b[key]);
      let result = 0;
      if (aLower < bLower) result = -1;
      else if (bLower < aLower) result = 1;
      return result;
    };
    return list.sort(compareFunction);
  },
  sortObjectByKeys(o) {
    // eslint-disable-next-line no-return-assign,no-param-reassign,no-sequences
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
  },
  toDataURL(url) {
    return fetch(url).then((response) => (
      response.blob()
    )).then((blob) => (
      URL.createObjectURL(blob)
    ));
  },
  async downloadFile({ url, name }) {
    const a = document.createElement('a');
    a.href = await this.toDataURL(url);
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  downloadBase64File(file, name) {
    const a = document.createElement('a');
    a.href = `data:application/octet-stream;base64,${file}`;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  isArrayEquals: (a, b) => a.length === b.length && a.every((v, i) => v === b[i]),
};
