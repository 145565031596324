export const fullscreenMSMixin = {
  data: () => ({
    $_fullscreenMixin: {
      eventName: 'MSFullscreenChange',
    },
  }),
  methods: {
    $_fullscreenMixin_exitFullScreenClickHandler() {
      if (!this.isFullScreen || !document.msExitFullscreen) {
        return;
      }

      this.toggleFullscreen(false);
      document.msExitFullscreen();
    },
    $_fullscreenMixin_fullScreenClickHandler() {
      this.toggleFullscreen(true);
      document.documentElement.msRequestFullscreen();
    },
    $_fullscreenMixin_onFullScreenChangeHandler() {
      this.toggleFullscreen(!!document.msFullscreenElement);
    },
  },
};

export default fullscreenMSMixin;
