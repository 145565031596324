<template>
  <div class="projects__page">
    <div class="projects__content">
      <CameraPreloader v-if="isLoading"
                       text="files.loading"/>
      <div v-if="!isLoading" class="projects__list_wrapper">
        <div  v-for="(project, index) in totalProjectList" :key="index"
             class="projects__list">
          <div class="projects__list__project">
            <div class="projects_list__project__name">
              {{ getCompanyName(project.company)  }} / {{ project.name }}
              <span v-if="project.active">
                ({{ $t('my_csite_menu.projects_menu.list.active')}})
              </span>
              <span v-else-if="!project.active && project.is_archived">
                ({{ $t('my_csite_menu.projects_menu.list.archived')}})
              </span>
              <span v-else>
                ({{ $t('my_csite_menu.projects_menu.list.finished')}})
              </span>
            </div>
            <div class="projects_list__project__camera_views">
              <div v-for="cameraview in project.cameraviews" :key="cameraview.id"
                   class="row projects_list__project__camera_views_loop">
                <div class="col-md-6 projects_list__project__camera_views__name">
                  {{ cameraview.name }}</div>
                <div class="col-md-3 projects_list__project__camera_views__state">
                  <div
                    v-if="cameraview.active === true" class="camera_active">
                    {{ $t('my_csite_menu.projects_menu.list.active')}}
                  </div>
                  <div
                    v-else-if="cameraview.active === false && !cameraview.archived"
                    class="camera_not_active">
                    {{ $t('my_csite_menu.projects_menu.list.finished')}}
                  </div>
                  <div v-else-if="cameraview.archived" class="camera_not_active">
                    {{ $t('my_csite_menu.projects_menu.list.available')}}
                  </div>
                </div>
                <div class="col-md-3 projects_list__project__camera_views__link">
                  <div v-if="cameraview.archived === true">
                    <div class="camera_archived">
                      <router-link :to="{ path: MY_CSITE_URL.path + '/profile', hash: 'support' }"
                                   class="camera_archived_link"
                                   @click.native="navigateToSupport">
                        {{ $t('my_csite_menu.projects_menu.list.request')}}
                      </router-link>
                    </div>

                  </div>
                  <div v-else-if="cameraview.archived === false">
                    <div @click="navigateToCamera(project.id, cameraview.projectview)"
                         v-if="cameraview.projectview" class="camera_not_archived">
                      {{ $t('my_csite_menu.projects_menu.list.go_to_images')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { projectViewsMixin } from '@/mixins/v3';
import { filter, isEmpty, map } from 'lodash';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import { MY_CSITE_URL } from '@/constants/v3/menu';

export default {
  name: 'Projects',
  components: { CameraPreloader },
  mixins: [
    projectViewsMixin,
  ],
  data: () => ({
    isLoading: false,
    totalProjectList: [],
    projectViewId: null,
    MY_CSITE_URL,
  }),
  async beforeMount() {
    if (isEmpty(this.allCompanies)) {
      await this.fetchAllCompanies();
    }
  },
  mounted() {
    this.initData();
  },
  computed: {
    filteredProjects() {
      if (!this.selectedCompany) {
        return this.totalProjectList;
      }
      return this.totalProjectList.filter((project) => project.company === this.selectedCompany);
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
      allCameraViews: (state) => state.extra.allCameraViews,
      allProjects: (state) => state.extra.allProjects,
      allProjectViews: (state) => state.extra.allProjectViews,
      user: (state) => state.activeUser,
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    navigateToSupport() {
      this.$router.push(
        { path: `${this.MY_CSITE_URL.path}/profile`, hash: 'support' },
      ).catch(() => {});
    },
    navigateToCamera(projectId, projectViewId) {
      if (projectViewId) {
        this.$router.push({ name: 'camera', params: { projectId, projectViewId } }).catch(() => {});
      }
    },
    getCompanyName(projectCompany) {
      const companyObj = this.allCompanies[projectCompany];
      return companyObj.name;
    },
    async initData() {
      this.isLoading = true;
      try {
        if (isEmpty(this.allProjects) || isEmpty(this.allCameraViews)) {
          await this.fetchAllProjects();
          await this.fetchAllCameraViews();
          await this.fetchAllProjectViews();
        }
        this.totalProjectList = map(this.allProjects, (project) => {
          project.new_id = project.id;
          project.cameraviews = filter(this.allCameraViews, (cameraview) =>
            cameraview.project === project.id);
          map(project.cameraviews, (cameraview) => {
            const correctProjectView = filter(this.allProjectViews, (projectview) =>
              cameraview.projectview === projectview.id);
            cameraview.latest_image = correctProjectView.length ? correctProjectView[0].latest_image
              : null;
          });
          return project;
        });

        this.totalProjectList.sort((a, b) => {
          if (a.active && !b.active) {
            return -1;
          }
          if (!a.active && b.active) {
            return 1;
          }
          if (!a.active && !b.active) {
            return a.is_archived - b.is_archived;
          }
          return 0;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    ...mapActions({
      fetchAllCompanies: 'fetchAllCompanies',
      fetchAllCameraViews: 'fetchAllCameraViews',
      fetchAllProjects: 'fetchAllProjects',
      fetchAllProjectViews: 'fetchAllProjectViews',
    }),
  },
};
</script>
<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.projects__page {
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;

  @media (orientation: portrait) {
    width: 100%;
  }

  .projects__content {
    flex: 1;
    display: inline-block;
    .projects__list_wrapper {
      margin-top: 5vh;
      .projects__list {
        .projects__list__project {
          margin-top: 3vh;
          margin-bottom: 3vh;
          .projects_list__project__name {
            font-size: 1.2em;
            font-weight: 600;
            margin-bottom: 5vh;
            margin-top: 1vh;
          }
          .projects_list__project__camera_views {
            margin-top: 3vh;
            margin-bottom: 5vh;
            margin-left: 2vw;
            .projects_list__project__camera_views_loop {
              align-items: center;
              .projects_list__project__camera_views__name {
                padding-top: 0;
                padding-right: 0;
                text-align: left;
                padding-left: 0;
              }
              .projects_list__project__camera_views__state {
                padding-top: 0;
                padding-right: 0;
                text-align: center;
              }
              .projects_list__project__camera_views__link {
                text-align: center;
                .camera_archived {
                  align-items: center;
                  color: inherit;

                  margin-bottom: 0.7vh;
                  padding: 0.5vh;
                  border: 1px solid $default-blue-dark-color;
                  .camera_archived_link {
                    text-decoration: none;
                    color: inherit;
                  }
                }
                .camera_not_archived {
                  margin-bottom: 0.7vh;
                  padding: 0.5vh;
                  border: 1px solid $default-blue-dark-color;
                  align-items: center;
                  color: inherit;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
