import Vue from 'vue';
import Router from 'vue-router';

// Layout
import Auth from '@/layouts/v3/Auth';
import MainLayoutV3 from '@/layouts/v3';

// Pages v3
import {
  CAMERAS_URL,
  CAMERA_DETAIL_URL,
  TIMELAPSES_URL,
  MY_CSITE_URL,
  AI_IMAGE_URL,
  AI_AREA_URL,
  AI_URL,
  SUPPORT_URL,
} from '@/constants/v3/menu';

// * * * //
import Support from '@/components/v3/Profile/Support';
import Profile from '@/components/v3/Profile/index';
import LikesTags from '@/components/v3/LikesTags/index';
import Projects from '@/components/v3/Projects/index';
import store from '@/store';
import authRoutes from './auth';
import NotFound from '../pages/404';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      component: MainLayoutV3,
      redirect: { name: CAMERAS_URL.name },
      meta: {
        title: 'C-SITE v3',
        requiresAuth: true,
      },
      name: 'v3',
      path: '/',
      children: [
        {
          name: 'notFound',
          component: NotFound,
          path: '404',
        },
        {
          component: CAMERAS_URL.component,
          meta: {
            title: `C-SITE | ${CAMERAS_URL.title}`,
          },
          name: CAMERAS_URL.name,
          path: CAMERAS_URL.path,
        },
        {
          path: CAMERA_DETAIL_URL.path,
          component: CAMERA_DETAIL_URL.component,
          name: CAMERA_DETAIL_URL.name,
          meta: {
            title: `C-SITE | ${CAMERA_DETAIL_URL.title}`,
            parentMenuName: CAMERAS_URL.name,
          },
          beforeEnter: async (to, from, next) => {
            const { projectId } = to.params;
            const { projectViewId } = to.params;

            if (!Number(projectId) || !Number(projectViewId)) {
              next({ name: 'notFound' });
              return;
            }
            await store.dispatch('listProjectviews', { project: projectId }).then((response) => {
              if (response.dataList.includes(Number(projectViewId))) {
                next();
              } else {
                next({ name: 'notFound' });
              }
            }).catch(() => {
              next({ name: 'notFound' });
            });
          },
        },
        {
          component: TIMELAPSES_URL.component,
          meta: {
            title: `C-SITE | ${TIMELAPSES_URL.title}`,
          },
          name: TIMELAPSES_URL.name,
          path: TIMELAPSES_URL.path,
        },
        {
          component: MY_CSITE_URL.component,
          meta: {
            title: 'C-SITE | My C-Site',
          },
          name: MY_CSITE_URL.name,
          path: MY_CSITE_URL.path,
          children: [
            {
              path: 'profile',
              name: 'profile',
              component: Profile,
            },
            {
              path: 'likes-and-tags',
              name: 'likes-and-tags',
              component: LikesTags,
            },
            {
              path: 'projects',
              name: 'projects',
              component: Projects,
            },
          ],
        },
        {
          name: SUPPORT_URL.name,
          path: SUPPORT_URL.path,
          component: Support,
        },
        {
          component: AI_URL.component,
          meta: {
            title: `C-SITE | ${AI_URL.title}`,
            requiresAuth: true,
          },
          name: AI_URL.name,
          path: AI_URL.path,
          beforeEnter: async (to, from, next) => {
            const isAllowed = await store.dispatch('getCurrentAuthenticatedUser').then((result) =>
              store.dispatch('listUsers',
                { username_in: result.username }).then((data) =>
                data.normData[result.username].permissions.includes('view_imageareaidentifier')));
            if (isAllowed) {
              next();
            } else {
              next({ name: 'notFound' });
            }
          },
        },
        {
          path: AI_IMAGE_URL.path,
          name: AI_IMAGE_URL.name,
          component: AI_IMAGE_URL.component,
          beforeEnter: async (to, from, next) => {
            const { projectId, projectViewId, imageId } = to.params;
            const isAllowed = await store.dispatch('getCurrentAuthenticatedUser').then((result) =>
              store.dispatch('listUsers',
                { username_in: result.username }).then((data) =>
                data.normData[result.username].permissions.includes('view_imageareaidentifier')));
            await store.dispatch('listImages', { id__in: imageId }).then((response) => {
              if (response.normData[imageId].project === Number(projectId)
                && response.normData[imageId].projectview === Number(projectViewId)
                && isAllowed
              ) {
                next();
              } else {
                next({ path: '*' });
              }
            }).catch(() => {
              next({ path: '*' });
            });
          },
          children: [
            {
              path: AI_AREA_URL.path,
              name: AI_AREA_URL.name,
              component: AI_AREA_URL.component,
            },
          ],
        },
      ],
    },
    {
      path: '/auth',
      name: 'auth',
      meta: {
        unauthorizedOnly: true,
      },
      redirect: { name: 'login' },
      component: Auth,
      children: authRoutes,
    },
    {
      redirect: { name: 'notFound' },
      path: '*',
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((route) => route.meta?.requiresAuth)) {
    // Route is protected
    store.dispatch('getCurrentAuthenticatedUser')
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          name: 'login',
          params: { nextUrl: to.fullPath },
        });
      });
    return;
  }
  if (to.matched.some((route) => route.meta?.unauthorizedOnly)) {
    // Route is protected
    store.dispatch('getCurrentAuthenticatedUser')
      .then(() => {
        next({
          name: 'v3',
        });
      })
      .catch(() => {
        next();
      });
    return;
  }

  // Route is not protected
  next();
});

export default router;
