export const SHARING = 'SHARING';
export const CAMERAS = 'CAMERAS';
export const TIMELAPSES = 'TIMELAPSES';
export const SUPPORT = 'SUPPORT';
export const SETTINGS = 'SETTINGS';

export const MENU_BUTTONS = {
  sharing: SHARING,
  cameras: CAMERAS,
  timelapses: TIMELAPSES,
  support: SUPPORT,
  settings: SETTINGS,
};
