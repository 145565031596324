<template>
  <div class="timelapses">
    <ExitFullscreen />
    <Menu :class="{ cameras__menu__hidden: isFullScreen }" :menu-list="permissionMenuList"
          :actions-list="actionsList" @menuHashInit="onTabChanged" @activeTabChanged="onTabChanged"
          class="timelapses__menu__mobile"
    />
    <component :is="activeComponent" v-bind="activeComponentProps" v-if="activeComponent" />
  </div>
</template>

<script>
import Timelapses from '@/components/v3/Timelapses';
import MakeOwnTimelapse from '@/components/v3/Timelapses/MakeOwnTimelapse/index';
import Menu from '@/components/v3/TabsMenu';
import Search from '@/components/v3/Helpers/Menu/Search';
import Fullscreen from '@/components/v3/Helpers/Menu/Fullscreen';
import { mapActions, mapState } from 'vuex';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import {
  CATEGORY_DAILY, CATEGORY_WEEKLY, CATEGORY_MONTHLY, CATEGORY_STP,
} from '@/constants/v3/timelapses';
import {
  filter, some, map, isEmpty, forEach, find,
} from 'lodash';
import ExitFullscreen from '@/components/v3/Helpers/ExitFullscreen';

export default {
  name: 'Timelapses',
  mixins: [permissionMixin],
  components: {
    ExitFullscreen,
    Menu,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('timelapses_menu.tabs.daily'),
          code: 'daily',
          component: Timelapses,
          props: { timelapseCategory: CATEGORY_DAILY },
          permissions: [PERMISSIONS.view_daily_timelapse],
        },
        {
          name: this.$t('timelapses_menu.tabs.weekly'),
          code: 'weekly',
          component: Timelapses,
          props: { timelapseCategory: CATEGORY_WEEKLY },
          permissions: [PERMISSIONS.view_weekly_timelapse],
        },
        {
          name: this.$t('timelapses_menu.tabs.monthly'),
          code: 'monthly',
          component: Timelapses,
          props: { timelapseCategory: CATEGORY_MONTHLY },
          permissions: [PERMISSIONS.view_monthly_timelapse],
        },
        {
          name: this.$t('timelapses_menu.tabs.stp'),
          code: 'stp',
          component: Timelapses,
          props: { timelapseCategory: CATEGORY_STP },
          permissions: [PERMISSIONS.view_stp_timelapse],
        },
        {
          name: this.$t('timelapses_menu.tabs.custom'),
          code: 'custom',
          component: MakeOwnTimelapse,
          permissions: [
            PERMISSIONS.view_custom_timelapse,
            PERMISSIONS.add_custom_timelapse,
            PERMISSIONS.add_logo,
            PERMISSIONS.delete_logo,
          ],
        },
      ],
      actionsList: [
        {
          name: this.$t('cameras_menu.buttons.search'),
          code: 'search',
          component: Search,
          props: {
            placeholder: this.$t('cameras_menu.buttons.search_glass'),
            backendSearchAction: this.getProjectsWithCompany,
            itemText: 'search_title',
          },
          events: {
            change: this.onSearchChange,
          },
        },
        {
          name: this.$t('cameras_menu.buttons.fullscreen'),
          code: 'fullscreen',
          component: Fullscreen,
        },
      ],
      searchByProjectId: null,
      activeMenuTab: null,
    };
  },
  computed: {
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(
            menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission),
          )),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || {};
    },
    activeComponent() {
      return this.activeMenuTabForRender.component;
    },
    activeComponentProps() {
      const props = this.activeMenuTabForRender?.props || {};
      props.searchBy = this.searchByProjectId;
      return props;
    },
    ...mapState({
      allCompanies: (state) => state.extra.allCompanies,
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    onTabChanged(newTab) {
      this.activeMenuTab = newTab;
    },
    onSearchChange(e) {
      this.searchByProjectId = e.value;
    },
    getProjectsWithCompany(options) {
      return new Promise((resolve, reject) => {
        this.fetchProjects(options).then(async (response) => {
          if (isEmpty(this.allCompanies)) {
            await this.fetchAllCompanies();
          }
          forEach(response.normData, (project, id) => {
            const projectCompany = find(
              this.allCompanies,
              (company) => company.id === project.company,
            );
            const searchTitleCompany = projectCompany ? `${projectCompany.name} / ` : '';
            response.normData[id].company_details = projectCompany || null;
            response.normData[id].search_title = `${searchTitleCompany}${project.name}`;
          });
          resolve(response);
        }).catch(reject);
      });
    },
    ...mapActions({
      fetchAllCompanies: 'fetchAllCompanies',
      fetchProjects: 'listProjects',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

  .timelapses {
    width: 100%;

    .cameras__menu__hidden {
      display: none;
    }
    .timelapses__menu__mobile {
      @media screen and (max-width: 900px) {
        font-size: 2.2em;
      }
    }
  }
</style>
