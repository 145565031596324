import {
  filter, includes, map, some,
} from 'lodash';
import { mapState } from 'vuex';

export const permissionMixin = {
  data() {
    return {
      activeMenuTab: null,
    };
  },
  beforeMount() {
    if (!this.activeMenuTab) {
      [this.activeMenuTab] = this.permissionMenuList;
    }
  },
  watch: {
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
  },
  computed: {
    $_permissionMixin_isAdmin() {
      return this.$_permissionMixin_user.is_staff || false;
    },
    $_permissionMixin_permissions() {
      return this.$_permissionMixin_user.permissions || [];
    },
    activeComponent() {
      return this.activeMenuTabForRender?.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    activeComponentEvents() {
      return this.activeMenuTabForRender?.events || {};
    },
    permissionMenuList() {
      // Include your logic for filtering menu items here if needed
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission))),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    ...mapState({
      $_permissionMixin_user: (state) => state.activeUser,
    }),
  },
  methods: {
    $_permissionMixin_hasPermission(permissionType) {
      if (this.$_permissionMixin_isAdmin) {
        return true;
      }

      return includes(this.$_permissionMixin_permissions, permissionType);
    },
    onTabChanged(newTab, newIndex) {
      this.activeIndex = newIndex;
    },
  },
};

export default permissionMixin;
