<template>
  <div class="underline-button" :class="{ active }">
    <div class="underline-button__text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: 'UnderlineButton',
  props: {
    text: {
      type: String,
      default: 'New button',
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.underline-button {
  position: relative;
  display: flex;
  flex-flow: nowrap;
  justify-content: center;
  width: 100%;
  padding-bottom: 1.3vh;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $default-blue-dark-color;
  }

  &.active {
    font-weight: 600;

    &:after {
      height: 2px;
    }
  }
}
</style>
