import { PROJECTS_LAYOUTS } from '@/constants/dashboard';
import {
  SET_ACTIVE_USER,
  CHANGE_DASHBOARD_CAMERAS_LAYOUT,
  CHANGE_DASHBOARD_PROJECTS_LAYOUT,
  SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS,
  TOGGLE_DASHBOARD_FULLSCREEN,
  TOGGLE_DASHBOARD_FOOTER_WEATHER,
  SET_ACTIVE_PROJECT,
  SET_TIMELAPSES_TYPE,
  TOGGLE_CUSTOM_TIMELAPSE,
  CHANGE_HOME_BUTTON_CLICKED,
} from './mutation-types';

export default {
  async setActiveUser({ commit }, payload) {
    commit(SET_ACTIVE_USER, payload);
  },
  async setCamerasLayout({ commit }, payload) {
    commit(CHANGE_DASHBOARD_CAMERAS_LAYOUT, payload);
  },
  /**
   * @deprecated since version 3
   */
  async setProjectsLayout({ commit }, payload) {
    commit(CHANGE_DASHBOARD_PROJECTS_LAYOUT, payload);
  },
  /**
   * @deprecated since version 3
   */
  async activateSingleProjectsLayout({ commit }, payload) {
    commit(SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS, payload);
    commit(CHANGE_DASHBOARD_PROJECTS_LAYOUT, PROJECTS_LAYOUTS.single);
  },
  /**
   * @deprecated since version 3
   */
  async resetSingleProjectLayoutParams({ commit }) {
    commit(SET_DASHBOARD_PROJECTS_SINGLE_LAYOUT_PARAMS, {});
  },
  /**
   * @deprecated since version 3
   */
  async toggleDashboardFullscreen({ commit }, payload) {
    commit(TOGGLE_DASHBOARD_FULLSCREEN, payload);
  },
  /**
   * @deprecated since version 3
   */
  async toggleDashboardFooterWeather({ commit }, payload) {
    commit(TOGGLE_DASHBOARD_FOOTER_WEATHER, payload);
  },
  /**
   * @deprecated since version 3
   */
  async setActiveProject({ commit }, payload) {
    commit(SET_ACTIVE_PROJECT, payload);
  },
  /**
   * @deprecated since version 3
   */
  async setTimelapsesType({ commit }, payload) {
    commit(SET_TIMELAPSES_TYPE, payload);
  },
  /**
   * @deprecated since version 3
   */
  async toggleCustomTimelapse({ commit }, payload) {
    commit(TOGGLE_CUSTOM_TIMELAPSE, payload);
  },
  /**
   * @deprecated since version 3
   */
  async changeHomeButtonClicked({ commit }, payload) {
    commit(CHANGE_HOME_BUTTON_CLICKED, payload);
  },
};
