import endpointDefinitions from '../services/csite/endpointDefinitions';

function getStates(endpointDef) {
  /*
    Create store objects based on the endpoints name.
  */
  const states = {};
  const listKey = `${endpointDef.singleName}List`;
  states[endpointDef.name] = {};
  states[listKey] = [];
  return states;
}

const autoGeneratedState = endpointDefinitions.reduce(
  (accumulator, endpointDef) => ({ ...accumulator, ...getStates(endpointDef) }),
  {},
);

export default {
  ...autoGeneratedState,
};
