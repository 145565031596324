<template>
  <div class="layout_v3__preloader">
    <div class="layout_v3__preloader__pictures">
      <picture
        class="layout_v3__preloader__pictures__animation">
        <img :src="logoPic" alt="logo_pic" />
      </picture>
      <picture>
        <img :src="logoText" alt="logo_text" />
      </picture>
    </div>
  </div>
</template>

<script>
const logoPic = require('@/assets/images/v3/c-site_logo_pic_only.svg');
const logoText = require('@/assets/images/v3/c-site_logo_text_only.svg');

export default {
  name: 'ImagePreloader',
  data: () => ({
    logoPic,
    logoText,
  }),
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/preloaders.scss';

.layout_v3__preloader {
  @include preloader;

  .layout_v3__preloader__pictures {

    picture img {
      @media only screen and (max-width: 900px) {
        width: 50%;
        height: 50%
      }
    }
  }
}

</style>
