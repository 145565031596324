<template>
  <form class="auth-form" ref="form" @submit.prevent="submitForm"
        @change="$_authMixin_onFormChange($refs.form)">
    <div class="auth-form__caption">
      {{ $t('auth.titles.login') }}
    </div>
    <div class="auth-form__inputs">
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="email" type="email" :placeholder="$t('auth.placeholders.email')"
                 v-model="form.email.value" @input="$_authMixin_validate" required autofocus />
        </div>
        <label for="email">{{ form.email.error }}</label>
      </div>
      <div class="auth-form__inputs__input">
        <div class="auth-form__inputs__input__wrapper">
          <input id="password" type="password" :placeholder="$t('auth.placeholders.password')"
               v-model="form.password.value" @input="$_authMixin_validate" required />
        </div>
        <label for="password">{{ form.password.error }}</label>
      </div>
      <div class="auth-form__inputs__input" v-if="isForceChangedPasswordRequired">
        <p class="auth-form__inputs__input__message">
          {{ $t('auth.captions.force_changed_password') }}
        </p>
        <div class="auth-form__inputs__input__wrapper">
          <input id="force_changed_password" type="password"
               :placeholder="$t('auth.placeholders.new_password')"
               v-model="form.force_changed_password.value" @input="$_authMixin_validate" required />
        </div>
        <label for="force_changed_password">{{ form.password.error }}</label>
      </div>
    </div>
    <ButtonSubmit :title="$t('auth.buttons.login')" @click="submitForm"
                  :is-show-loading="$_authMixin_loading"
                  :is-disabled="$_authMixin_submitDisabledState"
                  class="auth-form__submit_button"/>
    <div class="auth-form__actions">
      <router-link :to="{ name: 'resetPassword' }">
        {{ $t('auth.captions.reset_password') }}
      </router-link>
      <router-link :to="{ name: 'register' }">
        {{ $t('auth.captions.sign_up') }} {{ $t('auth.buttons.sign_up') }}
      </router-link>
    </div>
    <slot />
  </form>
</template>

<script>
import { mapActions } from 'vuex';

import AuthMixin from '@/mixins/authMixin';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

import { DEFAULT_HOME_URL } from '@/constants/v3/menu';

export default {
  name: 'Login',
  mixins: [AuthMixin],
  components: {
    ButtonSubmit,
  },
  data() {
    return {
      form: {
        email: {
          value: this.$route.query.email,
          error: null,
        },
        password: {
          value: null,
          error: null,
        },
        force_changed_password: {
          value: null,
          error: null,
        },
      },
      isForceChangedPasswordRequired: false,
      user: null,
      isShowLayoutPreloader: false,
    };
  },
  watch: {
    isShowLayoutPreloader(isShowLayoutPreloader) {
      this.$emit('showPreloader', { isShowLayoutPreloader });
    },
  },
  methods: {
    submitLogin() {
      this.login({ email: this.form.email.value.toLowerCase(), password: this.form.password.value })
        .then((user) => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            this.user = user;
            this.isForceChangedPasswordRequired = true;
            this.$_authMixin_isFormValid = false;
            return;
          }

          const nextUrl = this.$route.params.nextUrl
            ? this.$route.params.nextUrl : DEFAULT_HOME_URL.route_path;
          this.isShowLayoutPreloader = true;
          setTimeout(() => this.$router.push({ path: nextUrl }), 1500);
        })
        .catch((error) => {
          let errorMessage = this.$_authMixin_getCognitoErrorText(error.message);

          if (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException') {
            errorMessage = this.$t('auth.errors.invalid_combination');
          }
          this.$emit('apiError', { message: errorMessage });
        })
        .finally(() => {
          this.$_authMixin_loading = false;
        });
    },
    submitForceChangePassword() {
      this.forceChangePassword(
        { user: this.user, newPassword: this.form.force_changed_password.value },
      ).then(() => {
        const nextUrl = this.$route.params.nextUrl
          ? this.$route.params.nextUrl : DEFAULT_HOME_URL.route_path;
        this.$router.push({ path: nextUrl });
      }).catch((error) => {
        const errorMessage = this.$_authMixin_getCognitoErrorText(error.message);
        this.$emit('apiError', { message: errorMessage });
      }).finally(() => {
        this.$_authMixin_loading = false;
      });
    },
    submitForm(e) {
      if (!this.$_authMixin_submitForm(e)) {
        return;
      }

      this.$emit('clearApiError');

      const action = this.isForceChangedPasswordRequired
        ? this.submitForceChangePassword : this.submitLogin;
      action();
    },
    ...mapActions({
      login: 'login',
      forceChangePassword: 'forceChangePassword',
    }),
  },
  beforeDestroy() {
    this.$emit('clearApiError');
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/auth.scss';

</style>
