<template>
  <div class="popup">
    <div class="popup__box">
      <div class="popup__box__title">{{ projectSelected.name.toUpperCase() }}</div>
      <div class="popup__box__content" v-if="menuTabs.length != 0">
        <div class="popup__box__content__menu">
          <Menu
            :menu-list="menuTabs"
            @activeTabChanged="onTabChanged"
            :allow-hash="false" />
        </div>
        <div class="popup__box__content__camera_image">
          <img
            :src="noCameraImg"
            @click="onNoImageClickHandler"
            class="popup__box__content__camera_image__no_camera"
            v-if="!projectViewCurrent.latest_image"
          >
          <div class="popup__box__content__camera_image__camera" v-else>
            <picture>
              <img
                :src="projectViewCurrent.latest_image_details.image"
                alt="no img">
            </picture>
          </div>
          <div class="popup__box__content__camera_image__date">{{ imageDate }}</div>
        </div>
      </div>
      <div class="popup__box__content" v-else>{{ $t('cameras_menu.map_tab.popup') }}</div>
      <div class="popup__box__icon">
        <i class="icon__close mdi mdi-window-close" @click="onCloseClickHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
import noCameraImg from '@/assets/images/dashboardNg/no_camera.svg';
import { SUPPORT_URL } from '@/constants/v3/menu';
import Menu from '@/components/v3/TabsMenu';
import moment from 'moment-timezone';
import { map } from 'lodash';

export default {
  name: 'ProjectPopup',
  components: { Menu },
  data() {
    return {
      noCameraImg,
      activeMenuTab: {},
      projectViewCurrent: null,
    };
  },
  beforeMount() {
    this.initialActiveTab();
  },
  props: {
    projectSelected: {
      type: Object,
    },
    projectViewsSelected: {
      type: Array,
      default() {
        return [];
      },
    },
    formatDate: {
      type: String,
      default: 'dd DD MMM YYYY HH:mm',
    },
  },
  emits: ['close'],
  computed: {
    imageDate() {
      if (this.projectViewCurrent.latest_image_details !== undefined
        && moment(this.projectViewCurrent.latest_image_details.date).isValid()) {
        return `${moment(this.projectViewCurrent.latest_image_details.date).format(
          this.formatDate,
        )}`;
      }
      return '';
    },
    menuTabs() {
      return map(this.projectViewsSelected, (projectView) => ({
        name: projectView.name,
        code: projectView.name,
        object: projectView,
      }));
    },
  },
  methods: {
    initialActiveTab() {
      if (!this.menuTabs.length) {
        return;
      }
      const loadMenu = this.menuTabs[0];
      this.activeMenuTab = loadMenu;
      this.projectViewCurrent = this.menuTabs[0].object;
    },
    onNoImageClickHandler() {
      this.$router.push(SUPPORT_URL.path);
    },
    onTabChanged(newTab) {
      this.activeMenuTab = newTab;
      this.projectViewCurrent = newTab.object;
    },
    onCloseClickHandler() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

.popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);

  .popup__box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    max-height: 90%;
    margin-top: 10vh;
    padding: 2.5vh;
    background-color: $default-background-color;
    cursor: default;

    @media only screen and (max-width: 900px) {
      max-height: 80%;
      min-height: 80%;
      max-width: 100%;
    }

    .popup__box__title {
      padding-left: 1.5vw;
      padding-top: 1.5vh;
      padding-bottom: 5vh;
      font-size: 2em;
      font-weight: bold;
      color: $default-blue-dark-color;
    }
    .popup__box__content {
      padding-left: 1.5vw;
      padding-right: 1.5vw;

      .popup__box__content__menu {

        & .tabs {
            display:block;
            margin-bottom: 3vh;

          ::v-deep .tabs__left-side {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
          }
        }
      }

      .popup__box__content__camera_image {
        .popup__box__content__camera_image__camera {
          picture {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: var(--img-height);

            img {
              width: 100%;
              max-height: 60vh;
              object-fit: scale-down;
            }
          }
        }

        .popup__box__content__camera_image__no_camera {
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 30%;
          object-fit: contain;
        }

        .popup__box__content__camera_image__date {
          margin-left: 1vw;
          margin-top: 2vh;
          font-weight: bold;
        }
      }
    }
  }
}

.icon__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5vh;
  padding-top: 1.5vh;
  opacity: 0.4;
}
</style>
