<template>
<div class="cookies-popup" v-if="isShowCookiesPopup">
  <div class="cookies-popup__window">
    <div class="cookies-popup__title">
        Cookies
    </div>
    <div class="cookies-popup__text">
      <p>
        c-site.eu uses your browser's local storage and cookies. Based on the data stored,
        C-SITE collects and analyzes information about your visit.
      </p>
      <p>
        Enjoy an optimal C-SITE experience? Accept all cookies.
        Set preferences and determine what information you share with C-SITE.
        Please note that certain media are only available if you accept their cookies.
        c-site.eu saves your cookie preferences. You can adjust them via manage cookies.
      </p>
      <p>
        Knowing more? Consult our
        <a href="https://en.c-site.eu/cookie-policy" target="_blank">cookie policy</a>
      </p>
      <p>
        C-SITE appreciates your confidence and wishes you an enriching browsing experience!
      </p>
    </div>
    <div class="checkboxes" v-if="preferencesOpen">
      <div class="checkboxes__checkbox" >
        <input
          type="checkbox"
          id="application_cookies"
          checked disabled>
        <label for="application_cookies" />
        <div class="checkboxes__checkbox__title">
          Application cookies (Required)
        </div>
      </div>
      <div class="checkboxes__checkbox">
        <input
          type="checkbox"
          id="analytic_cookies"
          :checked="isAnalyticCookies"
          @change="isAnalyticCookies = !isAnalyticCookies">
        <label for="analytic_cookies" />
        <div class="checkboxes__checkbox__title">
          Analytic cookies
        </div>
      </div>
      <div class="checkboxes__checkbox">
        <input
          type="checkbox"
          id="youtube_cookies"
          :checked="isYoutubeCookies"
          @change="isYoutubeCookies = !isYoutubeCookies">
        <label for="youtube_cookies" />
        <div class="checkboxes__checkbox__title">
          YouTube cookies
        </div>
      </div>
    </div>
    <div class="cookies-popup__buttons" v-if="!preferencesOpen">
      <base-button
        title="Set Preferences"
        @click="preferencesOpen = true"
        skin="dark-blue"
        class="cookies-popup__buttons_button"/>
      <base-button
        title="Accept All"
        @click="acceptAllCookies"
        skin="dark-blue"
        class="cookies-popup__buttons_button"/>
    </div>
    <div class="cookies-popup__buttons" v-else-if="preferencesOpen">
      <base-button
        title="Back"
        skin="dark-blue"
        @click="preferencesOpen = false"
        class="cookies-popup__buttons_button"/>
      <base-button
        title="Save changes"
        skin="dark-blue"
        @click="acceptSelection"
        class="cookies-popup__buttons_button"/>
    </div>
    <i class="cookies-popup__close mdi mdi-window-close" @click="onCloseClickHandler"/>
  </div>
</div>
</template>

<script>
import BaseButton from '@/components/v3/BaseButton';
import cookieMixin, {
  ANALYTICS_CONSENT_COOKIE,
  APPLICATION_CONSENT_COOKIE, YOUTUBE_CONSENT_COOKIE,
} from '@/mixins/v3/cookies/cookieMixin';

export default {
  name: 'CookiesPopup',
  mixins: [cookieMixin],
  components: {
    BaseButton,
  },
  data() {
    return {
      isShowCookiesPopup: true,
      preferencesOpen: false,
    };
  },
  beforeMount() {
    this.checkCookies();
  },
  methods: {
    checkCookies() {
      if (Number(this.$cookies.get(APPLICATION_CONSENT_COOKIE)) === 1) {
        this.isShowCookiesPopup = false;
      }
    },
    onCloseClickHandler() {
      this.isShowCookiesPopup = false;
    },
    setApplicationCookieConsent() {
      this.$cookies.set(APPLICATION_CONSENT_COOKIE, 1, '365d', '/');
    },
    acceptAllCookies() {
      this.setApplicationCookieConsent();
      this.$cookies.set(ANALYTICS_CONSENT_COOKIE, 1, '365d', '/');
      this.$cookies.set(YOUTUBE_CONSENT_COOKIE, 1, '365d', '/');
      this.isShowCookiesPopup = false;
    },
    acceptSelection() {
      this.setApplicationCookieConsent();
      this.saveSelection();
      this.isShowCookiesPopup = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.cookies-popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  .cookies-popup__window {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    padding: 6vh 4vw;
    background-color: $default-light-color;
    cursor: default;

    .cookies-popup__title {
        position: relative;
        font-size: 2em;
        padding-bottom: 1.5vh;
        margin-bottom: 3.1vh;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 15%;
        height: 1px;
        background-color: $default-blue-dark-color;
      }
    }
    .cookies-popup__text {
      line-height: 1.2;

      p {
        margin-bottom: 3vh;
      }
    }

    .cookies-popup__buttons {
      display: flex;
      margin-top: 6vh;
      align-items: center;
      justify-content: center;

      .cookies-popup__buttons_button {
        margin-right: 3vw;
      }
    }

    .cookies-popup__close {
      position: absolute;
      top: 1.07vh;
      right: 0.6vw;
      font-size: 1.7em;
      cursor: pointer;
    }
  }
}
</style>
