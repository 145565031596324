import Vue from 'vue';
import * as types from '@/store/mutation-types';

import autoGeneratedMuatations from './autoGeneratedMutations';

export default {
  ...autoGeneratedMuatations,
  [types.ACTIVATE_NAVIGATIONDRAWER]: (state) => {
    Vue.set(state, 'navigationDrawer', true);
  },
  [types.DEACTIVATE_NAVIGATIONDRAWER]: (state) => {
    Vue.set(state, 'navigationDrawer', false);
  },
  [types.SET_LOADING]: (state, status) => {
    state.isLoading = status;
  },
  [types.SET_ACTIVEUSER]: (state, data) => {
    state.activeUser = data;
  },
  [types.SET_LOADING]: (state, status) => {
    state.isLoading = status;
  },
  [types.SET_ACTIVEUSER]: (state, id) => {
    state.activeUser = id;
  },
  [types.SET_AJAXERROR]: (state, data) => {
    Vue.set(state, 'ajaxError', data);
  },
  [types.SET_ACTIVE_MENU]: (state, data) => {
    Vue.set(state, 'activeMenu', data);
  },
  [types.TOGGLE_FULLSCREEN]: (state, payload) => {
    Vue.set(state, 'isFullscreenMode', payload);
  },
  [types.TOGGLE_MAIN_LAYOUT_PADDING]: (state, payload) => {
    Vue.set(state, 'isMainLayoutPadding', payload);
  },
};
