import Vue from 'vue';

const DETAIL_TYPE = 'detail';
const LIST_TYPE = 'list';

export const ACTION_TYPES = {
  detail: DETAIL_TYPE,
  list: LIST_TYPE,
};

export default [
  {
    name: 'boxaccounts',
    singleName: 'boxaccount',
    actions: [
      {
        name: 'authorize',
        mutationName: 'BoxAccountAuthorization',
        methods: ['post'],
        type: ACTION_TYPES.list,
        mutationCallback: (action, state, data) => {
          if (action === 'post') {
            const obj = data;
            Vue.set(state.boxaccounts, obj.id, obj);
            if (state.boxaccountList.includes(obj.id) === false) {
              state.boxaccountList.push(obj.id);
            }
          }
        },
      },
      {
        name: 'refresh',
        mutationName: 'BoxAccountRefresh',
        methods: ['post'],
        type: ACTION_TYPES.detail,
        mutationCallback: (action, state, data) => {
          if (action === 'post') {
            const obj = data;
            Vue.set(state.boxaccounts, obj.id, obj);
            if (state.boxaccountList.includes(obj.id) === false) {
              state.boxaccountList.push(obj.id);
            }
          }
        },
      },
    ],
  },
  {
    name: 'boxuploadrules',
    singleName: 'boxuploadrule',
  },
  {
    name: 'cameras',
    singleName: 'camera',
  },
  {
    name: 'cameraviews',
    singleName: 'cameraview',
    actions: [
      {
        name: 'start_stream',
        mutationName: 'StartStream',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'companies',
    singleName: 'company',
    actions: [
      {
        name: 'invite',
        mutationName: 'CompanyInvite',
        methods: ['post'],
        type: ACTION_TYPES.detail,
        mutationCallback: (action, state, data) => {
          if (action === 'post') {
            const obj = data;
            Vue.set(state.companymemberships, obj.id, obj);

            // Only add the id if this does not exist in the list
            if (state.companymembershipList.includes(obj.id) === false) {
              state.companymembershipList.push(obj.id);
            }
          }
          return data;
        },
      },
      {
        name: 'share',
        mutationName: 'CompanyShare',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'company_projects',
        mutationName: 'CompanyProjects',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'companymemberships',
    singleName: 'companymembership',
  },
  {
    name: 'customtimelapses',
    singleName: 'customtimelapse',
  },
  {
    name: 'dailytimelapses',
    singleName: 'dailytimelapse',
  },
  {
    name: 'threedaytimelapses',
    singleName: 'threedaytimelapse',
  },
  {
    name: 'dailyweatherforecasts',
    singleName: 'dailyweatherforecast',
  },
  {
    name: 'groups',
    singleName: 'group',
  },
  {
    name: 'ziprequests',
    singleName: 'ziprequest',
  },
  {
    name: 'ziparchives',
    singleName: 'ziparchive',
  },
  {
    name: 'imageareaidentifiers',
    singleName: 'imageareaidentifier',
  },
  {
    name: 'identifierlabels',
    singleName: 'identifierlabel',
  },
  {
    name: 'modelalgorithms',
    singleName: 'modelalgorithm',
  },
  {
    name: 'powerbitokens',
    singleName: 'powerbitoken',
  },
  {
    name: 'images',
    singleName: 'image',
    actions: [
      {
        name: 'closest_images',
        mutationName: 'ClosestImages',
        methods: ['get'],
        type: ACTION_TYPES.list,
      },
      {
        name: 'share',
        mutationName: 'ImageShare',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'download',
        mutationName: 'ImageDownload',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'scroll',
        mutationName: 'ImageScroll',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'recognize',
        mutationName: 'ImageRecognize',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'monthlytimelapses',
    singleName: 'monthlytimelapse',
  },
  {
    name: 'notifications',
    singleName: 'notification',
    actions: [
      {
        name: 'read',
        mutationName: 'NotificationRead',
        methods: ['post'],
        type: ACTION_TYPES.detail,
        mutationCallback: (action, state, data) => {
          if (action === 'post') {
            const obj = data;
            Vue.set(state.notifications, obj.id, obj);

            // Only add the id if this does not exist in the list
            if (state.notificationList.includes(obj.id) === false) {
              state.notificationList.push(obj.id);
            }
          }
          return data;
        },
      },
      {
        name: 'readall',
        mutationName: 'NotificationsReadAll',
        methods: ['post'],
        type: ACTION_TYPES.list,
        // eslint-disable-next-line no-unused-vars
        mutationCallback: (action, state, _data) => {
          if (action === 'post') {
            const notifications = Object.values(state.notifications);
            notifications.forEach((x) => {
              // eslint-disable-next-line no-param-reassign
              x.read = true;
            });
          }
        },
      },
    ],
  },
  {
    name: 'permissions',
    singleName: 'permission',
  },
  {
    name: 'projectmemberships',
    singleName: 'projectmembership',
  },
  {
    name: 'projects',
    singleName: 'project',
    actions: [
      {
        name: 'invite',
        mutationName: 'ProjectInvite',
        methods: ['post'],
        type: ACTION_TYPES.detail,
        mutationCallback: (action, state, data) => {
          if (action === 'post') {
            const obj = data;
            Vue.set(state.projectmemberships, obj.id, obj);

            // Only add the id if this does not exist in the list
            if (state.projectmembershipList.includes(obj.id) === false) {
              state.projectmembershipList.push(obj.id);
            }
          }
          return data;
        },
      },
      {
        name: 'share',
        mutationName: 'ProjectShare',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'project_members',
        mutationName: 'ProjectMembers',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'projectviews',
    singleName: 'projectview',
  },
  {
    name: 'stptimelapses',
    singleName: 'stptimelapse',
  },
  {
    name: 'tags',
    singleName: 'tag',
  },
  {
    name: 'taggedimages',
    singleName: 'taggedimage',
  },
  {
    name: 'timelapses',
    singleName: 'timelapse',
    actions: [
      {
        name: 'max_duration',
        mutationName: 'MaxDuration',
        methods: ['get'],
        type: ACTION_TYPES.list,
      },
      {
        name: 'download',
        mutationName: 'TimelapseDownload',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'users',
    singleName: 'user',
    actions: [
      {
        name: 'acceptinvite',
        mutationName: 'UserAcceptInvite',
        methods: ['post'],
        type: ACTION_TYPES.list,
      },
      {
        name: 'submit_ticket',
        mutationName: 'SubmitTicket',
        methods: ['post'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'projects_users',
        mutationName: 'ProjectsUsers',
        methods: ['get'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'change_permissions',
        mutationName: 'ChangePermissions',
        methods: ['patch'],
        type: ACTION_TYPES.detail,
      },
      {
        name: 'change_groups',
        mutationName: 'ChangeGroups',
        methods: ['patch'],
        type: ACTION_TYPES.detail,
      },
    ],
  },
  {
    name: 'videos',
    singleName: 'video',
  },
  {
    name: 'weeklytimelapses',
    singleName: 'weeklytimelapse',
  },
  {
    name: 'likes',
    singleName: 'like',
  },
  {
    name: 'logos',
    singleName: 'logo',
  },
];
