// eslint-disable-next-line import/no-cycle
import {
  ACTIVATE_NAVIGATIONDRAWER,
  DEACTIVATE_NAVIGATIONDRAWER,
  SET_LOADING,
  SET_ACTIVEUSER,
  SET_AJAXERROR,
  SET_ACTIVE_MENU,
  TOGGLE_FULLSCREEN,
  TOGGLE_MAIN_LAYOUT_PADDING,
} from '@/store/mutation-types';
// eslint-disable-next-line import/no-cycle
import autoGeneratedActions from './autoGeneratedActions';

// eslint-disable-next-line import/no-cycle
import csite from '../services/csite';

export default {
  ...autoGeneratedActions,
  async acceptUserInvite({ commit }, {
    firstName, lastName, signedEmail, password,
  }) {
    commit(SET_LOADING, true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      signed_email: signedEmail,
      password,
    };
    await csite.users.acceptinvite.post(data);
    commit(SET_LOADING, false);
  },
  async setActiveUser({ commit }, userData) {
    commit(SET_ACTIVEUSER, userData);
  },
  async toggleNavigationDrawer({ commit, state }) {
    if (state.navigationDrawer) {
      commit(DEACTIVATE_NAVIGATIONDRAWER);
    } else {
      commit(ACTIVATE_NAVIGATIONDRAWER);
    }
  },
  async setAjaxError({ commit }, axiosError) {
    const data = axiosError ? axiosError.response : axiosError;
    commit(SET_AJAXERROR, data);
  },
  async setActiveMenu({ commit }, payload) {
    commit(SET_ACTIVE_MENU, payload);
  },
  async toggleFullscreen({ commit }, payload) {
    commit(TOGGLE_FULLSCREEN, payload);
  },
  async toggleMainLayoutPadding({ commit }, payload) {
    commit(TOGGLE_MAIN_LAYOUT_PADDING, payload);
  },
  async updateActiveUserFrontendSettings({ commit, state }, {
    key, data,
  }) {
    const user = state.dashboard.activeUser;
    if (!user) {
      throw new Error('Failed to save user settings: active user not found!');
    }
    commit(SET_LOADING, true);
    const frontendSettings = user.frontend_settings || {};
    const payload = {
      ...frontendSettings,
      [key]: {
        ...frontendSettings[key],
        ...data,
      },
    };
    await autoGeneratedActions.patchUsers(
      { commit },
      {
        id: user.id,
        data: {
          frontend_settings: payload,
        },
      },
    );
    commit(SET_LOADING, false);
  },
};
