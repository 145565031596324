import { TIMELAPSE_CATEGORIES } from '@/constants/timelapses';
import { ONE_SCREEN } from '@/constants/v3/dashboard';

import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    activeUser: null,
    camerasLayout: ONE_SCREEN,

    /**
     * @deprecated all below since version 3
     */
    projectsLayout: null,
    timelapsesType: TIMELAPSE_CATEGORIES.three_days,
    isActiveCustomTimelapse: false,
    fullscreenMode: false,
    isShowFooterWeather: false,
    activeProject: null,
    singleProjectLayoutParams: {
      projectId: null,
      projectViewId: null,
    },
    homeButtonClicked: false,
  },
  actions,
  mutations,
};
