import SwiperCore, { Swiper, Pagination } from 'swiper/core';
import 'swiper/swiper-bundle.css';
import { mapState } from 'vuex';

SwiperCore.use([Pagination]);

export const defaultSwiperPaginationClass = 'swiper-pagination';

export const swiperMixin = {
  data: () => ({
    $_swiperMixin: {
      isInitialized: false,
      instance: {},
      swiperClass: 'swiper-container',
      swiperPaginationClass: defaultSwiperPaginationClass,
      swiperPaginationTitles: [],
      swiperOptions: {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
      },
    },
  }),
  mounted() {
    this.$_swiperMixin_swiper = {
      ...this.$_swiperMixin_swiper,
      swiperOptions: {
        ...this.$_swiperMixin_swiper.swiperOptions,
        pagination: {
          ...this.$_swiperMixin_swiper.swiperOptions.pagination,
          renderBullet: (index, className) => {
            const titles = this.$_swiperMixin_swiper.swiperPaginationTitles;
            const title = titles.length && index in titles ? `title="Camera: ${titles[index]}"` : '';
            return `<span class="${className}" ${title}></span>`;
          },
        },
      },
    };
  },
  updated() {
    this.$nextTick(() => {
      if (!this.$_swiperMixin_swiper.isInitialized) {
        this.$_swiperMixin_initSwiper();
      }
    });
  },
  watch: {
    fullscreenMode() {
      this.$_swiperMixin_destroySwiper();
    },
  },
  computed: {
    ...mapState({
      fullscreenMode: (state) => state.dashboard.fullscreenMode,
    }),
    $_swiperMixin_swiper: {
      get() {
        return this.$data.$_swiperMixin;
      },
      set(newValue) {
        this.$data.$_swiperMixin = {
          ...this.$data.$_swiperMixin,
          ...newValue,
        };
      },
    },
    $_swiperMixin_active_slide() {
      return this.$data.$_swiperMixin.instance.activeIndex;
    },
  },
  methods: {
    $_swiperMixin_initSwiper() {
      if (this.$_swiperMixin_swiper.isInitialized) {
        return;
      }

      this.$_swiperMixin_swiper = {
        isInitialized: true,
        instance: new Swiper(`.${this.$_swiperMixin_swiper.swiperClass}`,
          {
            ...this.$_swiperMixin_swiper.swiperOptions,
            on: {
              slideChange: this.$_swiperMixin_onSlideChange,
            },
          }),
      };
    },
    $_swiperMixin_destroySwiper() {
      if (!this.$_swiperMixin_swiper.isInitialized) {
        return;
      }

      this.$_swiperMixin_swiper.instance.destroy(false, false);
      this.$_swiperMixin_swiper = { isInitialized: false };
    },
    /* Methods could be overwritten in the component */
    $_swiperMixin_onSlideChange() { },
    /* * */
  },
};

export default swiperMixin;
