<template>
  <div class="footer">
    <div class="footer__caption">
      {{ $t('footer.titles.newsletter_subscribe')}}
    </div>
    <newsletter-subscription class="footer__subscribe"/>
    <div class="footer__info-list">
      <div class="footer__info-list__item">
        <div class="footer__info-list__item__caption">
          {{ $t('footer.titles.office')}}
        </div>
        <div class="footer__info-list__item__text">
          Sluis 2B/0001<br>
          9810 Eke, Belgium<br>
          BE 0822.654.525
        </div>
      </div>
      <div class="footer__info-list__item">
        <div class="footer__info-list__item__caption">
          {{ $t('footer.titles.contact_us')}}
        </div>
        <div class="footer__info-list__item__text">
          <a href="mailto:support@c-site.eu">support@c-site.eu</a>
          <a href="mailto:sales@c-site.eu">sales@c-site.eu</a>
          <a href="tel:+32092791260" class="footer__info-list__item__text__phone">
            +32 (0)9 279 12 60
          </a>
        </div>
      </div>
      <div class="footer__info-list__item">
        <div class="footer__info-list__item__caption">
          {{ $t('footer.titles.follow_us')}}
        </div>
        <div class="footer__info-list__item__text">
          {{ $t('footer.text')}}
          <a href="https://www.linkedin.com/company/c-siteeu/" title="C-SITE" target="_blank"
             class="footer__info-list__item__text__underline">Linkedin</a>
          <a href="https://www.instagram.com/csite_photos/" title="C-SITE" target="_blank"
             class="footer__info-list__item__text__underline">Instagram</a>
        </div>
      </div>
    </div>
    <div class="footer__links-list">
      <div class="footer__links-list__item">Copyright © C-SITE 2023</div>
      <a v-for="link in footerLinks" :key="link.key" :href="link.href" target="_blank"
           class="footer__links-list__item">
        {{ link.label }}
      </a>
    </div>

  </div>
</template>

<script>
import NewsletterSubscription from '@/components/v3/NewsletterSubscription';

export default {
  name: 'Footer',
  components: {
    NewsletterSubscription,
  },
  computed: {
    footerLinks() {
      const language = this.$i18n.locale;
      const subdomain = language === 'nl' ? 'www' : language;
      const baseLink = `https://${subdomain}.c-site.eu/`;
      return [
        {
          key: 'terms_conditions',
          href: `${baseLink}algemene-voorwaarden`,
          label: this.$t('footer.links.terms_conditions'),
        },
        {
          key: 'privacy_policy',
          href: `${baseLink}privacy-policy`,
          label: this.$t('footer.links.privacy_policy'),
        },
        {
          key: 'cookie_policy',
          href: `${baseLink}cookie-policy`,
          label: this.$t('footer.links.cookie_policy'),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.footer {
  font-family: $footer-font-family;
  width: 100vw;
  position: relative;
  left: -1 * $page-global-padding-horizontal;
  padding: 14.55vh $page-global-padding-horizontal 20vh;
  color: $default-blue-ultra-dark-color;
  background-color: $default-light-color;
  text-align: center;

  @media (orientation: portrait) {
    left: -1 * $page-global-portrait-padding-horizontal;
    padding-right: 14.55vh $page-global-portrait-padding-horizontal 20vh;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.8em;
    padding: 7.55vh $page-global-padding-horizontal 10vh;
  }

  .footer__caption {
    font-size: 2em;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 3.7vh;
  }

  .footer__subscribe {
    width: 41.7vw;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 6.2vh;

    @media (orientation: portrait) {
      width: 75%;
    }
  }

  .footer__info-list {
    display: grid;
    grid-template-columns: 1.5fr 1.5fr 1fr;
    width: 100%;
    text-align: left;
    padding-bottom: 3.6vh;
    border-bottom: 1px solid $default-blue-dark-color;

    @media (orientation: portrait) {
      grid-template-columns: 3fr;
    }

    .footer__info-list__item {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-flow: column;

      @media (orientation: portrait) {
        margin-bottom: 5vh;
      }

      .footer__info-list__item__caption {
        font-weight: 600;
        margin-bottom: 1.8vh;
      }

      .footer__info-list__item__text {
        line-height: 1.5;

        a {
          display: block;
          text-decoration: none;
          color: $default-blue-ultra-dark-color;

          &:active, &:hover, &:visited {
            text-decoration: none;
            color: $default-blue-ultra-dark-color;
          }

          &:hover {
            text-decoration: underline;
          }

          &.footer__info-list__item__text__phone {
            margin-top: 1.4vh;
          }

          &.footer__info-list__item__text__underline {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .footer__links-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 6.5vh;

    a {
      text-decoration: none;
      color: $default-blue-ultra-dark-color;

      &:visited, &:active {
        text-decoration: underline;
        color: $default-blue-ultra-dark-color;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
