<template>
  <div class="livestream" :class="{'full-screen': isFullScreen}">
    <Message :text="error.text" :type="error.type" v-if="error.isShow" />
    <div class="livestream__video__message" v-if="isReminder">
      <div class="livestream__video__message_text">
        The livestream will soon finish, please click the button to continue
      </div>
      <div class="livestream__video__message_buttons">
        <base-button title="Continue" skin="dark-blue" @click="onContinueClickHandler"/>
      </div>
    </div>
    <div class="livestream__container">
      <div class="livestream__container__info">
        <Fullscreen class="livestream__container__info__fullscreen"/>
      </div>
      <div class="livestream__video">
        <div class="livestream__video__player">
          <video ref="livestream" autoplay muted playsinline controls :poster="poster"
                 width="100%" :height="videoHeight"
                 class="livestream__video__player_style"
          ></video>
          <img :src="countdown" alt="Countdown" v-if="showCountdown" width="100%"
               class="livestream__video__player_countdown"/>
        </div>
        <i class="livestream__close mdi mdi-close-circle-outline"
           @click="onCloseClickHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
import Fullscreen from '@/components/v3/Helpers/Menu/Fullscreen';
import { mapActions, mapState } from 'vuex';
import Hls from 'hls.js';
import dashjs from 'dashjs';
import Message, { TYPES as MESSAGE_TYPES } from '@/components/v3/Message';
import { permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';
import WAIT_UNTIL_SHOW_FATAL from '@/constants/livestream';
import BaseButton from '@/components/v3/BaseButton';

const countdown = require('@/assets/images/v3/startlivestream3.gif');

export default {
  name: 'Livestream',
  emits: ['close'],
  mixins: [permissionMixin],
  components: {
    Message,
    Fullscreen,
    BaseButton,
  },
  props: {
    livestreams: {
      type: Object,
      required: true,
    },
    poster: {
      type: String,
      required: false,
    },
    projectView: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    countdown,
    isReminder: false,
    reminderTimeout: null,
    endingTimeout: null,
    showCountdown: true,
    hls: null,
    dash: null,
    videoHeight: 'auto',
    videoTag: null,
    error: {
      isShow: false,
      text: null,
      type: MESSAGE_TYPES.error,
      fatalErrorTimerId: null,
    },
    retryCount: 0,
  }),
  mounted() {
    window.addEventListener('resize', this.setVideoHeight);
    const html = document.querySelector('html');
    html.classList.add('no-scroll');
    this.setVideoHeight();

    if (!this.isAllowToViewLivestream) {
      const errorText = 'You\'re not allowed to view the livestream';
      this.setError(errorText);
      return;
    }
    this.initLivestream();
  },
  computed: {
    isAllowToViewLivestream() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.view_livestream);
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    stopVideoPlayer() {
      if (this.hls) {
        this.hls.destroy();
      } else if (this.dash) {
        this.dash.destroy();
        this.dash = null;
      }
      this.$emit('close');
    },
    onContinueClickHandler() {
      this.startStream({ id: this.projectView.cameraview });
      this.isReminder = false;
      clearTimeout(this.reminderTimeout);
      clearTimeout(this.endingTimeout);
      setTimeout(this.initLivestream, 7000);
    },
    showReminder() {
      this.isReminder = true;
      this.startReminderTimer();
    },
    startReminderTimer() {
      setTimeout(() => {
        this.isReminder = false;
      }, 10000);
    },
    retryInitialization() {
      setTimeout(() => {
        this.initDashLivestream();
      }, 1500);
    },
    initHlsLivestream(isHlsSupported) {
      if (!isHlsSupported) {
        this.videoTag.type = 'application/vnd.apple.mpegURL';
        this.videoTag.src = this.livestreams.m3u8;
        this.videoTag.addEventListener('loadedmetadata', () => {
          this.videoTag.play();
        });
        this.videoTag.addEventListener('canplay', () => {
          this.videoTag.play();
        });
        return;
      }
      if (this.hls) {
        this.hls.destroy();
      }
      this.hls = new Hls();
      this.hls.loadSource(this.livestreams.m3u8);
      this.hls.attachMedia(this.videoTag);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        clearTimeout(this.error.fatalErrorTimerId);
        this.videoTag.play();
        this.reminderTimeout = setTimeout(this.showReminder, 270000);
        this.endingTimeout = setTimeout(this.stopVideoPlayer, 290000);
      });
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        const { fatal, type } = data;
        if (fatal) {
          switch (type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              if (!this.error.fatalErrorTimerId) {
                this.error.fatalErrorTimerId = setTimeout(this.destroyHls, WAIT_UNTIL_SHOW_FATAL);
              }
              console.error('fatal network error encountered, try to recover');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              // try to recover media error
              if (!this.error.fatalErrorTimerId) {
                this.error.fatalErrorTimerId = setTimeout(this.destroyHls, WAIT_UNTIL_SHOW_FATAL);
              }
              console.error('fatal media error encountered, try to recover');
              this.hls.recoverMediaError();
              break;
            default:
              // cannot recover
              this.destroyHls();
              break;
          }
        }
      });
    },
    initDashLivestream() {
      this.dash = dashjs.MediaPlayer().create();
      this.dash.initialize(this.videoTag, this.livestreams.mpd, true);
      this.dash.updateSettings({ debug: { logLevel: dashjs.Debug.LOG_LEVEL_NONE } });
      this.dash.on('error', () => {
        this.dash.destroy();
        this.retryInitialization();
        if (this.retryCount <= 3) {
          // eslint-disable-next-line no-plusplus
          this.retryCount++;
          this.retryInitialization();
        } else {
          this.destroyDash();
        }
      });
      this.setVideoHeight();
      this.dash.on(dashjs.MediaPlayer.events.MANIFEST_LOADED, () => {
        setTimeout(() => {
          this.showCountdown = false;
          if (this.$refs.livestream) {
            this.$refs.livestream.style.display = 'block';
          }
        }, 500);
      });
      this.dash.on('canPlay', () => {
        this.reminderTimeout = setTimeout(this.showReminder, 270000);
        this.endingTimeout = setTimeout(this.stopVideoPlayer, 290000);
      });
    },
    initLivestream() {
      this.videoTag = this.$refs?.livestream;
      if (!this.videoTag) {
        const errorText = 'The livestream player not found. Please, reload the page.';
        this.setError(errorText);
      }
      const isSMESupported = dashjs.supportsMediaSource();
      if (isSMESupported) {
        if (this.livestreams.mpd) {
          this.initDashLivestream();
          return;
        }
      }

      const canPlayMpegurl = this.videoTag.canPlayType('application/vnd.apple.mpegurl');
      const isHlsSupported = Hls.isSupported();
      if ((isHlsSupported || canPlayMpegurl) && this.livestreams.m3u8) {
        this.initHlsLivestream(isHlsSupported);
        return;
      }

      this.setError('The livestream is not available.');
    },
    destroyHls() {
      this.hls.destroy();
      this.hls = null;

      const errorText = 'The livestream is temporarily unavailable. Please, reload the page or '
        + 'try again later.';
      this.setError(errorText);
    },
    destroyDash() {
      if (this.showCountdown) {
        this.showCountdown = false;
      }
      this.dash.destroy();
      this.dash = null;

      const errorText = 'The livestream is temporarily unavailable. Please, reload the page or '
        + 'try again later.';
      this.setError(errorText);
    },
    setError(text) {
      this.error = {
        ...this.error,
        text,
        isShow: true,
      };
    },
    setVideoHeight() {
      const videoWidth = this.$refs.livestream?.clientWidth;
      if (!videoWidth) {
        return;
      }
      this.videoHeight = videoWidth * 0.5625;
    },
    onCloseClickHandler() {
      this.dash.destroy();
      this.dash = null;
      this.$emit('close');
    },
    ...mapActions({
      startStream: 'postStartStream',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setVideoHeight);
    const html = document.querySelector('html');
    html.classList.remove('no-scroll');
  },
};
</script>

<style lang="scss">
  html.no-scroll {
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
</style>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .livestream {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, .85);
    z-index: 100000000;

    .livestream__video__message {
      width: auto;
      position: absolute;
      height: auto;
      left: 50%;
      top: 65%;
      transform: translate(-50%, -50%);
      z-index: 100000000;
      font-size: min(1.35vw, 2.55vh);

      .livestream__video__message_text {
        background-color: rgba(243, 248, 251, 0.4);
        color: $default-text-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: min(1.6vh, 3.1vh);
      }
      .livestream__video__message_buttons {
        margin-top: 2vh;
        display: flex;
        justify-content: center;
        .dark-blue {
          background-color: $default-blue-dark-color-opacity-60;
        }
      }
    }

    &.full-screen {
      .livestream__container__info,
      .livestream__close {
        display: none;
      }
      .livestream__video {
        margin: 0;
        .livestream__video__player {
          max-height: 100vh;
        }
      }
      .livestream__container {
        padding-top: 0;
        width: 100%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .livestream__container {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 73.9585vw;
      padding-top: 6.5vh;
      font-weight: 600;

      .touch-device & {
        top: 50vh;
        transform: translateX(-50%) translateY(-50%);
        padding: 0;
      }
    }

    .livestream__container__info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .livestream__container__info__fullscreen {
        justify-content: flex-end;
      }
    }

    .livestream__video {
      position: relative;
      width: 100%;
      margin-top: 1.5vh;

      .livestream__video__player {
        display: flex;
        align-content: flex-start;
        margin-bottom: 0.5vh;
        max-height: 80vh;
        .livestream__video__player_style {
          display: none;
        }
        .livestream__video__player_countdown {
          box-shadow: 2px 2px 2px $default-blue-ultra-dark-color,
          -2px -2px 2px $default-blue-ultra-dark-color,
        }
      }

      .livestream__close {
        position: absolute;
        top: 0;
        right: -.5vw;
        transform: translateX(100%);
        font-size: 2em;
        color: $default-blue-color;
        cursor: pointer;
      }
    }
  }
</style>
