import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    isLoading: false,
    activeUser: null,
  },
  actions,
  mutations,
};
