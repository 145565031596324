var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image-tags",class:{'fake-close': _vm.unmount.isScheduled},on:{"click":_vm.onClickHandler}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.error.isShow ? [] : _vm.tags,"item-value":"name","return-object":"","search-input":_vm.search,"hide-selected":"","label":_vm.$t('tags_likes.search_tag'),"multiple":"","small-chips":"","solo":"","disabled":_vm.error.isShow},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([(_vm.isAllowToAddNewTags)?{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"subheading"},[_vm._v(_vm._s(_vm.$t('tags_likes.create')))]),_c('v-chip',{attrs:{"color":((_vm.colors.new) + " lighten-3"),"label":"","small":""}},[_vm._v(" "+_vm._s(_vm.search)+" ")])],1)]},proxy:true}:null,{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.isAllowToUntagImage)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(" $delete ")]):_vm._e()],1):_vm._e()]}},{key:"item",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('v-chip',{attrs:{"color":((item.color) + " lighten-3"),"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.selectedTags),callback:function ($$v) {_vm.selectedTags=$$v},expression:"selectedTags"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }