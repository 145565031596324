<template>
  <div class="recognize-image">
    <div class="recognize-image-wrapper">
      <v-select :items="AIModels.list"
                item-text="text"
                item-value="value"
                append-icon=""
                label="select a model"
                single-line
                v-model="AIModels.selected"
                hide-details
                style="padding: 0;"/>
      <i class="mdi mdi-face-recognition" v-if="!isRequestingRecognition"
         @click="onRecognizeClickHandler"/>
      <v-progress-circular :indeterminate="true" :size="50" color="#1654A2" v-else/>
    </div>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { find, isEmpty, map } from 'lodash';

export default {
  name: 'RecognizeImage',
  emits: ['error', 'requested'],
  props: {
    objectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      AIModels: {
        list: [],
        selected: null,
      },
      isRequestingRecognition: false,
    };
  },
  beforeMount() {
    this.initData();
  },
  computed: {
    ...mapState({
      allAIModels: (state) => state.extra.allAIModels,
    }),
  },
  methods: {
    async initData() {
      if (isEmpty(this.allAIModels)) {
        await this.fetchAllAIModels();
      }
      this.AIModels.list = map(this.allAIModels, (model) => ({
        text: model.name,
        value: model.id,
      }));
    },
    getModelIdByName(modelName) {
      const model = find(this.allAIModels, (AIModel) => AIModel.name === modelName);
      return model ? model.id : null;
    },
    onRecognizeClickHandler() {
      if (this.isRequestingRecognition) {
        return;
      }

      this.isRequestingRecognition = true;
      const defaultModelId = this.getModelIdByName('yolov8') || 1;
      const modelId = this.AIModels.selected || defaultModelId;
      this.recognizeImage({ id: this.objectId, params: { model: modelId } }).then(() => {
        this.$emit('requested');
      }).catch((e) => {
        this.$emit('error', e);
      }).finally(() => {
        this.isRequestingRecognition = false;
      });
    },
    ...mapActions({
      recognizeImage: 'postImageRecognize',
      getModelalgorithms: 'listModelalgorithms',
      fetchAllAIModels: 'fetchAllAIModels',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.recognize-image {
  display: inline-block;
  margin-right: 0.75vw;
  .recognize-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mdi {
    cursor: pointer;
  }

  ::v-deep .v-progress-circular {
    width: 1em!important;
    height: 1em!important;

    .v-progress-circular__info {
      font-size: .35em;
    }
  }

  ::v-deep .v-select {
    .v-input__control {
      .v-input__slot {
        .v-select__selections {
          input {
            padding: 0 0 5px 0 !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    ::v-deep .v-progress-circular {
      width: .8em !important;
      height: .8em !important;

      .v-progress-circular__info {
        font-size: .35em;
      }
    }
  }
}
</style>
