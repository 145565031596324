<template>
  <div class="my_c_site">
    <Menu class="my_c_site__menu" :menu-list="permissionMenuList" template="blue-background"
          @activeTabChanged="onTabChanged" :init-active-index="activeIndex" :allow-hash="false">
      <Logout />
    </Menu>
    <component :is="activeComponent" v-if="activeComponent"
               v-bind="activeComponentProps"/>
  </div>
</template>

<script>
import Menu from '@/components/v3/TabsMenu';
import Logout from '@/components/v3/Logout';
import { permissionMixin } from '@/mixins/v3';
import { filter, map, some } from 'lodash';
import { mapState } from 'vuex';
import Settings from '@/components/v3/Profile/index';
import LikesTags from '@/components/v3/LikesTags/index';
import Projects from '@/components/v3/Projects/index';

export default {
  name: 'MyCSite',
  mixins: [permissionMixin],
  components: {
    Menu,
    Logout,
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t('my_csite_menu.tabs.projects'),
          code: 'projects',
          path: 'projects',
          component: Projects,
        },
        {
          name: this.$t('my_csite_menu.tabs.likes-and-tags'),
          code: 'likes-and-tags',
          component: LikesTags,
          path: 'likes-and-tags',
        },
        {
          name: this.$t('my_csite_menu.tabs.profile'),
          code: 'profile',
          component: Settings,
          path: 'profile',
        },
      ],
      activeIndex: 0,
      activeMenuTab: null,
    };
  },
  mounted() {
    this.setActiveTabBasedOnRoute();
  },
  watch: {
    $route() {
      this.setActiveTabBasedOnRoute();
    },
    '$i18n.locale': 'updateMenuNames',
    activeIndex: {
      handler(newIndex) {
        this.activeMenuTab = this.permissionMenuList[newIndex];
      },
      immediate: true,
    },
  },
  computed: {
    permissionMenuList() {
      return filter(
        this.menuList,
        (menuItem) => !menuItem?.permissions
          || some(map(menuItem.permissions,
            (permission) => this.$_permissionMixin_hasPermission(permission))),
      );
    },
    activeMenuTabForRender() {
      return this.activeMenuTab || this.permissionMenuList[0];
    },
    activeComponent() {
      return this.activeMenuTabForRender.component;
    },
    activeComponentProps() {
      return this.activeMenuTabForRender?.props || {};
    },
    ...mapState({
      user: (state) => state.activeUser,
      isFullScreen: (state) => state.isFullscreenMode,
    }),
  },
  methods: {
    setActiveTabBasedOnRoute() {
      const basePath = '/my-csite';
      const currentPath = this.$route.path.replace(basePath, '');
      const matchedMenu = this.menuList.find((menu) => `/${menu.path}` === currentPath);
      if (matchedMenu) {
        this.activeMenuTab = matchedMenu;
        this.activeIndex = this.menuList.indexOf(matchedMenu);
      }
    },
    updateMenuNames() {
      this.menuList.forEach((menuItem) => {
        menuItem.name = this.$t(`my_csite_menu.tabs.${menuItem.code}`);
      });
    },
    onTabChanged(newTab) {
      const basePath = '/my-csite';
      // this.activeMenuTab = newTab;

      const selectedMenu = this.menuList.find((menu) => menu.code === newTab.code);
      if (selectedMenu && selectedMenu.path) {
        this.$router.push(`${basePath}/${selectedMenu.path}`).catch(() => {});
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .my_c_site {
    width: 100%;

    .my_c_site__menu {
      margin-bottom: 2.3vh;

      @media screen and (max-width: 900px) {
        font-size: 1.4em;
      }
    }
  }
</style>
