<template>
  <div class="dashboard_layouts_dropdown">
    <v-select v-model="value" :items="items" dense flat hide-details placeholder="Dashboard layout"
              append-icon="">
      <template v-slot:item="{ item }">
        <div class="dashboard_layouts_dropdown__items_parent">
          <span class="dashboard_layouts_dropdown__item"
                :class="`dashboard_layouts_dropdown__item__${item.value}`">
            <div v-for="n in item.systemNumber" :key="n"/>
          </span>
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <div class="dashboard_layouts_dropdown__items_parent">
          <span class="dashboard_layouts_dropdown__item dashboard_layouts_dropdown__item__selected"
                :class="`dashboard_layouts_dropdown__item__${item.value}`">
            <div v-for="n in item.systemNumber" :key="n"/>
          </span>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import SCREEN_TYPES_LIST, {
  ONE_SCREEN, THREE_SCREENS, FOUR_SCREENS, EIGHT_SCREENS, NINE_SCREENS, SIXTEEN_SCREENS,
} from '@/constants/v3/dashboard';
import { map } from 'lodash';
import { SLIDESHOW, SLIDESHOW_LAYOUT } from '@/constants/v3/users';
import { updateUserFrontendSettingsMixin } from '@/mixins';

export default {
  name: 'DashboardLayoutsDropdown',
  emits: ['change'],
  mixins: [updateUserFrontendSettingsMixin],
  data() {
    return {
      value: ONE_SCREEN,
      items: map(SCREEN_TYPES_LIST, (text, value) => (
        { text, value, systemNumber: this.systemDivsCount(value) })),
    };
  },
  beforeMount() {
    const userSettings = this.$_updateUserFrontendSettingsMixin_FrontendSettings;
    const { [SLIDESHOW]: userSettingsSlideshow = {} } = userSettings;
    const { [SLIDESHOW_LAYOUT]: slideshowLayout = ONE_SCREEN } = userSettingsSlideshow;
    this.value = slideshowLayout;
  },
  methods: {
    systemDivsCount(code) {
      switch (code) {
        case THREE_SCREENS:
          return 3;
        case FOUR_SCREENS:
          return 4;
        case EIGHT_SCREENS:
          return 8;
        case NINE_SCREENS:
          return 9;
        case SIXTEEN_SCREENS:
          return 16;
        default:
          return 0;
      }
    },
  },
  watch: {
    value(newValue) {
      this.$emit('change', { value: newValue || null });
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.dashboard_layouts_dropdown__items_parent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .dashboard_layouts_dropdown__item {
    $height: 5vh;
    margin: 1vh;
    display: grid;
    grid-gap: 0;
    width: calc($height * 1.78);
    height: $height;
    border: 3px solid $default-blue-ultra-dark-color;

    &.dashboard_layouts_dropdown__item__selected {
      $height: 4vh;
      position: absolute;
      transform: translateY(-1.3vh);

      width: calc($height * 1.78);
      height: $height;
    }

    div {
      border-right: 3px solid $default-blue-ultra-dark-color;
    }

    &.dashboard_layouts_dropdown__item__three_screens {
      grid-template-columns: repeat(3, 1fr);

      div:last-child {
        border: none;
      }
    }

    &.dashboard_layouts_dropdown__item__four_screens {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);

      div {
        border-bottom: 3px solid $default-blue-ultra-dark-color;

        &:nth-child(even) {
          border-right: none;
        }

        &:nth-child(n+3) {
          border-bottom: none;
        }
      }
    }

    &.dashboard_layouts_dropdown__item__eight_screens {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);

      div {
        border-bottom: 3px solid $default-blue-ultra-dark-color;

        &:nth-child(n+5) {
          border-bottom: none;
        }

        &:not(:first-child):nth-child(-n+4), &:last-child {
          border-right: none;
        }
      }

      div:first-child {
        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }

    &.dashboard_layouts_dropdown__item__nine_screens {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);

      div {
        border-bottom: 3px solid $default-blue-ultra-dark-color;

        &:nth-child(3n+3) {
          border-right: none;
        }

        &:nth-child(n+7) {
          border-bottom: none;
        }
      }
    }

    &.dashboard_layouts_dropdown__item__sixteen_screens {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 1fr);

      div {
        border-bottom: 3px solid $default-blue-ultra-dark-color;

        &:nth-child(4n+4) {
          border-right: none;
        }

        &:nth-child(n+13) {
          border-bottom: none;
        }
      }
    }
  }
}

.dashboard_layouts_dropdown::v-deep {
  position: relative;
  font-family: $main-font-family, "Material Design Icons", sans-serif;
  margin-bottom: -0.1vh;

  @media (orientation: portrait), screen and (max-width: 900px) {
    margin-top: 1vh;
    margin-bottom: -0.25vh;
    width: 50vw;
  }

  .v-input {
    font-family: $main-font-family, "Material Design Icons", sans-serif;
    font-size: 1em;
    padding-top: 0;

    .v-input__slot:after,
    .v-input__slot:before {
      content: none;
    }

    &.v-text-field {
      margin: 0;
    }

    .v-select__slot {
      color: $default-blue-ultra-dark-color;

      input {
        padding: 0;
        text-align: center;
        line-height: 1.1;
        color: $default-blue-ultra-dark-color;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $default-blue-ultra-dark-color;
          text-align: center;
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $default-blue-ultra-dark-color;
          text-align: center;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
          color: $default-blue-ultra-dark-color;
          text-align: center;
        }
      }

      .selected-projectview {
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        color: $default-blue-ultra-dark-color;
        line-height: 1;
      }

      .text-caption {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
      }
    }

    .v-progress-linear {
      transform: translateY(1.3vh);
    }
  }
}
</style>
