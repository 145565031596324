<template>
  <div class="pictures-grouped-by-tags" :class="{ disabled: isTagRemoving }">
    <Message :text="error.text" :type="error.type" v-if="error.isShow" />
    <div class="tag-info">
      <div class="tag-info__tag-name">{{ tag.name }}</div>
      <div class="tag-info__delete" @click="onDeleteTagHandler" v-if="isAllowToRemoveTag">
        {{ $t('tags_likes.delete')}} <i class="mdi mdi-trash-can-outline" />
      </div>
    </div>
    <camera-picture-feed-list :images="images"/>
  </div>
</template>

<script>
import CameraPictureFeedList from '@/components/v3/Cameras/CameraPictureFeedList';
import { mapActions } from 'vuex';
import { getErrorFromResponse } from '@/helpers';
import Message from '@/components/v3/Message';
import { errorMixin, permissionMixin } from '@/mixins/v3';
import PERMISSIONS from '@/constants/v3';

export default {
  name: 'PicturesGroupedByTags',
  props: {
    tag: {
      type: Object,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  mixins: [errorMixin, permissionMixin],
  emits: ['removed'],
  data: () => ({
    isTagRemoving: false,
  }),
  components: {
    Message,
    CameraPictureFeedList,
  },
  computed: {
    isAllowToRemoveTag() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.delete_taggedimage);
    },
    error() {
      return this.$_errorMixin_data;
    },
  },
  methods: {
    onDeleteTagHandler() {
      if (!this.isAllowToRemoveTag) {
        this.$_errorMixin_showError('You are not allowed to remove tags');
        return;
      }
      if (this.isTagRemoving) {
        return;
      }

      this.isTagRemoving = true;
      this.deleteTag(this.tag.id).then(() => {
        this.$emit('removed', { tag: this.tag });
      }).catch((e) => {
        this.$_errorMixin_showError(getErrorFromResponse(e));
      }).finally(() => {
        this.isTagRemoving = false;
      });
    },
    ...mapActions({
      deleteTag: 'destroyTags',
    }),
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .pictures-grouped-by-tags {
    position: relative;
    width: 100%;
    padding: 0 9.53vw;

    &.disabled {
      animation: pictures-grouped-by-tags__fadein 1s ease-in alternate infinite;
    }

    .tag-info {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 3.7vh;

      .tag-info__delete {
        cursor: pointer;
      }
    }

    ::v-deep {
      .camera_picture_list__image {
        cursor: default;
      }
    }

    @-webkit-keyframes pictures-grouped-by-tags__fadein {
      from { opacity: .5; }
      to { opacity: 0; }
    }
    @-moz-keyframes pictures-grouped-by-tags__fadein {
      from { opacity: .5; }
      to { opacity: 0; }
    }
    @keyframes pictures-grouped-by-tags__fadein {
      from { opacity: .5; }
      to { opacity: 0; }
    }

  }
</style>
