import * as types from './mutation-types';

export default {
  [types.CURRENTUSER_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [types.CURRENTUSER_SUCCESS]: (state, data) => {
    state.isLoading = false;
    state.activeUser = data;
  },
  [types.CURRENTUSER_ERROR]: (state) => {
    state.isLoading = false;
  },
  [types.LOGIN_REQUEST]: (state) => {
    state.isLoading = true;
  },
  [types.LOGIN_SUCCESS]: (state) => {
    state.isLoading = false;
  },
  [types.LOGIN_ERROR]: (state) => {
    state.isLoading = false;
  },
};
