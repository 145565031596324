// eslint-disable-next-line import/no-cycle
import csite from '@/services/csite';
import { SET_LOADING } from '@/store/mutation-types';

import {
  GET_CLOSEST_IMAGES, SCROLL,
} from './mutation-types';

export default {
  async fetchClosestImages({ commit }, payload) {
    commit(SET_LOADING, true);
    return csite.images.closest_images.get({ params: payload }).then((result) => {
      commit(GET_CLOSEST_IMAGES, result);
      const { data = [] } = result;
      return data;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
  async getImagesScroll({ commit }, { id, payload }) {
    commit(SET_LOADING, true);
    return csite.images.scroll.get({ id, params: payload }).then((result) => {
      commit(SCROLL, result);
      const { data = [] } = result;
      return data;
    }).finally(() => {
      commit(SET_LOADING, false);
    });
  },
};
