import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
// eslint-disable-next-line import/no-cycle
import extra from './modules/extra';
import dashboard from './modules/dashboard';
// eslint-disable-next-line import/no-cycle
import support from './modules/support';
// eslint-disable-next-line import/no-cycle
import imagesExtra from './modules/images';
// eslint-disable-next-line import/no-cycle
import videosExtra from './modules/videos';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';
import autoGeneratedState from './autoGeneratedState';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ...autoGeneratedState,
    loadings: {},
    activeUser: null,
    activeMenu: null,
    isFullscreenMode: false,
    isMainLayoutPadding: true,
  },
  actions,
  mutations,
  modules: {
    auth,
    extra,
    dashboard,
    support,
    imagesExtra,
    videosExtra,
  },
});
