import { TYPES as MESSAGE_TYPES } from '@/components/v3/Message';

export const SHOW_ERROR_TIME = 3000; // 3 seconds

export const errorMixin = {
  data: () => ({
    $_errorMixin: {
      timeoutId: null,
      isShow: false,
      text: null,
      type: MESSAGE_TYPES.error,
    },
  }),
  computed: {
    $_errorMixin_data: {
      get() {
        return this.$data.$_errorMixin;
      },
      set(newValue) {
        this.$data.$_errorMixin = {
          ...this.$data.$_errorMixin,
          ...newValue,
        };
      },
    },
  },
  methods: {
    $_errorMixin_showError(text) {
      clearTimeout(this.$_errorMixin_data.timeoutId);
      this.$_errorMixin_data = {
        ...this.$_errorMixin,
        text,
        timeoutId: setTimeout(() => {
          this.$_errorMixin_data.isShow = false;
        }, SHOW_ERROR_TIME),
        isShow: true,
        type: MESSAGE_TYPES.error,
      };
    },
  },
};

export default errorMixin;
