export const DEFAULT_ERROR_MESSAGE_TEMPLATE = 'Oops... The timelapse has failed to load: #error_text#<br/> Please, try again later...';

/* Timelapse types */
export const START_TO_PRESENT = 'START_TO_PRESENT';
export const THREE_DAYS = 'THREE_DAYS';
export const SEVEN_DAYS = 'SEVEN_DAYS';
export const MONTHLY = 'MONTHLY';
export const SCHEDULED = 'SCHEDULED';

export const TIMELAPSE_CATEGORIES = {
  start_to_present: START_TO_PRESENT,
  three_days: THREE_DAYS,
  seven_days: SEVEN_DAYS,
  monthly: MONTHLY,
  scheduled: SCHEDULED,
};
/* * */
/* Timelapse statuses */
export const ERROR = 'error';
export const SUBMITTED = 'submitted';
export const QUEUED = 'queued';
export const CONFIGURING = 'configuring';
export const STARTING = 'starting';
export const CREATING = 'creating';
export const SAVING = 'saving';
export const FINISHED = 'finished';

export const TIMELAPSE_STATUSES = {
  error: ERROR,
  submitted: SUBMITTED,
  queued: QUEUED,
  configuring: CONFIGURING,
  starting: STARTING,
  creating: CREATING,
  saving: SAVING,
  finished: FINISHED,
};
/* * */
/* Timelapse video icons */
export const PLAY_ICON = 'mdi-play';
export const PAUSE_ICON = 'mdi-pause';

export const TIMELAPSE_VIDEO_ICONS = {
  play: PLAY_ICON,
  pause: PAUSE_ICON,
};
/* * */

/* * */
export const TIMELAPSE_REQUEST_MAPPING = {
  START_TO_PRESENT: 'stpTimelapses',
  THREE_DAYS: 'threeDayTimelapses',
  SEVEN_DAYS: 'weeklyTimelapses',
  MONTHLY: 'monthlyTimelapses',
  SCHEDULED: 'customTimelapses',
};
/* * */
