export const fullscreenBaseMixin = {
  data: () => ({
    $_fullscreenMixin: {
      eventName: 'fullscreenchange',
    },
  }),
  methods: {
    $_fullscreenMixin_exitFullScreenClickHandler() {
      if (!this.isFullScreen || !document.exitFullscreen) {
        return;
      }

      this.toggleFullscreen(false);
      document.exitFullscreen();
    },
    $_fullscreenMixin_fullScreenClickHandler() {
      this.toggleFullscreen(true);
      document.documentElement.requestFullscreen();
    },
    $_fullscreenMixin_onFullScreenChangeHandler() {
      this.toggleFullscreen(!!document.fullscreenElement);
    },
  },
};

export default fullscreenBaseMixin;
