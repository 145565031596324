import { forEach } from 'lodash';
import { GET_CLOSEST_IMAGES, SCROLL } from './mutation-types';

import {
  findIndexToMergeByDates,
  groupByCameraViews,
  mergePeriods,
} from './helpers';

export default {
  [GET_CLOSEST_IMAGES]: (state, payload) => {
    const { data = null } = payload;
    if (!data || !data.length) {
      return;
    }

    const imagesByCameraview = groupByCameraViews(data);
    forEach(imagesByCameraview, (images, cameraViewId) => {
      const imagesKeys = Object.keys(images);
      const from = images[imagesKeys[0]].date;
      const to = images[imagesKeys[imagesKeys.length - 1]].date;
      if (!(cameraViewId in state.closestImages)) {
        state.closestImages[cameraViewId] = [];
      }

      const indexToMerge = findIndexToMergeByDates(from, to, state.closestImages[cameraViewId]);
      if (indexToMerge > -1) {
        state.closestImages[cameraViewId][indexToMerge] = mergePeriods(
          { from, to, images },
          state.closestImages[cameraViewId][indexToMerge],
        );
        return;
      }

      state.closestImages[cameraViewId].push({ from, to, images });
    });
  },
  [SCROLL]: (state, payload) => {
    state.scroll = payload.normData;
  },
};
