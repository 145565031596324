<template>
  <div class="slideshow_camera" ref="slideshow_camera" :style="cssProps">
    <div class="swiper-container" :class="swiperContainer"
         @mouseenter="isSelectCamerasActive = true" @mouseleave="mouseLeaveHandler">
      <div class="swiper-wrapper">
        <div v-if="showBlankSlide" class="swiper-slide">
          <div class="blank-slide">
          </div>
        </div>
        <div class="swiper-slide" v-for="(projectView, index) in projectViews" v-else
             :key="`slideshow_${projectViewsUpdateNumber}_
             ${projectView.id}_${swiperRerenderKey}_${index}`">
          <CameraDetailCardSlideShow :project-view="projectView" v-if="!isShowCameraPreloader"
                                     :follow-link="!isSelectCamerasActive"
                                      @showCameraSelection="isSelectCamerasActive = true"/>
        </div>
      </div>
    </div>
    <CameraPreloader class="slideshow_camera__camera_preloader" v-if="isShowCameraPreloader" />
    <ProjectViewsList :selected-values="selectedCameras" @delay_change="onProjectViewChangeHandler"
                      v-if="isSelectCamerasActive" @menuState="menuState"/>
  </div>
</template>

<script>
import CameraDetailCardSlideShow from '@/components/v3/Cameras/CameraDetailCardSlideShow';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import ProjectViewsList from '@/components/v3/Helpers/Menu/ProjectViewsList';
import { findIndex } from 'lodash';

import { swiperMixin, projectViewsMixin } from '@/mixins/v3';
import { mapActions, mapState } from 'vuex';

import { THREE_SCREENS } from '@/constants/v3/dashboard';

import md5 from 'md5';

const SLIDESHOW_TIMEOUT = 10000; // ms
const SLIDESHOW_NO_IMAGES_TIMEOUT = 5000; // ms
const PROGRESS_BAR_UPDATE_TIMEOUT = 20; // ms
const FONT_SIZE_RATIO = 0.015;

export default {
  name: 'Slideshow',
  emits: ['project_views_change'],
  mixins: [
    projectViewsMixin,
    swiperMixin,
  ],
  components: {
    CameraPreloader,
    ProjectViewsList,
    CameraDetailCardSlideShow,
  },
  props: {
    selectedCameras: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    uid: null,
    projectViewsUpdateNumber: 0,
    isComponentActive: true,
    isInitialDataLoaded: false,
    isSelectCamerasActive: false,
    swiperContainer: null,
    progressbar: {
      value: 0,
      query: true,
      intervalId: null,
      intervalStep: 1,
    },
    nextSlide: {
      timeoutId: null,
    },
    swiperRerenderKey: 0,
    fontSize: null,
    isMenuActive: false,
    showBlankSlide: false,
  }),
  created() {
    const { _uid: uid } = this;
    this.swiperContainer = `swiper-container__${md5(uid)}`;
  },
  beforeMount() {
    if (this.selectedCameras.length) {
      const extraPayload = { id__in: this.selectedCameras.join(',') };
      this.$_projectViewsMixin_updateProjectViews({ extraPayload });
    }

    const swiperOptions = {
      ...this.swiper.swiperOptions,
      allowTouchMove: false,
      observer: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
    };
    this.swiper = { swiperClass: this.swiperContainer, swiperOptions };
  },
  mounted() {
    this.$_projectViewsMixin_loadNextProjectViews();
    this.$nextTick(() => {
      this.updateFontSize();
    });
    let timer;
    document.addEventListener('mousemove', () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        if (!this.isMenuActive) {
          this.isSelectCamerasActive = false;
        }
      }, 3000);
    });
  },
  watch: {
    projectViews(projectViews) {
      if (projectViews.length && !this.isInitialDataLoaded) {
        this.projectViewsUpdateNumber += 1;
        this.init(this.projectViews);
      }
    },
    camerasLayout() {
      this.swiperRerenderKey += 1;
      this.$nextTick(() => {
        this.updateFontSize();
      });
    },
    isFullScreen() {
      this.swiperRerenderKey += 1;
      this.$nextTick(() => {
        this.updateFontSize();
      });
    },
  },
  computed: {
    swiper: {
      get() {
        return this.$data.$_swiperMixin;
      },
      set(newValue) {
        this.$_swiperMixin_swiper = {
          ...this.$data.$_swiperMixin,
          ...newValue,
        };
      },
    },
    cssProps() {
      return {
        '--component-font-size': !this.fontSize ? 'inherit' : `${this.fontSize}px`,
        '--camera-picture-min-width': this.camerasLayout === THREE_SCREENS ? 'auto' : '100%',
      };
    },
    projectViews() {
      return this.$_projectViewsMixin_projectViewsList;
    },
    activeProjectView() {
      return this.projectViews[this.swiper.instance.realIndex];
    },
    isShowCameraPreloader() {
      return this.$data.$_projectViewsMixin.projectViews.isLoading && !this.isInitialDataLoaded;
    },
    ...mapState({
      isFullScreen: (state) => state.isFullscreenMode,
      camerasLayout: (state) => state.dashboard.camerasLayout,
    }),
  },
  methods: {
    menuState(value) {
      this.isMenuActive = value;
    },
    mouseLeaveHandler(e) {
      if (e.relatedTarget) {
        if (e.relatedTarget.classList.contains('project-views-list')
          || e.target.classList.contains('project-views-list')) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      }
      this.isSelectCamerasActive = false;
    },
    init(projectVews) {
      const [firstProjectView] = projectVews;
      if (!firstProjectView) {
        return;
      }

      const {
        latest_image_details: latestImageDetails = {},
        latest_image: latestImage = null,
      } = firstProjectView;

      if (latestImageDetails.is_pending) {
        return;
      }

      this.isInitialDataLoaded = true;

      const timeout = latestImage ? SLIDESHOW_TIMEOUT : SLIDESHOW_NO_IMAGES_TIMEOUT;
      this.scheduleProgressBar(timeout);
      this.nextSlide.timeoutId = setTimeout(this.getNextSlide, timeout);
    },
    updateProjectView(slideIndex) {
      const getLatestImagePayload = {
        cameraview: this.projectViews[slideIndex].cameraview,
        ordering: '-date',
        limit: 1,
      };
      this.fetchImages(getLatestImagePayload).then((response) => {
        const { projectViews } = this;
        const [newImageId] = response.dataList;
        const responseImage = {
          ...response.normData[newImageId],
          is_pending: false,
        };
        const projectViewIndex = findIndex(
          projectViews,
          (projectView) => projectView.cameraview === responseImage.cameraview,
        );
        projectViews[projectViewIndex] = {
          ...this.projectViews[projectViewIndex],
          latest_image: newImageId,
          latest_image_details: responseImage,
        };
        this.$_projectViewsMixin_updateProjectViews({ list: projectViews });
      }).finally(() => {
        this.goToNextSlide(slideIndex);
      });
    },
    goToNextSlide(slideIndex, timeout = SLIDESHOW_TIMEOUT) {
      if (this.projectViews.length - slideIndex <= 2) {
        this.$_projectViewsMixin_loadNextProjectViews();
      }
      // let Vue to rerender slides
      setTimeout(() => {
        slideIndex ? this.swiper.instance.slideNext() : this.swiper.instance.slideTo(0);
        this.scheduleProgressBar(timeout);
        this.nextSlide.timeoutId = setTimeout(this.getNextSlide, timeout);
      }, 1500);
    },
    getNextSlide() {
      this.resetProgressBar();
      let nextSlideIndex = this.swiper.instance.realIndex + 1;
      if (this.projectViews.length <= nextSlideIndex) {
        nextSlideIndex = 0;
      }
      const {
        cameraview = null,
        latest_image: latestImage = null,
      } = this.projectViews[nextSlideIndex];
      if (!cameraview || !latestImage) {
        this.goToNextSlide(nextSlideIndex, SLIDESHOW_NO_IMAGES_TIMEOUT);
        return;
      }
      this.updateProjectView(nextSlideIndex);
    },
    scheduleProgressBar(timeout) {
      this.progressbar.query = false;
      this.progressbar.intervalId = setInterval(
        () => {
          const step = PROGRESS_BAR_UPDATE_TIMEOUT * this.progressbar.intervalStep;
          this.progressbar.value = (step / (timeout - 200)) * 100;
          this.progressbar.intervalStep += 1;
          if (this.progressbar.value > 100) {
            clearInterval(this.progressbar.intervalId);
          }
        },
        PROGRESS_BAR_UPDATE_TIMEOUT,
      );
    },
    resetProgressBar() {
      clearInterval(this.progressbar.intervalId);
      this.progressbar = {
        ...this.progressbar,
        intervalStep: 1,
        intervalId: null,
        query: true,
        value: 0,
      };
    },
    updateFontSize() {
      const containerWidth = this.$refs.slideshow_camera.clientWidth;
      this.fontSize = containerWidth * FONT_SIZE_RATIO;
    },
    onProjectViewChangeHandler({ value = null }) {
      if (value.includes('(blank)')) {
        this.showBlankSlide = true;
        this.$emit('project_views_change', { value: '(blank)' });
        this.resetComponent();
      } else {
        this.showBlankSlide = false;
        this.$emit('project_views_change', { value });
        this.resetComponent();
        const extraPayload = value && value.length ? { id__in: value.join(',') } : {};
        this.$_projectViewsMixin_updateProjectViews({ extraPayload });
        this.$_projectViewsMixin_resetProjectViews();
      }
    },
    resetComponent() {
      clearTimeout(this.nextSlide.timeoutId);
      this.resetProgressBar();
      this.isInitialDataLoaded = false;
    },
    ...mapActions({
      fetchImages: 'listImages',
    }),
  },
  beforeDestroy() {
    clearTimeout(this.nextSlide.timeoutId);
    clearInterval(this.progressbar.intervalId);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/buttons.scss';
  .blank-slide {
    background-color: white;
    height: 100%;
    width: 100%;
  }
  .slideshow_camera {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 100%;
    font-size: var(--component-font-size);

    .swiper-container {
      height: 100%;
    }

    .slideshow_camera__camera_preloader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }

    ::v-deep {
      .camera-picture .camera-picture__picture.fill-by-height img {
        min-width: var(--camera-picture-min-width);
      }
    }
  }
</style>
