import VueCookies from 'vue-cookies';
import Vue from 'vue';

Vue.use(VueCookies);

export const APPLICATION_CONSENT_COOKIE = 'applicationConsent';
export const ANALYTICS_CONSENT_COOKIE = 'analyticsConsent';
export const YOUTUBE_CONSENT_COOKIE = 'youtubeConsent';
export const GOOGLE_ANALYTICS_COOKIE = '_ga';
export const GOOGLE_ID = '_gid';
export const GOOGLE_ANALYTICS_THROTTLE = '_gat';

export const cookieMixin = {
  data() {
    return {
      isAnalyticCookies: false,
      isYoutubeCookies: false,
      isApplicationCookies: false,
    };
  },
  beforeMount() {
    this.checkInitialCookies();
  },
  methods: {
    checkInitialCookies() {
      this.isAnalyticCookies = Number(this.$cookies.get(ANALYTICS_CONSENT_COOKIE)) === 1;
      this.isYoutubeCookies = Number(this.$cookies.get(YOUTUBE_CONSENT_COOKIE)) === 1;
      this.isApplicationCookies = Number(this.$cookies.get(APPLICATION_CONSENT_COOKIE)) === 1;
    },
    saveSelection() {
      if (!this.isApplicationCookies) {
        this.$cookies.set(APPLICATION_CONSENT_COOKIE, 1, '365d', '/');
      }
      const analyticChecked = this.isAnalyticCookies ? 1 : -1;
      const youtubeChecked = this.isYoutubeCookies ? 1 : -1;
      this.$cookies.set(ANALYTICS_CONSENT_COOKIE, analyticChecked, '365d', '/');
      this.$cookies.set(YOUTUBE_CONSENT_COOKIE, youtubeChecked, '365d', '/');
      if (analyticChecked === -1) {
        this.removeAllGoogleAnalyticCookies();
      }
    },
    removeAllGoogleAnalyticCookies() {
      if (this.$cookies.get(GOOGLE_ANALYTICS_COOKIE)) {
        this.$cookies.remove(GOOGLE_ANALYTICS_COOKIE);
      }
      if (this.$cookies.get(GOOGLE_ID)) {
        this.$cookies.remove(GOOGLE_ID);
      }
      if (this.$cookies.get(GOOGLE_ANALYTICS_THROTTLE)) {
        this.$cookies.remove(GOOGLE_ANALYTICS_THROTTLE);
      }
    },
  },
};

export default cookieMixin;
