<template>
  <div class="ai_questions">
    <div class="ai_questions_container">
      <div class="ai_questions__content" ref="scrollContainer">
        <div v-if="answers.length > 0" class="ai_questions__answer_section">
          <div v-for="(question, index) in questions" :key="index"
               class="ai_questions__answer_section_content">
            <div class="ai_questions__answer__wrap">
              <i class="mdi mdi-account"></i>
              <div>
                <div class="ai_questions__answer_label">
                  <strong>{{ $t('ai.chat.you') }}</strong>
                </div>
                <div class="ai_questions__answer_question">
                  {{ question }}
                </div>
              </div>
            </div>
            <div class="ai_questions__answer__wrap">
              <picture class="ai_questions_logo_location" >
                <img :src="logo" alt="C-SITE Logo" class="ai_questions_logo_size"/>
              </picture>
              <div>
                <div class="ai_questions__answer_label">
                  <strong>C-Site AI</strong>
                </div>
                <div class="ai_questions__answer_question">
                  {{ answers[index] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="ai_questions__logo_section">
          <picture class="ai_questions_logo_location">
            <img :src="logo" alt="C-SITE Logo" />
          </picture>
          <div class="ai_questions__logo_text">{{ $t('ai.chat.logo_text') }}</div>
        </div>
      </div>
    </div>
    <div class="fixed_questions_input">
      <form
        class="ai_questions_form"
        ref="form"
        @submit.prevent="submitForm">
          <div class="ai_questions_form__inputs">
            <div class="ai_questions_form__inputs__question">
              <input
                id="question"
                type="text"
                :placeholder="$t('ai.chat.question.placeholder')"
                v-model="formQuestion.value">
            </div>
            <i class="mdi mi-arrow-up send_button dark-blue" @click="submitForm" />
          </div>
          <i class="mdi mi-refresh refresh-icon" type="button" @click="clearText"></i>
      </form>
    </div>
  </div>
</template>

<script>
const logo = require('@/assets/images/v3/c-site_logo_pic_only.svg');

export default {
  name: 'AskTheMachine',
  data() {
    return {
      logo,
      questions: [],
      answers: [],
      answer: '',
      formQuestion: {
        value: '',
        error: null,
      },
    };
  },
  updated() {
    this.scrollToBottom();
  },
  methods: {
    clearText() {
      this.questions = [];
      this.answers = [];
    },
    scrollToBottom() {
      const container = this.$refs.scrollContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
    submitForm(e) {
      e.preventDefault();
      this.questions.push(this.formQuestion.value);
      this.answer = `No A.I. yet, so I return the question to you: ${this.formQuestion.value}`;
      this.answers.push(this.answer);
      this.formQuestion.value = '';
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/mixins/v3/settings';
@import '~@/sass/v3/variables.scss';

.ai_questions {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;
  @media (orientation: portrait) {
    width: 100%;
  }
  .ai_questions_container {
    .ai_questions__content {
      margin-left: 5vw;
      margin-right: 5vw;
      flex-grow: 1;
      overflow-y: auto;
      max-height: 50vh;
      .ai_questions__answer_section {
        width: 100%;
        min-height: 50vh;
        .ai_questions__answer_section_content {
          margin-left: 1vw;
          margin-right: 1vw;
          margin-bottom: 3vh;
          .ai_questions__answer__wrap {
            display: flex;
            margin-top: 1vh;
            margin-bottom: 1.4vh;
            .ai_questions__answer_label {
              margin-left: 0.5vw;
              margin-bottom: 1vh;
              padding-top: 0.4vh;
              font-size: 0.8em;
            }
            .ai_questions__answer_question {
              display: block;
              margin-left: 0.5vw;
              font-size: 0.8em;
            }
            .ai_questions_logo_size {
              width: 1vw;
              height: auto;
            }
          }
        }
      }
      .ai_questions__logo_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 50vh;
        .ai_questions__logo_text {
          margin-top: 2vh;
        }
      }
    }
  }

  .fixed_questions_input {
    margin-left: 5vw;
    margin-right: 5vw;
    .ai_questions_form {
      margin-top: 4vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ai_questions_form__inputs {
        flex-grow: 1;
        padding: 0.7vh;
        border-radius: 15px;
        border: 1px solid $default-disabled-color;
        display: flex;
        align-items: center;
        .ai_questions_form__inputs__question {
          width: 100%;
          margin-bottom: 1vh;
          margin-right: 2vw;

          input {
            font-size: 0.8em;
            width: 100%;
            padding: 0;
            line-height: 1;
            color: $default-blue-ultra-dark-color;
            &:focus, &:active {
              outline: none;
            }
          }
        }
        .send_button {
            width: auto;
            min-width: 2vw;
            height: auto;
            font-size: inherit;
            border: 1px solid #CBCFD9;
            padding: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
            &.dark-blue {
              color: $default-white-color;
              background-color: $default-blue-ultra-dark-color;
              border: none;
            }
          }
      }
      .refresh-icon {
        margin-left: 0.5vw;
        font-size: 1.1em;
      }
    }
  }
}
</style>
