<template>
  <div class="likes">
    <Message :text="error.text" :type="error.type" v-if="error.isShow" />
    <div class="likes_list" ref="likes">
      <CameraDetailCard :image-details="likeImage.image_details"
                        :company-name="likeImage.image_details.company_name"
                        :project-name="likeImage.image_details.project_name"
                        :camera-name="likeImage.image_details.projectview_name"
                        :project-id="likeImage.image_details.project"
                        :project-view-id="likeImage.image_details.projectview"
                        v-for="likeImage in likeImagesList" :key="`like_${likeImage.id}`"/>
    </div>
    <camera-preloader v-if="likeImages.isLoading" />
    <NoItemsYet
      v-if="isShowNoCameras"
      :header="$t('tags_likes.no_likes')"
      text="tags_likes.likes_text"
      icon="mdi mdi-cards-heart-outline" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CameraDetailCard from '@/components/v3/Cameras/CameraDetailCard';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import { LIKES_PER_REQUEST_LIMIT, LOAD_NEW_CAMERAS_WHEN_LEFT_NUM } from '@/constants/v3/cameras';
import {
  compact, difference, filter, includes, join, map, sortBy, uniq,
} from 'lodash';
import { isInViewport } from '@/helpers';
import { permissionMixin } from '@/mixins/v3';
import Message, { TYPES as MESSAGE_TYPES } from '@/components/v3/Message';
import PERMISSIONS from '@/constants/v3';
import NoItemsYet from '@/components/v3/Helpers/NoItemsYet';

export default {
  name: 'Likes',
  mixins: [permissionMixin],
  components: {
    NoItemsYet,
    Message,
    CameraPreloader,
    CameraDetailCard,
  },
  data: () => ({
    likeImages: {
      isLoading: false,
      totalCount: undefined,
      list: [],
    },
    error: {
      isShow: false,
      text: null,
      type: MESSAGE_TYPES.error,
    },
  }),
  mounted() {
    if (!this.isAllowToLike) {
      this.error = {
        ...this.error,
        text: 'You are not allowed to see likes',
        isShow: true,
      };
      return;
    }
    this.loadNextLikeImages();
    window.addEventListener('scroll', this.checkIfNeedsToStartLoadingLikes);
  },
  watch: {
    likeImagesList(updatedLikeImagesList, oldLikeImagesList) {
      const oldLikeImagesIds = map(
        oldLikeImagesList,
        (oldLikeImage) => oldLikeImage.id,
      );
      const updatedLikeImagesIds = map(
        updatedLikeImagesList,
        (updatedLikeImage) => updatedLikeImage.id,
      );
      const newLikeImagesIds = difference(updatedLikeImagesIds, oldLikeImagesIds);
      if (newLikeImagesIds.length) {
        this.loadImagesForLikes(newLikeImagesIds);
      }
    },
  },
  computed: {
    isAllowToLike() {
      return this.$_permissionMixin_hasPermission(PERMISSIONS.add_like);
    },
    isLoadedAllLikes() {
      return this.likeImages.totalCount - this.totalLikesLoaded > 0;
    },
    likeImagesList() {
      return sortBy(this.likeImages.list, (likeImage) => likeImage.id).reverse();
    },
    totalLikesLoaded() {
      return this.likeImages.list.length;
    },
    isShowNoCameras() {
      return this.likeImages.totalCount === 0 && !this.likeImages.isLoading;
    },
    ...mapState({
      likes: (state) => state.likes,
      user: (state) => state.activeUser,
    }),
  },
  methods: {
    loadNextLikeImages() {
      this.updateLikeImages({ isLoading: true });
      const fetchLikesPayload = {
        user: this.user.id,
        object_content_type__model: 'image',
        offset: this.totalLikesLoaded,
        limit: LIKES_PER_REQUEST_LIMIT,
        ordering: '-id',
      };
      this.fetchLikes(fetchLikesPayload).then((payload) => {
        const likes = map(payload.normData, (like) => ({
          ...like,
          image_details: {
            is_pending: true,
            like: true,
          },
        }));
        this.updateLikeImages({
          totalCount: payload.response.data.count,
          list: [
            ...this.likeImages.list,
            ...likes,
          ],
        });
      }).finally(() => {
        this.updateLikeImages({ isLoading: false });
      });
    },
    loadImagesForLikes(likeImagesIds) {
      const imagesIdsToLoad = uniq(compact(map(
        filter(
          this.likeImagesList,
          (likeImage) => includes(likeImagesIds, likeImage.id),
        ),
        (likeImage) => likeImage.object_id,
      )));
      if (!imagesIdsToLoad.length) {
        return;
      }
      this.fetchImages({ id__in: join(imagesIdsToLoad) })
        .then((payload) => {
          this.likeImages.list = map(this.likeImages.list, (likeImage) => {
            if (includes(payload.dataList, likeImage.object_id)) {
              likeImage.image_details = {
                ...likeImage.image_details,
                ...payload.normData[likeImage.object_id],
              };
            }
            return likeImage;
          });
        }).finally(() => {
          this.likeImages.list = map(this.likeImages.list, (likeImage) => {
            if (includes(likeImagesIds, likeImage.id)) {
              likeImage.image_details.is_pending = false;
            }
            return likeImage;
          });
        });
    },
    updateLikeImages(data) {
      this.likeImages = {
        ...this.likeImages,
        ...data,
      };
    },
    checkIfNeedsToStartLoadingLikes(e) {
      if (!this.isLoadedAllLikes
        || !this.$refs.likes
        || this.likeImages.isLoading
        || e.deltaY <= 0) {
        return;
      }
      const nodes = this.$refs.likes.querySelectorAll(':scope > *');
      if (!nodes.length) {
        return;
      }
      const nodeToCheckVisibility = nodes[nodes.length - LOAD_NEW_CAMERAS_WHEN_LEFT_NUM];
      if (isInViewport(nodeToCheckVisibility)) {
        this.loadNextLikeImages();
      }
    },
    ...mapActions({
      fetchLikes: 'listLikes',
      fetchImages: 'listImages',
    }),
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.checkIfNeedsToStartLoadingLikes);
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';
  @import '~@/sass/mixins/v3/buttons.scss';

  .likes {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 55.3vw;
    max-width: 100%;
    margin-top: 5vh;
  }
</style>
