import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getBrowserLocale, getLocalStorageLocale } from './utils/i18n/get-locale';
import { supportedLocalesInclude } from './utils/i18n/supported-locales';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function getStartingLocale() {
  const localStorageLocale = getLocalStorageLocale();
  if (supportedLocalesInclude(localStorageLocale)) {
    return localStorageLocale;
  }

  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  }

  return process.env.VUE_APP_I18N_LOCALE || 'en';
}

const i18n = new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
});

Vue.prototype.$changeLanguage = function (locale) {
  if (supportedLocalesInclude(locale)) {
    i18n.locale = locale;
  }
};
// export default new VueI18n({
//   locale: getStartingLocale(),
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages(),
// });

export default i18n;
