<template>
  <div class="base-button" :class="[ isActive ? 'active' : '', isDisabled ? 'disabled' : '', skin ]"
       @click="onClickHandler">
    {{ title }}
  </div>
</template>

<script>
import { includes } from 'lodash';

export const BUTTON_STYLES = ['blue', 'dark-blue'];

export default {
  name: 'WhiteButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    skin: {
      type: String,
      validator(value) {
        return !value.length || includes(BUTTON_STYLES, value);
      },
      default: '',
    },
  },
  data() {
    return {
      menuButtons: this.menuList,
      routePath: this.$route.matched,
      activeIndex: 0,
    };
  },
  methods: {
    onClickHandler(e) {
      if (this.isDisabled) {
        return;
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
  .base-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 10.2vw;
    height: 4.7vh;
    font-size: inherit;
    border: 1px solid #CBCFD9;
    padding: 5px;
    cursor: pointer;

    @media (orientation: portrait) {
      min-width: 15vw;
      padding: .7vh;
      height: 3vh;
    }

    @media (orientation: landscape) and (max-height: 500px) {
      font-size: 1.4em;
      padding: 4vh;
    }
    @media (orientation: portrait) and (max-height: 500px) {
      font-size: 1.4em;
      padding: 4vh;
    }

    &.blue {
      color: $default-white-color;
      background-color: $default-blue-color;
      border: none;
    }

    &.dark-blue {
      color: $default-white-color;
      background-color: $default-blue-ultra-dark-color;
      border: none;
    }

    &.active {
      background-color: $default-blue-ultra-dark-color;
      color: $default-white-color;
    }

    &.disabled {
      background-color: $default-disabled-color;
      cursor: default;
    }
  }
</style>
