<template>
  <div class="popup">
    <div class="popup__box">
      <div class="popup__box__title">
        Customize {{ memberToCustomize.last_name }} {{ memberToCustomize.first_name }}
      </div>
      <div class="popup__box__subtext">
        Reminder: Changing the user's rights can have a severe impact on what a user can see and do!
      </div>
      <div style="margin-left: 4vw;">
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="daily_timelapse"
              :checked="currentData.daily_timelapse" disabled>
            <label for="daily_timelapse" />
            <div class="checkboxes__checkbox__title">
              Can see daily timelapse (not customizable)
            </div>
          </div>
        </div>
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="weekly_timelapse"
              :checked="currentData.weekly_timelapse"
              @change="currentData.weekly_timelapse = !currentData.weekly_timelapse">
            <label for="weekly_timelapse" />
            <div class="checkboxes__checkbox__title">
              Can see weekly timelapse
            </div>
          </div>
        </div>
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="weekly_timelapse"
              :checked="currentData.monthly_timelapse"
              @change="currentData.monthly_timelapse = !currentData.monthly_timelapse">
            <label for="monthly_timelapse" />
            <div class="checkboxes__checkbox__title">
              Can see monthly timelapse
            </div>
          </div>
        </div>
        <div class="checkboxes" >
          <div class="checkboxes__checkbox">
            <input
              type="checkbox"
              id="weekly_timelapse"
              :checked="currentData.stp_timelapse"
              @change="currentData.stp_timelapse = !currentData.stp_timelapse">
            <label for="monthly_timelapse" />
            <div class="checkboxes__checkbox__title">
              Can see start-to-present timelapse
            </div>
          </div>
        </div>
        <div class="checkboxes">
          <div class="checkboxes__checkbox">
            <input type="checkbox"
                   id="custom_timelapse"
                   :checked="currentData.custom_timelapse"
                   @change="currentData.custom_timelapse = !currentData.custom_timelapse;"
                   >
            <label for="custom_timelapse"></label>
            <div class="checkboxes__checkbox__title">
              Can view and make custom timelapses
            </div>
          </div>
        </div>
        <div class="checkboxes">
          <div class="checkboxes__checkbox">
            <input type="checkbox"
                   id="logo_management"
                   :checked="currentData.logo_management"
                   @change="currentData.logo_management = !currentData.logo_management;"
                   >
            <label for="logo_management"></label>
            <div class="checkboxes__checkbox__title">
              Can do logo management
            </div>
          </div>
        </div>
        <div class="checkboxes">
          <div class="checkboxes__checkbox">
            <input type="checkbox"
                   id="project_location"
                   :checked="currentData.project_location"
                   @change="currentData.project_location = !currentData.project_location;"
                   >
            <label for="project_location"></label>
            <div class="checkboxes__checkbox__title">
              Can change the location of the project on the map
            </div>
          </div>
        </div>
        <div style="margin-top: 5vh;">
          <ButtonSubmit :title="$t('buttons.save_changes')"
                        @click="changeGroupsPermissions"
                        class="button_submit"/>
        </div>
      </div>
      <div class="popup__box__icon">
        <i class="icon__close mdi mdi-window-close" @click="onCloseClickHandler"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import { find } from 'lodash';

export default {
  name: 'CustomizeMemberForm',
  components: { ButtonSubmit },
  props: ['memberToCustomize'],
  data() {
    return {
      isLoading: false,
      startPermissions: null,
      startGroups: null,
    };
  },
  async mounted() {
    this.startPermissions = this.memberToCustomize.permissions;
    this.startGroups = this.memberToCustomize.groups;
    await this.fetchAllGroups();
  },
  computed: {
    // customTimelapses() {
    //   console.log(this.allGroups);// Check if allGroups is an array
    //   // const productOwnerId = this.allGroups.find((group) => group.name ===
    //   'Company owners')?.id;
    //   const customTimelapsesGroup = find(
    //     this.allGroups,
    //     (group) => group.name === 'Custom time-lapse videos',
    //   )?.id;
    //   console.log(customTimelapsesGroup);
    //   // return this.memberToCustomize.groups.includes(productOwnerId);
    //   return customTimelapsesGroup;
    // },
    // logoManagement() {
    //   console.log(this.allGroups);// Check if allGroups is an array
    //   // const productOwnerId = this.allGroups.find((group) => group.name ===
    //   'Company owners')?.id;
    //   const logoManagentGroup = find(
    //     this.allGroups,
    //     (group) => group.name === 'Logo management',
    //   )?.id;
    //   console.log(logoManagentGroup);
    //   // return this.memberToCustomize.groups.includes(productOwnerId);
    //   return logoManagentGroup;
    // },
    // projectLocation() {
    //   console.log(this.allGroups);// Check if allGroups is an array
    //   // const productOwnerId = this.allGroups.find((group) => group.name ===
    //   'Company owners')?.id;
    //   const projectLocationGroup = find(
    //     this.allGroups,
    //     (group) => group.name === 'Project Location',
    //   )?.id;
    //   console.log(projectLocationGroup);
    //   // return this.memberToCustomize.groups.includes(productOwnerId);
    //   return projectLocationGroup;
    // },
    currentData() {
      const customTimelapsesGroupId = find(
        this.allGroups,
        (group) => group.name === 'Custom time-lapse videos',
      )?.id;
      const logoManagementGroupId = find(
        this.allGroups,
        (group) => group.name === 'Logo management',
      )?.id;
      const projectLocationGroupId = find(
        this.allGroups,
        (group) => group.name === 'Project Location',
      )?.id;
      console.log(customTimelapsesGroupId);
      console.log(this.memberToCustomize.groups);
      console.log(this.memberToCustomize.groups.includes(customTimelapsesGroupId));
      return {
        daily_timelapse: this.memberToCustomize.permissions.includes('view_dailytimelapse'),
        weekly_timelapse: this.memberToCustomize.permissions.includes('view_weeklytimelapse'),
        monthly_timelapse: this.memberToCustomize.permissions.includes('view_monthlytimelapse'),
        stp_timelapse: this.memberToCustomize.permissions.includes('view_stptimelapse'),
        custom_timelapse: this.memberToCustomize.groups.includes(customTimelapsesGroupId),
        logo_management: this.memberToCustomize.groups.includes(logoManagementGroupId),
        project_location: this.memberToCustomize.groups.includes(projectLocationGroupId),
      };
    },
    ...mapState({
      allGroups: (state) => state.extra.allGroups,
    }),
  },
  methods: {
    lookUpName(name) {
      const customTimelapsesGroupId = find(
        this.allGroups,
        (group) => group.name === name,
      )?.id;
      return customTimelapsesGroupId;
    },
    async changeGroupsPermissions() {
      this.isLoading = true;
      const permissionsChanges = [];
      const groupChanges = [];
      const permissionsMap = {
        weekly_timelapse: 'view_weeklytimelapse',
        monthly_timelapse: 'view_monthlytimelapse',
        stp_timelapse: 'view_stptimelapse',
      };
      const groupsMap = {
        custom_timelapse: 'Custom time-lapse videos',
        logo_management: 'Logo management',
        project_location: 'Project Location',
      };
      Object.entries(this.currentData).forEach(([key, value]) => {
        if (permissionsMap[key]) {
          const permCodename = permissionsMap[key];
          if (this.startPermissions.includes(permCodename) !== value) {
            permissionsChanges.push({
              permission_codename: permCodename,
              grant: String(value),
            });
          }
        } else if (groupsMap[key]) {
          const groupCodename = groupsMap[key];
          const isMemberInitially = this.startGroups.includes(this.lookUpName(groupCodename));
          if (isMemberInitially !== value) {
            groupChanges.push({
              group_name: groupCodename,
              grant: String(value),
            });
          }
        }
      });
      if (permissionsChanges.length > 0) {
        const permissionsPayload = {
          id: this.memberToCustomize.id,
          permissions: permissionsChanges,
        };
        await this.patchChangePermissions(permissionsPayload).then((response) => {
          console.log(response);
        });
      }
      if (groupChanges.length > 0) {
        console.log('groupChanges');
        console.log(groupChanges);
        const groupsPayload = {
          id: this.memberToCustomize.id,
          groups: groupChanges,
        };
        console.log(groupsPayload);
        await this.patchChangeGroups(groupsPayload).then((response) => {
          console.log(response);
          console.log('** ** **');
        });
      }
      this.isLoading = true;
      this.$emit('close');
    },
    onCloseClickHandler() {
      this.$emit('close');
    },
    ...mapActions({
      fetchUsers: 'listUsers',
      fetchCompanyMemberships: 'listCompanymemberships',
      patchChangePermissions: 'patchChangePermissions',
      patchChangeGroups: 'patchChangeGroups',
      fetchAllGroups: 'fetchAllGroups',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/checkboxes.scss';

.popup {
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.2);

  .popup__box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60%;
    max-height: 90%;
    margin-top: 10vh;
    padding: 2.5vh;
    background-color: $default-background-color;
    cursor: default;

    @media only screen and (max-width: 900px) {
      max-height: 80%;
      min-height: 80%;
      max-width: 100%;
    }

    .popup__box__title {
      padding-left: 1.5vw;
      padding-top: 1.5vh;
      padding-bottom: 2vh;
      font-size: 2em;
      font-weight: bold;
      color: $default-blue-dark-color;
    }
    .popup__box__subtext {
      display: flex;
      margin-left: 2vw;
      margin-right: 2vw;
      margin-bottom: 4vh;
    }
    .popup__box__buttons {
      margin-top: 6vh;
    }
  }
}

.icon__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1.5vh;
  padding-top: 1.5vh;
  opacity: 0.4;
}
</style>
