import { render, staticRenderFns } from "./RegisterConfirmPage.vue?vue&type=template&id=207e4692&scoped=true&"
import script from "./RegisterConfirmPage.vue?vue&type=script&lang=js&"
export * from "./RegisterConfirmPage.vue?vue&type=script&lang=js&"
import style0 from "./RegisterConfirmPage.vue?vue&type=style&index=0&id=207e4692&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207e4692",
  null
  
)

export default component.exports