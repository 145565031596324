<template>
  <div class="exit_fullscreen__box" v-if="this.$_fullscreenMixin_isFullScreen">
    <i class="mdi mdi-close-box-outline" @click="$_fullscreenMixin_exitFullScreenClickHandler"></i>
  </div>
</template>

<script>
import { fullscreenMixin } from '@/mixins/v3/fullscreen/fullscreenMixin';

export default {
  name: 'ExitFullscreen',
  mixins: [fullscreenMixin],
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';

.exit_fullscreen__box {
  position: fixed;
  display: flex;
  box-sizing: content-box;
  flex-flow: nowrap;
  top: 0;
  right: 0;
  padding: 1.5vh 1vw;
  z-index: 1000000000;
  justify-content: center;

  i {
    cursor: pointer;
  }

  @media only screen and ( max-width: 900px) {
    font-size: 2em;
    padding: 2.5vw;
  }
}

</style>
