export const SINGLE_LAYOUT = 'SINGLE';
export const DOUBLE_LAYOUT = 'DOUBLE';
export const QUADRUPLE_LAYOUT = 'QUADRUPLE';
export const NINE_FOLD_LAYOUT = 'NINE-FOLD';

export const KEY_TO_EXIT_FULL_SCREEN_MODE = 'Escape';
export const FULL_SCREEN_ALERT_SHOW_PERIOD = 3000;

export const TIMEOUT_BEFORE_UPDATE_USER_FRONTEND_SETTINGS = 3000;

export const SHUFFLE_LAYOUT_CAMERAS_COUNT = 4;

export const PROJECTS_LAYOUTS = {
  single: SINGLE_LAYOUT,
  double: DOUBLE_LAYOUT,
  quadruple: QUADRUPLE_LAYOUT,
  nineFold: NINE_FOLD_LAYOUT,
};
