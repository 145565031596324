import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import Vue2GoogleMaps from './plugins/vue2-google-maps';
import vuetify from './plugins/vuetify';
import i18n from './i18n';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  Vue2GoogleMaps,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
