import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    // key: process.env.GOOGLE_MAPS_KEY,
    key: 'AIzaSyCdNQZ_gOfCXBJjJq7OmSqwO2B2-Rv4jMw',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // v: '3.26',
  },
  installComponents: true,
});

export default VueGoogleMaps;
