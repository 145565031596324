<template>
  <div class="layout_v3__preloader" :class="{'show': isShow}">
    <div class="layout_v3__preloader__pictures" v-if="!isShowError">
      <picture
        class="layout_v3__preloader__pictures__animation">
        <img :src="logoPic" alt="logo_pic" />
      </picture>
      <picture>
        <img :src="logoText" alt="logo_text" />
      </picture>
    </div>
    <message :text="errorText" v-if="isShowError" />
  </div>
</template>

<script>
import Message from '@/components/v3/Message';

const logoPic = require('@/assets/images/v3/c-site_logo_pic_only.svg');
const logoText = require('@/assets/images/v3/c-site_logo_text_only.svg');

export default {
  name: 'FullscreenPreloader',
  components: {
    Message,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
    },
  },
  data: () => ({
    logoPic,
    logoText,
  }),
  computed: {
    isShowError() {
      return !!this.errorText;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/preloaders.scss';

.layout_v3__preloader {
  @include preloader;

  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 0;
  transition: height 0s linear, 1.2s, top 1s ease;
  z-index: 100000000;

  &.show {
    height: 100%;
    top: 0;
    transition: height 0s linear, top 0.5s ease 0.05s;
  }

  .layout_v3__preloader__pictures {

    .layout_v3__preloader__pictures__animation {
      @include bounce-animation_mixin(bounce-animation);
      @include circle-animation_mixin(circle-animation);

        position: relative;
        margin-right: .7vw;
        animation: circle-animation 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite,
        bounce-animation 0.5s infinite linear;
    }
  }
}

</style>
