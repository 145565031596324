<template>
  <div class="button-submit">
    <base-button :title="title" skin="dark-blue" @click="onClickHandler"
                 :is-disabled="isDisabled"/>
    <div class="button-submit__loading" v-if="isShowLoading">{{ $t(loadingText) }}</div>
  </div>
</template>

<script>
import BaseButton from '@/components/v3/BaseButton';

export default {
  name: 'ButtonSubmit',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isShowLoading: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: 'messages.please_wait',
    },
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  methods: {
    onClickHandler(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@/sass/v3/variables.scss';

  .button-submit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 3.6vh;

    .button-submit__loading {
      margin-left: 1vw;
      animation: auth-form__inputs__button__status__color-change 1s infinite;
      white-space: nowrap;
    }

    @keyframes auth-form__inputs__button__status__color-change {
      0% { color: $default-blue-ultra-dark-color; }
      50% { color: $default-blue-color; }
      100% { color: $default-blue-ultra-dark-color; }
    }
  }
</style>
