<template>
  <div class="page-not-exist">
    <div class="page-not-exist__text">
      <div>
        <h1>PAGE NOT FOUND</h1>
        <br>
        <h4>Sorry, the page you're looking for doesn't exist</h4>
      </div>
    </div>
    <div>
      <ButtonSubmit
        class="page-not-exist__button"
        title="Back"
        @click="goBack"/>
    </div>
  </div>
</template>

<script>
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';

export default {
  components: {
    ButtonSubmit,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables.scss';
@import '~@/sass/mixins/v3/buttons.scss';

.page-not-exist {

  .page-not-exist__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4vh;

    h1 {
      text-align: center;
    }
  }
  .page-not-exist__button {
    margin-top: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
