import { mapActions, mapState } from 'vuex';
import { merge, isNull } from 'lodash';
import fullscreenMixinFactory from './factories/fullscreenMixinFactory';

export const fullscreenMixinAbstract = {
  mounted() {
    if (!this.$_fullscreenMixin_isSupported) {
      return;
    }

    document.addEventListener(
      this.$_fullscreenMixin_data.eventName,
      this.$_fullscreenMixin_onFullScreenChangeHandler,
    );
  },
  beforeDestroy() {
    if (!this.$_fullscreenMixin_isSupported) {
      return;
    }

    document.removeEventListener(
      this.$_fullscreenMixin_data.eventName,
      this.$_fullscreenMixin_onFullScreenChangeHandler,
    );
  },
  computed: {
    $_fullscreenMixin_isSupported() {
      return !isNull(this.$_fullscreenMixin_data.eventName);
    },
    $_fullscreenMixin_isFullScreen() {
      return this.isFullScreen;
    },
    $_fullscreenMixin_data: {
      get() {
        return this.$data.$_fullscreenMixin;
      },
      set(newValue) {
        this.$data.$_fullscreenMixin = {
          ...this.$data.$_fullscreenMixin,
          ...newValue,
        };
      },
    },
    ...mapState({ isFullScreen: (state) => state.isFullscreenMode }),
  },
  methods: {
    $_fullscreenMixin_exitFullScreenClickHandler() { },
    $_fullscreenMixin_onFullScreenChangeHandler() { },
    $_fullscreenMixin_fullScreenClickHandler() { },
    ...mapActions({
      toggleFullscreen: 'toggleFullscreen',
    }),
  },
};

const fullscreenMixinFromFactory = fullscreenMixinFactory();
export const fullscreenMixin = merge(fullscreenMixinAbstract, fullscreenMixinFromFactory);

export default fullscreenMixin;
