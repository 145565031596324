/**
 * Resource class
 */

// eslint-disable-next-line import/no-cycle
import csiteClient from './client';
// eslint-disable-next-line import/no-cycle
import ResourceAction from './resourceAction';

class Resource {
  constructor(base, otherActions) {
    const resourceActions = otherActions || [];
    this.base = base;

    for (let index = 0; index < resourceActions.length; index += 1) {
      const action = resourceActions[index];
      this[action.name] = new ResourceAction(
        this.base, action.name, action.type, action.methods,
      );
    }
  }

  get(id, options) {
    let axiosOptions = options;
    let url = `/${this.base}/`;
    // If only an object is passed, those will be options
    if (typeof id === 'object') {
      axiosOptions = id;

      // Django returns 100 rows by default in single page. Trying to load as max rows as possible
      // with some huge number of limitations.
      if (axiosOptions.params && axiosOptions.params.no_limit === true) {
        delete axiosOptions.params.no_limit;
        axiosOptions.params.all = true;
      }
    // Anything else will be the id of the resource
    } else if (id !== undefined) {
      url += `${id}/`;
    }
    return csiteClient.get(url, axiosOptions);
  }

  delete(id, options) {
    const url = `/${this.base}/${id}/`;
    return csiteClient.delete(url, options);
  }

  post(options) {
    const url = `/${this.base}/`;
    return csiteClient.post(url, options);
  }

  put(id, options) {
    const url = `/${this.base}/${id}/`;
    return csiteClient.put(url, options);
  }

  patch(id, options) {
    const url = `/${this.base}/${id}/`;
    return csiteClient.patch(url, options);
  }
}

export default Resource;
