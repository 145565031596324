export const ONE_SCREEN = 'one_screen';
export const THREE_SCREENS = 'three_screens';
export const FOUR_SCREENS = 'four_screens';
export const EIGHT_SCREENS = 'eight_screens';
export const NINE_SCREENS = 'nine_screens';
export const SIXTEEN_SCREENS = 'sixteen_screens';

export const SCREEN_TYPES_LIST = {
  [ONE_SCREEN]: 'One screen',
  [THREE_SCREENS]: 'Three screens',
  [FOUR_SCREENS]: 'Four screens',
  [EIGHT_SCREENS]: 'Eight screens',
  [NINE_SCREENS]: 'Nine screens',
  [SIXTEEN_SCREENS]: 'Sixteen screens',
};

export default SCREEN_TYPES_LIST;
