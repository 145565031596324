<template>
  <div class="users_management__page">
    <div class="users_management__content">
      <div>
        <v-alert class="popup__box__message"
                 :type="message.type" :value="message.isShow" v-if="message.isShow">
          {{ message.text }}
        </v-alert>
      </div>
      <CameraPreloader v-if="isProjectsLoading" />
      <div v-else>
        <div class="company__list"
             v-for="(company, index) in totalCompanyAdminList" :key="index">
          <AddCompanyMemberPopup
            v-if="isShowAddCompanyMemberPopup"
            :add-to-company="selectedCompany"
            @close="closeAddMemberCompanyPopup"
            @membershipAdded="finishedAddMembership"
          />
          <DeleteCompanyMemberConfirmPopup
            v-if="isShowDeleteMemberConfirmPopup"
            :company-of-member="selectedMemberCompany"
            :member-to-delete="selectedMember"
            @close="closeDeleteMemberConfirmPopup"
            @membershipDeleted="finishedDeleteMembership"
           />
          <AdjustMemberPermissionsPopup
            v-if="isShowAdjustMemberPermissionsPopup"
            :member-to-customize="memberToCustomize"
            @close="closeAdjustMemberPermissionsPopup"
          />
          <div class="company__list__company" >
            <div class="company__list__company__name">
              {{ $t('my_csite_menu.projects_menu.users.my_company') }}: {{ company.name }}
            </div>
            <div class="company__list__company__count">
              ( {{ company.projects_amount }}
              {{ $t('my_csite_menu.tabs.projects').toLowerCase() }} )
            </div>
            <button class="button_add_company_member" v-if="readyToShow"
                    @click="openAddCompanyMemberPopup(company)">
              <i class="mdi mdi-account-plus add-user-icon"></i>
            </button>
          </div>
          <div class="company__list__subtext">
            {{ $t('my_csite_menu.projects_menu.users.reminder')}}
          </div>
          <div class="company__list__members_section">
            <div class="section_wrapper">
              <div class="section_header_row">
                <div class="row">
                  <div class="col-md-2">
                    {{ $t('my_csite_menu.projects_menu.users.last_name')}}
                  </div>
                  <div class="col-md-2">
                    {{ $t('my_csite_menu.projects_menu.users.first_name')}}
                  </div>
                  <div class="col-md-5">
                    {{ $t('my_csite_menu.projects_menu.users.email')}}
                  </div>
                  <div class="col-md-3">
                    {{ $t('my_csite_menu.projects_menu.users.role')}}
                  </div>
                </div>
                <div>
                  <i class="mdi mdi-border-color invisible"></i>
                  <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                </div>
              </div>
              <div v-for="(member, index) in company.members" :key="index">
                <div class="viewer_wrapper">
                  <div class="row text-centering" >
                    <div class="col-md-2 title_case">
                      {{ member.last_name  || '-'}}</div>
                    <div class="col-md-2 title_case">
                      {{ member.first_name || '-'}}</div>
                    <div class="col-md-5">
                      {{ member.email }}</div>
                    <div class="col-md-3">
                      <div class="fixed-height"
                        v-if="editingViewerEmail === member.email">
                        <v-select :items="optionsMembersCompany.list"
                                  item-text="text"
                                  item-value="value"
                                  append-icon=""
                                  :label="selectRoleLabel"
                                  single-line
                                  v-model="optionsMembersCompany.selected"
                                  hide-details
                                  class="no-padding-select"/>
                      </div>
                      <div v-else class="centered-function">
                        <div class="title_case">
                          {{ $t(member.function) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="editing_tools_icons"
                       v-if="member.email !== user.email && readyToShow">
                    <i class="mdi mdi-check"
                       v-if="editingViewerEmail === member.email"
                       @click="saveCMViewerRole(member, company)">
                    </i>
                    <i class="mdi mdi-border-color"
                       v-else
                       @click="toggleCompanyMemberEdit(member, company)">
                    </i>
                    <i class="mdi mdi-timer-sand icon-spacing" v-if="isChangingCompanyRole
                    && editingViewerEmail === member.email"></i>
                    <i class="mdi mdi-window-close icon-spacing" v-else
                       @click="openDeleteMemberConfirmPopup(member, company)">
                    </i>
                  </div>
                  <div class="text-centering make-flex"
                       v-else>
                    <i class="mdi mdi-border-color invisible"></i>
                    <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-for="project in company.projects" :key="project.id">
            <div class="company__list__project"
                 v-if="project.active || showFinishedProjects[index]">
              <div class="company__list__project__wrapper">
                <div class="company__list__project__name">
                  {{ $t('my_csite_menu.projects_menu.users.project')}} {{ project.project }}
                  <span v-if="project.active">
                    - {{ $t('my_csite_menu.projects_menu.list.active')}}
                  </span>
                  <span v-else-if="!project.active && !project.is_archived">
                    - {{ $t('my_csite_menu.projects_menu.list.finished')}}
                  </span>
                  <span v-else-if="!project.active && project.is_archived">
                    - {{ $t('my_csite_menu.projects_menu.list.archived')}}
                  </span>
                </div>
                <button class="button_add_project_member"
                        v-if="activeCAProjectForAddingUser !== project.id && readyToShow"
                  @click="activeCAProjectForAddingUser = activeCAProjectForAddingUser === project.id
                  ? null : project.id">
                  <i class="mdi mdi-account-plus add-user-icon"></i>
                </button>
              </div>
              <div class="wrapper">
                <v-alert class="popup__box__message"
                         :type="message.type" :value="message.isShow" v-if="message.isShow">
                  {{ message.text }}
                </v-alert>
              </div>
              <div class="adding_project_member"
                   v-if="activeCAProjectForAddingUser === project.id">
                <div class="row">
                  <div class="col-md-4 make_flex">
                    <ButtonSubmit :title="$t('my_csite_menu.projects_menu.users.add_to_project')"
                                  :is-show-loading="isCreatingMembership"
                                  @click="addNewProjectMembership(project)"/>
                  </div>
                  <div class="col-md-5 top_padding">
                    <v-text-field class="no-padding-margin-text-field"
                      id="email" single-line dense flat hide-details
                                  v-model="emailToAddProjectMembership.value"
                      :placeholder="$t('components.share_email_popup.form.placeholders.email')"
                    />
                    <label for="email">{{ emailToAddProjectMembership.error }}</label>
                  </div>
                  <div class="col-md-3">
                    <v-select :items="optionsMembersProject.list"
                              item-text="text"
                              item-value="value"
                              append-icon=""
                              :label="selectRoleLabel"
                              single-line
                              v-model="selectedAddRoles[`${project.id}`]"
                              hide-details
                              class="no-padding-select"/>
                  </div>
                </div>
                <div class="close-icon">
                  <i class="mdi mdi-close-circle-outline"
                     @click="closeCAAddProjectUserForm"></i>
                  <i class="mdi mdi-border-color invisible"></i>
                </div>
              </div>
              <div class="section_wrapper company__project" v-if="!project.is_archived">
                <div class="section_header_row">
                  <div class="row">
                    <div class="col-md-2">
                      {{ $t('my_csite_menu.projects_menu.users.last_name')}}
                    </div>
                    <div class="col-md-2">
                      {{ $t('my_csite_menu.projects_menu.users.first_name')}}
                    </div>
                    <div class="col-md-5">
                      {{ $t('my_csite_menu.projects_menu.users.email')}}
                    </div>
                    <div class="col-md-3">
                      {{ $t('my_csite_menu.projects_menu.users.role')}}
                    </div>
                  </div>
                  <div>
                    <i class="mdi mdi-border-color invisible"></i>
                    <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                  </div>
                </div>
                <div v-for="(viewer, index) in project.viewers" :key="index">
                  <div class="viewer_wrapper">
                    <div class="row text-centering">
                      <div class="col-md-2 title_case">
                        {{ viewer.last_name  || '-'}}</div>
                      <div class="col-md-2 title_case">
                        {{ viewer.first_name || '-'}}</div>
                      <div class="col-md-5">
                        {{ viewer.email }}</div>
                      <div class="col-md-3">
                        <div class="fixed-height"
                          v-if="editingViewerEmail === viewer.email
                          && editingProjectId === project.id">
                          <v-select :items="optionsMembersProject.list"
                                    :ref="`viewerSelect-${index}`"
                                    item-text="text"
                                    item-value="value"
                                    append-icon=""
                                    :label="selectRoleLabel"
                                    single-line
                                    v-model="newRoleSelected[`${project.id}`]"
                                    hide-details
                                    class="no-padding-select"/>
                        </div>
                        <div v-else class="centered-function">
                          <div class="title_case">
                            {{ $t(viewer.function) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="editing_tools_icons"
                         v-if="(`${viewer.function}` === 'project admin'
                         || `${viewer.function}` === 'project member')
                         && viewer.email !== user.email && readyToShow">
                      <i class="mdi mdi-check"
                         v-if="editingViewerEmail === viewer.email
                         && editingProjectId === project.id"
                         @click="savePMViewerRole(viewer, project)"></i>
                      <i class="mdi mdi-border-color"
                         v-else
                         @click="toggleProjectMemberEdit(viewer, project)"></i>
                      <i class="mdi mdi-timer-sand icon-spacing" v-if="isChangingProjectRole
                    && editingViewerEmail === viewer.email && editingProjectId === project.id"></i>
                      <i class="mdi mdi-window-close icon-spacing" v-else
                         @click="deleteProjectMembership(project.id, viewer)">
                      </i>
                    </div>
                    <div class="text-centering make-flex"
                         v-else>
                      <i class="mdi mdi-border-color invisible"></i>
                      <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="finished_projects_button">
            <button v-if="checkForFinishedProjects(company.projects)"
              @click="toggleFinishedProjects(index)" class="finished_projects_button_border">
              {{ showFinishedProjects[index]
              ? $t('my_csite_menu.projects_menu.list.hide_finished')
              : $t('my_csite_menu.projects_menu.list.show_finished') }}
            </button>
          </div>

        </div>
        <div class="projects__list"
             v-for="(projectA, index) in totalProjectAdminList" :key="`project-${index}`">
          <div class="projects__list__project" v-if="projectA.active
          || visibilityOfFinishedProjects">
            <div class="projects__list__project__wrapper">
              <div class="projects__list__project__name">
                {{ $t('my_csite_menu.projects_menu.users.my_project') }}: {{ projectA.project }}
                ({{ projectA.company }})
                <span v-if="projectA.active">
                    - {{ $t('my_csite_menu.projects_menu.list.active')}}
                  </span>
                <span v-else-if="!projectA.active && !projectA.is_archived">
                    - {{ $t('my_csite_menu.projects_menu.list.finished')}}
                  </span>
                <span v-else-if="!projectA.active && projectA.is_archived">
                    - {{ $t('my_csite_menu.projects_menu.list.archived')}}
                  </span>
              </div>
              <button class="button_add_project_member"
                      v-if="activePAProjectForAddingUser !== `project-${index}` && readyToShow"
                      @click="activePAProjectForAddingUser = activePAProjectForAddingUser
                      === `project-${index}`? null : `project-${index}`">
                <i class="mdi mdi-account-plus add-user-icon"></i>
              </button>
            </div>
            <div class="adding_project_member extra"
                 v-if="activePAProjectForAddingUser === `project-${index}`
                 && !projectA.is_archived">
              <div class="row">
                <div class="col-md-4 make_flex">
                  <ButtonSubmit :title="$t('my_csite_menu.projects_menu.users.add_to_project')"
                                :is-show-loading="isCreatingMembership"
                                @click="addNewProjectMembership(projectA)"/>
                </div>
                <div class="col-md-5 top_padding">
                  <v-text-field class="no-padding-margin-text-field"
                    id="email" single-line dense flat hide-details
                    v-model="emailToAddProjectMembership.value"
                    :placeholder="$t('components.share_email_popup.form.placeholders.email')"
                    />
                  <label for="email">{{ emailToAddProjectMembership.error }}</label>
                </div>
                <div class="col-md-3">
                  <v-select :items="optionsMembersProject.list"
                            item-text="text"
                            item-value="value"
                            append-icon=""
                            :label="selectRoleLabel"
                            single-line
                            v-model="selectedAddRoles[`${projectA.id}`]"
                            hide-details
                            class="no-padding-select"/>
                </div>
              </div>
              <div class="close-icon">
                <i class="mdi mdi-close-circle-outline"
                      @click="closePAAddProjectUserForm"></i>
                <i class="mdi mdi-border-color invisible"></i>
              </div>
            </div>
            <div class="section_wrapper project__project" v-if="!projectA.is_archived">
              <div class="section_header_row">
                <div class="row">
                  <div class="col-md-2">
                    {{ $t('my_csite_menu.projects_menu.users.last_name')}}
                  </div>
                  <div class="col-md-2">
                    {{ $t('my_csite_menu.projects_menu.users.first_name')}}
                  </div>
                  <div class="col-md-5">
                    {{ $t('my_csite_menu.projects_menu.users.email')}}
                  </div>
                  <div class="col-md-3">
                    {{ $t('my_csite_menu.projects_menu.users.role')}}
                  </div>
                </div>
                <div>
                  <i class="mdi mdi-border-color invisible"></i>
                  <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                </div>
                </div>
                <div v-for="(viewer, vIndex) in projectA.viewers" :key="vIndex">
                  <div class="viewer_wrapper">
                    <div class="row text-centering">
                      <div class="col-md-2 title_case ">
                        {{ viewer.last_name  || '-'}}</div>
                      <div class="col-md-2 title_case">
                        {{ viewer.first_name || '-'}}</div>
                      <div class="col-md-5">
                        {{ viewer.email }}</div>
                      <div class="col-md-3">
                        <div class="fixed-height"
                          v-if="editingViewerEmail === viewer.email
                          && editingProjectId === projectA.id">
                          <v-select :items="optionsMembersProject.list"
                                    :ref="`viewerSelect-${vIndex}`"
                                    item-text="text"
                                    item-value="value"
                                    append-icon=""
                                    :label="selectRoleLabel"
                                    single-line
                                    v-model="newRoleSelected[`${projectA.id}`]"
                                    hide-details
                                    class="no-padding-select"/>
                        </div>
                        <div v-else class="centered-function">
                          <div class="title_case">
                            {{ $t(viewer.function) }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="editing_tools_icons"
                         v-if="(`${viewer.function}` === 'project admin'
                         || `${viewer.function}` === 'project member')
                         && viewer.email !== user.email && readyToShow">
                      <i class="mdi mdi-check"
                         v-if="editingViewerEmail === viewer.email
                         && editingProjectId === projectA.id"
                         @click="savePMViewerRole(viewer, projectA)"></i>
                      <i class="mdi mdi-border-color"
                         v-else
                         @click="toggleProjectMemberEdit(viewer, projectA)"></i>
                      <i class="mdi mdi-timer-sand icon-spacing" v-if="isChangingProjectRole
                    && editingViewerEmail === viewer.email && editingProjectId === projectA.id"></i>
                        <i class="mdi mdi-window-close icon-spacing" v-else
                           @click="deleteProjectMembership(projectA.id, viewer)">
                        </i>
                    </div>
                    <div class="text-centering make-flex"
                         v-else>
                      <i class="mdi mdi-border-color invisible"></i>
                      <i class="mdi mdi-close-circle invisible icon-spacing"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="finished_projects_button">
          <button v-if="hasFinishedProjects"
            @click="visibilityOfFinishedProjects = !visibilityOfFinishedProjects"
                  class="finished_projects_button_border">
            {{ visibilityOfFinishedProjects
            ? $t('my_csite_menu.projects_menu.list.hide_finished')
            : $t('my_csite_menu.projects_menu.list.show_finished') }}
          </button>
        </div>
        <div class="customize__member" v-if="permissionsAllowToChange">
          <div class="customize__member__title">
              Adjust a member's permissions
          </div>
          <div class="row" style="margin-left: 4vw;">
            <div class="col-md-8 top_padding">
              <v-text-field class="no-padding-margin-text-field"
                  id="email" single-line dense flat hide-details
                  v-model="emailToCustomize.value"
                  :placeholder="$t('components.share_email_popup.form.placeholders.email')"
              />
              <label for="email">{{ emailToCustomize.error }}</label>
            </div>
            <div class="col-md-4 make_flex">
              <ButtonSubmit title="Adjust"
                            :is-show-loading="isLoading"
                            @click="customizeMember"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CameraPreloader from '@/components/v3/Helpers/CameraPreloader';
import ButtonSubmit from '@/pages/v3/Auth/Helpers/ButtonSubmit';
import AddCompanyMemberPopup from '@/components/v3/Projects/AddCompanyMemberPopup';
import { forEach, some } from 'lodash';
import DeleteCompanyMemberConfirmPopup
  from '@/components/v3/Projects/DeleteCompanyMemberConfirmPopup';
import AdjustMemberPermissionsPopup from '@/components/v3/Projects/AdjustMemberPermissionsPopup';

export default {
  name: 'ProjectsTest',
  components: {
    AdjustMemberPermissionsPopup,
    DeleteCompanyMemberConfirmPopup,
    AddCompanyMemberPopup,
    ButtonSubmit,
    CameraPreloader,
  },
  data() {
    return {
      readyToShow: false,
      permissionsAllowToChange: false,
      isCreatingMembership: false,
      isChangingCompanyRole: false,
      isChangingProjectRole: false,
      selectedCompany: null,
      selectedMemberCompany: null,
      selectedMember: null,
      newRoleSelected: {},
      selectedAddRoles: {},
      isShowAddCompanyMemberPopup: false,
      isShowDeleteMemberConfirmPopup: false,
      isShowAdjustMemberPermissionsPopup: false,
      isLoading: false,
      totalCompanyAdminList: [],
      totalProjectAdminList: [],
      editingViewerEmail: null,
      editingProjectId: null,
      editingCompanyId: null,
      selectRoleLabel: this.$t('my_csite_menu.projects_menu.users.select_role'),
      optionsMembersCompany: {
        list: [
          {
            value: 'company admin',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.company_admin')),
          },
          {
            value: 'company member',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.company_member')),
          },
        ],
        selected: null,
      },
      optionsMembersProject: {
        list: [
          {
            value: 'project admin',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.project_admin')),
          },
          {
            value: 'project member',
            text: this.capitalize(this.$t('my_csite_menu.projects_menu.users.project_member')),
          },
        ],
        selected: null,
      },
      emailToAddProjectMembership: {
        value: null,
        error: null,
      },
      emailToCustomize: {
        value: null,
        error: null,
      },
      memberToCustomize: null,
      activeCAProjectForAddingUser: null,
      activePAProjectForAddingUser: null,
      userToChange: null,
      message: {
        type: null,
        isShow: false,
        text: '',
      },
      showFinishedProjects: {},
      visibilityOfFinishedProjects: false,
    };
  },
  computed: {
    hasFinishedProjects() {
      return some(this.totalProjectAdminList, (project) => project.active === false);
    },
    isProjectsLoading() {
      return this.isLoading;
    },
    ...mapState({
      user: (state) => state.activeUser,
    }),
  },
  async mounted() {
    await this.fetchProjectUsers();
    await this.fetchAllGroups();
  },
  methods: {
    checkForFinishedProjects(projects) {
      return some(projects, (project) => project.active === false);
    },
    toggleFinishedProjects(index) {
      this.$set(this.showFinishedProjects, index, !this.showFinishedProjects[index]);
    },
    capitalize(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async fetchProjectUsers() {
      this.isLoading = true;
      await this.getProjectsUsers({ id: this.user.id }).then((response) => {
        const sortedProjectData = response.data.project_admin.map((projectObj) => {
          const projectClone = JSON.parse(JSON.stringify(projectObj));
          projectClone.viewers.sort(
            (a, b) => a.function.localeCompare(b.function),
          );
          return projectClone;
        });
        const sortedCompanyData = response.data.company_admin.map((companyObj) => {
          const companyClone = JSON.parse(JSON.stringify(companyObj));
          companyClone.members.sort(
            (a, b) => a.function.localeCompare(b.function),
          );
          companyClone.projects.sort((a, b) => b.active - a.active);
          forEach(companyClone.projects, (project) =>
            project.viewers.sort((a, b) =>
              a.function.localeCompare(b.function)));
          return companyClone;
        });
        sortedProjectData.sort((a, b) => b.active - a.active);
        this.totalProjectAdminList = sortedProjectData;
        this.totalCompanyAdminList = sortedCompanyData;
      }).finally(() => {
        this.isLoading = false;
      });
    },
    closeAddMemberCompanyPopup() {
      this.selectedCompany = null;
      this.isShowAddCompanyMemberPopup = false;
    },
    finishedAddMembership() {
      this.selectedCompany = null;
      this.isShowAddCompanyMemberPopup = false;
      this.fetchProjectUsers();
    },
    finishedDeleteMembership() {
      this.selectedMember = null;
      this.selectedMemberCompany = null;
      this.isShowDeleteMemberConfirmPopup = false;
      this.fetchProjectUsers();
    },
    closeDeleteMemberConfirmPopup() {
      this.selectedMember = null;
      this.selectedMemberCompany = null;
      this.isShowDeleteMemberConfirmPopup = false;
    },
    closeAdjustMemberPermissionsPopup() {
      this.isShowAdjustMemberPermissionsPopup = false;
    },
    openAddCompanyMemberPopup(company) {
      this.selectedCompany = company;
      this.isShowAddCompanyMemberPopup = true;
    },
    resetCMEditingState() {
      this.editingViewerEmail = null;
      this.editingCompanyId = null;
      this.optionsMembersCompany.selected = null;
      this.userToChange = null;
      this.isChangingCompanyRole = false;
    },
    async saveCMViewerRole(viewer, company) {
      this.isChangingCompanyRole = true;
      if (!this.optionsMembersCompany.selected
        || this.userToChange.function === this.optionsMembersCompany.selected) {
        this.resetCMEditingState();
        return;
      }
      const companyMembership = await this.fetchCompanyMemberships(
        { member: this.userToChange.id, company: company.id },
      );
      const [membership] = companyMembership.dataList;
      const isCompanyAdmin = this.optionsMembersCompany.selected.includes('admin');
      await this.updateCompanymemberships({
        id: membership,
        data: {
          member: this.userToChange.id,
          company: company.id,
          roles: [],
          company_admin: isCompanyAdmin,
        },
      }).then(() => {
        this.fetchProjectUsers();
      }).finally(() => {
        this.resetCMEditingState();
      });
    },
    toggleCompanyMemberEdit(viewer, company) {
      this.userToChange = viewer;
      this.editingViewerEmail = this.editingViewerEmail === viewer.email ? null : viewer.email;
      this.editingCompanyId = this.editingCompanyId === company.id ? null : company.id;
    },
    openDeleteMemberConfirmPopup(member, company) {
      this.selectedMember = member;
      this.selectedMemberCompany = company;
      this.isShowDeleteMemberConfirmPopup = true;
    },
    async addNewProjectMembership(project) {
      this.isCreatingMembership = true;
      if (Object.keys(this.selectedAddRoles).length === 0) {
        this.message.isShow = true;
        this.message.type = 'error';
        this.message.text = this.$t('add_membership_popup.select_role');
        setTimeout(() => {
          this.message.isShow = false;
          this.isCreatingMembership = false;
        }, 3000);
        return;
      }
      const rolesKey = Object.keys(this.selectedAddRoles)[0];
      if (rolesKey.toString() === project.id.toString()) {
        const isProjectAdmin = Object.values(this.selectedAddRoles)[0].includes('admin');
        const response = await this.fetchUsers(
          { email: this.emailToAddProjectMembership.value, limit: 1 },
        );
        if (Object.keys(response.normData).length === 0) {
          await this.sendInvitation(this.emailToAddProjectMembership.value, project.id);
          this.message.type = 'info';
          this.message.text = this.$t('my_csite_menu.projects_menu.users.info_message');
          this.message.isShow = true;
          setTimeout(() => {
            this.message.isShow = false;
            this.fetchProjectUsers();
          }, 5000);

          return;
        }
        const userId = Object.keys(response.normData)[0];
        if (userId) {
          const payload = {
            member: userId,
            project: project.id,
            roles: [],
            project_admin: isProjectAdmin,
          };
          await this.createProjectmemberships(payload);
          await this.fetchProjectUsers();
        }
      }
      this.selectedAddRoles = {};
      this.emailToAddProjectMembership.value = null;
      this.isCreatingMembership = false;
      this.closeCAAddProjectUserForm();
      this.closePAAddProjectUserForm();
    },
    closeCAAddProjectUserForm() {
      this.activeCAProjectForAddingUser = null;
    },
    resetCPMEditingState() {
      this.editingViewerEmail = null;
      this.editingProjectId = null;
      this.newRoleSelected = {};
      this.userToChange = null;
      this.isChangingProjectRole = false;
    },
    async savePMViewerRole(viewer, project) {
      this.isChangingProjectRole = true;
      const [selectedCPMRole] = Object.values(this.newRoleSelected);
      if (this.userToChange.function === selectedCPMRole
        || selectedCPMRole === undefined) {
        this.resetCPMEditingState();
        return;
      }
      const projectMembership = await this.fetchProjectMemberships(
        { member: this.userToChange.id, project: project.id },
      );
      const [membership] = projectMembership.dataList;
      const isProjectAdmin = selectedCPMRole.includes('admin');
      await this.updateProjectMemberships({
        id: membership,
        data: {
          member: this.userToChange.id,
          project: project.id,
          roles: [],
          project_admin: isProjectAdmin,
        },
      }).then(() => {
        this.fetchProjectUsers();
      }).finally(() => {
        this.resetCPMEditingState();
      });
    },
    toggleProjectMemberEdit(viewer, project) {
      this.userToChange = viewer;
      this.editingViewerEmail = this.editingViewerEmail === viewer.email ? null : viewer.email;
      this.editingProjectId = this.editingProjectId === project.id ? null : project.id;
    },
    closePAAddProjectUserForm() {
      this.activePAProjectForAddingUser = null;
    },
    async deleteProjectMembership(projectId, viewer) {
      const response = await this.fetchProjectMemberships(
        { member: viewer.id, project: projectId },
      );
      const [projectMembershipId] = response.dataList;
      await this.destroyProjectmemberships(projectMembershipId).finally(() => {
        this.fetchProjectUsers();
      });
    },
    async customizeMember() {
      // only (project/company) members so first search
      try {
        const response = await this.fetchUsers(
          { email: this.emailToCustomize.value, limit: 1 },
        );
        const key = response.dataList[0];
        const memberObject = response.normData[key];

        if (memberObject) {
          this.memberToCustomize = memberObject;
          this.isShowAdjustMemberPermissionsPopup = true;
        } else {
          return;
        }
      } catch (error) {
        console.error('There was an error fetching the user data', error);
      }
      this.emailToCustomize.value = null;
    },
    async sendInvitation(emailAddress, projectId) {
      const payload = {
        id: projectId,
        params: {
          email: emailAddress,
        },
      };
      await this.postProjectInvite(payload);
    },
    ...mapActions({
      fetchProjectMemberships: 'listProjectmemberships',
      fetchCompanyMemberships: 'listCompanymemberships',
      getProjectsUsers: 'getProjectsUsers',
      updateProjectMemberships: 'updateProjectmemberships',
      updateCompanymemberships: 'updateCompanymemberships',
      destroyProjectmemberships: 'destroyProjectmemberships',
      createProjectmemberships: 'createProjectmemberships',
      postProjectInvite: 'postProjectInvite',
      fetchUsers: 'listUsers',
      fetchAllGroups: 'fetchAllGroups',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/sass/v3/variables';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mdi-timer-sand {
  display: inline-block;
  animation: spin 2s infinite linear;
  margin: 0;
  padding: 0;
}

i {
  cursor: pointer;
}
.make_flex {
  display: flex;
}
.top_padding {
  padding-top: 2vh;
}
.users_management__page {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 55.3vw;
  max-width: 100%;
  margin: 0 auto;

  @media (orientation: portrait) {
    width: 100%;
  }

  .users_management__content {
    flex: 1;
    display: inline-block;
    ::v-deep .no-padding-margin-text-field {
      margin: 0 !important;
      padding: 0 !important;
    }
    ::v-deep .no-padding-select {
      padding: 0 !important;
      margin: 0 !important;
    }
    .text-centering {
      display: flex;
      justify-content: center;
      align-items: center;
      .fixed-height {
        height: 3.8vh;
      }
    }
    .viewer_wrapper {
      height: 4vh;
      display: flex;
      .editing_tools_icons {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .company__project {
      margin-left: 2vw;
    }
    .project__project {
      margin-left: 4vw;
      padding-right: 2vw;
    }
    .icon-spacing {
      margin-left: 0.4vw;
      padding-bottom: 0.5vh;
    }
    .section_wrapper {
      margin-top: 3vh;
      margin-bottom: 3vh;
      .title_case:first-letter {
        text-transform: uppercase;
      }
      .section_header_row {
        border-bottom: 1px solid $default-blue-dark-color;
        margin-bottom: 2vh;
        display: flex;
        padding-bottom: 1vh;
      }
      .center_content {
        text-align: center;
      }
      .left_content {
        text-align: left;
      }
      .right_content {
        text-align: right;
      }
    }
    .add-user-icon {
      margin: 0;
    }
    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1vw;
    }
    .invisible {
      visibility: hidden;
    }
    .adding_project_member {
      display: flex;
      margin-top: 3vh;
      margin-bottom: 4vh;
      margin-left: 2vw;
      &.extra {
        margin-left: 4vw;
        padding-right: 2vw;
      }
    }
    .button_add_project_member {
      margin-left: 2vw;
      padding-top: 0.2vh;
    }
    .customize__member {
      margin-top: 2vh;
      .customize__member__title {
        margin-bottom: 1vh;
        font-size: 1.4em;
        font-weight: 600;
      }
    }
    .finished_projects_button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2vh;
      margin-bottom: 4vh;
      .finished_projects_button_border {
        padding: 1vh;
        border: 1px solid $default-blue-dark-color;
      }
    }
    .projects__list {
      margin-top: 2vh;
      .projects__list__project {
        & .button-submit {
          margin-bottom: 0;
          padding: 0;
        }
        & .v-text-field {
          margin-top: 0;
        }
        ::v-deep .base-button {
          margin: 0;
          min-width: 5vw;
          padding: 1vh;
          height: inherit;
        }
        .projects__list__project__wrapper {
          display: flex;
          .projects__list__project__name {
            margin-bottom: 1vh;
            font-size: 1.4em;
            font-weight: 600;
          }
        }
      }
    }
    .company__list {
      margin-top: 2vh;
      .company__list__company {
        display: flex;
        & .button-submit {
          margin-bottom: 0;
          padding: 0;
        }
        & .v-text-field {
         margin-top: 0;
        }
        ::v-deep .base-button {
          margin: 0;
          min-width: 5vw;
          padding: 1vh;
          height: inherit;
        }
        .company__list__company__name {
          margin-bottom: 1vh;
          font-size: 1.4em;
          font-weight: 600;
        }
        .company__list__company__count {
          margin-left: 0.5vw;
          padding-top: 0.7vh;
        }
        .button_add_company_member {
          margin-left: 2vw;
        }
      }
      .company__list__subtext {
        margin-top: 1vh;
        margin-bottom: 3vh;
        margin-left: 1vw;
      }
      .company__list__members_section {
        margin-left: 1vw;
        border: 1px solid $default-blue-dark-color;
        width: 100%;
        padding-left: 3vw;
        padding-right: 3vw;
      }
      .company__list__project {
        padding-right: 2vw;
        margin-left: 2vw;
        margin-top: 2vh;
        & .button-submit {
          margin-bottom: 0;
          padding: 0;
        }
        & .v-text-field {
          margin-top: 0;
        }
        .centered-function {
          height: 4vh;
          display:flex;
          align-items: center;
        }
        ::v-deep .base-button {
          margin: 0;
          min-width: 5vw;
          padding: 1vh;
          height: inherit;
        }
        .company__list__project__wrapper {
          display: flex;
          margin-top: 2vh;

          .company__list__project__name {
            font-size: 1.2em;
            font-weight: 500;
          }
        }
        .wrapper {
          margin-top: 1vh;
          margin-bottom: 1vh;
        }
      }
    }
  }
}

</style>
