/* Range types */
import galleryIcon from '@/assets/images/icons/gallery.png';
import sliderIcon from '@/assets/images/icons/slider.png';

export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const START_TO_PRESENT = 'stp';

export const RANGE_TYPES = {
  day: DAY,
  week: WEEK,
  month: MONTH,
  year: YEAR,
  stp: START_TO_PRESENT,
};

/* IMAGE STATUSES */
export const DOWNLOAD_IMAGE_COMPLETE = 'COMPLETE';
export const DOWNLOAD_IMAGE_ERROR = 'ERROR';
export const DOWNLOAD_IMAGE_PENDING = 'PENDING';

export const IMAGES_STATUSES = {
  complete: DOWNLOAD_IMAGE_COMPLETE,
  error: DOWNLOAD_IMAGE_ERROR,
  pending: DOWNLOAD_IMAGE_PENDING,
};

/* CAMERA MODES */
export const RANGE = 'RANGE';
export const EXACT_TIME = 'EXACT_TIME';

export const CAMERA_MODES = {
  range: RANGE,
  exact_time: EXACT_TIME,
};

/* CAMERA MODES MENU */
export const CAMERA_MODES_MENU = [
  {
    title: 'Exact time',
    icon: galleryIcon,
    mode: CAMERA_MODES.exact_time,
    active: false,
  },
  {
    title: 'Ranges',
    icon: sliderIcon,
    mode: CAMERA_MODES.range,
    active: false,
  },
];

export const DIRECTIONS = {
  up: 'UP',
  down: 'DOWN',
};

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DEFAULT_TIME_FORMAT = 'HH:mm';
export const SHUFFLE_CAMERAS_UPDATE_INTERVAL = 600000; // every 10m
